import { useEffect } from 'react'
import { initFlowbite } from 'flowbite'
import IconRoutera from 'src/components/atoms/icons/icons'
import duplicate from '../../../assets/images/duplicate.svg'
import { ability } from 'src/utils/permissionsCASL'

export interface Card {
  icon?: any
  title?: string
  description?: string
  refreshIcon?: boolean
  trashIcon?: boolean
  duplicateIcon?: boolean
  children: React.ReactNode
  onDelete?: (index: number) => void
  indexGroup?: number
  onRefreshFields?: () => void
  onDuplicate?: (index: number) => void
  iconRoutera?: boolean
  iconName?: string | null
}

const Card: React.FC<Card> = ({
  icon,
  iconRoutera,
  iconName,
  title,
  description,
  refreshIcon,
  duplicateIcon,
  trashIcon,
  children,
  onDelete,
  indexGroup,
  onRefreshFields,
  onDuplicate,
}) => {
  useEffect(() => {
    initFlowbite()
  }, [])

  const handleDelete = () => {
    if (onDelete && indexGroup !== undefined) {
      onDelete(indexGroup)
    }
  }

  const handleRefreshFields = async () => {
    if (onRefreshFields) {
      await onRefreshFields()
    }
  }

  const handleDuplicate = () => {
    if (onDuplicate && indexGroup !== undefined) {
      onDuplicate(indexGroup)
    }
  }

  return (
    <div className='w-[100%] border border-gray-200 rounded-lg shadow-sm mb-4'>
      <div className='bg-gray-50 flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 rounded-t-lg px-[1.5rem] py-[10px] items-center'>
        <div className='flex justify-start items-center w-[50%]'>
          {icon && (
            <div className='h-10 w-10 bg-white border-solid border-gray-200 border-[1px] shadow-inner p-[2px] flex justify-center items-center'>
              <img src={icon} alt='' />
            </div>
          )}
          {iconRoutera && iconName && (
            <div className='h-10 w-10 bg-white border-solid border-gray-200 border-[1px] shadow-inner p-[2px] flex justify-center items-center'>
              <IconRoutera
                iconName={iconName}
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            </div>
          )}
          <div className={`${(icon || iconRoutera) && 'ml-6'}`}>{title}</div>
        </div>
        <div className='flex justify-end items-center w-[50%]'>
          <div className='ml-6 mr-2 text-gray-900'>{description}</div>
          <div className='flex justify-end items-center'>
            {refreshIcon && (
              <button onClick={() => handleRefreshFields()}>
                <IconRoutera
                  iconName='ArrowPathIcon'
                  className='h-5 w-5 text-routera-800'
                ></IconRoutera>
              </button>
            )}
            {duplicateIcon && (
              <button
                onClick={() => handleDuplicate()}
                className={`${
                  !ability.can('update', 'router.sla') && 'cursor-not-allowed'
                }`}
              >
                <div className={`h-5 w-5 text-routera-800 mr-4`}>
                  <img src={duplicate} alt='' />
                </div>
              </button>
            )}
            {trashIcon && (
              <button
                onClick={() => handleDelete()}
                className={`${
                  !ability.can('update', 'router.sla') && 'cursor-not-allowed'
                }`}
              >
                <IconRoutera
                  iconName='TrashIcon'
                  className='h-5 w-5 text-routera-800'
                ></IconRoutera>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className='py-[15px] px-[30px] bg-white'>{children}</div>
    </div>
  )
}

export default Card
