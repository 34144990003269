import { UpdateIntegrationDto } from './../types/integration'
import { HttpService } from './base.service'

export enum INTEGRATION_QUERY_KEYS {
  GET_PROPERTY_MAPPINGS = 'getPropertyMapping',
}

class IntegrationsService extends HttpService {
  private readonly prefix = 'integrations'
  getIntegrations = (query: Record<string, any> = {}) =>
    this.get(`${this.prefix}/`, query)
  updateIntegration = async ({ id, dataToUpdate }: UpdateIntegrationDto) =>
    this.patch(`${this.prefix}/${id}`, dataToUpdate)
  getPropertyMappings = async (id: string) =>
    this.get(`${this.prefix}/property-mappings/${id}`)
  getCRMUsers = async (id: string) => this.get(`${this.prefix}/crm-users/${id}`)
  disconnectHubSpot = (id: string) => this.delete(`${this.prefix}/${id}`)
  getLeadStatusTypes = (id: string) =>
    this.get(
      `${this.prefix}/${id}/get-field-labels?objectType=contacts&propertyName=hs_lead_status`,
    )
  integrationCalendar = (data: any) =>
    this.post(`${this.prefix}/add-calendar`, data)
  deleteCalendar = () => this.delete(`${this.prefix}/delete-calendar`)
  getFieldsByObjectType = (objectType: string) =>
    this.get(`${this.prefix}/get-field-properties?objectType=${objectType}`)
  getOwnerFieldsByObjectType = (objectType: string) =>
    this.get(
      `${this.prefix}/get-field-properties-owner-type?objectType=${objectType}`,
    )
  getPipelines = (id: string) => this.get(`${this.prefix}/get-pipelines/${id}`)
}

export const integrationsService = new IntegrationsService()
