import { Toggle } from 'src/components/atoms/Toggle/Toggle.atom'
import SelectableTable from 'src/components/organisms/tables/SelectableTable.Organism'
import { useEffect, useState } from 'react'
import {
  PermissionsType,
  RolesPermissions,
  PermissionType,
  ColumnName,
  PERMISSIONS_SUBJECTS,
} from 'src/types/roles'
import { useAppSelector } from 'src/store'
import { listAllPermissions } from 'src/container/roles/roles.selector'

interface IProps {
  dataRole: PermissionsType[] | undefined
  handleStatusChange: (data: any, value: boolean) => void
  dataSettings: RolesPermissions[]
  setDataSettings: (val: RolesPermissions[]) => void
  freePlan: boolean
}

const SettingsRole: React.FC<IProps> = (props) => {
  const {
    dataRole,
    handleStatusChange,
    dataSettings,
    setDataSettings,
    freePlan,
  } = props
  const [subjectList, setSubjectList] = useState<ColumnName[]>([])
  const permissionsData = useAppSelector(listAllPermissions)
  const [permissionsList, setPermissionsList] = useState<PermissionsType[]>([])

  useEffect(() => {
    if (permissionsData) {
      const subjects = permissionsData
        .filter((item: PermissionsType) =>
          item.subject.includes(PERMISSIONS_SUBJECTS.SETTING + '.'),
        )
        .map(function (permission) {
          return permission.subject
        })

      const subjectNames: ColumnName[] = Array.from(new Set(subjects)).map(
        function (subject) {
          const permission = permissionsData.find(
            (item) => item.subject === subject && item.action !== 'access',
          )

          return {
            subject: subject,
            name: permission ? permission.name : '',
            hasRead: permissionsData.find(
              (element) =>
                element.subject == subject && element.action == 'read',
            ),
            hasCreate: permissionsData.find(
              (element) =>
                element.subject == subject && element.action == 'create',
            ),
            hasUpdate: permissionsData.find(
              (element) =>
                element.subject == subject && element.action == 'update',
            ),
            hasDelete: permissionsData.find(
              (element) =>
                element.subject == subject && element.action == 'delete',
            ),
          }
        },
      )
      setSubjectList(subjectNames)
      setPermissionsList(permissionsData)
    }
  }, [permissionsData])

  useEffect(() => {
    const permissionsSettings: PermissionType[] = []
    for (let index = 0; index < subjectList.length; index++) {
      permissionsSettings.push({
        subject: subjectList[index].subject,
        name: subjectList[index].name,
        read: false,
        create: false,
        update: false,
        delete: false,
        hasRead: subjectList[index].hasRead,
        hasUpdate: subjectList[index].hasUpdate,
        hasCreate: subjectList[index].hasCreate,
        hasDelete: subjectList[index].hasDelete,
      })
    }
    setDataSettings(permissionsSettings)
    if (dataRole && dataRole.length > 0 && subjectList) {
      const dataPermissionsSettings: PermissionsType[] = dataRole.filter(
        (item: PermissionsType) =>
          item.subject.includes(PERMISSIONS_SUBJECTS.SETTING),
      )
      if (dataPermissionsSettings.length > 0) {
        dataPermissionsSettings.forEach((element: PermissionsType) => {
          const existingPermissionIndex: number = permissionsSettings.findIndex(
            (item: PermissionType) => item.subject === element.subject,
          )
          if (existingPermissionIndex >= 0) {
            switch (element.action) {
              case 'read':
                permissionsSettings[existingPermissionIndex].read = true
                break
              case 'create':
                permissionsSettings[existingPermissionIndex].create = true
                break
              case 'update':
                permissionsSettings[existingPermissionIndex].update = true
                break
              case 'delete':
                permissionsSettings[existingPermissionIndex].delete = true
                break
            }
          }
        })
      }
      setDataSettings(permissionsSettings)
    }
  }, [dataRole])

  const columnsRolesSettings = [
    {
      label: 'Name',
      render: ({ name }: any) => {
        return <div className='capitalize'>{name}</div>
      },
    },
    {
      label: 'Permission to Read',
      render: ({ read, subject, hasRead }: any) => {
        return hasRead ? (
          <Toggle
            initialValue={read}
            data={{ actionName: 'read', subject: subject }}
            handleChange={handleStatusChange}
            disabled={freePlan && subject === 'setting.capacities'}
          />
        ) : (
          <span>-</span>
        )
      },
    },
    {
      label: 'Permission to create',
      render: ({ create, subject, hasCreate }: any) => {
        return hasCreate ? (
          <Toggle
            initialValue={create}
            data={{ actionName: 'create', subject: subject }}
            handleChange={handleStatusChange}
            disabled={freePlan && subject === 'setting.capacities'}
          />
        ) : (
          <span>-</span>
        )
      },
    },
    {
      label: 'Permission to edit',
      render: ({ update, subject, hasUpdate }: any) => {
        return hasUpdate ? (
          <Toggle
            initialValue={update}
            data={{ actionName: 'update', subject: subject }}
            handleChange={handleStatusChange}
            disabled={freePlan && subject === 'setting.capacities'}
          />
        ) : (
          <span>-</span>
        )
      },
    },
    {
      label: 'Permission to delete',
      render: ({ delete: deleteR, subject, hasDelete }: any) => {
        return hasDelete ? (
          <Toggle
            initialValue={deleteR}
            data={{ actionName: 'delete', subject: subject }}
            handleChange={handleStatusChange}
            disabled={freePlan && subject === 'setting.capacities'}
          />
        ) : (
          <span>-</span>
        )
      },
    },
  ]

  return (
    <div className='px-5 py-7'>
      <div className='flex flex-col'>
        <span className='text-lg leading-[27px] text-gray-700 font-medium'>
          Settings Permissions
        </span>
        <span className='text-sm leading-[21px] font-normal text-gray-700 mt-2'>
          Review your Role is setup correctly and publish it live
        </span>
      </div>
      <SelectableTable
        columns={columnsRolesSettings}
        rows={dataSettings}
        showSelectionType={false}
        showHeaderMeta={false}
        borderTable={true}
        className='p-0'
      />
    </div>
  )
}

export default SettingsRole
