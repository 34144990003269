import { createSlice } from '@reduxjs/toolkit'

import { IPLAN_STATE } from 'src/types/plan'
import { createReducerBuilder } from '../../utils/actions'
import { getPlansAction } from './plan.actions'

const initialState: IPLAN_STATE = {
  plans: {
    monthly: [],
    yearly: [],
  },
}

const reducerBuilder = createReducerBuilder<IPLAN_STATE>()

const getPlans = reducerBuilder(getPlansAction, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.plans = payload.data
    }
  },
})

const planService = createSlice({
  name: 'planSlice',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    getPlans(builder)
  },
})

export default planService.reducer
