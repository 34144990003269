import IconRoutera from 'src/components/atoms/icons/icons'
import { ModalFB } from 'src/components/atoms/Modal/ModalFB.atom'
import microsoftOffice from 'src/assets/images/MicrosoftOfficeOutlook.svg'
import googleCalendarImg from 'src/assets/images/google-calendar-logo.png'
import { useEffect, useState } from 'react'
import { config } from 'src/config/constants'
import { useActions } from 'src/actions'
import toastBuilder from 'src/utils/toastBuilder'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
declare global {
  interface Window {
    google: any;
  }
}
interface PropsCalendarList {
  src: string
  text: string
  soon: boolean
  id: number
}

const GoogleCalendar = () => {

  const { integrationCalendarAction, getCalendarAction, deleteCalendarAction } = useActions()
  const { GOOGLE_CALENDAR_CLIENT_ID, SCOPE_GOOGLE_API } = config
  const [availableConnect, setAvailableConnect] = useState(false)
  const [calendarSelected, setCalendarSelected] = useState('')
  const [isCalendarConnected, setIsCalendarConnected] = useState(false)
  const ability = useAbility(AbilityContext)

  useEffect(() => {
    getCalendars()
  }, [])

  const getCalendars = async () => {
    const results: any = await getCalendarAction()
    if (results.payload.success) {
      setIsCalendarConnected(results.payload.data.calendars.google.active)
    }
  }

  const connectGoogle = async (response: any) => {
    const client = window.google.accounts.oauth2.initCodeClient({
      client_id: GOOGLE_CALENDAR_CLIENT_ID,
      scope: SCOPE_GOOGLE_API,
      ux_mode: 'popup',

      callback: async (response: any) => {
        try {
          if (!response.code) {
            return
          }
          const requestBody = { code: response.code }
          const results: any = await integrationCalendarAction(requestBody)
          if (results.payload.success) {
            setIsCalendarConnected(true)
            toastBuilder('success', results.payload.success)
          }
        } catch (err) {
          toastBuilder('error', 'Error')
        }
      }
    })

    client.requestCode()

    setCalendarSelected('')
    setAvailableConnect(false)
  }

  const selectedCalendar = (dataCalendar: PropsCalendarList) => {
    if (!dataCalendar.soon) {
      setCalendarSelected(dataCalendar.text)
      setAvailableConnect(true)
    }
  }

  const disconnect = async () => {
    const results: any = await deleteCalendarAction()
    if (results.payload.success) {
      setIsCalendarConnected(false)
      toastBuilder('success', results.payload.success)
    }
  }

  const calendarsList: PropsCalendarList[] = [
    {
      src: googleCalendarImg,
      text: 'Google Calendar',
      soon: false,
      id: 1,
    },
    {
      src: microsoftOffice,
      text: 'Microsoft Outlook Calendar',
      soon: true,
      id: 2,
    },
  ]

  const modalConnect: JSX.Element = (
    <ModalFB
      id='openConnectCalendar'
      titleModal='Connect Calendar'
      className='max-w-[500px]'
      buttonOpen={
        <div>
          <button
            disabled={!ability.can('create', 'setting.profile.calendar_integration')}
            data-modal-target='openConnectCalendar'
            data-modal-toggle='openConnectCalendar'
            type='button'
            className={`px-[20px] py-[10px] ml-6 tablet:m-auto tablet:mt-2 text-white text-[14px] rounded-lg bg-routera-primary self-start mobile:mt-2 tablet:ml-0 ${!ability.can('create', 'setting.profile.calendar_integration') && 'cursor-not-allowed'}`}
          >
            Connect
          </button>
        </div>
      }
    >
      {
        <div className='p-0'>
          <span className='text-sm text-gray-500 leading-[21px] mt-4 inline-block'>
            Sync your calendar to have a more accurate active hours based on
            your upcoming events.
          </span>
          <div className='mt-4 mb-14'>
            {calendarsList.map((item: PropsCalendarList) => (
              <div
                key={item.id}
                className={`mb-3 bg-gray-50 rounded-lg p-3 flex items-center justify-between tablet:flex-col ${calendarSelected === item.text &&
                  'border-routera-800 border-[1px]'
                  } ${!item.soon ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                onClick={() => selectedCalendar(item)}
              >
                <div className='flex items-center'>
                  <img src={item.src} alt={item.text} height={25} width={25} />
                  <span className='text-gray-700 text-sm leading-[21px] font-bold ml-4'>
                    {item.text}
                  </span>
                </div>
                {item.soon && (
                  <span className='py-2 px-3 bg-gray-200 rounded-md text-xs text-gray-500 font-medium tablet:mt-4'>
                    Coming soon
                  </span>
                )}
              </div>
            ))}
          </div>
          <div>
            <button
              id='buttonConnectCalendar'
              onClick={connectGoogle}
              data-modal-hide='openConnectCalendar'
              disabled={calendarSelected === ''}
              type='button'
              className={`py-[12px] px-[24px] rounded-lg d-block w-[fit-content] ml-auto ${calendarSelected === ''
                ? 'bg-gray-200 text-gray-400'
                : 'bg-routera-primary text-white'
                }`}
            >
              Connect
            </button>
          </div>
        </div>
      }
    </ModalFB>
  )

  return (
    <div className={`mt-5 text-gray-700 flex flex-col ${isCalendarConnected && 'tablet:items-center'}`}>
      <span className='text-[18px] leading-[27px] font-medium'>
        Calendar Integration{' '}
      </span>
      <span className='leading-[21px] font-normal text-sm mt-2'>
        Sync your <b>Calendar</b> to have a more accurate active hours based on
        your upcoming events
      </span>
      {!isCalendarConnected ? (
        <div className='border border-gray-300 bg-white w-[300px] mt-2 rounded-[9px] flex items-center p-2 mobile:flex-col tablet:mt-5 mobile:w-[200px]'>
          <IconRoutera
            iconName='CalendarIcon'
            className='h-[45px] w-[45px] text-gray-500'
          />{' '}
          <span className='text-sm text-gray-700 leading-[21px]'>
            Connect Calendar
          </span>
          {modalConnect}
        </div>
      ) : (
        <div className='border border-gray-300 bg-white max-w-[545px] mt-2 rounded-[9px] flex items-center p-3 mobile:flex-col tablet:mt-5 mobile:w-[200px]'>
          <img
            src={googleCalendarImg}
            alt='googleCalendar'
            height={25}
            width={25}
            className='tablet:mb-2'
          />
          <span className='text-sm ml-2 text-gray-700'>
            Events in <b>Google Calendar</b> will mark you unavailable{' '}
          </span>
          <button
            type='button'
            disabled={!ability.can('delete', 'setting.profile.calendar_integration')}
            onClick={disconnect}
            className={`px-[20px] py-[10px] ml-6 text-white text-[14px] rounded-lg bg-red-500 self-start tablet:m-auto tablet:mt-2 ${!ability.can('delete', 'setting.profile.calendar_integration') && 'cursor-not-allowed'}`}
          >
            Disconnect
          </button>
        </div>
      )}
    </div>
  )
}

export default GoogleCalendar
