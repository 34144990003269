import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import ComboBox from 'src/components/molecules/inputs/comboBox/ComboBox.Molecule'
import getOperatorsForFieldType, {
  LITERAL_TYPES,
  OperatorConfig,
} from './getJsonOperator'
import { nanoid } from 'nanoid'

interface Props {
  label?: string | React.ReactNode
  initialValue: OperatorConfig | null
  description?: string | React.ReactNode
  fieldType: LITERAL_TYPES
  handleOperatorUpdate: (operator: OperatorConfig) => void
}

const MODEL_NAME = nanoid()

const JsonOperator: React.FC<Props> = ({
  label,
  description,
  initialValue,
  fieldType,
  handleOperatorUpdate,
}) => {
  const [operators, setOperators] = useState<OperatorConfig[] | undefined>(
    getOperatorsForFieldType(fieldType),
  )

  useEffect(() => {
    setOperators(getOperatorsForFieldType(fieldType))
  }, [fieldType])

  const operatorLabels = useMemo(() => {
    if (operators) {
      return operators.map(({ label }) => label)
    }
  }, [operators && operators[0]])

  const options = {
    label: label || 'Select an operator',
    description:
      description ||
      `Please select an operator for evaluating a condition against your selected property`,
    modelName: MODEL_NAME,
    initialValue: initialValue?.label || '',
    options: operatorLabels || [],
    handleOnSubmit: (model: Record<string, any>) => {
      const selectedOperator = operators?.find(({ label }) =>
        label.includes(model[MODEL_NAME]),
      )
      if (selectedOperator) {
        handleOperatorUpdate(selectedOperator)
      }
    },
  }

  return <ComboBox {...options} />
}

export default JsonOperator
