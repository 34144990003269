import React, { useMemo } from 'react'
import { HUBSPOT_OBJECT_TYPES, Source } from 'src/types/sources'
import { HUBSPOT_FIELD_TYPES } from './getHubspotFieldType'
import { HandleUpdate } from './HubspotQueryBuilder.Template'
import SetObjectTypeStep from './steps/SetObjectTypeStep.Template'
import SetPropertyStep from './steps/SetPropertyStep.Template'

export type DispatchQueryStepChange = (step: QUERY_STEPS) => void

export type ObjectProperty = Record<string, any> & {
  label: string
  description: string
  type: HUBSPOT_FIELD_TYPES
}

interface Props {
  queryStep: QUERY_STEPS
  currentSource: Source
  objectType?: HUBSPOT_OBJECT_TYPES
  handleUpdate: HandleUpdate
  objectProperties: ObjectProperty[]
  dispatchQueryStepChange: (step: QUERY_STEPS) => void
}

export enum QUERY_STEPS {
  SET_OBJECT_TYPE = 'SetObjectType',
  SET_PROPERTY = 'SetProperty',
}

export enum URL_PARAMS {
  STEP = 'step',
}

interface ComponentStepConfig {
  component: React.ElementType
  props: Record<string, any>
}

const StepBuilder: React.FC<Props> = ({
  queryStep,
  currentSource,
  objectType,
  handleUpdate,
  objectProperties,
  dispatchQueryStepChange,
}) => {
  const hasObjectProperties = objectProperties && objectProperties.length

  const objectPropertyLabels = useMemo(
    () => hasObjectProperties && objectProperties.map(({ label }) => label),
    [hasObjectProperties],
  )

  const currentObjectProperties = useMemo(
    () => currentSource?.subscription_configs?.object_properties,
    [hasObjectProperties],
  )

  const getPropertyFromLabel = (label: string): ObjectProperty | undefined => {
    if (hasObjectProperties && objectProperties.length > 0) {
      return objectProperties.find(({ label: objLabel }) => objLabel === label)
    }
  }

  const setObjectTypeStep = {
    component: SetObjectTypeStep,
    props: {
      objectType: objectType as HUBSPOT_OBJECT_TYPES,
      handleUpdate: handleUpdate,
      getPropertyFromLabel: getPropertyFromLabel,
      dispatchQueryStepChange: dispatchQueryStepChange,
    },
  }

  const getSetPropertySteps = () => {
    const setPropertyStep = {
      component: SetPropertyStep,
      props: {
        currentSource,
        handleUpdate: handleUpdate,
        dispatchQueryStepChange: dispatchQueryStepChange,
        labelOptions: objectPropertyLabels,
        getPropertyFromLabel: getPropertyFromLabel,
      },
    }
    if (
      currentObjectProperties &&
      Object.keys(currentObjectProperties).length > 0
    ) {
      return Object.keys(currentObjectProperties).map(
        (propertyLabel, index) => ({
          ...setPropertyStep,
          props: {
            ...setPropertyStep.props,
            index: index + 1,
            currentSourceObjectProperty: currentObjectProperties[propertyLabel],
          },
        }),
      )
    }
    return setPropertyStep
  }

  const QueryStepRegistry = {
    ...(queryStep === QUERY_STEPS.SET_OBJECT_TYPE ||
    queryStep === QUERY_STEPS.SET_PROPERTY
      ? { [QUERY_STEPS.SET_OBJECT_TYPE]: setObjectTypeStep }
      : {}),
    ...(queryStep === QUERY_STEPS.SET_PROPERTY
      ? { [QUERY_STEPS.SET_PROPERTY]: getSetPropertySteps() }
      : {}),
  }

  const ActiveSteps = useMemo(() => {
    const StepsMap = Object.keys(QueryStepRegistry).map((key) => {
      const Step = QueryStepRegistry[key as keyof typeof QueryStepRegistry]
      return Step
    })

    const currentStepIndex = StepsMap.indexOf(QueryStepRegistry[queryStep])

    const addStepComponent = (
      arr: React.ReactNode[],
      Step: ComponentStepConfig,
    ) => {
      const StepComponent = Step.component
      arr.push(() => <StepComponent {...(Step.props as any)} />)
    }

    return StepsMap.reduce<(() => JSX.Element)[]>((arr, Step, index) => {
      if (index <= currentStepIndex && Step) {
        // Handle multiple fields
        if (Array.isArray(Step)) {
          for (const SubStep of Step) {
            addStepComponent(arr, SubStep)
          }
        } else {
          addStepComponent(arr, Step)
        }
      }
      return arr
    }, [])
  }, [queryStep, hasObjectProperties && objectProperties.length])

  return (
    <>
      {ActiveSteps.map((Step, index) => (
        <Step key={index} />
      ))}
    </>
  )
}

export default StepBuilder
