import { createReducerBuilder } from 'src/utils/actions'
import { createSlice } from '@reduxjs/toolkit'
import {
	getTotalRouted,
	getTotalCapacity,
	getTotalSLAs,
	getDashboardDataAction,
	getRoutedOverview,
	getConversionList,
	getListRoutedLeads,
	getDetailSLAs
} from './dashboard.actions'
import { DashboardState } from 'src/types/dashboard'
import { ROUTER_SLA_ACTION_TYPES } from 'src/types/routerDetail'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'

const dashboardDefault = {
	totalRouted: null,
	totalCapacities: null,
	totalSLAs: {
		totalSlas: 0,
		totalSlaMet: 0,
		totalSlasMissed: 0,
		totalMet: undefined,
		totalMissed: undefined
	},
	detailsSLAs: {
		listSLA: null,
		total: 0
	},
	dashboard: {
		leads: [],
		totalDataSize: 10,
	},
	routedOverview: [{
		date: '',
		total: 0
	}],
	conversionList: {
		conversionRouterItem: [{
			conversionRate: 0,
			router: {
				_id: '',
				name: '',
				attachedLeads: [],
				attachedSources: [],
				webhookAddress: '',
				isWebhookActive: false,
				owner: '',
				assignedUsers: [],
				createdAt: new Date(),
				updatedAt: new Date(),
				assignedUsersInfo: [],
				attachedSourcesInfo: [],
				attachedLeadsInfo: [],
				assignedModel: '',
				isPublished: false,
				can_overwrite_owner: false,
				disable_default_assignment: false,
				defaultUserForAssignment: '',
				haveAnyHubspotSource: false,
				DefaultUserNotSelected: false,
				account_matching: null,
			}
		}],
		conversionUserItem: [{
			conversionRate: 0,
			user: {
				_id: '',
				firstName: '',
				lastName: '',
				email: '',
				avatar: '',
				phone: '',
			}
		}]
	},
	routedLeads: [{
		totalRecords: 0,
		leads: {
			assignedUser: '',
			router: '',
			_id: '',
			properties: {
				email: '',
				firstname: '',
				lastname: '',
				hs_object_id: '',
				hs_pipeline: '',
			}
		}
	}]
}

const initialState: DashboardState = {
	totalRouted: dashboardDefault.totalRouted,
	totalCapacities: dashboardDefault.totalCapacities,
	totalSLAs: dashboardDefault.totalSLAs,
	dashboard: dashboardDefault.dashboard,
	routedOverview: dashboardDefault.routedOverview,
	conversionList: dashboardDefault.conversionList,
	routedLeads: dashboardDefault.routedLeads,
	detailsSLAs: dashboardDefault.detailsSLAs
}

const reducerBuilder = createReducerBuilder<DashboardState>()

const getTotalRoutedState = reducerBuilder(getTotalRouted, {
	fulfilled: (state, { payload }) => {
		if (payload || payload === null) {
			state.totalRouted = payload
		}
	},
})

const getTotalCapacityState = reducerBuilder(getTotalCapacity, {
	fulfilled: (state, { payload }) => {
		if (payload) {
			state.totalCapacities = payload
		}
	},
})

const getTotalSLAsState = reducerBuilder(getTotalSLAs, {
	fulfilled: (state, { payload }) => {
		if (payload) {
			state.totalSLAs = payload
		}
	},
})

const getRoutedOverviewState = reducerBuilder(getRoutedOverview, {
	fulfilled: (state, { payload }) => {
		if (payload) {
			state.routedOverview = payload
		}
	},
})

const getDashbordDataReducer = reducerBuilder(getDashboardDataAction, {
	fulfilled: (state, { payload }) => {
		if (payload) {
			state.dashboard = payload
		}
	},
})

const getConversionListState = reducerBuilder(getConversionList, {
	fulfilled: (state, { payload }) => {
		if (payload) {
			state.conversionList = payload
		}
	},
})

const getListRoutedLeadsState = reducerBuilder(getListRoutedLeads, {
	fulfilled: (state, { payload }) => {
		if (payload) {
			state.routedLeads = payload
		}
	},
})

const getDetailSLAsState = reducerBuilder(getDetailSLAs, {
	fulfilled: (state, { payload }) => {
		if (payload) {
			state.detailsSLAs = payload
		}
	},
})

const DashboardService = createSlice({
	name: 'DashboardSlice',
	initialState,
	reducers: {},

	extraReducers: (builder) => {
		getTotalRoutedState(builder)
		getTotalCapacityState(builder)
		getTotalSLAsState(builder)
		getDashbordDataReducer(builder)
		getRoutedOverviewState(builder)
		getConversionListState(builder)
		getListRoutedLeadsState(builder)
		getDetailSLAsState(builder)
	},
})

export default DashboardService.reducer