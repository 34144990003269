import { ROUTER_SLA_ACTION_TYPES } from './routerDetail'
import { HUBSPOT_OBJECT_TYPES } from './sources'

export interface ISLA {
  _id: string
  name: string
  timeInterval: string
  action: ROUTER_SLA_ACTION_TYPES
  account_id: string
  createdAt: string
  is_active: boolean
  updatedAt: string
  triggerRule: SLA_TRIGGER_RULE
  object_type: HUBSPOT_OBJECT_TYPES
  actions: string[]
  rules: Array<any>
  router_id: string
  integration_type: string
}

export interface ISLAProp {
  name: string
  action: ROUTER_SLA_ACTION_TYPES
  triggerRule: SLA_TRIGGER_RULE
  metadata: ISLA
}

export interface SLA_TRIGGER_RULE {
  objectType: HUBSPOT_OBJECT_TYPES
  value: string
  fieldName: string
  fieldLabel: string
  operator: string
}

export enum SLA_ACTIONS_TEXT_MAP {
  SEND_EMAIL = 'EMAIL',
  SEND_SMS = 'SMS',
  REASSIGN = 'Reassign Record',
  UPDATE_FIELD = 'Update Field',
}

export interface ISLA_STATE {
  slas: ISLA[]
}

export interface RuleInterface {
  field_name: string
  field_label: string
}
