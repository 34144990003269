import { HttpService } from './base.service'

class rolesService extends HttpService {
	private readonly prefix = 'roles'

	getRolesList = () =>
		this.get(`${this.prefix}`)

	createRole = async () =>
		this.post(`${this.prefix}/`, {})

	getRoleById = (id: string) => this.get(`${this.prefix}/${id}`)

	updateRole = (id: string, body: { name: string }) =>
		this.patch(`${this.prefix}/${id}`, body)

	getPermissions = () =>
		this.get('/permissions')

	updatePermissions = (id: string, body: { permissions: string[] }) =>
		this.patch(`${this.prefix}/${id}/set-permission`, body)

	deleteRoles = (role_ids: string[]) =>
		this.delete(`${this.prefix}/`, {}, { role_ids })

}

export const RolesService = new rolesService()
