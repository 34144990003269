import { useEffect, useState } from 'react'
import { initFlowbite } from 'flowbite'
import IconRoutera from 'src/components/atoms/icons/icons'
import { IOption } from 'src/types/Ihome_state'

interface IProps {
  data: IOption[]
  labelDropdown: string
  inputSearch?: boolean
  placeholderDropdown: string
  setDataItem: (data: IOption[]) => void
  idDropDown: string
  dropdownName: string
  disabled?: boolean
  customWidth?: string

}

const MultipleDropdown: React.FC<IProps> = (props) => {
  const {
    labelDropdown,
    data,
    inputSearch,
    placeholderDropdown,
    setDataItem,
    idDropDown,
    dropdownName,
    disabled = false,
    customWidth = 'w-[15rem]',
  } = props
  const [dataOption, setDataOption] = useState<IOption[]>(data)
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [placeholderSelect, setPlaceholderSelect] = useState<string>('')
  const [dataEmpty, setDataEmpty] = useState<boolean>(false)

  useEffect(() => {
    initFlowbite()
  }, [])

  useEffect(() => {
    if (data.length > 0) {
      setDataEmpty(false)
      setDataOption(data)
      if (data[0].checked) {
        setPlaceholderSelect('')
      } else {
        const actives = data.filter((itemData: any) => itemData.checked)
        if (actives.length === 1) {
          setPlaceholderSelect(actives[0].label)
        }

        if (actives.length > 1) {
          setPlaceholderSelect(
            actives.length.toString() + ' ' + dropdownName + ' selected',
          )
        }
      }
      if (data.length === 1 && data[0].id === 0) {
        setDataEmpty(true)
      }
    }
  }, [data])

  const selectItem = (item: IOption, indexItem: number) => {
    setPlaceholderSelect('')
    let oldData: IOption[] = [...dataOption]
    let indexI = indexItem

    if (isSearch) {
      oldData = [...data]
      indexI = data.findIndex(
        (itemData: IOption) => itemData.value === item.value,
      )
    }

    if (item.id === 0) {
      oldData = []
      dataOption.forEach((item: IOption, index: number) => {
        oldData.push({
          id: index,
          value: item.value,
          label: item.label,
          checked: false,
        })
      })
      oldData[0].checked = true
      setDataItem(oldData)
      setDataOption(oldData)
      return
    }

    oldData[indexI].checked = !oldData[indexI].checked
    if (oldData[0].id === 0) {
      oldData[0].checked = false
    }
    setDataItem(oldData)
    setDataOption(oldData)
    const itemsTrue = oldData.every((item) => {
      if (!item.checked) {
        return true
      }
    })
    if (itemsTrue) {
      oldData[0].checked = true
      setDataItem(oldData)
      setDataOption(oldData)
    }

    const actives = dataOption.filter((itemData: any) => itemData.checked)

    setPlaceholderSelect(actives[0].label)

    if (actives.length > 1) {
      setPlaceholderSelect(
        actives.length.toString() + ' ' + dropdownName + ' selected',
      )
    }
  }

  const clearSelection = () => {
    setPlaceholderSelect('')
    setIsSearch(false)
    const oldData: IOption[] = []
    data.forEach((item: IOption, index: number) => {
      oldData.push({
        id: index,
        value: item.value,
        label: item.label,
        checked: false,
      })
    })
    oldData[0].checked = true
    setDataOption(oldData)
    setDataItem(oldData)
  }

  const onSearchTextChange = (searchText: string) => {
    setIsSearch(true)

    const itemSearch = data.filter((item) =>
      item.label.toLowerCase().includes(searchText.toLowerCase()),
    )

    if (searchText === '') {
      setDataOption(data)
      setIsSearch(false)
      return
    }

    const index = itemSearch.findIndex((item: IOption) => item.id === 0)

    if (index !== -1) {
      itemSearch.splice(index, 1)
    }

    setDataOption(
      itemSearch.filter((item) =>
        item.label.toLowerCase().includes(searchText.toLowerCase()),
      ),
    )
  }

  return (
    <div className={`mr-8 flex flex-col ${customWidth} mt-2`}>
      <div>
        <label
          htmlFor='checkbox-item-11'
          className='block text-sm font-medium text-gray-800 leading-[21px]'
        >
          {labelDropdown}
        </label>
        <div
          id={'multipleDropdownButton' + idDropDown}
          data-dropdown-toggle={idDropDown}
          data-dropdown-placement='bottom'
          className={`relative w-full border border-gray-300 bg-white flex pl-3 pr-10 text-left focus:outline-none focus:ring-1 sm:text-sm h-[46px] rounded-[8px] text-gray-500 mt-[9px] items-center capitalize ${dataOption.length > 1 && 'cursor-pointer'
            }`}
        >
          {dataOption.length === 1
            ? labelDropdown
            : placeholderSelect !== ''
              ? placeholderSelect
              : placeholderDropdown}
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
            <IconRoutera
              iconName='ChevronDownIcon'
              className='h-5 w-5 text-gray-500'
              aria-hidden='true'
            />
          </span>
        </div>
      </div>

      {!disabled && <div
        id={idDropDown}
        className='z-10 hidden bg-white rounded-lg shadow w-60'
      >
        {inputSearch && (
          <div className='p-3'>
            <label
              htmlFor={'input-group-search' + idDropDown}
              className='sr-only'
            >
              Search
            </label>
            <div className='relative'>
              <div className='absolute inset-y-0 left-2 h-full pr-3 flex justify-center items-center pl-2'>
                <i className='fa-solid fa-magnifying-glass text-gray-500' />
              </div>
              <input
                type='text'
                id={'input-group-search' + idDropDown}
                className='block w-full py-2 pl-[40px] text-sm text-gray-500 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500'
                placeholder={'Search'}
                onChange={(evt) => {
                  onSearchTextChange(evt.currentTarget.value)
                }}
              />
            </div>
          </div>
        )}
        {!dataEmpty ? (
          <ul
            className={`h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 ${!inputSearch && 'mt-2'
              }`}
            aria-labelledby={'multipleDropdownButton' + idDropDown}
          >
            {dataOption &&
              dataOption.map((item: IOption, index: number) => (
                <li
                  key={item.value + 'dropDown'}
                  onClick={() => selectItem(item, index)}
                >
                  <div className='flex items-center pl-2 rounded hover:bg-gray-100 capitalize'>
                    <input
                      id={item.label + item.value}
                      checked={item.checked}
                      type='checkbox'
                      value=''
                      className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2'
                      onChange={() => {
                        return
                      }}
                    />
                    <label
                      htmlFor={item.label + item.value + 'multipDropdown'}
                      className='w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded'
                    >
                      {item.label}
                    </label>
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          <span className='text-gray-500 flex justify-center mb-2'>
            No data
          </span>
        )}
        <a
          onClick={clearSelection}
          className='flex items-center p-3 text-sm font-medium text-red-600 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100 cursor-pointer hover:text-red-600'
        >
          <svg
            className='w-4 h-4 mr-2'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 18'
          >
            <path d='M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-6a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2Z' />
          </svg>
          Clear Selection
        </a>
      </div>}
    </div>
  )
}

export default MultipleDropdown
