import React from 'react'
import Dialog, {
  DialogProps,
} from 'src/components/molecules/dialog/Dialog.Molecule'

interface Props extends DialogProps {
  onCancel: () => void
  onConfirm: () => void
  cancelButtonText?: string
  confirmButtonText?: string
  loading?: boolean
}

const ConfirmationDialog: React.FC<Props> = ({
  title,
  description,
  isOpen,
  onDialogClose,
  onCancel,
  onConfirm,
  loading = false,
  cancelButtonText = 'No',
  confirmButtonText = 'Yes',
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      description={description}
      onDialogClose={onDialogClose}
    >
      <div className='flex justify-center relative'>
        <button
          type='button'
          className='inline-flex justify-center border-transparent px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg bg-routera-primary self-start w-[188px] hover:bg-blue-700 font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
          onClick={() => {
            onDialogClose()
            onCancel()
          }}
        >
          {cancelButtonText}
        </button>
        <button
          type='button'
          className='ml-4 inline-flex justify-center border border-transparent px-[20px] py-[10px] mt-2 text-[14px] rounded-lg self-start w-[188px] bg-red-400 hover:bg-red-600 text-white font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
          onClick={onConfirm}
          disabled={loading}
        >
          {!loading ? (
            confirmButtonText
          ) : (
            <div className='spinner-border spinner-border-sm' role='status' />
          )}
        </button>
      </div>
    </Dialog>
  )
}

export default ConfirmationDialog
