import { Toggle } from 'src/components/atoms/Toggle/Toggle.atom'
import IconRoutera from 'src/components/atoms/icons/icons'
import SelectableTable from 'src/components/organisms/tables/SelectableTable.Organism'
import { DataReviewPublish } from 'src/types/roles'
import { RolesPermissions } from 'src/types/roles'

interface IProps {
  dataConversions: RolesPermissions[]
  dataRouter: RolesPermissions[]
  dataDashboard: RolesPermissions[]
  dataSettings: RolesPermissions[]
}
const ReviewAndPublish: React.FC<IProps> = (props) => {
  const { dataConversions, dataRouter, dataDashboard, dataSettings } = props

  const columnsRolesDashboard = [
    {
      label: 'Name',
      render: () => {
        return <div className='md:w-[180px]'>Dashboard</div>
      },
    },
    {
      label: 'Permission to Read',
      render: ({ read }: any) => {
        return (
          <div>
            {read ? (
              <IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : read !== undefined ? (
              <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      label: 'Permission to create',
      render: () => {
        return <div>-</div>
      },
    },
    {
      label: 'Permission to edit',
      render: () => {
        return <div>-</div>
      },
    },
    {
      label: 'Permission to delete',
      render: () => {
        return <div>-</div>
      },
    },
  ]

  const columnsRolesRouter = [
    {
      label: 'Name',
      render: ({ name }: any) => {
        return <div className='capitalize md:w-[180px]'>{name}</div>
      },
    },
    {
      label: 'Permission to Read',
      render: ({ read, hasRead }: any) => {
        return (
          <div>
            {hasRead ? (
              read ? (<IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />) : <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : ('-')}
          </div>
        )
      },
    },
    {
      label: 'Permission to create',
      render: ({ create, hasCreate }: any) => {
        return (
          <div>
            {hasCreate ? (
              create ? (<IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />) : <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : ('-')}
          </div>
        )
      },
    },
    {
      label: 'Permission to edit',
      render: ({ update, hasUpdate }: any) => {
        return (
          <div>
            {hasUpdate ? (
              update ? (<IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />) : <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : ('-')}
          </div>
        )
      },
    },
    {
      label: 'Permission to delete',
      render: ({ delete: deleteR, hasDelete }: any) => {
        return (
          <div>
            {hasDelete ? (
              deleteR ? (<IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />) : <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : ('-')}
          </div>
        )
      },
    },
  ]

  const columnsRolesConversions = [
    {
      label: 'Name',
      render: () => {
        return <div className='md:w-[180px]'>Conversions</div>
      },
    },
    {
      label: 'Permission to Read',
      render: ({ read }: any) => {
        return (
          <div>
            {read ? (
              <IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : read !== undefined ? (
              <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      label: 'Permission to create',
      render: ({ create }: any) => {
        return (
          <div>
            {create ? (
              <IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : create !== undefined ? (
              <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      label: 'Permission to edit',
      render: ({ update }: any) => {
        return (
          <div>
            {update ? (
              <IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : update !== undefined ? (
              <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      label: 'Permission to delete',
      render: ({ delete: deleteR }: any) => {
        return (
          <div>
            {deleteR ? (
              <IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : deleteR !== undefined ? (
              <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
  ]

  const columnsRolesSettings = [
    {
      label: 'Name',
      render: ({ name }: any) => {
        return <div className='capitalize md:w-[180px]'>{name}</div>
      },
    },
    {
      label: 'Permission to Read',
      render: ({ read, hasRead }: any) => {
        return (
          <div>
            {hasRead ? (
              read ? (<IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />) : <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : ('-')}
          </div>
        )
      },
    },
    {
      label: 'Permission to create',
      render: ({ create, hasCreate }: any) => {
        return (
          <div>
            {hasCreate ? (
              create ? (<IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />) : <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : ('-')}
          </div>
        )
      },
    },
    {
      label: 'Permission to edit',
      render: ({ update, hasUpdate }: any) => {
        return (
          <div>
            {hasUpdate ? (
              update ? (<IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />) : <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : ('-')}
          </div>
        )
      },
    },
    {
      label: 'Permission to delete',
      render: ({ delete: deleteR, hasDelete }: any) => {
        return (
          <div>
            {hasDelete ? (
              deleteR ? (<IconRoutera
                iconName='CheckIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />) : <IconRoutera
                iconName='XMarkIcon'
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            ) : ('-')}
          </div>
        )
      },
    },
  ]

  return (
    <div className='px-5 py-7'>
      <div className='flex flex-col'>
        <span className='text-lg leading-[27px] text-gray-700 font-medium'>
          Review and Publish
        </span>
        <span className='text-sm leading-[21px] font-normal text-gray-700 mt-2'>
          Review your Role is setup correctly and publish it live
        </span>
      </div>
      <div className='mt-4'>
        <span className='text-lg font-medium leading-[27px]'>Dashboard</span>
        <SelectableTable
          columns={columnsRolesDashboard}
          rows={dataDashboard}
          showSelectionType={false}
          showHeaderMeta={false}
          borderTable={true}
          className='p-0'
        />
      </div>
      <div className='mt-4'>
        <span className='text-lg font-medium leading-[27px]'>Router</span>
        <SelectableTable
          columns={columnsRolesRouter}
          rows={dataRouter}
          showSelectionType={false}
          showHeaderMeta={false}
          borderTable={true}
          className='p-0'
        />
      </div>
      <div className='mt-4'>
        <span className='text-lg font-medium leading-[27px]'>Conversions</span>
        <SelectableTable
          columns={columnsRolesConversions}
          rows={dataConversions}
          showSelectionType={false}
          showHeaderMeta={false}
          borderTable={true}
          className='p-0'
        />
      </div>
      <div className='mt-4'>
        <span className='text-lg font-medium leading-[27px]'>Settings</span>
        <SelectableTable
          columns={columnsRolesSettings}
          rows={dataSettings}
          showSelectionType={false}
          showHeaderMeta={false}
          borderTable={true}
          className='p-0'
        />
      </div>
    </div>
  )
}

export default ReviewAndPublish
