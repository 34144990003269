import { useState, useEffect } from 'react'
import 'react-phone-input-2/lib/style.css'
import CreateleadRoutes from '../../components/LeadRoutes/leadRoutes'
import LeadRoutesTable from '../../components/LeadRoutes/leadRoutesTabel'
import { useActions } from '../../actions'
import { useAppSelector } from '../../store'
import toastBuilder from '../../utils/toastBuilder'
import { uselistofAllroutes } from './leadRoutes.selector'
import { useNavigate } from 'react-router-dom'
import NoPermission from 'src/components/templates/noPermission'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'

const description =
  'A trigger is an event that enrolls an object into your rotation'

const MyAccount = () => {
  const ability = useAbility(AbilityContext)

  if (!ability.can('access', 'router')) {
    return <NoPermission value='access' />
  } else {
    if (!ability.can('read', 'router')) {
      return <NoPermission value='read' />
    }
  }

  const [show, setShow] = useState(false)
  const { registernewRoute, getallRoutes } = useActions()
  const ListofAllroutes = useAppSelector(uselistofAllroutes)
  const navigate = useNavigate()

  const handleFormSubmit = async (
    values: { name: string },
    { resetForm, setSubmitting }: any,
  ) => {
    const payload = {
      name: values.name,
    }

    const response: any = await registernewRoute(payload)
    if ((response as unknown as { payload: { id: string } }).payload.id) {
      navigate(`/routers/${response.payload.id}/triggers`)
      toastBuilder('success', 'Router added successfully!')
      resetForm()
      setShow(false)
    }
    setSubmitting(false)
  }

  useEffect(() => {
    getallRoutes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section>
      <div className='h-[67px] w-full justify-start items-center flex px-4'>
        <span className='text-2xl font-semibold text-gray-700'>Routers</span>
      </div>
      <CreateleadRoutes
        onSubmit={handleFormSubmit}
        setShow={setShow}
        show={show}
        description={description}
        isNewRouter={true}
      />
      <LeadRoutesTable allRoutes={ListofAllroutes} setShow={setShow} />
    </section>
  )
}

export default MyAccount
