import TimeInterval from 'src/components/atoms/TimeInterval/TimeInterval.atom'

const Time = ({ setThereAreChanges, time, setTime }: any) => {
  const onChange = (time: any) => {
    setTime(time)
    setThereAreChanges(true)
  }

  return (
    <div className='px-4 p-4'>
      <span className='text-gray-700 text-xl leading-[30px]'>Time</span>
      <p className='text-gray-700 text-sm leading-[21px] mt-2'>
        How long after this SLA is triggered do we want to check if the rule is
        met?
      </p>

      <div className='w-[495px]'>
        <TimeInterval
          handleChange={(time: string) => onChange(time)}
          label={''}
          value={time}
        />
      </div>
    </div>
  )
}

export default Time
