import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import TimeSlotPicker, {
  TimeSlot,
  TimeSlots,
} from 'src/components/organisms/time-slot-picker/TimeSlotPicker.Molecule'
import { usewokingTime } from 'src/container/my_account/account.selector'
import { useAppSelector } from 'src/store'
import { Formik } from 'formik'
import { DataDate } from 'src/types/profile'
import { useActions } from 'src/actions'
import DatesSection from 'src/components/organisms/datesSection/DatesSection.Organism'
import GoogleCalendar from 'src/components/molecules/googleCalendar/googleCalendar'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import { Toggle } from 'src/components/atoms/Toggle/Toggle.atom'
import NoPermission from 'src/components/templates/noPermission'

const WorkingHoursTabs: React.FC<{
  saveForm: any
  idForm: string
  user: any
  dataDates: DataDate[]
  setDataDates: Dispatch<SetStateAction<DataDate[]>>
  setWorkingHours: Dispatch<SetStateAction<TimeSlots>>
  workingHours: TimeSlots
  showWorkingHours: boolean
  setShowWorkingHours: (val: boolean) => void
}> = ({
  saveForm,
  idForm,
  user,
  dataDates,
  setDataDates,
  setWorkingHours,
  workingHours,
  showWorkingHours,
  setShowWorkingHours,
}) => {

    const ability = useAbility(AbilityContext)

    if (!ability.can('read', 'setting.profile.working_hours')) {
      return <NoPermission classHeight='h-[78vh]' value='read' />
    }

    const { get_working_hours } = useActions()

    const [showToolTip, setShowToolTip] = useState<boolean>(false)

    const workTimings = useAppSelector(usewokingTime)


    const onWorkingHoursChange = (timeSlots: TimeSlots) => {
      const sortedSlots = Object.keys(timeSlots).reduce((acc, day: string) => {
        acc[day] = [...timeSlots[day]].sort((slot1: TimeSlot, slot2: TimeSlot) =>
          slot1.startTime < slot2.startTime ? -1 : 1,
        )
        return acc
      }, {} as TimeSlots)
      setWorkingHours(sortedSlots)
    }

    useEffect(() => {
      const sortedSlots = Object.keys(workTimings).reduce((acc, day: string) => {
        acc[day] = [...workTimings[day]].sort(
          (slot1: TimeSlot, slot2: TimeSlot) =>
            slot1.startTime < slot2.startTime ? -1 : 1,
        )
        return acc
      }, {} as TimeSlots)
      setWorkingHours(sortedSlots)
    }, [workTimings])

    useEffect(() => {

      const daysWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      let cantDays = 0
      setShowWorkingHours(true)

      daysWeek.forEach((day: string) => {
        if (Object.keys(workingHours).length > 0 && workingHours[day].length > 0 && workingHours[day][0].endTime === "23:59" && workingHours[day][0].startTime === "00:00") {
          cantDays++
        }
      })

      if (cantDays === 7) {
        setShowWorkingHours(false)
      }
    }, [workingHours])

    useEffect(() => {
      const datesUser: DataDate[] = []

      user.outOfficeDates &&
        user.outOfficeDates.forEach((element: any) => {
          datesUser.push({
            endDate: new Date(element.endDate),
            startDate: new Date(element.startDate),
          })
        })
      setDataDates(datesUser)
      get_working_hours()
    }, [user])

    const handleStatusChange247 = () => {
      setShowWorkingHours(!showWorkingHours)
    }

    return (
      <div className='px-5 pt-6 pb-7'>
        <Formik onSubmit={saveForm} initialValues={{}} enableReinitialize={true}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            dirty,
            isValid,
            errors,
          }) => {
            return (
              <form id={idForm} className='settingform' onSubmit={handleSubmit}>
                <div className='bg py-0'>
                  <ul className='tg-list working-hours mt-0'>
                    <li className='tg-list-item'>
                      <div className='flex mt-3 mb-4'>
                        <Toggle
                          initialValue={!showWorkingHours}
                          data={''}
                          handleChange={handleStatusChange247}
                          className='w-[45px] mr-2'
                          disabled={!ability.can('update', 'setting.profile.working_hours')}
                        />
                        <span className='text-base text-gray-700 leading-6'>24/7 Availability</span>
                      </div>
                      {showWorkingHours && <>
                        <span className='w-100 inline-block text-left text-gray-600 text-[14px] leading[21px] font-semibold'>
                          Select the active hours when users will be available for assignments:
                        </span>
                        <TimeSlotPicker
                          selectedSlots={workingHours}
                          onChange={onWorkingHoursChange}
                          permissions={{
                            delete: ability.can('delete', 'setting.profile.working_hours'),
                            update: ability.can('update', 'setting.profile.working_hours'),
                            create: ability.can('create', 'setting.profile.working_hours')
                          }}
                        />
                      </>}
                    </li>

                    <li className='tg-list-item'>
                      {showToolTip && (
                        <div className='shadow-md max-w-[190px] text-center text-base bg-[#e8e8e8] p-2 rounded-md z-10 absolute -my-60 mx-6'>
                          <span>
                            Out of the office is a feature that will help you to
                            control user assignment status, we automatically
                            switch this status one time per day at 00:00 in your
                            timezone depending of the dates defined bu you.
                          </span>
                        </div>
                      )}
                      <div className='flex items-center mt-[25px]'>
                        <span
                          className={
                            `${dataDates.length === 0
                              ? 'mb-3 text-[18px]'
                              : 'text-[18px]'} font-medium leading-[27px]`
                          }
                        >
                          Out of Office Dates
                        </span>
                        <div
                          className={
                            dataDates.length === 0
                              ? '-mt-[14px] ml-2'
                              : 'ml-2 mt-[3px]'
                          }
                          onMouseEnter={() => setShowToolTip(true)}
                          onMouseLeave={() => setShowToolTip(false)}
                        >
                          <i className='fa-solid fa-info-circle text-lg'></i>
                        </div>
                      </div>
                      <DatesSection
                        stateDates={setDataDates}
                        dataDefaultDates={dataDates}
                        permissions={{
                          delete: ability.can('delete', 'setting.profile.out_office_dates'),
                          update: ability.can('update', 'setting.profile.out_office_dates'),
                          create: ability.can('create', 'setting.profile.out_office_dates')
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </form>
            )
          }}
        </Formik>
        <GoogleCalendar />
      </div>
    )
  }

export default WorkingHoursTabs
