import { useForm } from 'react-hook-form'
import { addConversionJSON } from 'src/forms/addConversionJSON'
import FormBuilder from 'src/components/molecules/FormBuilder/FormBuilder'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import { useEffect, useState } from 'react'
import toastBuilder from 'src/utils/toastBuilder'
import { useActions } from 'src/actions'
import { IFormDropdownOptions } from 'src/types/form'
import {
  AVAILABLE_OPERATORS,
  JsonFieldTypeMap,
} from 'src/components/organisms/forms/inputs/jsonOperator/getJsonOperator'
import moment from 'moment'
import { Conversions } from 'src/types/conversions'

const enumKeyToString = (key: string) => {
  const str = key.replaceAll('_', ' ')
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

const availableOperatorValues = Object.keys(AVAILABLE_OPERATORS).map((key) => {
  return {
    // @ts-ignore
    value: AVAILABLE_OPERATORS[key],
    // @ts-ignore
    label: enumKeyToString(key),
  }
})

const getOperatorValues = (type: string) => {
  const newOperatorValues: {
    value: string
    label: string
  }[] = []
  availableOperatorValues.forEach((opVal) => {
    // @ts-ignore
    if (JsonFieldTypeMap[type]?.includes(opVal.value)) {
      newOperatorValues.push(opVal)
    }
  })
  return newOperatorValues
}

interface IProps {
  setShowSlider: (value: boolean) => void
  currentObjectType: HUBSPOT_OBJECT_TYPES
  setShowTable: (value: boolean) => void
  conversionId?: string
  conversionEditData: Conversions | null
  dataField: any,
  data: any
}

const ConversionForm: React.FC<IProps> = ({
  setShowSlider,
  currentObjectType,
  setShowTable,
  conversionId,
  conversionEditData,
  dataField,
  data
}) => {
  const { updateConversion } = useActions()
  const [isLoading, setIsLoading] = useState(false)
  const [fieldOptions, setFieldOptions] = useState<IFormDropdownOptions[]>([])
  const [conversionFormJSON, setConversionFormJSON] = useState({})
  const [operatorsData, setOperatorsData] = useState({})

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, dirtyFields, touchedFields },
    reset,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
  } = useForm()

  const onSave = async () => {
    const values = getValues()

    if (
      !values.value &&
      values.operatorType !== AVAILABLE_OPERATORS.IS_KNOWN &&
      values.operatorType !== AVAILABLE_OPERATORS.IS_UNKNOWN
    ) {
      toastBuilder('error', 'Value is empty')
      return
    }

    let valueSend = values.value

    if (values.operatorType === AVAILABLE_OPERATORS.IS_KNOWN ||
      values.operatorType === AVAILABLE_OPERATORS.IS_UNKNOWN) {
      valueSend = ''
    }

    const fieldLabel =
      fieldOptions.find((option) => option.value === values.fieldType)?.label ||
      JSON.parse(values.fieldType).name
    if (values.fieldType.includes('datetime')) {
      values.value = moment(values.value).toISOString()
    }
    const data = {
      conversionRule: {
        objectType: currentObjectType,
        value: valueSend,
        fieldName: JSON.parse(values.fieldType).name,
        fieldLabel,
        operator: values.operatorType,
      },
    }
    if (conversionId) {
      const resp: any = await updateConversion({ id: conversionId, data })
      if (resp.payload.success) {
        setShowTable(true)
        toastBuilder(
          'success',
          conversionEditData
            ? 'Conversion builder updated successfully'
            : 'Conversion builder added successfully',
        )
      } else {
        toastBuilder('error', 'Failed to change object type')
      }
      setShowSlider(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)

    const formJSON = { ...addConversionJSON }

    addConversionJSON && setConversionFormJSON(formJSON)
    if (conversionEditData && Object.keys(conversionEditData).length) {
      setValue('objectType', conversionEditData.conversionRule.objectType)
      setValue('value', conversionEditData.conversionRule.value)
    }
    refetchHelper()

    if (data.length > 0) {
      setIsLoading(false)
    }
  }, [])

  const refetchHelper = async () => {
    getValues().objectType && (await dataField())
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'objectType' && type === 'change') {
        refetchHelper()
      } else if (name === 'fieldType' && value.fieldType) {
        const operators = getOperatorValues(JSON.parse(value.fieldType).type)
        const operatorOptions = JSON.parse(value.fieldType).options
        setOperatorsData({ operators, operatorOptions })
      }
      const valueDiv = window.document.querySelector(
        'div#elementController-value',
      )
      let displayCSS = 'block'
      if (
        (name === 'value' || type === 'change') &&
        value.operatorType &&
        (value.operatorType === AVAILABLE_OPERATORS.IS_KNOWN ||
          value.operatorType === AVAILABLE_OPERATORS.IS_UNKNOWN)
      ) {
        displayCSS = 'none'
      }
      if (control._formValues.operatorType === 'isUnknown') {
        displayCSS = 'none'
      }
      if (valueDiv) {
        // @ts-ignore
        valueDiv.style.display = displayCSS
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const setOperatorsToForm = (operatorsObj: any) => {
    const { operators, operatorOptions } = operatorsObj
    const oldFormJSON = { ...addConversionJSON }
    if (oldFormJSON?.fields.length) {
      oldFormJSON.fields[1].options = operators
      oldFormJSON.fields[2].options = operatorOptions
      setConversionFormJSON(oldFormJSON)
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (operatorsData?.operators?.length) {
      setOperatorsToForm(operatorsData)
      const oldFormJSON = { ...addConversionJSON }
      if (oldFormJSON?.fields.length) {
        const valueElementType = JSON.parse(getValues().fieldType).type
        oldFormJSON.fields[2].type = valueElementType
        setConversionFormJSON(oldFormJSON)
        if (conversionEditData && Object.keys(conversionEditData).length) {
          setValue('operatorType', conversionEditData.conversionRule.operator)
          setValue('value', conversionEditData.conversionRule.value)
        } else {
          // @ts-ignore
          setValue('operatorType', JsonFieldTypeMap[valueElementType][0])
        }

        if (
          ['datetime'].includes(valueElementType) &&
          conversionEditData?.conversionRule.value
        ) {
          const date: Date = new Date(getValues().value)
          setValue('value', moment(date).format('YYYY-MM-DD'))
        }
        let fieldUpdateSpecificValue = ''
        if (
          ['select', 'enumeration', 'bool'].includes(valueElementType) &&
          !conversionEditData?.conversionRule.value
        ) {
          fieldUpdateSpecificValue = JSON.parse(getValues().fieldType)
            ?.options[0]?.value
          setValue('value', fieldUpdateSpecificValue)
        }
      }
    }
  }, [operatorsData])

  useEffect(() => {
    if (Object.keys(addConversionJSON).length && !isLoading && data) {
      const fields:
        | ((prevState: never[]) => never[])
        | { label: string; value: string }[] = []
      if (data && data.length) {
        data.forEach((field: any) => {
          fields.push({
            label: field.label,
            value: JSON.stringify({
              name: field.name,
              type: field.type,
              options: field?.options,
            }),
          })
        })

        const oldFormJSON = { ...addConversionJSON }
        //   @ts-ignore
        if (oldFormJSON?.fields.length && oldFormJSON?.fields[0].options) {
          //   @ts-ignore
          oldFormJSON.fields[0].options = fields
          setFieldOptions(fields)
          setConversionFormJSON(oldFormJSON)

          let fieldType = ''
          let value = ''
          if (conversionEditData && Object.keys(conversionEditData).length) {
            value = conversionEditData.conversionRule.value
            const selectedField = fields.find((field) => {
              return (
                JSON.parse(field.value).name ===
                conversionEditData.conversionRule.fieldName
              )
            })
            fieldType = selectedField?.value || ''
          } else {
            fieldType = fields[0].value
          }
          setValue('fieldType', fieldType)
          setValue('value', value)
        }
      }
    }
  }, [isLoading])

  return (
    <div
      className={`flex flex-col justify-between h-full ${isLoading && 'items-center'
        }`}
    >
      {isLoading ? (
        <div
          className='spinner-border spinner-border-sm m-[20px]'
          role='status'
        />
      ) : (
        <>
          <div className='overflow-y-auto p-8'>
            <div className='mb-[2.5rem]'>
              <span className='text-lg font-medium flex-grow-1'>
                Add Goal Conversion
              </span>
            </div>
            <form onSubmit={handleSubmit(onSave)}>
              <FormBuilder
                formJSON={conversionFormJSON}
                // @ts-ignore
                setValue={setValue}
                // @ts-ignore
                control={control}
              />
            </form>
          </div>
          <div className='flex flex-shrink-0 justify-between px-4 py-[0.75rem] bg-gray-50'>
            <button
              type='button'
              className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#0062FF] focus:ring-offset-2'
              onClick={() => setShowSlider(false)}
            >
              Cancel
            </button>
            <button
              type='submit'
              className={`ml-4 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[#0062FF] focus:ring-offset-2 ${isLoading ? 'bg-gray-400' : 'bg-[#0062FF]'
                }`}
              onClick={onSave}
            >
              Save & close
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default ConversionForm
