import { useEffect, useMemo, useState } from 'react'
import { HubspotIntegrationInterface } from 'src/container/integrations/integrations.interface'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Source, HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import StepBuilder, { QUERY_STEPS, URL_PARAMS } from './StepBuilder.Template'
import useGetSourceProperties from 'src/libs/hooks/queries/hubspot/useGetSourceProperties.query'
import toastBuilder from 'src/utils/toastBuilder'

export type HandleUpdate = (sourceModel: Partial<Source>) => void

interface Props {
  handleUpdate: HandleUpdate
  currentSource: Source
  integration: HubspotIntegrationInterface
}

const isvalidObjectProperty = (source: Source) => {
  if (
    !source.subscription_configs ||
    !source.subscription_configs.object_properties
  ) {
    return false
  }
  const configuredObjectProperties =
    source.subscription_configs.object_properties

  if (
    !configuredObjectProperties ||
    Object.keys(configuredObjectProperties).length < 1
  ) {
    return false
  }

  if (!source.conditions || Object.keys(source.conditions).length < 1) {
    return false
  }

  for (const objectKey in configuredObjectProperties) {
    for (const conditionGroupingKey in source.conditions) {
      const conditionsArray =
        source.conditions[conditionGroupingKey as 'all' | 'or']

      if (!conditionsArray || conditionsArray.length < 1) {
        return false
      }
      const matchedCondition = conditionsArray.find(
        (condition) => condition.fact === objectKey,
      )

      if (!matchedCondition) {
        return false
      }
    }
  }
  return true
}

const isValidCondition = (source: Source) => {
  if (!source.conditions || Object.keys(source.conditions).length < 1) {
    return false
  }
  for (const conditionGroupingKey in source.conditions) {
    const conditionsArray =
      source.conditions[conditionGroupingKey as 'all' | 'or']

    if (!conditionsArray || conditionsArray.length < 1) {
      return false
    }
    for (const condition of conditionsArray) {
      if (!condition.fact || !condition.operator || !condition.value) {
        return false
      }
    }
  }

  return true
}

const validateSource = (source: Source) => {
  return !!(isvalidObjectProperty(source) && isValidCondition(source))
}

const HubspotQueryBuilder: React.FC<Props> = ({
  currentSource,
  integration,
  handleUpdate,
}) => {
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()
  const [queryStep, setQueryStep] = useState<QUERY_STEPS>(
    (params.get(URL_PARAMS.STEP) as QUERY_STEPS) || QUERY_STEPS.SET_PROPERTY,
  )

  // Load Current Tab
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (!isMounted) {
      setParams({ [URL_PARAMS.STEP]: queryStep })
      setIsMounted(true)
    }
  }, [isMounted])

  const objectType = useMemo<HUBSPOT_OBJECT_TYPES | undefined>(
    () =>
      currentSource?.subscription_configs?.object_type as HUBSPOT_OBJECT_TYPES,
    [currentSource?.subscription_configs?.object_type],
  )

  const { isSuccess, data, isFetching } = useGetSourceProperties({
    integration,
    currentSource,
    objectType,
  })

  const objectProperties = useMemo(() => {
    if (isSuccess && data?.data?.sourceProperties) {
      return data?.data?.sourceProperties
    }
  }, [isSuccess, isFetching])

  const dispatchQueryStepChange = (step: QUERY_STEPS) => {
    setQueryStep(step)
    setParams({ [URL_PARAMS.STEP]: step })
  }

  const validSource = validateSource(currentSource)

  return (
    <div className='pt-16'>
      <div className='space-y-8 divide-y divide-gray-200'>
        <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
          <div className='header-section'>
            <h3 className='text-2xl leading-6 font-medium text-gray-900'>
              Hubspot Query
            </h3>
            <p className='mt-1 max-w-6xl text-xl text-gray-500'>
              Build your data query to enroll data into assignment routers.
            </p>
          </div>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <StepBuilder
              {...{
                queryStep,
                currentSource,
                objectType,
                handleUpdate,
                objectProperties,
                dispatchQueryStepChange,
              }}
            />
          </div>
          <div className='flex justify-end pt-8'>
            <button
              type='submit'
              disabled={!validSource}
              onClick={() => {
                toastBuilder(
                  'success',
                  `Source '${currentSource.name}' updated successfully`,
                )
                navigate('/sources')
              }}
              className='btn btn-primary'
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HubspotQueryBuilder
