import { useEffect, useState } from 'react'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import DropdownCustom, {
  defaultOptionRenderer,
} from 'src/components/atoms/Dropdown/DropdownCustom.atom'
import { IOption } from 'src/types/Ihome_state'
import MultipleDropdown from 'src/components/atoms/Dropdown/multipleDropdown'
import { getfromToDateRange } from 'src/utils'
import { User } from 'src/types/customers'
import { useAppSelector } from 'src/store'
import { getallusers } from 'src/container/my_account/account.selector'
import { useActions } from 'src/actions'
import { DropDownSearch, FiltersData, FrequencyType } from 'src/types/dashboard'
import { uselistofAllroutes } from 'src/container/leadRouter/leadRoutes.selector'
import { IRoute } from 'src/types/leadRoutes'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import { FREQUENCY_TYPES } from 'src/container/dashboard/dashboard'
import debounce from 'lodash.debounce'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import { authService } from 'src/services/auth.service'

interface IProps {
  setObjectType: (val: HUBSPOT_OBJECT_TYPES) => void
  setChangeRange: (range: number) => void
  selectedFrequency: string
  setSelectedFrequency: (frequency: FrequencyType) => void
  conversionsSelected?: DropDownSearch
  setUsersSelected: (value: IOption[]) => void
  setRoutersSelected: (value: IOption[]) => void
  limitUsersItems: number
  currentPageUser: number
  currentPageSLAsItems: number
  limitSLAsItems: number
  setDateRangeSLA: (value: string) => void
  orderBySLADetail: number
}

const changeOptionsConversions = (
  filterData: FiltersData,
  getConversionList: any,
) => {
  filterData.currentPage = 1
  const data = { ...filterData }
  getConversionList(data)
}

const debouncedChangesConversion = debounce(
  (filterData, getConversionList) =>
    changeOptionsConversions(filterData, getConversionList),
  1000,
)

const changeOptions = (
  filterData: FiltersData,
  getTotalSLAs: any,
  getTotalRouted: any,
  getRoutedOverview: any,
  getListRoutedLeads: any,
  getDetailSLAs: any,
) => {
  getTotalSLAs(filterData)
  getDetailSLAs(filterData)
  getTotalRouted(filterData)
  getRoutedOverview(filterData)
  getListRoutedLeads(filterData)
}

const debouncedChanges = debounce(
  (
    filterData,
    getTotalSLAs,
    getTotalRouted,
    getRoutedOverview,
    getListRoutedLeads,
    getDetailSLAs,
  ) =>
    changeOptions(
      filterData,
      getTotalSLAs,
      getTotalRouted,
      getRoutedOverview,
      getListRoutedLeads,
      getDetailSLAs,
    ),
  1000,
)

const changeDetailSLA = (filterData: FiltersData, getDetailSLAs: any) => {
  getDetailSLAs(filterData)
}

const debouncedChangesDetailSLA = debounce(
  (filterData, getDetailSLAs) => changeDetailSLA(filterData, getDetailSLAs),
  1000,
)

const changeOptionsCapacity = (
  filterData: FiltersData,
  getTotalCapacity: any,
) => {
  getTotalCapacity(filterData)
}

const debouncedChangesCapacity = debounce(
  (filterData, getTotalCapacity) =>
    changeOptionsCapacity(filterData, getTotalCapacity),
  1000,
)

const FiltersDashboard: React.FC<IProps> = (props) => {
  const {
    setObjectType,
    setChangeRange,
    selectedFrequency,
    conversionsSelected,
    setSelectedFrequency,
    setUsersSelected,
    setRoutersSelected,
    limitUsersItems,
    currentPageUser,
    setDateRangeSLA,
    limitSLAsItems,
    currentPageSLAsItems,
    orderBySLADetail,
  } = props

  const [selectedObjectType, setSelectedObjectType] = useState<IOption>({
    id: 1,
    label: 'Contacts',
    value: 'contacts',
  })
  const [filterData, setFilterData] = useState<FiltersData>()
  const [selectedDate, setselectedDate] = useState<{
    from: string
    to: string
    range?: number
  }>(getfromToDateRange(0))
  const allUsers = useAppSelector(getallusers)
  const listRoutes = useAppSelector(uselistofAllroutes)
  const [params, setParams] = useSearchParams()
  const [dataItemUsers, setDataItemUsers] = useState<IOption[]>([])
  const [dataItemRoutes, setDataItemRoutes] = useState<IOption[]>([])
  const [dealPipelineOptions, setDealPipelineOptions] = useState<IOption[]>([])
  const [pipelineType, setPipelineType] = useState<IOption>({
    value: 'all-pipelines',
    label: 'All Pipelines',
    id: -1,
  })
  const [customWidth, setCustomWidth] = useState('[15rem]')
  const ability = useAbility(AbilityContext)

  const [dateTo, setDateTo] = useState<string>('')
  const [dateFrom, setDateFrom] = useState<string>('')

  const {
    getallcustomer,
    getConversionsList,
    getTotalRouted,
    getallRoutes,
    getTotalSLAs,
    getTotalCapacity,
    getRoutedOverview,
    getConversionList,
    getListRoutedLeads,
    getDetailSLAs,
  } = useActions()

  const dateRangeOptions: IOption[] = [
    { id: 1, label: 'Today', value: 0 },
    { id: 3, label: 'Last 7 days', value: 7 },
    { id: 4, label: 'Last 14 days', value: 14 },
    { id: 5, label: 'Last 30 days', value: 30 },
    { id: 6, label: 'Last 60 days', value: 60 },
    { id: 7, label: 'Last 365 days', value: 365 },
  ]

  const classDropworn = 'h-[46px] rounded-[8px] mt-1'

  const availableObjectTypes = [
    {
      id: 1,
      label: 'Contacts',
      value: HUBSPOT_OBJECT_TYPES.CONTACTS,
    },
    {
      id: 2,
      label: 'Deals',
      value: HUBSPOT_OBJECT_TYPES.DEALS,
    },
    {
      id: 3,
      label: 'Companies',
      value: HUBSPOT_OBJECT_TYPES.COMPANIES,
    },
    {
      id: 4,
      label: 'Tickets',
      value: HUBSPOT_OBJECT_TYPES.TICKETS,
    },
  ]

  const getData = () => {
    if (filterData && allUsers.length > 0 && listRoutes.length > 0) {
      if (
        filterData.objectType === 'contacts' ||
        filterData.objectType === 'deals'
      ) {
        debouncedChangesCapacity(filterData, getTotalCapacity)
      }

      debouncedChanges(
        filterData,
        getTotalSLAs,
        getTotalRouted,
        getRoutedOverview,
        getListRoutedLeads,
        getDetailSLAs,
      )
      debouncedChangesConversion(filterData, getConversionList)
    }
  }

  useEffect(() => {
    setObjectType(HUBSPOT_OBJECT_TYPES.CONTACTS)
    getallcustomer()
    getallRoutes()

    if (ability.can('access', 'conversions')) {
      getConversionsList()
    }
  }, [])

  useEffect(() => {
    if (limitSLAsItems && filterData) {
      filterData.limit = limitSLAsItems
      const data = { ...filterData }
      debouncedChangesDetailSLA(data, getDetailSLAs)
    }
  }, [limitSLAsItems])

  useEffect(() => {
    if (orderBySLADetail && filterData) {
      filterData.orderDirection = orderBySLADetail.toString()
      const data = { ...filterData }
      debouncedChangesDetailSLA(data, getDetailSLAs)
    }
  }, [orderBySLADetail])

  useEffect(() => {
    if (currentPageSLAsItems && filterData) {
      filterData.currentPage = currentPageSLAsItems
      const data = { ...filterData }
      debouncedChangesDetailSLA(data, getDetailSLAs)
    }
  }, [currentPageSLAsItems])

  useEffect(() => {
    let dataRoutes: IOption[] = []
    if (listRoutes) {
      dataRoutes.push({
        id: 0,
        label: 'All Routers',
        value: '',
        checked: true,
      })

      listRoutes.forEach((item: IRoute, index: number) => {
        dataRoutes.push({
          id: index + 1,
          value: item._id,
          label: item.name,
          checked: false,
        })
      })

      if (params.has('router_ids')) {
        const value = params.get('router_ids')
        let isRouter: any = {}
        if (value && listRoutes.length > 0) {
          dataRoutes = []
          dataRoutes.push({
            id: 0,
            label: 'All Routers',
            value: '',
            checked: false,
          })

          const values: string[] = value.split(',')
          listRoutes.forEach((item: IRoute, index: number) => {
            isRouter = values.filter((element: string) => element === item._id)
            dataRoutes.push({
              id: index + 1,
              value: item._id,
              label: item.name,
              checked: isRouter.length > 0 ? true : false,
            })
          })
        }
      }

      setDataItemRoutes(dataRoutes)
    }
  }, [listRoutes])

  function updateFilterObject(target: any, src: any) {
    const res: any = {}
    Object.keys(target).forEach((k) => (res[k] = k in src ? src[k] : target[k]))
    return res
  }

  const sendSelectedDate = (value: number) => {
    const dateRange = getfromToDateRange(value)
    dateRange.from = moment(dateRange.from).startOf('day').toISOString()
    dateRange.to = moment(dateRange.to).endOf('day').toISOString()
    setselectedDate(dateRange)
  }

  const setFiltersByParams = () => {
    if (params.has('objectType')) {
      const value = params.get('objectType')
      const objectType = availableObjectTypes.filter(
        (element) => element.value === value,
      )
      setObjectType(objectType[0].value)
      setSelectedObjectType(objectType[0])
    }

    if (params.has('frequency')) {
      const value = params.get('frequency')
      const frequency = FREQUENCY_TYPES.filter(
        (element) => element.value === value,
      )
      setSelectedFrequency(frequency[0])
    }

    if (params.has('dateRange')) {
      const value = params.get('dateRange')
      if (value) {
        sendSelectedDate(Number(value))
      }
    }
  }

  useEffect(() => {
    if (listRoutes && allUsers) {
      let defaultData = {
        objectType: 'contacts',
        startDate: moment(getfromToDateRange(0).from)
          .startOf('day')
          .toISOString(),
        endDate: moment(getfromToDateRange(0).to).endOf('day').toISOString(),
        router_ids: getIdsRoutes(listRoutes, false),
        user_ids: getIdsUsers(allUsers, false),
        frequency: 'daily',
        conversion_id: conversionsSelected ? conversionsSelected.id : '',
        currentPage: 1,
        limit: 5,
        routePagerLimit: 5,
        currentPageRouter: 1,
        orderBy: 'slaStatus',
        orderDirection: '-1',
      }

      setFiltersByParams()

      const allParams: any = {}

      params.forEach((value, key) => {
        if (key === 'user_ids' || key === 'router_ids') {
          allParams[key] = value.split(',')
        } else if (key === 'dateRange') {
          allParams['startDate'] = moment(
            getfromToDateRange(Number(value)).from,
          )
            .startOf('day')
            .toISOString()
          allParams['endDate'] = moment(getfromToDateRange(Number(value)).to)
            .endOf('day')
            .toISOString()
        } else {
          allParams[key] = value
        }
      })
      defaultData = updateFilterObject(defaultData, allParams)
      setFilterData(defaultData)
    }
  }, [listRoutes, allUsers])

  useEffect(() => {
    let dataUsers: IOption[] = []
    const fetchedUsers = allUsers.map((user: User) => ({
      ...user,
      label: user.firstName + ' ' + user.lastName,
    }))
    dataUsers.push({
      id: 0,
      label: 'All users',
      value: '',
      checked: true,
    })
    fetchedUsers.forEach((user: User, index: number) => {
      dataUsers.push({
        id: index + 1,
        value: user._id,
        label: user.firstName + ' ' + user.lastName,
        checked: false,
      })
    })

    if (params.has('user_ids')) {
      const value = params.get('user_ids')
      let isUser: any = {}
      if (value && allUsers.length > 0) {
        dataUsers = []
        dataUsers.push({
          id: 0,
          label: 'All users',
          value: '',
          checked: false,
        })

        const values: string[] = value.split(',')
        fetchedUsers.forEach((user: User, index: number) => {
          isUser = values.filter((element: string) => element === user._id)
          dataUsers.push({
            id: index + 1,
            value: user._id,
            label: user.firstName + ' ' + user.lastName,
            checked: isUser.length > 0 ? true : false,
          })
        })
      }
    }

    setDataItemUsers(dataUsers)
  }, [allUsers])

  const getIdsUsers = (allUsers: any, option: boolean) => {
    const usersIds: string[] = []

    if (!option) {
      allUsers.length > 0 &&
        allUsers.map((item: any) => {
          usersIds.push(item._id)
        })
      return usersIds
    }

    allUsers.length > 0 &&
      allUsers.map((item: any) => {
        usersIds.push(item.value)
      })

    return usersIds
  }

  const getIdsRoutes = (listRoutes: any, option: boolean) => {
    const routersIds: string[] = []
    if (!option) {
      listRoutes.length > 0 &&
        listRoutes.map((item: IRoute) => {
          routersIds.push(item._id)
        })
      return routersIds
    }

    listRoutes.length > 0 &&
      listRoutes.map((item: any) => {
        routersIds.push(item.value)
      })
    return routersIds
  }

  useEffect(() => {
    if (filterData && dataItemUsers) {
      let list = dataItemUsers.filter((itemData: IOption) => itemData.checked)
      if (dataItemUsers[0].checked) {
        list = dataItemUsers.filter((itemData: IOption) => !itemData.checked)
      }

      if (list.length > 0) {
        setUsersSelected(list)
      }

      const users = getIdsUsers(list, true)
      let idsUsers = ''
      let comma = ''
      if (users.length > 0) {
        users.forEach((id, index) => {
          comma = ','
          if (index === users.length - 1) {
            comma = ''
          }
          idsUsers += id + comma
        })
        if (
          !dataItemUsers[0].checked &&
          list.length !== dataItemUsers.length - 1
        ) {
          addParametersUrl('user_ids', idsUsers)
        } else {
          params.delete('user_ids')
          setParams(params)
        }
      }

      filterData.user_ids = getIdsUsers(list, true)
      filterData.currentPage = 1
      const data = { ...filterData }
      setFilterData(data)
      getData()
    }
  }, [dataItemUsers])

  useEffect(() => {
    if (filterData && dataItemRoutes) {
      let list = dataItemRoutes.filter((itemData: IOption) => itemData.checked)

      if (dataItemRoutes[0].checked) {
        list = dataItemRoutes.filter((itemData: IOption) => !itemData.checked)
      }

      if (list.length > 0) {
        setRoutersSelected(list)
      }

      const routers = getIdsRoutes(list, true)
      let idsRouters = ''
      let comma = ''
      if (routers.length > 0) {
        routers.forEach((id, index) => {
          comma = ','
          if (index === routers.length - 1) {
            comma = ''
          }
          idsRouters += id + comma
        })

        if (
          !dataItemRoutes[0].checked &&
          list.length !== dataItemRoutes.length - 1
        ) {
          addParametersUrl('router_ids', idsRouters)
        } else {
          params.delete('router_ids')
          setParams(params)
        }
      }

      filterData.router_ids = routers
      filterData.currentPage = 1
      const data = { ...filterData }
      setFilterData(data)
      getData()
    }
  }, [dataItemRoutes])

  useEffect(() => {
    if (filterData && selectedObjectType) {
      if (selectedObjectType.value === 'contacts') {
        deleteParameter('objectType')
      } else {
        addParametersUrl('objectType', selectedObjectType.value)
      }

      filterData.objectType = selectedObjectType.value
      filterData.currentPage = 1
      const data = { ...filterData }
      setFilterData(data)
      getData()
    }
  }, [selectedObjectType])

  useEffect(() => {
    if (filterData && selectedDate) {
      filterData.startDate = selectedDate.from
      filterData.endDate = selectedDate.to

      const fromDate = moment(getfromToDateRange(0).from)
        .startOf('day')
        .toISOString()
      const toDate = moment(getfromToDateRange(0).to).endOf('day').toISOString()

      if (fromDate === selectedDate.from && toDate === selectedDate.to) {
        deleteParameter('startDate')
        deleteParameter('endDate')
        deleteParameter('dateRange')
      } else {
        if ('range' in selectedDate) {
          deleteParameter('startDate')
          deleteParameter('endDate')
          addParametersUrl('dateRange', selectedDate.range)
        } else {
          deleteParameter('dateRange')
          addParametersUrl('startDate', selectedDate.from)
          addParametersUrl('endDate', selectedDate.to)
        }
      }
      filterData.currentPage = 1
      const data = { ...filterData }
      setFilterData(data)
      getData()
    }
  }, [selectedDate])

  useEffect(() => {
    if (
      filterData &&
      conversionsSelected &&
      listRoutes.length > 0 &&
      allUsers.length
    ) {
      if (
        filterData.conversion_id !== '' &&
        filterData.conversion_id !== conversionsSelected.id
      ) {
        addParametersUrl('conversion_id', conversionsSelected.id)
      }

      filterData.conversion_id = conversionsSelected.id
      const data = { ...filterData }
      setFilterData(data)
      debouncedChangesConversion(filterData, getConversionList)
    }
  }, [conversionsSelected])

  useEffect(() => {
    if (filterData && selectedFrequency) {
      filterData.frequency = selectedFrequency

      if (selectedFrequency === 'daily') {
        deleteParameter('frequency')
      } else {
        addParametersUrl('frequency', selectedFrequency)
      }

      const data = { ...filterData }
      setFilterData(data)
      getRoutedOverview(filterData)
    }
  }, [selectedFrequency])

  useEffect(() => {
    if (currentPageUser && filterData) {
      filterData.currentPage = currentPageUser
      const data = { ...filterData }
      getListRoutedLeads(data)
    }
  }, [currentPageUser])

  useEffect(() => {
    if (limitUsersItems && filterData) {
      filterData.limit = limitUsersItems
      filterData.currentPage = 1
      const data = { ...filterData }
      getListRoutedLeads(data)
    }
  }, [limitUsersItems])

  const onObjectTypeChange = (option: any) => {
    setObjectType(option.label)
    setSelectedObjectType(option)
  }

  const clearFilter = () => {
    setselectedDate(getfromToDateRange(0))
    setSelectedFrequency(FREQUENCY_TYPES[0])

    let oldData: IOption[] = []

    dataItemUsers.forEach((item: IOption, index: number) => {
      oldData.push({
        id: index,
        value: item.value,
        label: item.label,
        checked: false,
      })
    })
    oldData[0].checked = true

    setDataItemUsers(oldData)

    oldData = []

    dataItemRoutes.forEach((item: IOption, index: number) => {
      oldData.push({
        id: index,
        value: item.value,
        label: item.label,
        checked: false,
      })
    })
    oldData[0].checked = true

    setDataItemRoutes(oldData)
    setSelectedObjectType(availableObjectTypes[0])
    params.forEach((value, key) => {
      params.delete(key)
    })
    setParams(params)
    setPipelineType({ value: 'all-pipelines', label: 'All Pipelines', id: -1 })
    if (filterData?.pipeline) {
      filterData.pipeline = 'all-pipelines'
    }
  }

  const getDateRangeOption = (selectedDateOption: any): IOption => {
    const valueFind = dateRangeOptions.find(
      (opt: any) => opt.value === selectedDateOption.range,
    )
    if (!valueFind) {
      return dateRangeOptions[1]
    }
    setDateRangeSLA(valueFind.label)
    return valueFind
  }

  const handleDateRangeChange = (option: any, dateCustom?: boolean) => {
    setDateRangeSLA(option.label)
    setChangeRange(getfromToDateRange(option.value).range)
    if (!dateCustom) {
      const dateRange = getfromToDateRange(option.value)
      dateRange.from = moment(dateRange.from).startOf('day').toISOString()
      dateRange.to = moment(dateRange.to).endOf('day').toISOString()
      setselectedDate(dateRange)
      return
    }

    setChangeRange(0)

    const dates = option.label.split('/')

    const dateValue = {
      from: dates[0],
      to: dates[1],
    }
    setDateRangeSLA(dates[0] + ' / ' + dates[1])
    setselectedDate(dateValue)
  }

  const addParametersUrl = (filterType: string, value: any) => {
    if (params.has(filterType)) {
      params.set(filterType, value)
      setParams(params)
      return
    }
    params.append(filterType, value)
    setParams(params)
  }

  const deleteParameter = (filterType: string) => {
    params.delete(filterType)
    setParams(params)
  }

  useEffect(() => {
    if (
      pipelineType &&
      pipelineType.value &&
      filterData &&
      selectedObjectType.value === HUBSPOT_OBJECT_TYPES.DEALS
    ) {
      filterData.pipeline = pipelineType.value
      debouncedChangesCapacity(filterData, getTotalCapacity)
    }
  }, [pipelineType])

  useEffect(() => {
    authService.getDealPipelines().then((rsp: any) => {
      if (rsp && rsp.length > 0) {
        const pipelineOptions = rsp.map(
          (option: { label: string; value: string }, index: number) => ({
            id: index,
            label: option.label,
            value: option.value,
          }),
        )

        pipelineOptions.push({
          id: -1,
          label: 'All Pipelines',
          value: 'all-pipelines',
        })

        setDealPipelineOptions(pipelineOptions)
        const index = pipelineOptions.findIndex(
          (itemData: IOption) =>
            itemData.value === 'all-pipelines' || itemData.label === 'All',
        )
        setPipelineType(pipelineOptions[index])
      }
    })
  }, [])

  const onPipelineChange = (pipeline: IOption) => {
    setPipelineType({ ...pipeline })
  }

  useEffect(() => {
    if (
      selectedObjectType &&
      selectedObjectType.value === HUBSPOT_OBJECT_TYPES.DEALS
    ) {
      setCustomWidth('w-[13rem]')
    } else {
      setCustomWidth('w-[15rem]')
    }
  }, [selectedObjectType])

  return (
    <div className='dashboard-filter-reaper px-4 mb-5 flex items-center flex-wrap justify-between'>
      <div className='flex items-center flex-wrap w-full lg:justify-between'>
        <DropdownCustom
          dropdownLabel='Object'
          options={availableObjectTypes}
          selectedOption={selectedObjectType}
          setSelectedOption={onObjectTypeChange}
          optionRenderer={defaultOptionRenderer}
          classInput={classDropworn}
          customClass='mr-8'
          disabled={
            !ability.can('read', 'dashboard') ||
            !ability.can('access', 'dashboard')
          }
          customWidth={customWidth}
        />
        {selectedObjectType &&
          selectedObjectType.value === HUBSPOT_OBJECT_TYPES.DEALS && (
            <DropdownCustom
              dropdownLabel='Pipeline'
              options={dealPipelineOptions}
              selectedOption={pipelineType}
              setSelectedOption={onPipelineChange}
              optionRenderer={defaultOptionRenderer}
              classInput={classDropworn}
              customClass='mr-8'
              customWidth={customWidth}
            />
          )}
        <MultipleDropdown
          dropdownName='Routers'
          labelDropdown='By Router'
          placeholderDropdown='All Routers'
          data={dataItemRoutes}
          inputSearch={true}
          setDataItem={setDataItemRoutes}
          idDropDown={'dropdownRouters'}
          disabled={
            !ability.can('read', 'dashboard') ||
            !ability.can('access', 'dashboard')
          }
          customWidth={customWidth}
        />
        <DropdownCustom
          dropdownLabel='Date range'
          options={dateRangeOptions}
          selectedOption={getDateRangeOption(selectedDate)}
          setSelectedOption={handleDateRangeChange}
          optionRenderer={defaultOptionRenderer}
          classInput={classDropworn}
          customClass='mr-8'
          setDateRangeSLA={setDateRangeSLA}
          datePicker={{
            datePicker: true,
            dateFrom: dateFrom,
            dateTo: dateTo,
          }}
          disabled={
            !ability.can('read', 'dashboard') ||
            !ability.can('access', 'dashboard')
          }
          customWidth={customWidth}
        />
        <MultipleDropdown
          dropdownName='Users'
          labelDropdown='User'
          placeholderDropdown='All Users'
          data={dataItemUsers}
          inputSearch={true}
          setDataItem={setDataItemUsers}
          idDropDown={'dropdownUsers'}
          disabled={
            !ability.can('read', 'dashboard') ||
            !ability.can('access', 'dashboard')
          }
          customWidth={customWidth}
        />
        <div className={`flex lg:justify-end mt-2`}>
          <button
            className='px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start mt-[36px]'
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        </div>
      </div>
    </div>
  )
}

export default FiltersDashboard
