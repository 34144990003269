import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { RootState } from 'src/store'
const selectedreducer = (state: RootState) => state.dashboardState

export const totalRoutedData = createDraftSafeSelector(
	selectedreducer,
	(dashboardS) => dashboardS.totalRouted,
)

export const totalCapacityData = createDraftSafeSelector(
	selectedreducer,
	(dashboardS) => dashboardS.totalCapacities,
)

export const totalSLAsData = createDraftSafeSelector(
	selectedreducer,
	(dashboardS) => dashboardS.totalSLAs,
)

export const routedOverviewData = createDraftSafeSelector(
	selectedreducer,
	(dashboardS) => dashboardS.routedOverview,
)

export const conversionListData = createDraftSafeSelector(
	selectedreducer,
	(dashboardS) => dashboardS.conversionList,
)

export const use_home_data = createDraftSafeSelector(
	selectedreducer,
	(dashboardS) => dashboardS.dashboard,
)

export const routedLeadsData = createDraftSafeSelector(
	selectedreducer,
	(dashboardS) => dashboardS.routedLeads,
)

export const detailSLAs = createDraftSafeSelector(
	selectedreducer,
	(dashboardS) => dashboardS.detailsSLAs,
)