import { useEffect, useMemo, useState } from 'react'
import { IRouterDetail } from 'src/types/routerDetail'
import { usespecificRouterDetail } from '../../container/RouterDetail/routerDetail.selector'
import { useAppSelector } from '../../store'
import EditNameHeader from '../organisms/forms/editNameHeader/EditNameHeader.Organism'

type Iprops = {
  edit_router_name: {
    error: boolean
    edit: boolean
  }
  set_edit_router_name: any
  handle_router_save_click: any
  disabled?: boolean
}

const Edit_router_name: React.FC<Iprops> = (props) => {
  const {
    handle_router_save_click,
    disabled = false
  } = props

  const specificRouterDetail = useAppSelector(usespecificRouterDetail)

  const [edit_router, set_edit_router] = useState<{
    text: string
  }>({ text: '' })

  useEffect(() => {
    set_edit_router({
      text: specificRouterDetail.name as string,
    })
  }, [specificRouterDetail.name])

  const handleUpdate = ({ name }: Partial<IRouterDetail>) => {
    handle_router_save_click(name)
  }

  const EditNameHeaderWrapper = useMemo(() => {
    const EditName = () => (
      <>
        {edit_router.text ? (
          <EditNameHeader<IRouterDetail>
            currentName={edit_router.text}
            handleOnSubmit={handleUpdate}
            disabled={disabled}
          />
        ) : (
          <h3 className='mt-12'>Loading...</h3>
        )}
      </>
    )
    return EditName
  }, [edit_router.text])

  return <EditNameHeaderWrapper />
}

export default Edit_router_name
