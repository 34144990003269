import React from 'react'
import hobspot from '../../assets/images/hubspot-logo.png'

const HubsportLogo: React.FC<{
  onclick: any
}> = ({ onclick }) => {
  return (
    <div className=''>
      <div className='connect-wrapper'>
        <div className='connect-box'>
          <div onClick={onclick} className='connectLogo'>
            <img src={hobspot} alt='' />
          </div>
          <h6>HubSpot</h6>
        </div>
      </div>
    </div>
  )
}

export default HubsportLogo
