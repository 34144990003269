import React, { useEffect } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'

import Login from '../container/auth/Login'
import PrivacyPolicy from '../components/PrivacyPolicy'
import { useActions } from '../actions'
import { CallbackUrl } from 'src/container/auth/CallbackUrl'

const UnAuthenticatedRoutes: React.FC = () => {
  const location = useLocation()
  const { getProductMetricsInfosAction } = useActions()

  useEffect(() => {
    getProductMetricsInfosAction()
  }, [])

  return (
    <Routes location={location}>
      <Route path='/' element={<Login />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path="/callback" element={<CallbackUrl />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  )
}

export default UnAuthenticatedRoutes
