import { Toggle } from 'src/components/atoms/Toggle/Toggle.atom'
import SelectableTable from 'src/components/organisms/tables/SelectableTable.Organism'
import { useEffect } from 'react'
import {
  PERMISSIONS_SUBJECTS,
  PermissionsType,
  RolesPermissions,
} from 'src/types/roles'

interface IProps {
  dataRole: PermissionsType[] | undefined
  handleStatusChange: (data: any, value: boolean) => void
  setDataConversions: (val: RolesPermissions[]) => void
  dataConversions: RolesPermissions[]
  freePlan: boolean
}

const ConversionRole: React.FC<IProps> = (props) => {
  const {
    dataRole,
    handleStatusChange,
    setDataConversions,
    dataConversions,
    freePlan,
  } = props

  useEffect(() => {
    const permissionsConversion = [
      {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
    ]
    setDataConversions(permissionsConversion)
    if (dataRole && dataRole.length > 0) {
      const dataPermissionsConversion: PermissionsType[] = dataRole.filter(
        (item: PermissionsType) =>
          item.subject === PERMISSIONS_SUBJECTS.CONVERSION,
      )
      if (dataPermissionsConversion.length > 0) {
        dataPermissionsConversion.forEach((element: PermissionsType) => {
          switch (element.action) {
            case 'read':
              permissionsConversion[0].read = true
              break
            case 'create':
              permissionsConversion[0].create = true
              break
            case 'update':
              permissionsConversion[0].update = true
              break
            case 'delete':
              permissionsConversion[0].delete = true
              break
          }
        })
      }
      setDataConversions(permissionsConversion)
    }
  }, [dataRole])

  const columnsRolesConversions = [
    {
      label: 'Name',
      render: () => {
        return <div>Conversion</div>
      },
    },
    {
      label: 'Permission to Read',
      render: ({ read }: any) => {
        return (
          <Toggle
            initialValue={read}
            data={{
              actionName: 'read',
              subject: PERMISSIONS_SUBJECTS.CONVERSION,
            }}
            handleChange={handleStatusChange}
            disabled={freePlan}
          />
        )
      },
    },
    {
      label: 'Permission to create',
      render: ({ create }: any) => {
        return (
          <Toggle
            initialValue={create}
            data={{
              actionName: 'create',
              subject: PERMISSIONS_SUBJECTS.CONVERSION,
            }}
            handleChange={handleStatusChange}
            disabled={freePlan}
          />
        )
      },
    },
    {
      label: 'Permission to edit',
      render: ({ update }: any) => {
        return (
          <Toggle
            initialValue={update}
            data={{
              actionName: 'update',
              subject: PERMISSIONS_SUBJECTS.CONVERSION,
            }}
            handleChange={handleStatusChange}
            disabled={freePlan}
          />
        )
      },
    },
    {
      label: 'Permission to delete',
      render: ({ delete: deleteR }: any) => {
        return (
          <Toggle
            initialValue={deleteR}
            data={{
              actionName: 'delete',
              subject: PERMISSIONS_SUBJECTS.CONVERSION,
            }}
            handleChange={handleStatusChange}
            disabled={freePlan}
          />
        )
      },
    },
  ]

  return (
    <div className='px-5 py-7'>
      <div className='flex flex-col'>
        <span className='text-lg leading-[27px] text-gray-700 font-medium'>
          Conversion Permissions
        </span>
        <span className='text-sm leading-[21px] font-normal text-gray-700 mt-2'>
          Review your Role is setup correctly and publish it live
        </span>
      </div>
      <SelectableTable
        columns={columnsRolesConversions}
        rows={dataConversions}
        showSelectionType={false}
        showHeaderMeta={false}
        borderTable={true}
        className='p-0'
      />
    </div>
  )
}

export default ConversionRole
