import { useEffect, useState } from 'react'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'

const TypeConversions = ({
  valueSelect,
  setObjectType,
  setThereAreChanges,
}: any) => {
  const [changeValue, setChangeValue] = useState(valueSelect)

  useEffect(() => {
    setChangeValue(valueSelect)
  }, [valueSelect])

  const changeSelectType = (value: string) => {
    setChangeValue(value)
    setObjectType(value)
    setThereAreChanges(true)
  }

  return (
    <div className='px-4 p-4'>
      <span className='text-gray-700 text-xl leading-[30px]'>
        Object Type
      </span>
      <p className='text-gray-700 text-sm leading-[21px] mt-2'>
        Select which object you want to see conversions for
      </p>
      <div>
        <label className='block mb-2 text-sm font-medium text-gray-800'>
          Object Type
        </label>
        <select
          id='countries'
          value={changeValue}
          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 max-w-[400px]'
          onChange={(value) => changeSelectType(value.target.value)}
        >
          <option value={HUBSPOT_OBJECT_TYPES.CONTACTS}>Contact</option>
          <option value={HUBSPOT_OBJECT_TYPES.DEALS}>Deal</option>
          <option value={HUBSPOT_OBJECT_TYPES.COMPANIES}>Company</option>
          <option value={HUBSPOT_OBJECT_TYPES.TICKETS}>Ticket</option>
        </select>
      </div>
    </div>
  )
}

export default TypeConversions
