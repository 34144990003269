export type DataReviewPublish = {
  label: string
  id: number
  tableData: {
    name: string
    read: boolean | undefined
    create: boolean | undefined
    edit: boolean | undefined
    deleteP: boolean | undefined
    id: number
  }[]
}

export type PermissionsUser = {
  permissions: {
    firstName: string
    role: RolesListType[]
    userId: string
  }
}

export type RolesListType = {
  access: AccessType | undefined
  role: RoleType | undefined
}

export type RoleType = {
  canBeRemoved: boolean
  id: string
  name: string
  permissions: PermissionsType[]
}

export type PermissionsType = {
  action: string
  condition?: null
  description: string
  field?: null
  slug: string
  subject: string
  _id: string
  name: string
}

export type RolesPermissions = {
  read: boolean
  create: boolean
  update: boolean
  delete: boolean
}

export type AccessType = {
  conversions: {
    fullAccess: boolean
  }
  dashboard: {
    fullAccess: boolean
  }
  routers: {
    fullAccess: boolean
  }
  settings: {
    fullAccess: boolean
  }
}

export type RolesState = {
  allRoles: RolesListType[]
  specificRoleById: SpecificRole[]
  listAllPermissions: PermissionsType[]
}

export type SpecificRole = {
  canBeRemoved: boolean
  name: string
  _id: string
  permissions: PermissionsType[]
}

export type PermissionType = {
  subject: string
  name: string
  read: boolean
  create: boolean
  update: boolean
  delete: boolean
  hasRead: PermissionsType | undefined
  hasUpdate: PermissionsType | undefined
  hasCreate: PermissionsType | undefined
  hasDelete: PermissionsType | undefined
}

export type ColumnName = {
  subject: string
  name: string
  hasRead: PermissionsType | undefined
  hasUpdate: PermissionsType | undefined
  hasCreate: PermissionsType | undefined
  hasDelete: PermissionsType | undefined
}

export enum PERMISSIONS_SUBJECTS {
  DASHBOARD = 'dashboard',
  ROUTER = 'router',
  ROUTER_TRIGGER = "router.trigger",
  ROUTER_USERS = "router.users",
  ROUTER_MODEL = "router.model",
  ROUTER_SLA = "router.sla",
  ROUTER_ACCOUNT_MATCHING = "router.account_matching",
  CONVERSION = 'conversion',
  SETTING = "setting",
  SETTING_PROFILE_USER_SETTING = "setting.profile.user_setting",
  SETTING_PROFILE_ASSIGNMENT_STATUS = "setting.profile.assignment_status",
  SETTING_PROFILE_WORKING_HOURS = "setting.profile.working_hours",
  SETTING_ACCOUNT = "setting.account",
  SETTING_ROLE = "setting.role",
  SETTING_USER = "setting.user",
  SETTING_USER_TEAM = "setting.user.team",
  SETTING_CAPACITIES = "setting.capacities",
  SETTING_INTEGRATION = "setting.integration",
  SETTING_BILLING = "setting.billing"
}