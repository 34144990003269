import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './styles/index.scss'
import './assets/css/tailwind.css'
import UnauthenticatedApp from './Routes/UnauthenticatedApp'
import AuthenticatedApp from './Routes/AuthenticatedApp'
import { HelmetProvider } from 'react-helmet-async'
import Helmet from './components/organisms/helmet/Helmet.Organism'
import { selectToken } from 'src/container/auth/auth.selector'
import { useAppSelector } from 'src/store'
import { config } from 'src/config/constants'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ReactTagManager } from 'react-gtm-ts';
const { RECAPTCHA_SITE_KEY, GOOGLE_TAG_MANAGER_ID } = config

ReactTagManager.init({
  code: GOOGLE_TAG_MANAGER_ID, // GTM Code
  debug: false, // debug mode (default false)
  performance: false, // starts GTM only after user interaction (improve initial page load)
});

const App = () => {
  const token = useAppSelector(selectToken)
  return (
    <div className={token && 'bg-gray-100 h-[calc(100vh-64px)]'}>
      <HelmetProvider>
        <Helmet />
        <ToastContainer position='bottom-right' limit={3} />
        {token ? <AuthenticatedApp /> :
          <UnauthenticatedApp />
        }
      </HelmetProvider>

    </div>
  )
}

export default App
