import { Dispatch, SetStateAction } from 'react'
import { userRoles } from 'src/config/constants'
import { selectUser } from 'src/container/auth/auth.selector'
import { useAppSelector } from 'src/store'
import { PlusIcon } from '@heroicons/react/24/outline'

interface Props {
  setShowCreateModal: Dispatch<SetStateAction<boolean>>
}

const SourcesHeaderTemplate = ({ setShowCreateModal }: Props) => {
  const { role } = useAppSelector(selectUser)

  return (
    <div className='mt-28 w-full relative'>
      <div className='section-header w-full flex justify-between'>
        <h3 className='flex-grow-1 text-2xl'>Sources</h3>
        <button
          className='flex flex-row items-center px-4 py-2 bg-routera-700 rounded text-white'
          onClick={() => setShowCreateModal(true)}
        >
          <PlusIcon
            className='inline h-4 w-4 text-white'
            aria-hidden='true'
          />
          <span className='ml-2 inline-block'>Add Source</span>
        </button>
      </div>
    </div>
  )
}
export default SourcesHeaderTemplate
