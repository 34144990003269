import React from 'react'
import { Form, Modal, Button } from 'react-bootstrap'

import { Formik } from 'formik'
import { useActions } from '../../actions'
import toastBuilder from '../../utils/toastBuilder'
import { createNewCustomerValidation } from '../../validations/myAccount'
import { getrunagain } from '../../container/Loader/loader.selector'
import { useAppSelector } from '../../store'
import { useDispatch } from 'react-redux'
import { setrunagain } from '../../container/Loader/loader.slice'

const CreatNewuser: React.FC<{ show: boolean; setShow: any }> = (props) => {
  const { show, setShow } = props
  const runagain = useAppSelector(getrunagain)
  const dispatch = useDispatch()
  const { registerCustomer } = useActions()

  const handleFormSubmit = async (values: any) => {
    const action: any = await registerCustomer([values])

    if (action.payload.success) {
      toastBuilder('success', 'user invited successfully')
      setShow(false)
      dispatch(setrunagain(!runagain))
    }
  }

  const initaValue = {
    email: '',
    firstName: '',
    lastName: '',
  }

  return (
    <>
      {show ? (
        <Modal show={true} onHide={() => setShow(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Add New User</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initaValue}
              validationSchema={createNewCustomerValidation}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                dirty,
                isValid,
                errors,
              }) => {
                return (
                  <Form className='authform' onSubmit={handleSubmit}>
                    <div className='mainform accountform mt-0 mb-0'>
                      <div className='mb-4'>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          className='input'
                          type='text'
                          value={values.email}
                          onBlur={handleBlur}
                          name='email'
                          onChange={handleChange}
                          isInvalid={!!errors.email && touched.email}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {touched.email && errors.email}
                        </Form.Control.Feedback>
                      </div>
                      <div className='mb-4'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          className='input'
                          type='text'
                          value={values.firstName}
                          onBlur={handleBlur}
                          name='firstName'
                          onChange={handleChange}
                          isInvalid={!!errors.firstName && touched.firstName}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {touched.firstName && errors.firstName}
                        </Form.Control.Feedback>
                      </div>
                      <div className='mb-4'>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          className='input'
                          type='text'
                          name='lastName'
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.lastName && touched.lastName}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {touched.lastName && errors.lastName}
                        </Form.Control.Feedback>
                      </div>
                      <div>
                        <Button
                          type='submit'
                          className='btn btn-primary float-end mb-3 mt-2'
                        >
                          Add User
                        </Button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  )
}

export default CreatNewuser
