import { createAsyncThunk } from '@reduxjs/toolkit'
// i will remove it later to sources sacreen
import { sourceService } from '../../services/sources.service'

import { routerDetailService } from '../../services/router_detail.service'
import { customService } from '../../services/customer.service'
import {
  Iall_team_Members,
  IRouterDetail,
  ISource,
  ITeamMember,
} from '../../types/routerDetail'
import { ApiClient, socketClient } from '../..'
import { team_lead_add_callback } from '../../socket/callbackFunctions'
import { Ispecific_souce_detail } from '../../types/specific_source'

enum actiontype {
  getRouterDetail = 'getRouterDetail action type',
  getTeamMembersList = 'getTeamMembersList action type',
  getAssignedTeamMembersList = 'getAssignedTeamMembersList action type',
  updateAssignedTeamMembers = 'updateAssignedTeamMembersList action type',
  addNewSource = 'addNewSource action type',
  getAllSources = 'getAllSources action type',
  getAssignableSources = 'getAssignableSources action type',
  updateAssignedSourcesList = 'updateAssignedSourcesList action type',
  AssignedUserAction = 'AssignedUserAction AssignedUserAction',
  assign_model = 'assign_model action type',
  publish_router = 'publish_router',
  edit_router = 'edit_router',
  get_Specific_Source = 'get_Specific_Source type',
  addNewSourcedsds = 'addNewSourcedsdsaddNewSourcedsdsaddNewSourcedsdsaddNewSourcedsds',
  setTrigger = 'SET_TRIGGER',
  SET_SLA = 'SET_SLA',
  getUsersQueue = 'get Users Queue',
}

export const getspecificRouterDetail = createAsyncThunk(
  actiontype.getRouterDetail,
  async (routerId: string) => {
    const response = await routerDetailService.getspecifiRouterDetail(routerId)
    return response?.data?.routerInfo as IRouterDetail
  },
)

export const getUsersQueue = createAsyncThunk(
  actiontype.getUsersQueue,
  async (routerId: string) => {
    const response = await routerDetailService.getUsersQueue(routerId)
    return response
  },
)

export const getTeamMembersList = createAsyncThunk(
  actiontype.getTeamMembersList,
  async () => {
    const response = await customService.getTeamMembersList()
    return (response?.data?.result ?? []) as Iall_team_Members
  },
)

export const getAssignedTeamMembers = createAsyncThunk(
  actiontype.getAssignedTeamMembersList,
  async (routerId: string) => {
    const response = await routerDetailService.getAssignedTeamMembers(routerId)
    return (response?.data?.users ?? []) as Array<ITeamMember>
  },
)

export const updateAssignedTeamMembersList = createAsyncThunk(
  actiontype.updateAssignedTeamMembers,
  async (payload: unknown, thunkApi): Promise<Array<string>> => {
    const response = await routerDetailService.assignTeamMember(payload)
    // if (response)
    return response?.data as Array<string>
    // else return thunkApi.rejectWithValue("");
  },
)

export const getAllSources = createAsyncThunk(
  actiontype.getAllSources,
  async (id: string, thunkApi) => {
    const response = await sourceService.getAllSources()
    if (response) return response?.data?.sources as Array<ISource>
    else return thunkApi.rejectWithValue('')
  },
)

export const addNewSource = createAsyncThunk(
  actiontype.addNewSource,
  async (payload: createSourcePayload) => {
    const response = await sourceService.addNewSource(payload)
    return response?.data?.addedSource as ISource
  },
)

export const upsertTrigger = createAsyncThunk(
  actiontype.setTrigger,
  async (
    payload: {
      routerId: string
      triggerPayload: any
    },
    thunkApi: any,
  ) => {
    const response = await routerDetailService.setTrigger(
      payload.routerId,
      payload.triggerPayload,
    )
    if (response) return response
    else return thunkApi.rejectWithValue('')
  },
)

export const setSLAToRouterAction = createAsyncThunk(
  actiontype.SET_SLA,
  async (
    payload: {
      routerId: string
      setSLAPayload: { slas: string[] }
    },
    thunkApi: any,
  ) => {
    const response = await routerDetailService.setSLA(
      payload.routerId,
      payload.setSLAPayload,
    )
    if (response) {
      if (response?.data?.router?.slas) {
        thunkApi.dispatch(getspecificRouterDetail(response?.data?.router?._id))
      }
      return response
    } else return thunkApi.rejectWithValue('')
  },
)

export const delete_soruce = createAsyncThunk(
  actiontype.addNewSourcedsds,
  async (payload: { sourcesIdsArray: string[] }, thunkApi) => {
    const response = await sourceService.delete_source(payload)
    if (response) return response?.data
    else return thunkApi.rejectWithValue('')
  },
)

export const getAssignableSources = createAsyncThunk(
  actiontype.getAssignableSources,
  async (routerId: string) => {
    const response = await routerDetailService.getAssignableSources(routerId)
    return (response?.data?.sources ?? []) as Array<ISource>
  },
)

export const updateAssignedSourcesList = createAsyncThunk(
  actiontype.updateAssignedSourcesList,
  async (payload: any) => {
    const response = await routerDetailService.assignSources(payload)
    return response?.data as Array<string>
  },
)

export const AssignedUserAction = createAsyncThunk(
  actiontype.AssignedUserAction,
  async (id: string, thunkApi) => {
    const response = await routerDetailService.assignedusers(id)
    if (response) return response?.data?.users ?? ([] as Array<ITeamMember>)
    else return thunkApi.rejectWithValue('')
  },
)

export const assign_modelAction = createAsyncThunk(
  actiontype.assign_model,
  async (
    model: {
      routerId: string
      modelName: string
      assignedUsers: Array<{ userId: string; percentage: number }>
    },
    thunkApi,
  ) => {
    const response = await routerDetailService.assign_model(model)
    if (response) return response
    else return thunkApi.rejectWithValue('')
  },
)

export const publish_router = createAsyncThunk(
  actiontype.publish_router,
  async (id: { routerId: string }, thunkApi) => {
    const response = await routerDetailService.publish_router(id)
    if (response) return response.data as string
    else return thunkApi.rejectWithValue('')
  },
)

export const edit_router_action = createAsyncThunk(
  actiontype.edit_router,
  async (
    payload: {
      routerId: string
      name?: string
      can_overwrite_owner?: boolean
      optionsOverwriteOwner?: any
      disableWorkingHours?: boolean
      routerStrategyData?: any
      disable_default_assignment?: boolean
    },
    thunkApi,
  ) => {
    const response = await routerDetailService.edit_router(payload)
    if (response) return response.data as string
    else return thunkApi.rejectWithValue('')
  },
)

// export get_Specific_Source

export const get_Specific_Source_action = createAsyncThunk(
  actiontype.get_Specific_Source,
  async (id: string, thunkApi) => {
    const response = await sourceService.getSpecificSource(id)

    if (response) return response.data.sourceInfo as Ispecific_souce_detail
    else return thunkApi.rejectWithValue('')
  },
)

export const set_default_user = createAsyncThunk(
  'set_default_user/set_default_user',
  async ({ routerId, ...payload }: default_user_payload, thunkApi) => {
    const response = await ApiClient.post(
      `/routers/${routerId}/set-default-user-for-source`,
      payload,
    )

    if (response) return response
    else return thunkApi.rejectWithValue('')
  },
)

export const getListOfDefaultUsersFromSource = createAsyncThunk(
  'getlistofusers/getListOfDefaultUsersFromSource',
  async (routerId: string, thunkApi) => {
    const response = await ApiClient.get(
      `/routers/${routerId}/get-default-users-from-source`,
    )

    if (response) return response
    else return thunkApi.rejectWithValue('')
  },
)

export const DeleteUser = createAsyncThunk(
  'accounts/delete-team-members',
  async (payload: { userIdsArray: Array<string> }, thunkApi) => {
    const response = await ApiClient.post(
      `/accounts/delete-team-members`,
      payload,
    )

    if (response) return response
    else return thunkApi.rejectWithValue('')
  },
)

export const unpublishrouter = createAsyncThunk(
  'publish/router',
  async ({ routerId, ...data }: { routerId: string }, thunkApi) => {
    const response = await ApiClient.put(`/routers/${routerId}/unpublish`, data)

    if (response) return response
    else return thunkApi.rejectWithValue('')
  },
)

//here is all actions realted to socket

enum SocketActionTypes {
  ADD_USER = 'addUser action type',
  register_leads_page = 'register_leads_page',
  leaves_leads_page_action_type = 'leaves_leads_page_action_type',
  new_lead_post_action_type = 'new_lead_post action type',
}

enum EmitActions {
  ADD_USER = 'addUser',
  JOIN_LEADS = 'joinLeadsPage',
  leave_leads_page = 'leaveLeadsPage',
  new_lead_post = 'NEW_LEAD_POSTED',
  leaves_leads_PAGES = 'leaveLeadsPage',
}

export const registercurrentuserwithsocket = createAsyncThunk(
  SocketActionTypes.ADD_USER,
  async (id: { userId: string }, store) => {
    return socketClient.emit(EmitActions.ADD_USER, id)
  },
)

export const register_leads_page = createAsyncThunk(
  SocketActionTypes.ADD_USER,
  async (router_id_payload: { sourceId: string }, store) => {
    return socketClient.emit(EmitActions.JOIN_LEADS, router_id_payload)
  },
)

export const leaves_leads_page = createAsyncThunk(
  SocketActionTypes.leaves_leads_page_action_type,
  async (payload, store) => {
    return socketClient.emit(EmitActions.leaves_leads_PAGES, payload)
  },
)

export const new_lead_post = createAsyncThunk(
  SocketActionTypes.new_lead_post_action_type,
  async (payload, store) => {
    return socketClient.on(EmitActions.new_lead_post, team_lead_add_callback)
  },
)
