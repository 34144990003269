import { Formik, Form, Field, useFormikContext } from 'formik'
import {
  AVAILABLE_OPERATORS,
  LITERAL_TYPES,
} from 'src/components/organisms/forms/inputs/jsonOperator/getJsonOperator'
import { nanoid } from 'nanoid'
import { useCallback, useMemo } from 'react'
import { getRuleInputType, INPUT_FIELD_TYPES } from './getRuleInputType'
import _debounce from 'lodash.debounce'

interface Props {
  fieldType: AVAILABLE_OPERATORS
  label?: string | React.ReactNode
  description?: string | React.ReactNode
  handleOnSubmit: (model: Record<string, any>) => void
  modelName: string
  shouldSubmit?: boolean
  initialValue: unknown
}

interface RulesInputFormProps {
  inputType: INPUT_FIELD_TYPES
  label?: string | React.ReactNode
  description?: string | React.ReactNode
  modelName: string
}

const RulesInputForm: React.FC<RulesInputFormProps> = ({
  label,
  description,
  inputType,
  modelName,
}) => {
  const { submitForm, handleSubmit, handleChange } = useFormikContext()

  const debouncedFormSubmission = useCallback(
    _debounce(() => {
      submitForm()
    }, 1000),
    [],
  )

  return (
    <Form onSubmit={handleSubmit} className='py-4'>
      {label && (
        <label className='block text-xl font-medium text-gray-700'>
          {label}
        </label>
      )}
      {description && (
        <div className='mt-2 text-lg text-gray-700'>{description}</div>
      )}
      <Field
        as='input'
        type={inputType}
        name={modelName}
        onChange={(args: any) => {
          handleChange(args)
          debouncedFormSubmission()
        }}
        className='relative max-w-xl mt-2 w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 text-lg'
      />
    </Form>
  )
}

const RulesInput: React.FC<Props> = ({
  fieldType,
  label = 'Enter a condition value',
  description = 'Please enter a value.',
  modelName,
  initialValue,
  shouldSubmit = true,
  handleOnSubmit,
}) => {
  const initialModel = useMemo(
    () => ({
      [modelName]: initialValue || '',
    }),
    [initialValue],
  )

  const inputType = useMemo(() => getRuleInputType(fieldType), [fieldType])

  return (
    <Formik
      onSubmit={(model) => {
        if (shouldSubmit) {
          handleOnSubmit(model)
        }
      }}
      initialValues={initialModel}
    >
      <RulesInputForm
        {...{
          label,
          description,
          inputType,
          modelName,
        }}
      />
    </Formik>
  )
}

export default RulesInput
