import { HttpService } from './base.service'

class RouterDetailService extends HttpService {
  private readonly prefix = 'routers'
  getspecifiRouterDetail = (id: string) =>
    this.get(`${this.prefix}/${id}/get-projected-router`)
  getAssignedTeamMembers = (routerId: string) =>
    this.get(`${this.prefix}/${routerId}/assignable-users`)
  assignTeamMember = ({ routerId, ...data }: any) =>
    this.put(`${this.prefix}/${routerId}/assign-users`, data)
  getAssignableSources = (routerId: string) =>
    this.get(`${this.prefix}/assignable-sources/${routerId}`)
  getUsersQueue = (routerId: string) =>
    this.get(`${this.prefix}/${routerId}/availability-allocation-queue`)
  assignSources = ({ routerId, ...data }: any) =>
    this.put(`${this.prefix}/${routerId}/add-sources`, data)
  assignedusers = (id: string) =>
    this.get(`${this.prefix}/${id}/assigned-users`)
  assign_model = ({ routerId, ...data }: any) =>
    this.put(`${this.prefix}/${routerId}/assign-model`, data)
  edit_router = ({ routerId, ...data }: any) =>
    this.patch(`${this.prefix}/${routerId}`, data)

  publish_router = ({ routerId }: { routerId: string }) =>
    this.put(`${this.prefix}/${routerId}/publish`)
  getFieldProperties = (routerId: string, objectType: string) =>
    this.get(
      `${this.prefix}/${routerId}/get-field-properties?objectType=${objectType}`,
    )
  setTrigger = (routerId: string, data: any) =>
    this.patch(`${this.prefix}/${routerId}/set-trigger`, data)
  setSLA = (routerId: string, data: any) =>
    this.patch(`${this.prefix}/${routerId}/set-sla`, data)
  setRules = (routerId: string, data: any) =>
    this.patch(`${this.prefix}/${routerId}/set-account-matching`, data)
}

export const routerDetailService = new RouterDetailService()
