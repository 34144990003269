import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Button } from 'react-bootstrap'
import { timeformater } from '../../utils'
import { IRouterDetail } from '../../types/routerDetail'
import { PERCENTAGE_MODEL } from '../../config/constants'
import { useActions } from '../../actions'
import { useParams } from 'react-router-dom'
import toastBuilder from '../../utils/toastBuilder'
import { useNavigate } from 'react-router-dom'
import Table, { TableColumn } from '../organisms/tables/Table.Organism'
import Avatar from '../atoms/avatar/Avatar.Atom'
import { TriggerTypeToText } from 'src/types/triggers'
import { SLA_ACTIONS_TEXT_MAP } from 'src/types/sla'
import Icons from 'src/assets/images/icons/Icons'
import { routerDetailService } from 'src/services/router_detail.service'
import { User } from 'src/components/LeadRouterDetail/TeamMembersList/types'
import { UsersQueueTypes } from 'src/types/routerDetail'
import { UserGroupIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { ModalAtom } from 'src/components/atoms/Modal/Modal.atom'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import { IOption } from 'src/types/Ihome_state'
import DropdownCustom, {
  defaultOptionRenderer,
} from '../atoms/Dropdown/DropdownCustom.atom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const ReviewAndPublish: React.FC<{
  specificRouterDetail: IRouterDetail
  onSave: any
}> = forwardRef((props, ref) => {
  const [isMounted, setIsMounted] = useState(false)
  const [overWriteOwner, setOverWriteOwner] = useState<boolean>(false)
  const [slaRows, setSLARows] = useState<Record<string, string>[]>([])
  const [rulesRows, setRulesRows] = useState<Record<string, string>[]>([])
  const [usersQueue, setUsersQueue] = useState<UsersQueueTypes | null>(null)
  const [userListQueue, setUserListQueue] = useState<User[]>([])
  const [dataQueue, setDataQueue] = useState<User[] | null>(null)
  const [dataModalQueue, setDataModalQueue] = useState<User[] | null>(null)
  const [showModal, setShowModal] = useState(false)
  const { specificRouterDetail, onSave } = props
  const [posIndex, setPosIndex] = useState<number>(0)
  const { getspecificRouterDetail } = useActions()
  const zIndex = [30, 20, 10, 0]
  const { unpublishrouter, edit_router_action, AssignedUserAction } =
    useActions()
  const { routerId = '' } = useParams()
  const [users, setUsers] = useState<Record<string, string>[]>([])
  const navigate = useNavigate()
  const ability = useAbility(AbilityContext)
  const [selectedOverwriteOption, setSelectedOverwriteOption] =
    useState<IOption>({
      id: 1,
      label: 'Never overwrite',
      value: 'do_not_overwrite',
    })
  const [customWidth, setCustomWidth] = useState('w-[24rem]')
  const classDropworn = 'h-[46px] rounded-[8px] mt-1'

  useEffect(() => {
    if (!isMounted) {
      getUsersQueueData()
      getspecificRouterDetail(routerId)
      setIsMounted(true)
      ;(async () => {
        await getDataUsersModel()
      })()
    }
  }, [isMounted])

  const getDataUsersModel = async () => {
    const dataUsers: any = await AssignedUserAction(routerId)
    setUsers(dataUsers.payload)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  useEffect(() => {
    if (usersQueue) {
      const data: User[] = []
      const dataModal: User[] = []
      const cant = 4

      if (usersQueue.assignedModel === PERCENTAGE_MODEL.PERCENTAGE) {
        if (
          usersQueue?.routerStrategyData &&
          'nextIndex' in usersQueue?.routerStrategyData
        ) {
          setPosIndex(usersQueue?.routerStrategyData?.nextIndex)
        }
        setUserListQueue(usersQueue.users)
      } else {
        if (
          usersQueue?.routerStrategyData &&
          'currentPointerIndex' in usersQueue?.routerStrategyData
        ) {
          setPosIndex(usersQueue?.routerStrategyData?.currentPointerIndex)
        }
      }

      if (specificRouterDetail && !specificRouterDetail.assignedUsers.length) {
        toastBuilder('error', 'Please select users for assignment')
      } else {
        for (let index = posIndex; index < usersQueue.users.length; index++) {
          dataModal.push(usersQueue.users[index])

          if (index <= posIndex + cant) {
            data.push(usersQueue.users[index])
          }

          if (index === usersQueue.users.length - 1) {
            for (let indexP = 0; indexP < posIndex; indexP++) {
              dataModal.push(usersQueue.users[indexP])
            }
          }
        }
      }

      setDataModalQueue(dataModal)
      setDataQueue(data)
    }
  }, [usersQueue, posIndex])

  const getUsersQueueData = () => {
    routerDetailService.getUsersQueue(routerId).then((response) => {
      setUsersQueue(response.data)
    })
  }

  useEffect(() => {
    setOverWriteOwner(specificRouterDetail.can_overwrite_owner)
    if (specificRouterDetail?.slas && specificRouterDetail?.slas.length) {
      const rowData = specificRouterDetail.slas.map((sla) => {
        return {
          _id: sla?._id,
          name: sla?.name || '',
          time: sla?.timeInterval || '',
          property:
            sla?.triggerRule?.fieldLabel || sla?.triggerRule?.fieldName || '',
          action: SLA_ACTIONS_TEXT_MAP[sla?.action] || '',
        }
      })
      setSLARows(rowData)
    }
    if (specificRouterDetail?.account_matching) {
      const { account_matching } = specificRouterDetail
      const rowData = {
        name: account_matching.name,
        field: account_matching?.fieldName || account_matching?.fieldType,
      }
      setRulesRows([rowData])
    }

    if (specificRouterDetail && specificRouterDetail.optionsOverwriteOwner) {
      if (specificRouterDetail.optionsOverwriteOwner.do_not_overwrite) {
        setSelectedOverwriteOption({
          id: 1,
          label: 'Never overwrite',
          value: 'do_not_overwrite',
        })
        setOverWriteOwner(false)
      } else if (
        specificRouterDetail.optionsOverwriteOwner
          .do_not_overwrite_check_availability
      ) {
        setSelectedOverwriteOption({
          id: 2,
          label: 'Overwrite only if current user is not available',
          value: 'do_not_overwrite_check_availability',
        })
        setOverWriteOwner(false)
      } else {
        setSelectedOverwriteOption({
          id: 3,
          label: 'Always overwrite',
          value: 'overwrite',
        })
        setOverWriteOwner(true)
      }
    }
  }, [specificRouterDetail])

  const modelListWithoutUsers: TableColumn[] = [
    { label: '', className: 'w-[1rem]' },
    {
      label: 'TYPE',
      field: 'type',
      className: 'w-[300px] tablet:w-[310px]',
    },
    {
      label: 'NEXT USER',
      field: 'nextUser',
      className: 'w-[250px] tablet:w-[fit-content]',
      render: () => {
        return (
          <div>
            {!specificRouterDetail.assignedUsers.length && (
              <span>There are no users for the assignment</span>
            )}
          </div>
        )
      },
    },
    {
      label: 'QUEUE',
      field: 'queue',
      render: () => {
        return (
          <div>
            {!specificRouterDetail.assignedUsers.length && (
              <span>There are no users for the assignment</span>
            )}
          </div>
        )
      },
    },
  ]

  const modelList: TableColumn[] = [
    { label: '', className: 'w-[1rem]' },
    {
      label: 'TYPE',
      field: 'type',
      className: 'w-[300px] tablet:w-[310px]',
    },
    {
      label: 'NEXT USER',
      field: 'nextUser',
      className: 'w-[250px] tablet:w-[fit-content]',
      render: () => {
        return (
          <div>
            {dataQueue && (
              <div className='flex gap-2 text-xs items-center w-[215px] tablet:mr-6'>
                <Avatar
                  className='h-[32px] text-sm w-[32px]'
                  firstName={
                    dataQueue[0].firstName ? dataQueue[0].firstName : ''
                  }
                  lastName={dataQueue[0].lastName ? dataQueue[0].lastName : ''}
                  imgURL={dataQueue[0].avatar}
                  available={{
                    userAvailability: dataQueue[0].userAvailability,
                    officeStatus: dataQueue[0].office_status
                      ? dataQueue[0].office_status
                      : false,
                  }}
                />
                <div className='flex flex-column'>
                  <span className='text-gray-700 font-normal text-sm'>
                    {dataQueue[0].firstName ? dataQueue[0].firstName : ''}{' '}
                    {dataQueue[0].lastName ? dataQueue[0].lastName : ''}
                  </span>
                </div>
              </div>
            )}
          </div>
        )
      },
    },
    {
      label: 'QUEUE',
      field: 'queue',
      render: () => {
        return (
          <div>
            {dataQueue && (
              <div className='flex items-center'>
                <div className='flex -space-x-4 w-[113px]'>
                  {dataQueue.map(
                    (data: User, index: number) =>
                      index > 0 && (
                        <Avatar
                          key={'dataqueue ' + data._id + zIndex[index]}
                          className={
                            'w-[32px] h-[32px] dark:border-gray-800 mr-[2px] border-2 border-white rounded-full'
                          }
                          firstName={data.firstName}
                          classDiv={`z-${zIndex[index - 1]}`}
                          lastName={data.lastName}
                          imgURL={data.avatar}
                          available={{
                            userAvailability: data.userAvailability,
                            officeStatus: data.office_status
                              ? data.office_status
                              : false,
                          }}
                        />
                      ),
                  )}
                </div>
                <div
                  className='flex items-center cursor-pointer'
                  onClick={openModal}
                >
                  <UserGroupIcon className='h-6 w-6 text-[#0062FF] ml-10' />
                  <span className='text-[#0062FF] ml-2 mr-4'>See Queue</span>
                </div>
              </div>
            )}
          </div>
        )
      },
    },
  ]

  const modelRows = [
    {
      type:
        specificRouterDetail.assignedModel === PERCENTAGE_MODEL.ROUNDROBIN
          ? 'Round Robin'
          : specificRouterDetail.assignedModel ===
              PERCENTAGE_MODEL.PERCENTAGE && 'Percentage',
    },
  ]

  const assignedUserList: TableColumn[] = [
    { label: '', className: 'w-[1rem]' },
    {
      label: 'Name',
      render: ({
        avatar,
        firstName,
        lastName,
        userAvailability,
        office_status,
      }: any) => {
        return (
          <div className='mr-2 flex items-center'>
            <Avatar
              className='w-[32px] h-[32px] text-sm mr-2'
              firstName={firstName}
              lastName={lastName}
              imgURL={avatar}
              available={{
                userAvailability: userAvailability,
                officeStatus: office_status ? office_status : false,
              }}
            />
            <span> {`${firstName || ''} ${lastName || ''}`}</span>
          </div>
        )
      },
    },
    {
      label: 'Email',
      field: 'email',
      render: ({ email }: any) => {
        return <span>{email}</span>
      },
    },
    {
      label: 'Phone number',
      field: 'phone',
    },
  ]

  const sources: TableColumn[] = [
    {
      label: 'Name',
      render: ({ name }: any) => {
        return (
          <div>
            <div className='mb-0'>
              <span> {name}</span>
            </div>
          </div>
        )
      },
    },
    {
      label: 'Date Created',
      field: 'createdAt',
      render: ({ createdAt }: any) => {
        return (
          <div>
            <div className='mb-0'>
              <span> {timeformater(createdAt)}</span>
            </div>
          </div>
        )
      },
    },
    {
      label: 'Last Activity',
      render: ({ updatedAt }: any) => {
        return (
          <div>
            <div className='mb-0'>
              <span> {timeformater(updatedAt)}</span>
            </div>
          </div>
        )
      },
    },
  ]
  // TODO Add triggers summary here
  const triggerTableColumns: TableColumn[] = [
    { label: '', className: 'w-[1rem]' },
    { label: 'Type', field: specificRouterDetail.trigger_type },
    {
      label: '',
      className: 'w-[10rem]',
    },
  ]

  const triggerTableRows = [
    {
      // @ts-ignore
      [specificRouterDetail.trigger_type]:
        // @ts-ignore
        TriggerTypeToText[specificRouterDetail.trigger_type],
    },
  ]

  const slaTableColumns: TableColumn[] = [
    { label: '', className: 'w-[1rem]' },
    {
      label: 'Name',
      field: 'name',
      className: 'w-[25rem]',
    },
    { label: 'Time', field: 'time', className: 'w-[20rem]' },
    { label: 'Property', field: 'property', className: 'w-[20rem]' },
    { label: 'Action', field: 'action', className: 'w-[20rem]' },
  ]

  const rulesTableColumns: TableColumn[] = [
    { label: '', className: 'w-[1rem]' },
    {
      label: 'Name',
      field: 'name',
      className: 'w-[25rem]',
    },
    { label: 'Field', field: 'field', className: 'w-[20rem]' },
  ]

  const handleunpublishClick = async () => {
    const results: any = await unpublishrouter({ routerId: routerId })
    if (results.payload.success) {
      navigate(`/routers`)
      toastBuilder('success', 'Router unpublished successfully')
    }
  }

  useImperativeHandle(ref, () => ({
    onRefSave() {
      return onSave()
    },
  }))

  const overwriteOptions = [
    {
      id: 1,
      label: 'Never overwrite',
      value: 'do_not_overwrite',
    },
    {
      id: 2,
      label: 'Overwrite only if current user is not available',
      value: 'do_not_overwrite_check_availability',
    },
    {
      id: 3,
      label: 'Always overwrite',
      value: 'overwrite',
    },
  ]

  const onOverwriteOptionChange = async (option: any) => {
    setSelectedOverwriteOption(option)

    let options = null
    let canOverwrite = false
    if (option) {
      switch (option.value) {
        case 'do_not_overwrite':
          options = {
            do_not_overwrite: true,
            do_not_overwrite_check_availability: false,
            overwrite: false,
          }
          canOverwrite = false
          break
        case 'do_not_overwrite_check_availability':
          options = {
            do_not_overwrite: false,
            do_not_overwrite_check_availability: true,
            overwrite: false,
          }
          canOverwrite = false
          break
        case 'overwrite':
          options = {
            do_not_overwrite: false,
            do_not_overwrite_check_availability: false,
            overwrite: true,
          }
          canOverwrite = true
          break
      }
    }

    await edit_router_action({
      routerId,
      can_overwrite_owner: canOverwrite,
      optionsOverwriteOwner: options,
    })
  }

  const handleDragEnd = (e: any) => {
    if (!e.destination) return
    const tempData = Array.from(userListQueue)
    const [source_data] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
    setUserListQueue(tempData)
  }

  const updateQueue = async () => {
    let userIds: any = []
    if (userListQueue.length > 0) {
      userIds = userListQueue.map((user) => user._id)
    }

    const routerStrategyData = {
      userIds: userIds,
      nextIndex: 0,
    }

    await edit_router_action({
      routerId,
      routerStrategyData,
    })

    await getUsersQueueData()

    toastBuilder(
      'success',
      'The assignment queue for this router has been updated',
    )

    setShowModal(false)
  }

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    ...draggableStyle,
    userSelect: 'none',
    position: 'static',
  })

  return (
    <>
      <section className='review-publish h-full'>
        <div className='my-[1.3rem]'>
          <h4>Review and Publish</h4>
          <div className='flex justify-center items-center my-3'>
            <Icons name='info' />
            <span className='ml-1.5 text-bs flex-grow-1'>
              Review your Router is setup correctly and publish it live
            </span>
            {specificRouterDetail?.isPublished ? (
              <button
                type='submit'
                onClick={handleunpublishClick}
                disabled={!ability.can('update', 'router')}
                className={`icon-btn danger margin-left20 px-4 py-[10px] rounded-md text-white ${
                  !ability.can('update', 'router') && 'cursor-not-allowed'
                }`}
              >
                Unpublish
              </button>
            ) : null}
          </div>
        </div>

        <div className='overwrite-container py-3 flex flex-start items-center mt-2'>
          <DropdownCustom
            dropdownLabel='Overwrite options'
            options={overwriteOptions}
            selectedOption={selectedOverwriteOption}
            setSelectedOption={onOverwriteOptionChange}
            optionRenderer={defaultOptionRenderer}
            classInput={classDropworn}
            customClass='mr-8'
            disabled={!ability.can('update', 'router')}
            customWidth={customWidth}
          />
        </div>

        <div className='table-search-bar px-2'>
          <h4>Triggers</h4>
          {specificRouterDetail?.trigger_metadata && (
            <Table columns={triggerTableColumns} rows={triggerTableRows} />
          )}
        </div>
        <div className='table-search-bar px-2'>
          <h4>Users</h4>
          <Table columns={assignedUserList} rows={users} />
        </div>
        <div className='table-search-bar px-2'>
          <h4>Model</h4>
          <Table
            columns={
              specificRouterDetail.assignedUsers.length > 0
                ? modelList
                : modelListWithoutUsers
            }
            rows={modelRows}
          />
        </div>
        {specificRouterDetail?.slas && specificRouterDetail?.slas.length ? (
          <div className='table-search-bar px-2'>
            <h4>SLA</h4>
            <Table columns={slaTableColumns} rows={slaRows} />
          </div>
        ) : null}
        {specificRouterDetail?.account_matching ? (
          <div className='table-search-bar px-2'>
            <h4>Account Matching</h4>
            <Table columns={rulesTableColumns} rows={rulesRows} />
          </div>
        ) : null}
        <div className='table-responsive table-search-bar bordertable px-2'>
          <div className='publish-row'></div>
        </div>
        <ModalAtom
          showModal={showModal}
          setShowModal={setShowModal}
          showCancelBtn={false}
          closeOnOut={false}
          handleSave={
            usersQueue &&
            usersQueue.assignedModel === PERCENTAGE_MODEL.PERCENTAGE
              ? updateQueue
              : undefined
          }
        >
          <div className='w-[380px] mobile:w-[290px] h-[fit-content]'>
            <div className='p-4 flex items-center justify-between'>
              <div>Router Queue </div>
              <XMarkIcon
                onClick={closeModal}
                className='h-5 w-5 text-gray-500 cursor-pointer'
              />
            </div>
            <div className='bg-gray-100 rounded p-3 m-2 h-[75px] flex justify-center flex-col'>
              <span className='text-gray-500 font-semibold m-2 text-xs'>
                NEXT USER
              </span>
              {specificRouterDetail.assignedUsers.length > 0 && dataQueue && (
                <div className='flex gap-2 text-xs items-center w-[fit-content] tablet:mr-6 mb-2'>
                  <Avatar
                    className='w-[32px] min-w-[32px] h-[32px] text-sm'
                    firstName={
                      dataQueue[0].firstName ? dataQueue[0].firstName : ''
                    }
                    lastName={
                      dataQueue[0].lastName ? dataQueue[0].lastName : ''
                    }
                    imgURL={dataQueue[0].avatar}
                    available={{
                      userAvailability: dataQueue[0].userAvailability,
                      officeStatus: dataQueue[0].office_status
                        ? dataQueue[0].office_status
                        : false,
                    }}
                  />
                  <div className='flex flex-column'>
                    <span className='text-gray-900 font-semibold text-sm'>
                      {dataQueue[0].firstName ? dataQueue[0].firstName : ''}{' '}
                      {dataQueue[0].lastName ? dataQueue[0].lastName : ''}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <span className='p-4 m-2 text-gray-500 font-semibold text-xs'>
              QUEUE
            </span>
            <div className='px-3 m-2 overflow-y-auto h-[460px]'>
              {usersQueue &&
              usersQueue.assignedModel === PERCENTAGE_MODEL.PERCENTAGE ? (
                <div>
                  <table className='table-fixed w-100% border-collapse border-spacing-0'>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId='droppable-1'>
                        {(provider) => (
                          <tbody
                            className='text-capitalize'
                            ref={provider.innerRef}
                            {...provider.droppableProps}
                          >
                            {userListQueue &&
                              userListQueue.map((user, index) => {
                                return (
                                  <Draggable
                                    key={index + user._id}
                                    draggableId={index + user._id}
                                    index={index}
                                    isDragDisabled={
                                      !ability.can('update', 'router.model')
                                    }
                                  >
                                    {(provider, snapshot) => (
                                      <tr
                                        {...provider.draggableProps}
                                        ref={provider.innerRef}
                                        {...provider.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provider.draggableProps.style,
                                        )}
                                      >
                                        <td className='px-0 py-2'>
                                          <div className='flex items-center'>
                                            <Avatar
                                              className='w-[32px] h-[32px] text-sm'
                                              firstName={user.firstName}
                                              lastName={user.lastName}
                                              imgURL={user.avatar}
                                              available={{
                                                userAvailability:
                                                  user.userAvailability,
                                                officeStatus: user.office_status
                                                  ? user.office_status
                                                  : false,
                                              }}
                                            />
                                            <div className='ml-4'>
                                              <span className='text-gray-900 font-semibold text-sm'>
                                                {user.firstName
                                                  ? user.firstName
                                                  : ''}{' '}
                                                {user.lastName
                                                  ? user.lastName
                                                  : ''}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                )
                              })}
                            {provider.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </table>
                </div>
              ) : (
                <div className='flex items-center'>
                  <div className='flex flex-col'>
                    {dataModalQueue &&
                      dataModalQueue.map(
                        (data: User, index: number) =>
                          index > 0 && (
                            <div
                              key={'usersQueue ' + data._id + index}
                              className='flex flex-row py-2 items-center'
                            >
                              <Avatar
                                className='w-[32px] h-[32px] text-sm'
                                firstName={data.firstName ? data.firstName : ''}
                                lastName={data.lastName ? data.lastName : ''}
                                imgURL={data.avatar}
                                available={{
                                  userAvailability: data.userAvailability,
                                  officeStatus: data.office_status
                                    ? data.office_status
                                    : false,
                                }}
                              />
                              <div className='ml-4'>
                                <span className='text-gray-900 font-semibold text-sm'>
                                  {data.firstName ? data.firstName : ''}{' '}
                                  {data.lastName ? data.lastName : ''}
                                </span>
                              </div>
                            </div>
                          ),
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalAtom>
      </section>
    </>
  )
})

ReviewAndPublish.displayName = 'ReviewAndPublish'

export default ReviewAndPublish
