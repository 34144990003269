import { IntegrationParams, Source } from 'src/types/sources'
import { HttpService } from './base.service'

class SourceService extends HttpService {
  private readonly prefix = 'sources'
  createSource = async (data: Record<string, any>) =>
    this.post(`${this.prefix}/`, data)
  getAllSources = (query: Record<string, any> = {}) =>
    this.get(`${this.prefix}/`, query)
  getSource = (sourceId: string) => this.get(`${this.prefix}/${sourceId}`)
  getSourceProperties = (
    sourceId: string,
    { integrationParams }: { integrationParams: IntegrationParams },
  ) =>
    this.get(
      `${this.prefix}/${sourceId}/get-source-properties`,
      integrationParams,
    )
  updateSource = (sourceId: string, payload: Partial<Source>) =>
    this.patch(`${this.prefix}/${sourceId}`, payload)
  deleteSources = (deleteManyDto: { sourceIds: string[] }) =>
    this.delete(`${this.prefix}/`, deleteManyDto)
  addNewSource = (data: unknown) => this.post(`${this.prefix}/sources`, data)
  getSpecificSource = (id: string) => this.get(`${this.prefix}/sources/${id}`)
  delete_source = (data: any) => this.put(`${this.prefix}/delete-sources`, data)
}

export const sourceService = new SourceService()
