import { AVAILABLE_OPERATORS } from 'src/components/organisms/forms/inputs/jsonOperator/getJsonOperator'

export enum INPUT_FIELD_TYPES {
  TEXT = 'text',
  DATETIME = 'datetime-local',
  NUMBER = 'number',
}

const InputFieldTypeMap = {
  [AVAILABLE_OPERATORS.EQUALS]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.NOT_EQUAL]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.LESS_THAN]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.LESS_THAN_INCLUSIVE]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.GREATER_THAN]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.GREATER_THAN_INCLUSIVE]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.IN]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.NOT_IN]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.CONTAINS]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.DOES_NOT_CONTAIN]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.IS_BEFORE_DATE]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.IS_AFTER_DATE]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.IS_EQUAL_TO_DATE]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.IS_KNOWN]: INPUT_FIELD_TYPES.TEXT,
  [AVAILABLE_OPERATORS.IS_UNKNOWN]: INPUT_FIELD_TYPES.TEXT,
}

export const getRuleInputType = (fieldType: AVAILABLE_OPERATORS) =>
  InputFieldTypeMap[fieldType]
