import React, { ChangeEvent, useEffect, useState } from 'react'
import DropdownCustom, {
  defaultOptionRenderer,
} from 'src/components/atoms/Dropdown/DropdownCustom.atom'
import { IOption } from '../../types/Ihome_state'
import SelectableTable, {
  TableColumn,
} from '../organisms/tables/SelectableTable.Organism'
import { Toggle } from '../atoms/Toggle/Toggle.atom'
import { User } from 'src/types/customers'
import { useAppSelector } from 'src/store'
import { getallusers } from 'src/container/my_account/account.selector'
import { useActions } from 'src/actions'
import { authService } from 'src/services/auth.service'
import toastBuilder from 'src/utils/toastBuilder'
import Avatar from 'src/components/atoms/avatar/Avatar.Atom'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import debounce from 'lodash.debounce'

const objectOptions: Array<IOption> = [
  {
    id: 1,
    label: 'Contacts',
    value: 'contacts',
  },
  {
    id: 2,
    label: 'Deals',
    value: 'deals',
  },
]

const setDataCapacity = (rowData: any, capacityPayload: any, getallcustomer: any) => {
  // TODO - use actions
  authService
    .setCapacities(rowData._id, capacityPayload)
    .then((res) => {
      toastBuilder('success', 'Capacities updated')
      getallcustomer()
    })
    .catch((err) => {
      toastBuilder('error', 'Error updating capacities')
    })
}

const debouncedChangesCapacity = debounce((rowData, capacityPayload, getallcustomer) => setDataCapacity(rowData, capacityPayload, getallcustomer), 1000)

const getCapacityRowData = (capacities: Record<string, any>) => {
  const rowsArray: Array<any> = []
  const rowData = {
    max: capacities.max,
    current: capacities.current,
  }
  rowsArray.push(rowData)
  return rowsArray
}

const Capacities: React.FC<any> = () => {
  const [rowsToShow, setRowsToShow] = useState<Array<Record<string, any>>>([])
  const [colsToShow, setColsToShow] = useState<Array<TableColumn>>([])
  const [capicityTableRows, setCapicityTableRows] = useState<
    Array<Record<string, any>>
  >([])
  const [objectType, setObjectType] = useState(objectOptions[0])
  const [dealPipelineOptions, setDealPipelineOptions] = useState<IOption[]>([])
  const [pipelineType, setPipelineType] = useState<IOption>({ value: '', label: '', id: 0 })
  const [currentUser, setCurrentUser] = useState<Record<string, any>>({})

  const { getallcustomer, getTeamMembersList } = useActions()

  const allUsers = useAppSelector(getallusers)

  const ability = useAbility(AbilityContext)

  const getDataUsers = async () => {
    const users: any = await getTeamMembersList()
    setRowsToShow(
      users.payload.map((user: User) => {
        let isOn = false
        if (pipelineType && pipelineType.value && user.capacities && pipelineType.value in user.capacities.deals) {
          isOn = user.capacities.deals[pipelineType.value].isOn || false
        }
        return {
          ...user,
          name: user.firstName + ' ' + user.lastName,
          isOn:
            objectType?.value === 'contacts'
              ? user?.capacities?.contacts?.isOn
              : objectType?.value === 'deals' && isOn,

        }
      }),
    )
  }

  useEffect(() => {
    setColsToShow(columns)
    getDataUsers()
    authService.getDealPipelines().then((rsp: any) => {
      if (rsp && rsp.length > 0) {
        const pipelineOptions = rsp.map(
          (option: { label: string; value: string }, index: number) => ({
            id: index,
            label: option.label,
            value: option.value,
          }),
        )
        setDealPipelineOptions(pipelineOptions)
        const index = pipelineOptions.findIndex((itemData: IOption) => itemData.value === "default" || itemData.label === "Sales Pipeline")
        setPipelineType(pipelineOptions[index])
        getallcustomer()
      }
    })
  }, [])

  useEffect(() => {
    setRowsToShow(
      allUsers.map((user: User) => {
        let isOn = false
        if (pipelineType && user.capacities && pipelineType.value && pipelineType.value in user.capacities.deals) {
          isOn = user.capacities.deals[pipelineType.value].isOn || false
        }
        return {
          ...user,
          name: user.firstName + ' ' + user.lastName,
          isOn:
            objectType?.value === 'contacts'
              ? user?.capacities?.contacts?.isOn
              : objectType?.value === 'deals' && isOn,

        }
      }),
    )
    getCurrentCapacity()
  }, [allUsers])

  useEffect(() => {
    const rowsToShowClone = structuredClone(allUsers)
    if (objectType?.value === 'contacts') {
      rowsToShowClone.forEach((row: any) => {
        row.isOn = row?.capacity?.contacts?.isOn || false
      })
    } else if (objectType?.value === 'deals') {
      rowsToShowClone.forEach((row: any) => {
        if (pipelineType && pipelineType.value && pipelineType.value in row?.capacities?.deals) {
          if (row?.capacities && !row?.capacities?.deals) {
            row.capacities.deals[pipelineType.value] = {
              isOn: false,
              current: 0,
              max: 0,
            }
          } else {
            row.isOn = row?.capacities?.deals[pipelineType.value].isOn || false
          }
        }
        row.dealPipelineType = pipelineType?.value
      })
    }

    setRowsToShow(rowsToShowClone)

    columns[2].label = 'Capacity'
    setColsToShow(columns)

  }, [objectType, pipelineType])

  useEffect(() => {
    getCurrentCapacity()
  }, [currentUser])

  const getCurrentCapacity = () => {
    if (allUsers && allUsers.length) {
      let capacityRowsData: Array<any> = []
      allUsers.forEach((user: User) => {
        if (user._id === currentUser._id && user.capacities) {
          // @ts-ignore
          const capacitiesData = user.capacities[objectType?.value]
          if (objectType?.value === 'contacts') {
            capacityRowsData = getCapacityRowData(capacitiesData)
          } else if (objectType?.value === 'deals') {
            let capacities = {}
            if (!capacitiesData['deals']) {
              let max = 0
              let current = 0
              if (pipelineType.value && pipelineType.value in capacitiesData.deals) {
                max = capacitiesData.deals[pipelineType.value].max || 0
                current = capacitiesData.deals[pipelineType.value].current || 0
              }

              capacities = {
                isOn: false,
                max,
                current,
              }
            } else {
              capacities = capacitiesData['deals']
            }
            capacityRowsData = getCapacityRowData(capacities)
          }
        }
      })
      setCapicityTableRows(capacityRowsData)
    }
  }

  const handleStatusChange = async (data: any, val: boolean) => {
    if (data?.capacities) {
      let capacityPayload
      if (objectType?.value === 'contacts') {
        capacityPayload = {
          objectType: 'contacts',
          propertyName: 'contacts',
          isOn: val,
          max: data.capacities.contacts.max ? data.capacities.contacts.max : 0,
          current: data.capacities.contacts.current
            ? data.capacities.contacts.current
            : 0,
        }
      } else if (objectType?.value === 'deals') {

        let max = 0
        let current = 0

        if (pipelineType.value && pipelineType.value in data.capacities.deals) {
          max = data.capacities.deals[pipelineType.value].max || 0
          current = data.capacities.deals[pipelineType.value].current || 0
        }

        capacityPayload = {
          objectType: 'deals',
          propertyName: 'deals',
          pipelineName: pipelineType.value,
          pipelineLabel: pipelineType.label,
          isOn: val,
          max,
          current,
        }
      }
      authService
        .setCapacities(data._id, capacityPayload)
        .then((res) => {
          toastBuilder('success', 'Status updated')
          getallcustomer()
        })
        .catch((err) => {
          toastBuilder('error', 'Error updating status')
        })
    } else {
      toastBuilder('error', 'Capacity not available')
    }
  }

  const changeCapacity = async (e: ChangeEvent<HTMLInputElement>, rowData: any) => {
    const capacityClone = structuredClone(rowData.capacities)

    if (objectType.value === 'contacts') {
      capacityClone.contacts.max = e.target.valueAsNumber
    } else {

      if (pipelineType.value && pipelineType.value in capacityClone.deals) {
        capacityClone.deals[pipelineType.value].max = e.target.valueAsNumber
      }
    }

    let capacityPayload
    if (objectType.value === 'contacts') {
      capicityTableRows.forEach((capacity) => {
        capacityClone.contacts.max = capacity.max
        capacityClone.contacts.current = capacity.current
      })
      capacityPayload = {
        objectType: 'contacts',
        propertyName: 'contacts',
        isOn: capacityClone.contacts.isOn,
        max: capacityClone.contacts.max,
        current: capacityClone.contacts.current,
      }
    } else if (objectType.value === 'deals') {


      capicityTableRows.forEach((capacity) => {
        if (!capacityClone.deals) {
          if (pipelineType.value) {
            capacityClone.deals[pipelineType.value] = {
              isOn: false,
              max: 0,
              current: 0,
            }
          }
        }
      })

      let isOn = false
      let current = 0
      let max = 0

      if (pipelineType.value && pipelineType.value in capacityClone.deals) {
        isOn = capacityClone.deals[pipelineType.value].isOn || false
        current = capacityClone.deals[pipelineType.value].current || 0
        max = capacityClone.deals[pipelineType.value].max || 0
      }

      capacityPayload = {
        objectType: 'deals',
        propertyName: 'deals',
        pipelineName: pipelineType.value,
        pipelineLabel: pipelineType.label,
        isOn,
        max,
        current,
      }
    }

    debouncedChangesCapacity(rowData, capacityPayload, getallcustomer)
  }

  const onSearchTextChange = (searchText: string) => {
    // FIXME - Change this to server side search as per need
    if (searchText === '') {
      setRowsToShow(
        allUsers.map((user: User) => {

          let isOn = false

          if (pipelineType.value && user.capacities && pipelineType.value in user.capacities.deals) {
            isOn = user.capacities.deals[pipelineType.value].isOn || false
          }
          return {
            ...user,
            name: user.firstName + ' ' + user.lastName,
            isOn:
              objectType?.value === 'contacts'
                ? user?.capacities?.contacts?.isOn
                : objectType?.value === 'deals' && isOn,

          }
        }),
      )
      return
    }
    setRowsToShow(
      allUsers
        .map((user: User) => {
          let isOn = false

          if (pipelineType.value && user.capacities && pipelineType.value in user.capacities.deals) {
            isOn = user.capacities.deals[pipelineType.value].isOn || false
          }
          return {
            ...user,
            name: user.firstName + ' ' + user.lastName,
            isOn:
              objectType?.value === 'contacts'
                ? user?.capacities?.contacts?.isOn
                : objectType?.value === 'deals' && isOn,

          }
        })
        .filter((teamMember: any) =>
          teamMember.name.toLowerCase().includes(searchText.toLowerCase()),
        ),
    )
  }

  const columns: Array<TableColumn> = [
    {
      label: 'Name',
      render: ({
        avatar,
        firstName,
        lastName,
        userAvailability,
        office_status,
      }: any) => {
        return (
          <div className='flex items-center'>
            <Avatar
              className='h-[32px] text-sm w-[32px]'
              firstName={firstName ? firstName : ''}
              lastName={lastName ? lastName : ''}
              imgURL={avatar}
              available={{
                userAvailability: userAvailability,
                officeStatus: office_status ? office_status : false,
              }}
            />
            <span className='text-[14px] font-normal leading-[21px] text-gray-700 ml-2'>
              {' '}
              {`${firstName} ${lastName}`}
            </span>
          </div>
        )
      },
    },
    {
      label: 'Status',
      field: 'status',
      render: (rowData: any) => {
        let statusDeal = false
        if (objectType?.value === 'deals' && pipelineType && pipelineType.value && pipelineType.value in rowData.capacities.deals) {
          statusDeal = rowData.capacities.deals[pipelineType.value].isOn || false
        }
        return (
          <Toggle
            initialValue={
              objectType?.value === 'contacts'
                ? rowData.capacities.contacts.isOn
                : statusDeal
            }
            key={rowData._id}
            data={rowData}
            disabled={!ability.can('update', 'setting.capacities')}
            handleChange={handleStatusChange}
          />
        )
      },
    },
    {
      label: 'Capacity',
      field: 'capacity',
      render: (rowData: any) => {

        let capacityDeal = 0

        if (objectType?.value === 'deals' && pipelineType && pipelineType.value && pipelineType.value in rowData.capacities.deals) {
          capacityDeal = rowData.capacities.deals[pipelineType.value].max || 0
        }

        return (
          <input
            id='capacityMax'
            name='capacityMax'
            type='number'
            value={
              objectType?.value === 'contacts'
                ? rowData.capacities.contacts.max
                : capacityDeal
            }
            min='0'
            disabled={!ability.can('update', 'setting.capacities')}
            onChange={(event) => changeCapacity(event, rowData)}
            className={`block w-[79px] appearance-none rounded-md border placeholder-gray-400 text-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm leading-[21px] ${!ability.can('update', 'setting.capacities') && 'cursor-not-allowed'}`}
          />
        )
      },
    },
    {
      label: 'Current open count',
      field: 'status_capacity',
      render: (rowData: any) => {

        let current = 0
        if (objectType?.value === 'deals' && pipelineType && pipelineType.value && pipelineType.value in rowData.capacities.deals) {
          current = rowData.capacities.deals[pipelineType.value].current || 0
        }
        return (
          <span className='text-gray-700 text-sm font-semibold mr-2 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300'>
            {objectType?.value === 'contacts'
              ? rowData.capacities.contacts.current
              : current}
          </span>
        )
      },
    },
  ]

  const onPipelineChange = (pipeline: IOption) => {
    setPipelineType({ ...pipeline })
  }

  return (
    <div className='px-5 py-7'>
      <div className='flex'>
        <DropdownCustom
          dropdownLabel='Object / Stage'
          options={objectOptions}
          selectedOption={objectType}
          setSelectedOption={setObjectType}
          optionRenderer={defaultOptionRenderer}
          withAvatar={false}
        />
        {objectType.value === 'deals' && (
          <div className='ml-6'>
            <DropdownCustom
              dropdownLabel='Pipeline'
              options={dealPipelineOptions}
              selectedOption={pipelineType}
              setSelectedOption={onPipelineChange}
              optionRenderer={defaultOptionRenderer}
              withAvatar={false}
            />
          </div>
        )}
      </div>
      <div className='mt-4'>
        <SelectableTable
          columns={colsToShow}
          rows={rowsToShow}
          initialSelection={[]}
          onSearchChange={onSearchTextChange}
          objectType={objectType.value}
          pipelineType={pipelineType}
          showSelectionType={false}
        />
      </div>
    </div>
  )
}

export default Capacities
