import { config } from '../../../config/constants'
import { Helmet as ReactHelmet } from 'react-helmet-async'

const Helmet: React.FC = () => (
  <ReactHelmet>
    {config.HUBSPOT_EMBED_LINK && (
      <script
        type='text/javascript'
        id='hs-script-loader'
        async
        defer
        src={config.HUBSPOT_EMBED_LINK}
      />
    )}
  </ReactHelmet>
)

export default Helmet
