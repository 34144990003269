import { createSlice } from '@reduxjs/toolkit'

import { IBasicState } from '../../types/basic'
import { createReducerBuilder } from '../../utils/actions'
import { getProductMetricsInfosAction } from './basic.actions'

const initialState = {
  productMetricsInfo: [],
}

const reducerBuilder = createReducerBuilder<IBasicState>()

const get_product_metrics_info = reducerBuilder(getProductMetricsInfosAction, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.productMetricsInfo = [...payload]
    }
  },
})

const basicService = createSlice({
  name: 'basicDetailSlice',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    get_product_metrics_info(builder)
  },
})

export default basicService.reducer
