import { useEffect } from 'react'
import { DataTabs, DataTab } from 'src/types/tabs'
import { initFlowbite } from 'flowbite'
import { useNavigate, useLocation } from 'react-router-dom'

const Tabs = (props: DataTabs) => {
  const { dataTabs,
    classContentTab,
    idProp,
    onChangeTab,
    classTabs,
    className,
    addPropsUrl = true
  } = props

  const navigation = useNavigate()
  const location = useLocation()

  useEffect(() => {
    initFlowbite()
  }, [])

  const changeTab = (id: string) => {
    if (addPropsUrl) {
      navigation(location.pathname + id)
    }
  }

  return (
    <>
      <div className={`${className ? className : 'bg-gray-100'}`}>
        <ul
          className={`flex flex-wrap -mb-px text-sm font-medium text-center ${classTabs ? classTabs : 'px-5'}`}
          id='myTab'
          data-tabs-toggle='#myTabContent'
          role='tablist'
        >
          {dataTabs.map((item: DataTab) => {
            return (
              <li
                key={item.id + '-tabRoutera'}
                className='mr-2'
                role='presentation'
              >
                <button
                  className='inline-block py-[12px] mr-4 border-b-2 rounded-t-lg text-[16px] leading-6'
                  id={`${item.id}-tab`}
                  data-tabs-target={`#${item.id}`}
                  type='button'
                  role='tab'
                  aria-controls={item.id}
                  aria-selected={
                    idProp
                      ? item.id === idProp
                      : location.search === '?tab=' + item.id
                  }
                  onClick={
                    onChangeTab
                      ? () => onChangeTab(undefined, item.id)
                      : () => changeTab('?tab=' + item.id)
                  }
                >
                  {item.label}
                </button>
              </li>
            )
          })}
        </ul>
      </div>
      <div
        id='myTabContent'
        className={`${classContentTab ? classContentTab : 'min-h-[calc(100vh-256px)] bg-routera-gray'
          }`}
      >
        {dataTabs.map((item: DataTab) => {
          return (
            <div
              key={item.id + '-contentTab'}
              className='hidden rounded-lg dark:bg-gray-800 bg-routera-gray'
              id={item.id}
              role='tabpanel'
              aria-labelledby={`${item.id}-tab`}
            >
              {item.dataContentTab}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Tabs
