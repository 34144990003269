import { createSlice } from '@reduxjs/toolkit'
import { IRouterDetailState } from '../../types/routerDetail'
import { createReducerBuilder } from '../../utils/actions'
import {
  getspecificRouterDetail,
  getTeamMembersList,
  getAssignedTeamMembers,
  getAllSources,
  addNewSource,
  getAssignableSources,
  AssignedUserAction,
  publish_router,
  get_Specific_Source_action,
  getListOfDefaultUsersFromSource,
  upsertTrigger,
} from './routerDetail.actions'

const initialState: IRouterDetailState = {
  specific_router_detail: {
    _id: '',
    name: '',
    attachedLeads: [],
    attachedSources: [],
    webhookAddress: '',
    isWebhookActive: false,
    owner: '',
    assignedUsers: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    assignedUsersInfo: [],
    attachedSourcesInfo: [],
    attachedLeadsInfo: [],
    assignedModel: '',
    isPublished: false,
    can_overwrite_owner: false,
    disable_default_assignment: false,
    defaultUserForAssignment: '',
    haveAnyHubspotSource: false,
    DefaultUserNotSelected: false,
    account_matching: null,
    teams: [],
    account_id: '',
    optionsOverwriteOwner: {
      do_not_overwrite: true,
      do_not_overwrite_check_availability: false,
      overwrite: false,
    },
    disableWorkingHours: false,
    defaultAssignmentType: 'user',
    defaultTeamAssignment: '',
  },
  allTeamMembers: [],
  assignedTeamMembers: [],
  allSources: [],
  assignableSources: [],
  assigned_user_full_details: [],
  web_hook_address: '',
  specifisource: {
    webhookAddress: '',
    createdAt: new Date(),
    is_active: false,
    attachedLeads: [],
    attachedLeadsInfo: [],
  },

  defaultuser: '',
  sourceUserList: [],
}

const reducerBuilder = createReducerBuilder<IRouterDetailState>()

const get_route_detail = reducerBuilder(getspecificRouterDetail, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.specific_router_detail = payload
    }
  },
})

const updateTriggers = reducerBuilder(upsertTrigger, {
  fulfilled: (state, { payload }) => {
    if (payload && 'data' in payload) {
      state.specific_router_detail = {
        ...state.specific_router_detail,
        trigger_type: payload.data.trigger_type,
        trigger_metadata: payload.data.trigger_metadata,
      }
    }
  },
})

const get_all_team_members = reducerBuilder(getTeamMembersList, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.allTeamMembers = payload
    }
  },
})
const get_assigned_team_members = reducerBuilder(getAssignedTeamMembers, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.assignedTeamMembers = payload
    }
  },
})
const get_all_sources = reducerBuilder(getAllSources, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.allSources = payload
    }
  },
})
const add_new_source = reducerBuilder(addNewSource, {
  fulfilled: (state, { payload, ...other }) => {
    if (payload) {
      state.allSources = [...state.allSources, payload]
      state.assignableSources = [...state.assignableSources, payload]
    }
  },
})
const get_assignable_sources = reducerBuilder(getAssignableSources, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.assignableSources = payload
    }
  },
})

const assigned_user_full_detail = reducerBuilder(AssignedUserAction, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.assigned_user_full_details = payload
    }
  },
})

const publish_router_action = reducerBuilder(publish_router, {
  fulfilled: (state, { payload }: any) => {
    if (payload) {
      state.web_hook_address = payload.assignedUrl
    }
  },
})

const get_Specific_Source = reducerBuilder(get_Specific_Source_action, {
  fulfilled: (state, { payload }: any) => {
    if (payload) {
      state.specifisource = payload
    }
  },
})

const listofHubspotusers = reducerBuilder(getListOfDefaultUsersFromSource, {
  fulfilled: (state, { payload }: any) => {
    if (payload) {
      state.sourceUserList = payload?.data?.users
    }
  },
})

const routerDetailService = createSlice({
  name: 'routerDetailSlice',
  initialState,
  reducers: {
    set_socket_receive_data: (state, action) => {
      state.specifisource.attachedLeadsInfo.push(action.payload)
    },
    setDefaultUser: (state, action) => {
      state.defaultuser = action.payload
    },
  },

  extraReducers: (builder) => {
    get_route_detail(builder)
    get_all_team_members(builder)
    get_assigned_team_members(builder)
    get_all_sources(builder)
    add_new_source(builder)
    get_assignable_sources(builder)
    assigned_user_full_detail(builder)
    publish_router_action(builder)
    get_Specific_Source(builder)
    listofHubspotusers(builder)
    updateTriggers(builder)
  },
})

export const { set_socket_receive_data, setDefaultUser } =
  routerDetailService.actions

export default routerDetailService.reducer
