import { LITERAL_TYPES } from 'src/components/organisms/forms/inputs/jsonOperator/getJsonOperator'

export enum HUBSPOT_FIELD_TYPES {
  BOOLEAN = 'bool',
  ENUM = 'enumeration',
  DATE = 'date',
  DATETIME = 'datetime',
  STRING = 'string',
  NUMBER = 'number',
}

const HubspotFieldTypeMap = {
  [HUBSPOT_FIELD_TYPES.STRING]: LITERAL_TYPES.STRING,
  [HUBSPOT_FIELD_TYPES.NUMBER]: LITERAL_TYPES.NUMBER,
  [HUBSPOT_FIELD_TYPES.ENUM]: LITERAL_TYPES.ENUM,
  [HUBSPOT_FIELD_TYPES.DATE]: LITERAL_TYPES.STRING,
  [HUBSPOT_FIELD_TYPES.DATETIME]: LITERAL_TYPES.DATETIME,
  [HUBSPOT_FIELD_TYPES.BOOLEAN]: LITERAL_TYPES.STRING,
}

export const getHubspotFieldType = (fieldType: HUBSPOT_FIELD_TYPES) =>
  HubspotFieldTypeMap[fieldType]
