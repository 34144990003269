import IconRoutera from 'src/components/atoms/icons/icons'
import SelectableTable from 'src/components/organisms/tables/SelectableTable.Organism'
import { Link, useNavigate } from 'react-router-dom'
import { useActions } from 'src/actions'
import { useAppSelector } from 'src/store'
import { listAllRoles } from 'src/container/roles/roles.selector'
import { useEffect, useState } from 'react'
import { RolesListType } from 'src/types/roles'
import toastBuilder from 'src/utils/toastBuilder'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'

const RolesList = () => {
  const { getRolesList, createRole, deleteRole } = useActions()
  const rolesData = useAppSelector(listAllRoles)
  const [rowsToRolesData, setRowsToRolesData] = useState<
    RolesListType[] | undefined
  >(undefined)
  const [loadingCreateRole, setLoadingCreateRole] = useState(false)
  const [loadingViewRoles, setLoadingViewRoles] = useState(false)
  const [canBeRemoved, setCanBeRemoved] = useState<string[]>([])
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const navigation = useNavigate()
  const ability = useAbility(AbilityContext)
  const [rolesList, setRolesList] = useState<RolesListType[]>([])

  useEffect(() => {
    setLoadingViewRoles(true)
    getRolesList()
  }, [])

  useEffect(() => {
    if (rolesData && rolesData[0].role && rolesData[0].access) {
      setRolesList(rolesData)
    }
  }, [rolesData])

  useEffect(() => {
    if (rolesList.length > 0 && rolesList[0].access) {
      const roles = rolesList.filter(
        (item: RolesListType) => item.role && !item.role.canBeRemoved,
      )
      let ids: string[] = []
      ids = roles.map((rol) => {
        if (rol.role) {
          return rol.role.id
        }
        return ''
      })
      setCanBeRemoved(ids)

      setLoadingViewRoles(false)
      setRowsToRolesData(rolesList)
    }
  }, [rolesList])

  const searchRol = (searchText: string) => {
    setIsSearch(true)
    if (searchText === '') {
      setRowsToRolesData(rolesList)
      setIsSearch(false)
      return
    }
    setRowsToRolesData(
      rolesList.filter((role: RolesListType) =>
        role.role?.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    )
  }

  const createRol = async () => {
    setLoadingCreateRole(true)
    let idConversion = ''
    const result: any = await createRole()
    if (result.payload.success) {
      idConversion = result.payload.data.id
      navigation(
        `/settings/create-role/${idConversion}?tab=create-role-dashboard`,
      )
    } else {
      setLoadingCreateRole(false)
    }
  }

  const handleDelete = async (rolesListDelete: Array<any>) => {
    const rolesIds: string[] = rolesListDelete.map((rol) => rol.role.id)

    const response: any = await deleteRole(rolesIds)
    if (response.payload.success) {
      setLoadingViewRoles(true)
      toastBuilder('success', 'The item(s) were removed')
      getRolesList()
    } else {
      setLoadingViewRoles(false)
    }
  }
  const columnsRolesTable = [
    {
      label: 'Name',
      render: ({ role }: any) => {
        return <div>{role && role.name}</div>
      },
    },
    {
      label: 'Dashboard',
      render: ({ access }: any) => {
        return (
          <div>
            {access &&
              (access.dashboard.fullAccess ? (
                <span className='text-routera-800 bg-routera-50 py-1 px-3 rounded-[6px] text-xs leading-[18px] font-medium'>
                  Full Access
                </span>
              ) : (
                <span className='text-gray-900 bg-gray-100 py-1 px-3 rounded-[6px] text-xs leading-[18px] font-medium'>
                  Limited
                </span>
              ))}
          </div>
        )
      },
    },
    {
      label: 'Router',
      render: ({ access }: any) => {
        return (
          <div>
            {access &&
              (access.routers.fullAccess ? (
                <span className='text-routera-800 bg-routera-50 py-1 px-3 rounded-[6px] text-xs leading-[18px] font-medium'>
                  Full Access
                </span>
              ) : (
                <span className='text-gray-900 bg-gray-100 py-1 px-3 rounded-[6px] text-xs leading-[18px] font-medium'>
                  Limited
                </span>
              ))}
          </div>
        )
      },
    },
    {
      label: 'Conversions',
      render: ({ access }: any) => {
        return (
          <div>
            {access &&
              (access.conversions.fullAccess ? (
                <span className='text-routera-800 bg-routera-50 py-1 px-3 rounded-[6px] text-xs leading-[18px] font-medium'>
                  Full Access
                </span>
              ) : (
                <span className='text-gray-900 bg-gray-100 py-1 px-3 rounded-[6px] text-xs leading-[18px] font-medium'>
                  Limited
                </span>
              ))}
          </div>
        )
      },
    },
    {
      label: 'Settings',
      render: ({ access }: any) => {
        return (
          <div>
            {access &&
              (access.settings.fullAccess ? (
                <span className='text-routera-800 bg-routera-50 py-1 px-3 rounded-[6px] text-xs leading-[18px] font-medium'>
                  Full Access
                </span>
              ) : (
                <span className='text-gray-900 bg-gray-100 py-1 px-3 rounded-[6px] text-xs leading-[18px] font-medium'>
                  Limited
                </span>
              ))}
          </div>
        )
      },
    },
    {
      label: 'Edit role',
      render: ({ role }: any) => {
        const pencilIcon: JSX.Element = (
          <IconRoutera
            iconName='PencilIcon'
            className={`w-[15px] h-[15px] mr-2 mt-[2px] text-blue-600 ${
              ability.can('update', 'setting.role')
                ? 'cursor-pointer'
                : 'cursor-not-allowed'
            } `}
          />
        )
        return (
          <div>
            {!canBeRemoved.includes(role.id) &&
              (ability.can('update', 'setting.role')
                ? role && (
                    <Link
                      to={`/settings/edit-role/${role.id}?tab=create-role-dashboard`}
                    >
                      {pencilIcon}
                    </Link>
                  )
                : pencilIcon)}
          </div>
        )
      },
    },
  ]

  return (
    <div className='px-5 py-7'>
      <button
        className={`flex flex-row items-center justify-center px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start ml-auto ${
          (loadingCreateRole || !ability.can('create', 'setting.role')) &&
          'w-[131px] h-[46px] cursor-not-allowed'
        }`}
        onClick={createRol}
        disabled={loadingCreateRole || !ability.can('create', 'setting.role')}
      >
        {!loadingCreateRole ? (
          <>
            <IconRoutera
              iconName='PlusSmallIcon'
              className='w-[19px] mr-2 mt-[2px]'
            />{' '}
            Add Role
          </>
        ) : (
          <div className='spinner-border spinner-border-sm' role='status' />
        )}
      </button>
      <SelectableTable
        columns={columnsRolesTable}
        rows={rowsToRolesData ? rowsToRolesData : []}
        initialSelection={[]}
        showSelectionType={true}
        showHeaderMeta={true}
        borderTable={true}
        noItemsCheck={canBeRemoved}
        onSearchChange={searchRol}
        loadingTable={loadingViewRoles}
        className={loadingViewRoles ? 'bg-white' : ''}
        onDelete={handleDelete}
        permissions={{
          delete: ability.can('delete', 'setting.role'),
        }}
      />
    </div>
  )
}

export default RolesList
