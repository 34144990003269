import { createAsyncThunk } from '@reduxjs/toolkit'
import { ApiClient } from '../..'
import { routerService } from '../../services/lead_routes'
import { IRoute } from '../../types/leadRoutes'

enum actiontype {
  registernewRoute = 'registernewRoute action type',
  getallRoutes = 'getallRoutes action type',
}
export const registernewRoute = createAsyncThunk(
  actiontype.registernewRoute,
  async (payload: any) => {
    const response = await routerService.createrouter(payload)
    return response?.data?.addedRouter as IRoute
  },
)

export const getallRoutes = createAsyncThunk(
  actiontype.getallRoutes,
  async () => {
    const response = await routerService.getallrouters()
    return response?.data?.routers as IRoute[]
  },
)

export const delete_source_item = createAsyncThunk(
  actiontype.getallRoutes,
  async () => {
    const response = await routerService.getallrouters()
    return response?.data?.routers as IRoute[]
  },
)

export const Change_publishe_status = createAsyncThunk(
  '/router/Change_publishe_status',
  async (payload: routerstatuschangepaylaod, thunkAPi) => {
    const response = await ApiClient.post('/router/change-status', payload)
    if (response) return response
    else thunkAPi.rejectWithValue('')
  },
)

export const Delete_bulk_routes = createAsyncThunk(
  '/routerIdsArray/routerIdsArray',
  async (payload: Array<string>, thunkAPi) => {
    const response = await ApiClient.delete('/routers', {
      params: { routerIds: payload },
    })
    if (response) return response
    else thunkAPi.rejectWithValue('')
  },
)
