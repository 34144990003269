import { useEffect, useState } from 'react'
import ReviewAndPublish from 'src/components/molecules/permissionsTabs/reviewAndPublish'
import EditName from 'src/components/molecules/editName/editName'
import ConversionRole from 'src/components/molecules/permissionsTabs/conversion'
import DashboardRole from 'src/components/molecules/permissionsTabs/dashboard'
import RouterRole from 'src/components/molecules/permissionsTabs/router'
import SettingsRole from 'src/components/molecules/permissionsTabs/settings'
import Tabs from 'src/components/molecules/tabs/tabs'
import { DataTab } from 'src/types/tabs'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useActions } from 'src/actions'
import { useAppSelector } from 'src/store'
import {
  specificRoleById,
  listAllPermissions,
} from 'src/container/roles/roles.selector'
import { PermissionsType, RolesPermissions } from 'src/types/roles'
import toastBuilder from 'src/utils/toastBuilder'
import { getAccountById } from 'src/container/my_account/account.selector'
import { selectUser } from 'src/container/auth/auth.selector'
import { config } from 'src/config/constants'

const { FREE_PLAN_MONTHLY } = config

const CreateRole = () => {
  const location = useLocation()
  const navigation = useNavigate()
  const {
    getRoleById,
    updateRole,
    getPermissions,
    updatePermissions,
    getAccount,
  } = useActions()

  const [currentTab, setCurrentTab] = useState(0)
  const [editName, setEditName] = useState<boolean>(false)
  const [roleName, setRoleName] = useState<string>('Rol Name')
  const { roleId } = useParams()
  const roleById = useAppSelector(specificRoleById)
  const account = useAppSelector(getAccountById)
  const currentUser = useAppSelector(selectUser)
  const [permissionsList, setPermissionsList] = useState<PermissionsType[]>([])
  const permissionsData = useAppSelector(listAllPermissions)
  const [sendPermissions, setSendPermissions] = useState<PermissionsType[]>()
  const [thereAreChanges, setThereAreChanges] = useState<boolean>(false)

  const [dataConversions, setDataConversions] = useState<RolesPermissions[]>([])
  const [dataRouter, setDataRouter] = useState<RolesPermissions[]>([])
  const [dataDashboard, setDataDashboard] = useState<RolesPermissions[]>([])
  const [dataSettings, setDataSettings] = useState<RolesPermissions[]>([])

  const [loadingRol, setLoadingRol] = useState<boolean>(false)
  const [freePlan, setFreePlan] = useState(false)

  useEffect(() => {
    if (currentUser && currentUser.account_id) {
      getAccount(currentUser.account_id)
    }
  }, [])

  useEffect(() => {
    if (account && account._id) {
      if (account.plan_id === FREE_PLAN_MONTHLY) {
        setFreePlan(true)
      }
    }
  }, [account])

  useEffect(() => {
    setRoleName('Rol Name')
    getPermissions()
    if (roleId) {
      getRoleById(roleId)
    }
  }, [])

  useEffect(() => {
    if (roleById[0]._id !== '') {
      setLoadingRol(false)
      setRoleName(roleById[0].name)
      setSendPermissions(roleById[0].permissions)
    }
  }, [roleById])

  useEffect(() => {
    setLoadingRol(true)
    setSendPermissions(undefined)
    setRoleName('Loading...')
  }, [specificRoleById])

  useEffect(() => {
    if (permissionsData) {
      setPermissionsList(permissionsData)
    }
  }, [permissionsData])

  const handleStatusChange = (data: any, value: boolean) => {
    let currentPermissions: any = []
    let permissionsToSend = []

    if (permissionsList && permissionsList.length > 0) {
      const dataPermissions: PermissionsType | undefined = permissionsList.find(
        (item: PermissionsType) =>
          item.subject === data.subject && item.action === data.actionName,
      )
      if (sendPermissions && sendPermissions.length > 0) {
        currentPermissions = [...sendPermissions]
        const existingPermissionIndex: number = currentPermissions.findIndex(
          (item: PermissionsType) =>
            item.subject === data.subject && item.action === data.actionName,
        )
        if (value) {
          if (existingPermissionIndex < 0 && dataPermissions) {
            currentPermissions.push(dataPermissions)
          }
        } else {
          if (existingPermissionIndex >= 0) {
            currentPermissions.splice(existingPermissionIndex, 1)
          }
        }
        permissionsToSend = currentPermissions
      } else {
        if (value) {
          currentPermissions.push(dataPermissions)
        } else {
          const existingPermissionIndex: number = currentPermissions.findIndex(
            (item: PermissionsType) =>
              item.subject === data.subject && item.action === data.actionName,
          )
          if (existingPermissionIndex) {
            currentPermissions.splice(existingPermissionIndex, 1)
          }
        }
        permissionsToSend = currentPermissions
      }
      setThereAreChanges(true)
      setSendPermissions(permissionsToSend)
    }
  }

  const dataTabs: DataTab[] = [
    {
      label: 'Dashboard',
      id: 'create-role-dashboard',
      dataContentTab: (
        <DashboardRole
          dataRole={sendPermissions}
          handleStatusChange={handleStatusChange}
          setDataDashboard={setDataDashboard}
          dataDashboard={dataDashboard}
        />
      ),
    },
    {
      label: 'Router',
      id: 'create-role-router',
      dataContentTab: (
        <RouterRole
          dataRole={sendPermissions}
          handleStatusChange={handleStatusChange}
          dataRouter={dataRouter}
          setDataRouter={setDataRouter}
          freePlan={freePlan}
        />
      ),
    },
    {
      label: 'Conversion',
      id: 'create-role-conversion',
      dataContentTab: (
        <ConversionRole
          dataRole={sendPermissions}
          handleStatusChange={handleStatusChange}
          dataConversions={dataConversions}
          setDataConversions={setDataConversions}
          freePlan={freePlan}
        />
      ),
    },
    {
      label: 'Settings',
      id: 'create-role-settings',
      dataContentTab: (
        <SettingsRole
          dataRole={sendPermissions}
          handleStatusChange={handleStatusChange}
          dataSettings={dataSettings}
          setDataSettings={setDataSettings}
          freePlan={freePlan}
        />
      ),
    },
    {
      label: 'Review & Publish',
      id: 'create-role-publish',
      dataContentTab: (
        <ReviewAndPublish
          dataConversions={dataConversions}
          dataRouter={dataRouter}
          dataDashboard={dataDashboard}
          dataSettings={dataSettings}
        />
      ),
    },
  ]

  const saveName = async () => {
    if (roleId) {
      const data = {
        name: roleName,
      }
      setEditName(false)
      const resp: any = await updateRole({ id: roleId, data })
      if (resp.payload.success) {
        toastBuilder('success', 'Role name updated successfully')
      } else {
        toastBuilder('error', 'Failed to change Role name')
      }
    }
  }

  const onNextTab = () => {
    if (currentTab < dataTabs.length - 1) {
      changeTab('next')
    }
    if (currentTab === 4) {
      toastBuilder('success', 'Role saved successfully')
      cancelCreate()
    }
  }

  const cancelCreate = () => {
    navigation('/settings/roles')
  }

  const savePermissions = async () => {
    const savePermissions: string[] = []
    if (sendPermissions && sendPermissions.length > 0) {
      sendPermissions.forEach((element: PermissionsType) => {
        savePermissions.push(element._id)
      })

      if (roleId && savePermissions.length > 0) {
        const data = {
          permissions: savePermissions,
        }
        setEditName(false)
        const resp: any = await updatePermissions({ id: roleId, data })
        if (resp.payload.success) {
          if (roleId) {
            getRoleById(roleId)
          }
          toastBuilder('success', 'Role updated successfully')
        } else {
          toastBuilder('error', 'Failed to change Role')
        }
      }
    }
  }

  useEffect(() => {
    if (thereAreChanges) {
      savePermissions()
      setThereAreChanges(false)
    }
  }, [currentTab])

  const changeTab = (action?: string, id?: string) => {
    setEditName(false)
    if (id) {
      const currentTabIndex = dataTabs.findIndex((tab) => {
        return tab.id == id
      })
      setCurrentTab(currentTabIndex)
      navigation(location.pathname + '?tab=' + id)
      return
    }

    const currentTabIndex = dataTabs.findIndex((tab) => {
      return tab.id == location.search.split('=')[1]
    })

    switch (action) {
      case 'prev':
        navigation(
          location.pathname + '?tab=' + dataTabs[currentTabIndex - 1].id,
        )
        setCurrentTab(currentTabIndex - 1)
        break
      case 'next':
        navigation(
          location.pathname + '?tab=' + dataTabs[currentTabIndex + 1].id,
        )
        setCurrentTab(currentTabIndex + 1)
        break
      default:
        navigation(location.pathname + '?tab=' + dataTabs[currentTabIndex].id)
        setCurrentTab(currentTabIndex)
    }
  }

  return (
    <section className='border-l border-gray-200'>
      <div className='p-4 px-5 bg-gray-100'>
        <h3 className='text-2xl block'> New Role </h3>
      </div>
      <EditName
        editName={editName}
        name={roleName}
        setEditName={setEditName}
        setName={setRoleName}
        editNameChange={saveName}
        className='mx-4'
        placeholder='Rol Name'
        loading={loadingRol}
      />
      <Tabs
        dataTabs={dataTabs}
        classContentTab='min-h-[calc(100vh-297px)] bg-routera-gray'
        onChangeTab={changeTab}
      />
      <div className='py-[8px] -my-[6px] px-4 bg-white flex'>
        <button
          className='btn btn-primary px-4 d-block ms-auto border border-gray-200 w-[143px] bg-white text-black'
          onClick={cancelCreate}
        >
          Cancel
        </button>
        <button
          className='btn btn-primary px-4 d-block ml-4 w-[143px]'
          onClick={onNextTab}
        >
          {location.search === '?tab=create-role-publish' ? 'Publish' : 'Next'}
        </button>
      </div>
    </section>
  )
}

export default CreateRole
