import { useEffect } from "react"
import IconRoutera from "src/components/atoms/icons/icons"
import { TotalRoutedType } from "src/types/dashboard"
import { HUBSPOT_OBJECT_TYPES } from "src/types/sources"

interface IProps {
	objectType: HUBSPOT_OBJECT_TYPES
	totalRouted: TotalRoutedType | null
	classWidget?: string
}

const WidgetTotalRouted: React.FC<IProps> = (props) => {

	const { objectType, totalRouted, classWidget } = props

	return <div className={`bg-white mt-4 p-3 shadow-sm rounded-lg flex flex-col ${classWidget}`}>
		<IconRoutera
			iconName='ChartPieIcon'
			className='h-6 w-6 text-gray-400'
			aria-hidden='true'
		/>
		<span className='capitalize text-gray-500 text-base font-semibold'>
			Total {objectType} Routed
		</span>
		{totalRouted ? <div>
			<span className='text-gray-700 text-3xl font-bold mb-2'>
				{totalRouted.total}
			</span>
			<div className='flex flex-row'>
				{totalRouted.comparativePeriod > 0 ? <span className='text-green-500 text-base font-semibold flex flex-row'>
					<IconRoutera
						iconName='ArrowUpIcon'
						className='h-4 w-4'
						aria-hidden='true'
					/>
					{Math.trunc(totalRouted.comparativePeriod)}%{' '}
				</span>
					: <span className={`${totalRouted.comparativePeriod === 0 ? 'text-gray-500' : 'text-red-500'} text-base font-semibold flex flex-row`}>
						{totalRouted.comparativePeriod != 0 && <IconRoutera
							iconName='ArrowDownIcon'
							className='h-4 w-4'
							aria-hidden='true'
						/>}
						{totalRouted.comparativePeriod}%{' '}
					</span>}
				<span className='text-gray-500 text-base font-normal ml-2'>
					vs Last Period
				</span>
			</div>
		</div> : <div>
			<span className='text-gray-700 text-3xl font-bold mb-2 mt-2'>
				No data*
			</span>
			<div className='bg-routera-50 py-[2px] px-[10px] rounded-md w-fit mt-2'>
				<a target="_blank" rel="noreferrer" href='https://help.routera.io/article/17-set-user-capacities'><span className='flex text-routera-700 text-xs font-medium leading-[18px] items-center cursor-pointer'>
					<IconRoutera iconName='InformationCircleIcon' className='w-[14px] h-[14px] mr-1' />
					How does this widget work?
					<IconRoutera iconName='ArrowRightIcon' className='w-[14px] h-[14px] ml-1' />
				</span></a>
			</div>
		</div>}
	</div>
}

export default WidgetTotalRouted