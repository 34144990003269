import { createAsyncThunk } from '@reduxjs/toolkit'

import { planService } from 'src/services/plan.service'

enum actionType {
  GET_PLANS = 'GET_PLANS',
}

export const getPlansAction = createAsyncThunk(
  actionType.GET_PLANS,
  async (_, thunkApi) => {
    const response = await planService.getPlans()
    if (response) return response
    else return thunkApi.rejectWithValue('')
  },
)
