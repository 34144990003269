import { useEffect, useState } from 'react'
import { useActions } from 'src/actions'
import { Toggle } from 'src/components/atoms/Toggle/Toggle.atom'
import toastBuilder from 'src/utils/toastBuilder'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'

const AssignmentStatus = ({ user }: any) => {

  const ability = useAbility(AbilityContext)

  if (!ability.can('read', 'setting.profile.assignment_status')) {
    return <NoPermission classHeight='h-[60vh]' value='read' />
  }

  const [office_status, set_office_status] = useState(true)

  const { changeoffice_status } = useActions()


  const handlestatusChange = (_: any, val: boolean) => {
    set_office_status(false)

    const initialCall = async () => {
      const payload = {
        office_status: val,
        user_id: user._id,
      }
      const result: any = await changeoffice_status(payload)
      if (result.payload.success) {
        set_office_status(result?.payload?.data?.office_status)
        toastBuilder('success', 'status changed successfully')
      }
    }
    initialCall()
  }

  useEffect(() => {
    set_office_status(user.office_status)
  }, [user])

  return (
    <div className='px-5 pt-6'>
      <ul className='tg-list list-unstyled items-start'>
        <li className='tg-list-item text-initial'>
          <div className='w-[220px] flex justify-center '>
            <Toggle
              initialValue={office_status}
              handleChange={handlestatusChange}
              data={null}
              className='mr-2 -ml-3 mt-[1px]'
              assignmentStatus={true}
              disabled={!ability.can('update', 'setting.profile.assignment_status')}
            />
            <span className='text-[18px] font-medium'>Assignment Status</span>
          </div>
          <span className='inline-block text-left lg:w-[670px] w-[100%] text-gray-700 mt-3 ml-1 text-[14px]'>
            Shows <span className='text-green-400 font-semibold'>GREEN</span>{' '}
            when you are active and available for assignments. Shows{' '}
            <span className='text-red-500 font-semibold'>RED</span> when you are
            offline, and unavailable for assignments
          </span>
          <label className='tgl-btn' htmlFor='cb4'></label>
        </li>
      </ul>
    </div>
  )
}

export default AssignmentStatus
