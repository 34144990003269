import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form, Modal, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import { createSourceValidator } from '../../../../validations/sources.validation'
import { selectIntegrations } from '../../../../container/integrations/integrations.selector'
import { useAppSelector } from '../../../../store'
import hubspot from '../../../../assets/images/hubspot-logo.png'
import webhook from '../../../../assets/images/webhook-logo.png'
import { getTitleCase } from 'src/utils/common/string.common'
import { useActions } from 'src/actions'

const integrationImages = {
  hubspot,
  webhook,
}

interface Props {
  showCreateModal: boolean
  setShowCreateModal: Dispatch<SetStateAction<boolean>>
  onSubmit: (...dto: any) => void
}

const SourcesModalTemplate = (props: Props) => {
  const { getIntegrations } = useActions()
  const [hasMounted, setHasMounted] = useState(false)
  const { showCreateModal, setShowCreateModal, onSubmit } = props
  const integrations = useAppSelector(selectIntegrations)

  useEffect(() => {
    if (!hasMounted) {
      getIntegrations()
      setHasMounted(true)
    }
  }, [hasMounted])

  const initialValue = {
    name: '',
    integrationId: '',
  }

  return (
    <Modal
      show={showCreateModal}
      onHide={() => setShowCreateModal(false)}
      centered
      contentClassName='bg-white rounded-lg'
    >
      <Modal.Header closeButton className='bg-white px-8'>
        <Modal.Title className='fw-bold'>Create Source</Modal.Title>
      </Modal.Header>

      <Modal.Body className='bg-white mt-4 mx-8 rounded-lg'>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValue}
          validationSchema={createSourceValidator}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isSubmitting,
            dirty,
            errors,
            setFieldValue,
          }) => {
            return (
              <Form className='text-lg' onSubmit={handleSubmit}>
                <div className='mt-0 mb-0'>
                  <div className='mb-4'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      className='input'
                      type='text'
                      value={values.name}
                      onBlur={handleBlur}
                      name='name'
                      onChange={handleChange}
                      isInvalid={!!errors.name && touched.name}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {touched.name && errors.name}
                    </Form.Control.Feedback>
                  </div>
                  <div className='integration-options'>
                    <Form.Label>Select an Integration Type</Form.Label>
                    <div className='mt-6 flex flex-row justify-start gap-6'>
                      {integrations &&
                        Object.keys(integrations).length > 0 &&
                        Object.keys(integrations).map(
                          (integrationName, index) => {
                            const isCurrentIntegrationSelected = (
                              integrationName: string,
                            ) =>
                              values.integrationId.includes(
                                (integrations as any)[integrationName]._id,
                              )
                            return (
                              <div key={`${integrationName}-${index}`}>
                                <button
                                  type='button'
                                  className={`${
                                    isCurrentIntegrationSelected(
                                      integrationName,
                                    )
                                      ? 'bg-blue-100'
                                      : 'bg-white'
                                  } py-2 px-2 hover:pointer shadow rounded-lg`}
                                  onClick={() => {
                                    setFieldValue(
                                      'integrationId',
                                      (integrations as any)[integrationName]
                                        ._id,
                                    )
                                  }}
                                >
                                  <img
                                    src={
                                      (integrationImages as any)[
                                        integrationName
                                      ]
                                    }
                                    alt=''
                                  />
                                  <p className='mt-2'>
                                    {getTitleCase(integrationName)}
                                  </p>
                                </button>
                              </div>
                            )
                          },
                        )}
                    </div>
                    <Form.Control.Feedback type='invalid'>
                      {touched.integrationId && errors.integrationId}
                    </Form.Control.Feedback>
                  </div>
                  <div className='bg-white'>
                    <Button
                      type='submit'
                      disabled={!dirty || isSubmitting}
                      className='btn btn-primary float-end mb-4 mt-4'
                    >
                      Add Source
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default SourcesModalTemplate
