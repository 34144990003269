import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Formik } from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useActions } from 'src/actions'
import toastBuilder from 'src/utils/toastBuilder'
import Avatar from 'src/components/atoms/avatar/Avatar.Atom'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'

const UserSetting: React.FC<{
  saveForm: any
  idForm: string
  user: any
  setuserTimeZone: Dispatch<SetStateAction<string>>
  userTimeZone: string
  timeZones: string[]
}> = ({ saveForm, idForm, user, setuserTimeZone, userTimeZone, timeZones }) => {
  const { UploadProfileAction } = useActions()
  const ability = useAbility(AbilityContext)

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0]
    const bodyFormData = new FormData()
    bodyFormData.append('image', file)

    const response: any = await UploadProfileAction(bodyFormData)
    if (response.payload.success) {
      toastBuilder('success', 'image uploded successfully')
    }
  }

  const [initalValue, setinitalValue] = useState({
    firstName: '',
    lastName: '',
    phone: '+1',
    companyName: '',
  })

  const handleTimeZoneChange = (event: any) => {
    setuserTimeZone(event.target.value)
  }

  useEffect(() => {
    setinitalValue({
      firstName: user.firstName,
      lastName: user.lastName,
      companyName: user.companyName,
      phone: user.phone ? user.phone : '+1',
    })
  }, [user])

  return (
    <>
      <div className='accountform mb-2 mt-0'>
        <Formik
          onSubmit={saveForm}
          initialValues={initalValue}
          enableReinitialize={true}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => {
            return (
              <form className='settingform' onSubmit={handleSubmit} id={idForm}>
                <div className='bg py-0'>
                  <div className='px-5 p-4'>
                    <div className='col-md-12'>
                      <span className='block text-[14px] font-medium leading-[21px]'>
                        Profile Picture
                      </span>
                    </div>
                    <div className='col-md-12 mb-4 mt-1'>
                      <div className='profilepic'>
                        <div className='mr-2 flex items-center'>
                          <Avatar
                            className='w-20 h-20 text-2xl mr-2'
                            firstName={user?.firstName || ''}
                            lastName={user?.lastName || ''}
                            imgURL={user?.avatar}
                          />
                        </div>

                        <label
                          htmlFor='file-upload'
                          className='fileuplaod [&>*:first-child]:md:bg-routera-700'
                        >
                          <i className='fa-solid fa-camera pointer text-[white]'></i>
                          <input
                            id='file-upload'
                            type='file'
                            onChange={(event) => handleImageChange(event)}
                            style={{ opacity: '0' }}
                            disabled={!ability.can('update', 'setting.profile.user_setting')}
                          />
                        </label>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='firstName'
                        className='block mb-2 text-[14px] font-medium text-gray-800 dark:text-white leading-[21px]'
                      >
                        <span className='text-[14px] font-medium'>First Name</span>
                      </label>
                      <input
                        className='block w-[100%] text-gray-500 lg:w-[45%] md:w-[50%] appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none h-[45px] focus:ring-indigo-500 sm:text-sm'
                        type='text'
                        value={values.firstName}
                        onBlur={handleBlur}
                        name='firstName'
                        onChange={handleChange}
                        disabled={!ability.can('update', 'setting.profile.user_setting')}
                      />
                      {errors.firstName && (
                        <span
                          id='firstName'
                          className='text-[red] text-[9px] capitalize'
                        >
                          {errors.firstName}
                        </span>
                      )}
                    </div>
                    <div className='col-md-12 mb-3 mt-3'>
                      <label
                        htmlFor='lastName'
                        className='block mb-2 text-[14px] font-medium leading-[21px] text-gray-800 dark:text-white'
                      >
                        <span className='text-[14px] font-medium'>Last Name</span>
                      </label>
                      <input
                        className='block h-[45px] w-[100%] lg:w-[45%] md:w-[50%] appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                        type='text'
                        name='lastName'
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!ability.can('update', 'setting.profile.user_setting')}
                      />
                      {errors.lastName && (
                        <span
                          id='lastName'
                          className='text-[red] text-[9px] capitalize'
                        >
                          {errors.lastName}
                        </span>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor='phoneNumber'
                        className='block mb-2 text-[18px] font-medium leading-[27px]  text-gray-700 dark:text-white'
                      >
                        Phone Number
                      </label>
                      <span className='fs-12 mt-2 mb-4 d-block w-[100%] lg:w-[70%] md:w-[80%] inline-block text-[14px] font-normal leading-[21px] '>
                        We may use this phone number to contact you about
                        security events, sending workflow SMS and for owner
                        property values. Please refer to{' '}
                        <span className='text-routera-700 underline'>
                          our privacy policy for moire information
                        </span>
                      </span>
                      <div className='col-md-12 mb-3 [&>*:first-child]:w-[100%] [&>*:first-child]:lg:w-[45%] [&>*:first-child]:md:w-[50%]'>
                        <PhoneInput
                          inputClass='block h-[45px] w-[100%] lg:w-[45%] md:w-[50%] appearance-none rounded-md border border-gray-300 rounded-[8px] py-2 text-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                          searchClass='border border-gray-300 text-gray-500'
                          enableSearch={true}
                          defaultMask='us'
                          country='us'
                          inputProps={{
                            name: 'phone',
                          }}
                          value={values.phone}
                          onChange={(value, data, event, formattedValue) => {
                            handleChange(event)
                          }}
                          onBlur={handleBlur}
                          placeholder='Enter your phone number'
                          disabled={!ability.can('update', 'setting.profile.user_setting')}
                        />
                        {errors.phone && (
                          <span
                            id='phone'
                            className='text-[red] text-[9px] capitalize'
                          >
                            {errors.phone}
                          </span>
                        )}
                      </div>
                      <span className='text-[18px] font-medium leading-[27px]  text-gray-700 mt-6 mb-1 inline-block'>
                        Select Timezone
                      </span>
                      <select
                        id='countries'
                        className='bg-white border border-gray-300 text-[14px] font-normal text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-[100%] lg:w-[45%] md:w-[50%]'
                        name='defaultuser'
                        onChange={handleTimeZoneChange}
                        value={userTimeZone}
                        disabled={!ability.can('update', 'setting.profile.user_setting')}
                      >
                        {timeZones.map((timeZone: any, index: number) => {
                          return (
                            <option value={timeZone} key={index}>
                              {timeZone.replace(/_/g, ' ')}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default UserSetting
