import { useEffect, useState } from 'react'
import Tabs from 'src/components/molecules/tabs/tabs'
import { DataTab } from 'src/types/tabs'
import { useParams } from 'react-router-dom'
import { useActions } from 'src/actions'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { initFlowbite } from 'flowbite'
import toastBuilder from 'src/utils/toastBuilder'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import EditName from 'src/components/molecules/editName/editName'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import { ISLA } from 'src/types/sla'
import { ROUTER_SLA_ACTION_TYPES } from 'src/types/routerDetail'
import Trigger from 'src/components/molecules/SLATabs/trigger'
import Time from 'src/components/molecules/SLATabs/time'
import Rule from 'src/components/molecules/SLATabs/rules'
import Actions from 'src/components/molecules/SLATabs/actions'
import { uselistofAllroutes } from 'src/container/leadRouter/leadRoutes.selector'
import { useAppSelector } from 'src/store'

const SLADefault = {
  _id: '',
  name: '',
  timeInterval: '1 minutes',
  account_id: '',
  createdAt: '',
  is_active: false,
  updatedAt: '',
  object_type: HUBSPOT_OBJECT_TYPES.CONTACTS,
  actions: ['SEND_EMAIL'],
  rules: [
    [
      {
        operator: {
          value: 'equal',
          label: 'Equals',
        },
        label: 'Additional email addresses',
        name: 'hs_additional_emails',
        value: [],
      },
    ],
  ],
  router_id: '',
  integration_type: '',
  action: ROUTER_SLA_ACTION_TYPES.EMAIL_REMINDER, //remove
  triggerRule: {
    objectType: HUBSPOT_OBJECT_TYPES.CONTACTS,
    value: '',
    fieldName: '',
    fieldLabel: '',
    operator: '',
  }, // remove
}

const SLADetail = () => {
  const [slaName, setSlaName] = useState<string>('Unnamed SLA')
  const [editName, setEditName] = useState<boolean>(false)
  const [hasMounted, setHasMounted] = useState(false)
  const [thereAreChanges, setThereAreChanges] = useState<boolean>(false)
  const [objectType, setObjectType] = useState<HUBSPOT_OBJECT_TYPES>(
    SLADefault.object_type,
  )
  const [currentSLA, setCurrentSLA] = useState<ISLA>(SLADefault)
  const [currentTab, setCurrentTab] = useState(0)
  const {
    getOneSLAAction,
    getAllSLAsAction,
    updateOneSLAAction,
    setRulesSLA,
    getallRoutes,
  } = useActions()
  const location = useLocation()
  const navigation = useNavigate()
  const ability = useAbility(AbilityContext)
  const [trigger, setTrigger] = useState<string>('workflow_action')
  const [rules, setRules] = useState<any>(SLADefault.rules)
  const { slaId } = useParams()
  const [timeInterval, setTimeInterval] = useState<string>(
    SLADefault.timeInterval,
  )
  const [actions, setActions] = useState<string[]>(SLADefault.actions)
  const [routerId, setRouterId] = useState<string>(SLADefault.router_id)
  const [selectedRules, setSelectedRules] = useState<any>([])
  const routerList = useAppSelector(uselistofAllroutes)

  useEffect(() => {
    if (!hasMounted && slaId) {
      getSLAData(slaId)
      setHasMounted(true)
    }
  }, [hasMounted])

  useEffect(() => {
    getallRoutes()
  }, [])

  useEffect(() => {
    if (routerList && routerList.length && !routerId) {
      setRouterId(routerList[0]._id)
    }
  }, [routerList])

  useEffect(() => {
    if (currentSLA && currentSLA.integration_type === 'hubspot') {
      setTrigger('workflow_action')
    }
  }, [currentSLA])

  const getSLAData = async (id: string) => {
    const resp: any = await getOneSLAAction(id)
    if (resp.payload) {
      setCurrentSLA(resp.payload.data)
      setSlaName(resp.payload.data.name)
      if (resp.payload.data.rules && resp.payload.data.rules.length > 0) {
        setRules(resp.payload.data.rules)
      }
      if (resp.payload.data.object_type) {
        setObjectType(resp.payload.data.object_type)
      }
      if (resp.payload.data.router_id) {
        setRouterId(resp.payload.data.router_id)
      }
      if (resp.payload.data.actions.length) {
        setActions(resp.payload.data.actions)
      }
      if (resp.payload.data.timeInterval) {
        setTimeInterval(resp.payload.data.timeInterval)
      }
    }
  }

  useEffect(() => {
    if (slaId && location.search === '?tab=actions') {
      getSLAData(slaId)
    }
  }, [location])

  const dataTabs: DataTab[] = [
    {
      label: 'Trigger',
      id: 'trigger',
      dataContentTab: (
        <Trigger
          valueSelect={currentSLA?.object_type}
          setObjectType={setObjectType}
          setThereAreChanges={setThereAreChanges}
          trigger={trigger}
        />
      ),
    },
    {
      label: 'Rule',
      id: 'rule',
      dataContentTab: (
        <Rule
          groups={rules}
          objectType={objectType}
          selectedRules={selectedRules}
          setSelectedRules={setSelectedRules}
          setThereAreChanges={setThereAreChanges}
        />
      ),
    },
    {
      label: 'Time',
      id: 'time',
      dataContentTab: (
        <Time
          setThereAreChanges={setThereAreChanges}
          time={timeInterval}
          setTime={setTimeInterval}
        />
      ),
    },
    {
      label: 'Actions',
      id: 'actions',
      dataContentTab: (
        <Actions
          setThereAreChanges={setThereAreChanges}
          actions={actions}
          setActions={setActions}
          routerId={routerId}
          setRouterId={setRouterId}
          routerList={routerList}
        />
      ),
    },
  ]

  const changeTab = (action?: string, id?: string) => {
    if (id) {
      const currentTabIndex = dataTabs.findIndex((tab) => {
        return tab.id == id
      })
      setCurrentTab(currentTabIndex)
      navigation(location.pathname + '?tab=' + id)
      return
    }

    const currentTabIndex = dataTabs.findIndex((tab) => {
      return tab.id == location.search.split('=')[1]
    })

    switch (action) {
      case 'prev':
        navigation(
          location.pathname + '?tab=' + dataTabs[currentTabIndex - 1].id,
        )
        setCurrentTab(currentTabIndex - 1)
        break
      case 'next':
        navigation(
          location.pathname + '?tab=' + dataTabs[currentTabIndex + 1].id,
        )
        setCurrentTab(currentTabIndex + 1)
        break
      default:
        navigation(location.pathname + '?tab=' + dataTabs[currentTabIndex].id)
        setCurrentTab(currentTabIndex)
    }
  }

  useEffect(() => {
    changeTab()
  }, [])

  useEffect(() => {
    initFlowbite()
  }, [currentTab])

  const onPrevTab = () => {
    if (currentTab > 0) {
      changeTab('prev')
    }
  }

  const onNextTab = async () => {
    setEditName(false)
    if (currentTab < dataTabs.length - 1) {
      changeTab('next')
      if (location.search === '?tab=trigger' && slaId && thereAreChanges) {
        setThereAreChanges(false)
        const updateSLAPayload = {
          object_type: objectType,
        }

        const resp: any = await updateOneSLAAction({
          slaId: slaId,
          updateSLAPayload,
        })
        if (resp.payload.success) {
          setCurrentSLA(resp.payload.data)
          toastBuilder('success', 'Object type changed successfully')
        } else {
          toastBuilder('error', 'Failed to change object type')
        }
      }
    }
    if (location.search === '?tab=rule' && slaId && thereAreChanges) {
      setThereAreChanges(false)

      const data = {
        group: selectedRules,
      }

      const resp: any = await setRulesSLA({ slaId: slaId, data })

      if (resp.payload.success) {
        toastBuilder('success', 'Rule(s) created/updated successfully')
      } else {
        toastBuilder('error', 'Failed to change Rule(s)')
      }
    }
    if (
      location.search === '?tab=time' &&
      slaId &&
      timeInterval &&
      thereAreChanges
    ) {
      setThereAreChanges(false)
      const updateSLAPayload = {
        timeInterval: timeInterval,
      }

      const resp: any = await updateOneSLAAction({
        slaId: slaId,
        updateSLAPayload,
      })
      if (resp.payload.success) {
        setCurrentSLA(resp.payload.data)
        toastBuilder('success', 'The time range has been updated successfully')
      } else {
        toastBuilder('error', 'Failed to change time range')
      }
    }
    if (location.search === '?tab=actions' && slaId) {
      if (actions && actions.length === 0) {
        toastBuilder('error', 'An action must be selected')
        return
      }

      if (actions && actions.includes('REASSIGN')) {
        if (!routerId) {
          toastBuilder('error', 'A router must be selected')
          return
        }

        if (routerList.length === 0) {
          const updateSLAPayload = {
            is_active: false,
            router_id: null,
          }

          await updateOneSLAAction({
            slaId: slaId,
            updateSLAPayload,
          })

          toastBuilder('error', 'There are no routers, please create one')
          return
        }
      }

      setThereAreChanges(false)

      const updateSLAPayload = {
        is_active: true,
        router_id: routerId,
        actions: actions,
        timeInterval: timeInterval,
        rules: selectedRules,
        object_type: objectType,
      }

      const resp: any = await updateOneSLAAction({
        slaId: slaId,
        updateSLAPayload,
      })

      if (resp.payload.success) {
        toastBuilder('success', 'The action(s) were successfully updated')
        navigation('/slas')
      } else {
        toastBuilder('error', 'The action(s) could not be updated')
      }
    }
  }

  const saveName = async () => {
    if (slaId) {
      const updateSLAPayload = {
        name: slaName,
      }
      setEditName(false)
      const resp: any = await updateOneSLAAction({
        slaId: slaId,
        updateSLAPayload,
      })
      if (resp.payload.success) {
        toastBuilder('success', 'SLA name updated successfully')
      } else {
        toastBuilder('error', 'Failed to change SLA name')
      }
    }
  }

  return (
    <section className='bg-gray-100 min-h-full'>
      <div className='p-4 px-4'>
        <h3 className='text-2xl block'>New SLA</h3>
      </div>
      <EditName
        placeholder='SLA Name'
        editName={editName}
        name={slaName}
        setEditName={setEditName}
        setName={setSlaName}
        editNameChange={saveName}
        disabled={!ability.can('update', 'router.sla')}
      />
      <Tabs
        dataTabs={dataTabs}
        classContentTab='min-h-[calc(100vh-297px)] bg-routera-gray'
        classTabs='px-4'
        idProp={currentTab > 0 ? dataTabs[currentTab].id : ''}
        onChangeTab={changeTab}
      />
      <div className='py-[8px] -my-[6px] px-4 bg-white flex'>
        <button
          className={`btn btn-primary px-4 d-block w-[143px] bg-white border border-gray-200 ${
            !ability.can('update', 'router.sla') && 'cursor-not-allowed'
          }`}
          onClick={onPrevTab}
          disabled={!ability.can('update', 'router.sla')}
        >
          <span className='text-gray-800'>Back</span>
        </button>
        <button
          className={`btn btn-primary px-4 d-block ms-auto w-[143px] ${
            !ability.can('update', 'router.sla') && 'cursor-not-allowed'
          }`}
          onClick={onNextTab}
          disabled={!ability.can('update', 'router.sla')}
        >
          {location.search === '?tab=actions' ? 'Save' : 'Next'}
        </button>
      </div>
    </section>
  )
}

export default SLADetail
