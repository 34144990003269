import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { RootState } from 'src/store'
const selectedreducer = (state: RootState) => state.rolesState

export const listAllRoles = createDraftSafeSelector(
	selectedreducer,
	(rolesS) => rolesS.allRoles,
)

export const specificRoleById = createDraftSafeSelector(
	selectedreducer,
	(rolesS) => rolesS.specificRoleById,
)

export const listAllPermissions = createDraftSafeSelector(
	selectedreducer,
	(rolesS) => rolesS.listAllPermissions,
)
