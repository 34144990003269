import React from 'react'
import { toast } from 'react-toastify'

type tosstypes = 'success' | 'info' | 'warn' | 'error'

const toastBuilder = (type: tosstypes, message: string): React.ReactText => {
  switch (type) {
    case 'success':
      return toast.success(message, {
        toastId: message,
        position: toast.POSITION.TOP_RIGHT,
      })
    case 'info':
      return toast.info(message, {
        toastId: message,
        position: toast.POSITION.TOP_RIGHT,
      })

    case 'warn':
      return toast.warn(message, {
        toastId: message,
        position: toast.POSITION.TOP_RIGHT,
      })

    case 'error':
      return toast.error(message, {
        toastId: message,
        position: toast.POSITION.TOP_RIGHT,
      })

    default:
      return toast(message, {
        toastId: message,
        position: toast.POSITION.TOP_RIGHT,
      })
  }
}

export default toastBuilder
