import React, { useState } from 'react'
import Icons from '../../../assets/images/icons/Icons'
import CreateTrigger from './CreateTrigger'
import { useAppSelector } from '../../../store'
import { usespecificRouterDetail } from '../../../container/RouterDetail/routerDetail.selector'
import Table, { TableColumn } from '../../organisms/tables/Table.Organism'
import { TriggerTypeToText } from '../../../types/triggers'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'

const Triggers: React.FC<any> = () => {

  const ability = useAbility(AbilityContext)

  if (!ability.can('read', 'router.trigger')) {
    return <NoPermission classHeight='h-[60vh]' value='read' />
  }

  const [showSlider, setShowSlider] = useState(false)

  const specificRouterDetail = useAppSelector(usespecificRouterDetail)

  const tableColumns: TableColumn[] = [
    { label: '', className: 'w-[1rem]' },
    { label: 'Type', field: specificRouterDetail.trigger_type },
    {
      label: '',
      render: () => {
        return (
          <button
            type='button'
            className={`inline-flex items-center ${!ability.can('update', 'router.trigger') && 'cursor-not-allowed'}`}
            onClick={ability.can('update', 'router.trigger') ? () => setShowSlider(true) : () => { return }}
            disabled={!ability.can('update', 'router.trigger')}
          >
            <Icons name='edit-pencil-blue' />
          </button>
        )
      },
      className: 'w-[10rem]',
    },
  ]

  const rows = [
    {
      // @ts-ignore
      [specificRouterDetail.trigger_type]:
        // @ts-ignore
        TriggerTypeToText[specificRouterDetail.trigger_type],
    },
  ]

  return (
    <div className={`${!specificRouterDetail?.trigger_metadata && 'h-full'}`}>
      <div className='mt-[1.3rem]'>
        <span className='text-lg font-medium flex-grow-1'>Trigger</span>
      </div>
      <div className='mt-2 mb-2.5 flex justify-center items-center'>
        <Icons name='info' />
        <span className='ml-1.5 text-bs flex-grow-1'>
          Select how leads will be enrolled into this rotation
        </span>
      </div>
      <CreateTrigger
        hasData={specificRouterDetail?.trigger_metadata ? true : false}
        showSlider={showSlider}
        setShowSlider={setShowSlider}
        disabled={!ability.can('create', 'router.trigger')}
      />
      {specificRouterDetail?.trigger_metadata && (
        <Table columns={tableColumns} rows={rows} />
      )}

    </div>
  )
}

export default Triggers
