/**
 * ObjectPropertyMapRegistry
 * @description Returns appropriate operator options as string
 * for use in a ComboBox dropdown based on object property field type
 * @uses Operators provided by json-rules-engine
 * @see https://github.com/cachecontrol/json-rules-engine/blob/HEAD/docs/rules.md#operators
 */

export enum AVAILABLE_OPERATORS {
  EQUALS = 'equal',
  NOT_EQUAL = 'notEqual',
  LESS_THAN = 'lessThan',
  IS_BEFORE_DATE = 'isBeforeDate',
  IS_AFTER_DATE = 'isAfterDate',
  IS_EQUAL_TO_DATE = 'isEqualToDate',
  LESS_THAN_INCLUSIVE = 'lessThanInclusive',
  GREATER_THAN = 'greaterThan',
  GREATER_THAN_INCLUSIVE = 'greaterThanInclusive',
  IN = 'in',
  NOT_IN = 'notIn',
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'doesNotContain',
  IS_KNOWN = 'isKnown',
  IS_UNKNOWN = 'isUnknown',
}

export enum LITERAL_TYPES {
  STRING = 'string',
  NUMBER = 'number',
  ARRAY = 'array',
  ENUM = 'enumeration',
  DATETIME = 'datetime',
  BOOLEAN = 'bool',
}

export const JsonFieldTypeMap = {
  [LITERAL_TYPES.STRING]: [
    AVAILABLE_OPERATORS.EQUALS,
    AVAILABLE_OPERATORS.NOT_EQUAL,
    AVAILABLE_OPERATORS.IS_KNOWN,
    AVAILABLE_OPERATORS.IS_UNKNOWN,
  ],
  [LITERAL_TYPES.NUMBER]: [
    AVAILABLE_OPERATORS.EQUALS,
    AVAILABLE_OPERATORS.NOT_EQUAL,
    AVAILABLE_OPERATORS.LESS_THAN,
    AVAILABLE_OPERATORS.LESS_THAN_INCLUSIVE,
    AVAILABLE_OPERATORS.GREATER_THAN,
    AVAILABLE_OPERATORS.GREATER_THAN_INCLUSIVE,
    AVAILABLE_OPERATORS.IS_KNOWN,
    AVAILABLE_OPERATORS.IS_UNKNOWN,
  ],
  [LITERAL_TYPES.ARRAY]: [
    AVAILABLE_OPERATORS.IN,
    AVAILABLE_OPERATORS.NOT_IN,
    AVAILABLE_OPERATORS.CONTAINS,
    AVAILABLE_OPERATORS.DOES_NOT_CONTAIN,
    AVAILABLE_OPERATORS.IS_KNOWN,
    AVAILABLE_OPERATORS.IS_UNKNOWN,
  ],
  [LITERAL_TYPES.ENUM]: [
    AVAILABLE_OPERATORS.EQUALS,
    AVAILABLE_OPERATORS.NOT_EQUAL,
    AVAILABLE_OPERATORS.IS_KNOWN,
    AVAILABLE_OPERATORS.IS_UNKNOWN,
  ],
  [LITERAL_TYPES.DATETIME]: [
    AVAILABLE_OPERATORS.IS_BEFORE_DATE,
    AVAILABLE_OPERATORS.IS_AFTER_DATE,
    AVAILABLE_OPERATORS.IS_EQUAL_TO_DATE,
    AVAILABLE_OPERATORS.IS_KNOWN,
    AVAILABLE_OPERATORS.IS_UNKNOWN,
  ],
  [LITERAL_TYPES.BOOLEAN]: [
    AVAILABLE_OPERATORS.EQUALS,
    AVAILABLE_OPERATORS.NOT_EQUAL,
    AVAILABLE_OPERATORS.IS_KNOWN,
    AVAILABLE_OPERATORS.IS_UNKNOWN,
  ],
}

export const FieldTypeComponentRegistry = {
  [AVAILABLE_OPERATORS.EQUALS]: '=',
  [AVAILABLE_OPERATORS.NOT_EQUAL]: '≠',
  [AVAILABLE_OPERATORS.LESS_THAN]: '<',
  [AVAILABLE_OPERATORS.LESS_THAN_INCLUSIVE]: '≤',
  [AVAILABLE_OPERATORS.GREATER_THAN]: '>',
  [AVAILABLE_OPERATORS.GREATER_THAN_INCLUSIVE]: '≥',
  [AVAILABLE_OPERATORS.IN]: 'is in',
  [AVAILABLE_OPERATORS.NOT_IN]: 'is not in',
  [AVAILABLE_OPERATORS.CONTAINS]: 'contains',
  [AVAILABLE_OPERATORS.DOES_NOT_CONTAIN]: 'does not contain',
  [AVAILABLE_OPERATORS.IS_BEFORE_DATE]: 'is before',
  [AVAILABLE_OPERATORS.IS_AFTER_DATE]: 'is after',
  [AVAILABLE_OPERATORS.IS_EQUAL_TO_DATE]: 'is equal',
  [AVAILABLE_OPERATORS.IS_KNOWN]: 'is known',
  [AVAILABLE_OPERATORS.IS_UNKNOWN]: 'is unknown',
}

export interface OperatorConfig {
  value: AVAILABLE_OPERATORS
  label: string
}

const getOperatorsForFieldType = (fieldType: LITERAL_TYPES) => {
  return JsonFieldTypeMap[fieldType].map((operatorValue) => ({
    value: operatorValue,
    label: getLabelFromOperatorValue(operatorValue),
  }))
}

export const getLabelFromOperatorValue = (
  operatorValue: AVAILABLE_OPERATORS,
) => {
  return FieldTypeComponentRegistry[operatorValue]
}

export default getOperatorsForFieldType
