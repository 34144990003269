import React, { useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'

interface IProps {
  initialValue: boolean
  data: any
  handleChange: (data: any, enabled: boolean) => void | Promise<any>
  disabled?: boolean
  className?: string
  assignmentStatus?: boolean
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const Toggle: React.FC<IProps> = ({
  initialValue = false,
  data,
  className,
  handleChange = () => {
    //
  },
  disabled = false,
  assignmentStatus
}) => {
  const [enabled, setEnabled] = useState(initialValue)

  useEffect(() => {
    setEnabled(initialValue)
  }, [initialValue])

  return (
    <div className={className}>
      <Switch
        checked={enabled}
        onChange={async (val: boolean) => {
          setEnabled(!enabled)
          handleChange(data, val)
        }}
        className={classNames(
          enabled ? (!assignmentStatus ? 'bg-[#0062FF]' : 'bg-green-400') : assignmentStatus ? 'bg-red-500' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
          `${disabled ? 'opacity-60 cursor-not-allowed' : 'pointer'}`,
        )}
        disabled={disabled}
      >
        <span className='sr-only'>Use setting</span>
        <span
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        >
          <span
            className={classNames(
              enabled
                ? 'opacity-0 ease-out duration-100'
                : 'opacity-100 ease-in duration-200',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
            )}
            aria-hidden='true'
          ></span>
          <span
            className={classNames(
              enabled
                ? 'opacity-100 ease-in duration-200'
                : 'opacity-0 ease-out duration-100',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
            )}
            aria-hidden='true'
          ></span>
        </span>
      </Switch>
    </div >
  )
}
