import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import type { AppDispatch } from './store'
import * as authActions from './container/auth/auth.actions'
import * as customerActions from './container/my_account/account.actions'
import * as leadRouterAction from './container/leadRouter/leadRoutes.actions'
import * as routerDetailAction from './container/RouterDetail/routerDetail.actions'
import * as hubsport_actions from './container/Hubspot/hubsport.actions'
import * as integrationActions from './container/integrations/integrations.actions'
import * as basicActions from './container/Basic/basic.actions'
import * as slaActions from './container/SLA/SLA.actions'
import * as planActions from './container/Plan/plan.actions'
import * as conversionsActions from './container/conversions/conversions.actions'
import * as dashboardActions from './container/dashboard/dashboard.actions'
import * as homeActions from './container/Home/home.actions'
import * as rolesActions from './container/roles/roles.actions'
import * as teamsActions from './container/users/teams.actions'

export const useActions = () => {
  const dispatch = useDispatch<AppDispatch>()

  return bindActionCreators(
    {
      ...authActions,
      ...customerActions,
      ...leadRouterAction,
      ...routerDetailAction,
      ...hubsport_actions,
      ...integrationActions,
      ...basicActions,
      ...slaActions,
      ...planActions,
      ...conversionsActions,
      ...dashboardActions,
      ...homeActions,
      ...rolesActions,
      ...teamsActions,
    },
    dispatch,
  )
}
