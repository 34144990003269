import IconRoutera from 'src/components/atoms/icons/icons'
import ChartTotalSLAs from 'src/components/atoms/chartTotalSLAs/chartTotalSLAs'
import { DataBasic, DetailSLAs } from 'src/types/dashboard'
import { useEffect, useState } from 'react'
import { ModalFB } from 'src/components/atoms/Modal/ModalFB.atom'
import SelectableTable, {
  TableColumn,
} from 'src/components/organisms/tables/SelectableTable.Organism'
import { useAppSelector } from 'src/store'
import { detailSLAs } from 'src/container/dashboard/dashboard.selector'
import { User } from 'src/types/customers'
import { getallusers } from 'src/container/my_account/account.selector'
import Avatar from 'src/components/atoms/avatar/Avatar.Atom'
import { totalSLAsData } from 'src/container/dashboard/dashboard.selector'
import { useNavigate } from 'react-router-dom'

interface IProps {
  classWidget?: string
  dateRangeSLA: string
  setLimitSLAsItems: (value: number) => void
  limitSLAsItems: number
  setCurrentPageSLAsItems: (value: number) => void
  currentPageSLAsItems: number
  setOrderBySLADetail: (value: number) => void
  orderBySLADetail: number
  freePlan: boolean
}

const WidgetTotalSLAs: React.FC<IProps> = (props) => {
  const {
    classWidget,
    dateRangeSLA,
    setLimitSLAsItems,
    limitSLAsItems,
    setCurrentPageSLAsItems,
    currentPageSLAsItems,
    setOrderBySLADetail,
    orderBySLADetail,
    freePlan,
  } = props

  const totalSLAs = useAppSelector(totalSLAsData)
  const [noData, setNoData] = useState<boolean>(false)
  const [loadingTable, setLoadingTable] = useState<boolean>(false)
  const detailsSLA = useAppSelector(detailSLAs)
  const [startLimitSLAs, setStartLimitSLAs] = useState<number>(0)
  const [dataTotalSLAs, setDataTotalSLAs] = useState<DataBasic[]>([
    { label: '', value: 0, labelValue: '' },
  ])
  const [dataDetailSLA, setDataDetailSLA] = useState<DetailSLAs[]>([])
  const [endLimitSLAs, setEndLimitSLAs] = useState<number>(5)
  const [totalResult, setTotalResult] = useState<number>(detailsSLA.total)
  const [users, setUsers] = useState<User[]>()
  const allUsers = useAppSelector(getallusers)

  useEffect(() => {
    setNoData(false)
    setDataTotalSLAs([{ label: '', value: 0, labelValue: '' }])
    if (
      !totalSLAs.totalSlaMet &&
      !totalSLAs.totalSlas &&
      !totalSLAs.totalSlasMissed
    ) {
      setNoData(true)
    } else {
      setDataTotalSLAs([
        {
          label: 'Met',
          value:
            totalSLAs && totalSLAs.totalSlaMet !== null
              ? Math.round(totalSLAs.totalSlaMet)
              : -1,
          labelValue:
            'Met: ' +
            (totalSLAs &&
            totalSLAs.totalMet !== undefined &&
            totalSLAs.totalMet !== null
              ? totalSLAs.totalMet.toString()
              : ''),
        },
        {
          label: 'Missed',
          value:
            totalSLAs && totalSLAs.totalSlaMet !== null
              ? 100 - Math.round(totalSLAs.totalSlaMet)
              : -1,
          labelValue:
            'Missed: ' +
            (totalSLAs &&
            totalSLAs.totalMissed !== undefined &&
            totalSLAs.totalMissed !== null
              ? totalSLAs.totalMissed.toString()
              : ''),
        },
      ])
    }
  }, [totalSLAs])

  const onPaginationChangeSLAs = (
    startOffSet: number,
    endOffSet: number,
    currentPage: number,
  ) => {
    setStartLimitSLAs(startOffSet)
    setEndLimitSLAs(endOffSet)
    setCurrentPageSLAsItems(currentPage)
  }

  useEffect(() => {
    if (allUsers.length > 0) {
      setUsers(allUsers)
    }
  }, [allUsers])

  useEffect(() => {
    getData()
  }, [detailsSLA])

  const getData = () => {
    setLoadingTable(false)
    setDataDetailSLA([])
    if (detailsSLA.listSLA && detailsSLA.listSLA.length > 0) {
      setTotalResult(detailsSLA.total)
      setDataDetailSLA(detailsSLA.listSLA)
    }
  }

  const backgroundData = ['bg-routera-600', 'bg-red-400']

  const getUserInfo = (id: string): User[] | undefined => {
    if (users) {
      return users.filter((item: User) => item._id === id)
    }
  }

  const columns: Array<TableColumn> = [
    {
      label: 'name',
      render: ({ sla }: DetailSLAs) => {
        return (
          <div>
            {sla ? (
              sla.name
            ) : (
              <span className='text-gray-500 text-sm font-normal italic mr-2'>
                No data*
              </span>
            )}
          </div>
        )
      },
    },
    {
      label: 'Object type',
      render: ({ objectType }: DetailSLAs) => {
        return <div className='capitalize w-[90px]'>{objectType}</div>
      },
    },
    {
      label: 'Object Name',
      render: ({ objectData }: DetailSLAs) => {
        return (
          <div>
            {objectData &&
            objectData.properties.firstname &&
            objectData.properties.lastname ? (
              objectData.properties.firstname +
              ' ' +
              objectData.properties.lastname
            ) : (
              <span className='text-gray-500 text-sm font-normal italic mr-2'>
                No data*
              </span>
            )}
          </div>
        )
      },
    },
    {
      label: 'User',
      render: ({ user }: DetailSLAs) => {
        const userData = getUserInfo(user._id)
        return (
          <div>
            {userData && userData.length > 0 && (
              <div className='mr-2 flex items-center'>
                <Avatar
                  className='text-sm mr-2 w-[32px] h-[32px]'
                  firstName={userData[0].firstName}
                  lastName={userData[0].lastName}
                  imgURL={userData[0].avatar}
                  available={{
                    userAvailability: userData[0].userAvailability,
                    officeStatus: userData[0].office_status
                      ? userData[0].office_status
                      : false,
                  }}
                />
                <span className='font-normal text-[14px] leading-[21px] text-gray-700'>
                  {' '}
                  {`${userData[0].firstName} ${userData[0].lastName}`}
                </span>
              </div>
            )}
          </div>
        )
      },
    },
    {
      label: 'SLA’s status',
      render: ({ slaStatus }: DetailSLAs) => {
        return (
          <div className='w-[90px]'>
            {slaStatus ? (
              <span className='text-routera-800 text-xs font-medium py-[4px] px-[12px] bg-routera-50 rounded-md w-fit'>
                Met
              </span>
            ) : (
              <span className='text-red-500 text-xs font-medium py-[4px] px-[12px] bg-red-50 rounded-md w-fit'>
                Missed
              </span>
            )}
          </div>
        )
      },
      colName: 'slaStatus',
      canSort: true,
    },
    {
      label: 'Date',
      render: ({ date }: DetailSLAs) => {
        return <div>{date}</div>
      },
    },
  ]

  const closeModal = () => {
    setLoadingTable(false)
  }

  const navigate = useNavigate()

  const redirectBillingPage = () => {
    navigate('/settings/billing')
  }

  const modalSLADetail: JSX.Element = (
    <ModalFB
      id='openDetailSLA'
      titleModal='SLA’s Details'
      className='max-w-[1150px]'
      actionClose={closeModal}
      buttonOpen={
        <div className='flex flex-row justify-center -mt-1'>
          {dataTotalSLAs &&
            dataTotalSLAs.map((item: DataBasic, index: number) => (
              <button
                className='text-routera-800 text-sm font-normal ml-2 mt-[1px] cursor-pointer flex items-center'
                data-modal-target='openDetailSLA'
                data-modal-toggle='openDetailSLA'
                key={item.label}
              >
                <span className='text-xs ml-4'>
                  <span
                    className={`inline-block h-3 w-3 rounded-[50%] ${backgroundData[index]}`}
                  />{' '}
                  {item.label} {Math.trunc(item.value)}%
                </span>
              </button>
            ))}
        </div>
      }
    >
      {
        <div className='p-0'>
          <span className='text-gray-500 font-normal text-base'>
            {dateRangeSLA}
          </span>
          <SelectableTable
            columns={columns}
            rows={dataDetailSLA}
            showSelectionType={false}
            showHeaderMeta={false}
            borderTable={false}
            sort={true}
            loadingTable={loadingTable}
            limitItems={{
              setLimitItems: setLimitSLAsItems,
              totalItems: totalResult,
              startLimit: startLimitSLAs,
              endLimit:
                currentPageSLAsItems === 1
                  ? dataDetailSLA.length
                  : endLimitSLAs,
              loading: {
                setLoadingData: setLoadingTable,
                loadingData: loadingTable,
              },
            }}
            pagination={{
              pageSize: limitSLAsItems,
              handlePageChange: onPaginationChangeSLAs,
              fetchedDataSize: dataDetailSLA.length,
              totalDataSize: totalResult,
              totalPages: Math.ceil(totalResult / limitSLAsItems),
              loading: {
                setLoadingData: setLoadingTable,
                loadingData: loadingTable,
              },
            }}
            orderBySLA={{ setOrderBySLADetail, orderBySLADetail }}
          />
        </div>
      }
    </ModalFB>
  )

  return (
    <div
      className={`bg-white mt-4 p-3 flex flex-col shadow-sm rounded-lg ${classWidget}`}
    >
      <div className='flex flex-row justify-between'>
        <div className='flex flex-col'>
          <IconRoutera
            iconName='TicketIcon'
            className='h-6 w-6 text-gray-400'
            aria-hidden='true'
          />
          <span className='capitalize text-gray-500 text-base font-semibold w-[160px] flex items-center'>
            Total SLA's
          </span>
          {!noData && !freePlan ? (
            <span className='text-gray-700 text-3xl font-bold mb-2'>
              {totalSLAs.totalSlas}
            </span>
          ) : (
            <>
              {freePlan ? (
                <div>
                  <div className='flex items-baseline'>
                    <IconRoutera
                      iconName='LockClosedIcon'
                      className='w-[14px] h-[14px] mr-1'
                    />
                    <span className='text-gray-500 text-base font-normal ml-2 mt-2'>
                      Upgrade to unlock SLA
                    </span>
                  </div>
                  <button
                    onClick={redirectBillingPage}
                    className='px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start mt-[10px]'
                  >
                    Upgrade Now
                  </button>
                </div>
              ) : (
                <div>
                  <span className='text-gray-700 text-3xl font-bold mb-2 mt-2'>
                    No data*
                  </span>
                  <div className='bg-routera-50 py-[2px] px-[10px] rounded-md w-fit mt-2'>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://help.routera.io/article/16-create-a-service-level-agreement'
                    >
                      <span className='flex text-routera-700 text-xs font-medium leading-[18px] items-center cursor-pointer'>
                        <IconRoutera
                          iconName='InformationCircleIcon'
                          className='w-[14px] h-[14px] mr-1'
                        />
                        How does this widget work?
                        <IconRoutera
                          iconName='ArrowRightIcon'
                          className='w-[14px] h-[14px] ml-1'
                        />
                      </span>
                    </a>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <ChartTotalSLAs dataChart={dataTotalSLAs} />
      </div>
      {!noData && modalSLADetail}
    </div>
  )
}

export default WidgetTotalSLAs
