import DateRange from 'src/components/molecules/dateRange/dateRange.Molecule'
import { DataDate } from 'src/types/profile'
import toastBuilder from 'src/utils/toastBuilder'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  stateDates: Dispatch<SetStateAction<DataDate[]>>
  dataDefaultDates: DataDate[]
  permissions?: {
    delete?: boolean
    create?: boolean
    update?: boolean
  }
}

const DatesSection = ({ stateDates, dataDefaultDates, permissions }: Props) => {

  let tomorrow: Date = new Date()
  let nextTomorrow: Date = new Date()

  if (dataDefaultDates.length > 0) {
    tomorrow = new Date(dataDefaultDates[dataDefaultDates.length - 1].endDate)
    nextTomorrow = new Date(dataDefaultDates[dataDefaultDates.length - 1].endDate)
  }
  tomorrow.setDate(tomorrow.getDate() + 1)
  nextTomorrow.setDate(nextTomorrow.getDate() + 2)
  const changeStartDate = (date: Date, index: number) => {
    if (isDateOverlapping(date, index, 'start')) {
      errorMessage('timeOverlap')
      return
    }
    if (dataDefaultDates[index]['endDate'] < date) {
      errorMessage('earlierTime')
      return
    }
    const updateDate: DataDate[] = [...dataDefaultDates]
    updateDate[index]['startDate'] = date
    stateDates(updateDate)
  }
  const changeEndDate = (date: Date, index: number) => {
    if (isDateOverlapping(date, index, 'end')) {
      errorMessage('timeOverlap')
      return
    }
    if (dataDefaultDates[index]['startDate'] > date) {
      errorMessage('earlierTime')
      return
    }
    const updateDate = [...dataDefaultDates]
    updateDate[index]['endDate'] = date
    stateDates(updateDate)
  }
  const isDateOverlapping = (
    date: Date,
    index: number,
    typeDate: string,
  ): boolean => {
    let endDate: Date = dataDefaultDates[index].endDate
    let startDate: Date = dataDefaultDates[index].startDate
    if (typeDate === 'start') {
      startDate = date
    } else {
      endDate = date
    }

    const auxDataDefault: DataDate[] = [...dataDefaultDates]
    auxDataDefault.splice(index, 1)
    return auxDataDefault.some(
      (data: DataDate) =>
        (data.startDate <= startDate && endDate <= data.endDate) ||
        (data.startDate <= startDate && startDate <= data.endDate) ||
        (data.startDate <= endDate && endDate <= data.endDate) ||
        (data.startDate >= startDate && endDate >= data.endDate),
    )
  }
  const onClick = (index: number) => {
    const removeDate: DataDate[] = [...dataDefaultDates]
    removeDate.splice(index, 1)
    stateDates(removeDate)
  }
  const onAddOutOfDate = () => {
    stateDates([
      ...dataDefaultDates,
      {
        idDate: dataDefaultDates.length + 1,
        endDate: nextTomorrow,
        startDate: tomorrow,
      },
    ])
  }

  const errorMessage = (message?: string) => {
    if (message === 'timeOverlap') {
      toastBuilder('error', 'Time slot seem to overlap. Please check')
    }

    if (message === 'earlierTime') {
      toastBuilder(
        'error',
        'Oops. End time cannot be earlier than start time. Please check',
      )
    }
  }

  return (
    <div className='flex flex-column'>
      {dataDefaultDates.map((item: DataDate, index: number) => (
        <DateRange
          key={item.idDate}
          valueEndDate={item.endDate}
          valueStartDate={item.startDate}
          minDate={true}
          onChangeStartDate={(date) => changeStartDate(date, index)}
          onChangeEndDate={(date) => changeEndDate(date, index)}
          onClick={() => onClick(index)}
          permissions={{ update: permissions?.update }}
        />
      ))}
      <button
        onClick={onAddOutOfDate}
        type='button'
        disabled={!permissions?.update}
        className={`px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg bg-routera-primary self-start ${!permissions?.update && 'cursor-not-allowed'}`}
      >
        <i className='fa-solid fa-plus text-white inline-block mr-2'></i>
        Add Out of Office Dates
      </button>
    </div>
  )
}

export default DatesSection
