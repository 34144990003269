import { createAsyncThunk } from '@reduxjs/toolkit'

import { ApiClient } from '../../index'

enum actiontype {
  sendcode = 'sendcode sendcode',
  get_hubsport_list = 'get_hubsport_list get_hubsport_list',
}

const integrationBaseUrl = '/integrations'
const HUBSPOT_LIST_ENDPOINT = `/hubsport/get_all_lists`

export const send_hubsprot_token = createAsyncThunk(
  actiontype.sendcode,
  async (code: string, thunk_api) => {
    const result = await ApiClient.post(integrationBaseUrl, {
      integration_type: 'hubspot',
      code,
    })

    if (result) {
      return result
    } else {
      thunk_api.rejectWithValue('')
    }
  },
)

interface GetHubspotListPayload {
  id: string
  offset: number
  count: number
}

export const get_hubsport_list = createAsyncThunk(
  actiontype.get_hubsport_list,
  async ({ id, offset, count }: GetHubspotListPayload, thunk_api) => {
    const result = await ApiClient.get(HUBSPOT_LIST_ENDPOINT, {
      params: {
        source_id: id,
        offset,
        count,
      },
    })
    if (result) return result
    else thunk_api.rejectWithValue('')
  },
)
