import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { DataBasic } from 'src/types/dashboard'

interface IProps {
	dataChart: DataBasic[]
}

const ChartTotalSLAs: React.FC<IProps> = (props) => {

	const { dataChart } = props
	const series = dataChart.map(data => data.value)

	const roundOptions: 'butt' | 'square' | 'round' = 'round'
	const [options, setOptions] = useState({})

	useEffect(() => {
		if ((dataChart[0].labelValue !== '' || dataChart[0].labelValue)) {
			setOptions({
				chart: {
					id: 'radialBar'
				},
				plotOptions: {
					radialBar: {
						dataLabels: {
							show: true,
							value: {
								formatter: function (val: any) {
									return ''
								}
							},
							name: {
								fontSize: '12',
								offsetY: -42,
								formatter: function (val: any) {
									return val
								}
							}
						},
						borderRadius: 30,
						hollow: {
							size: '15%',
						},
						track: {
							background: '#E5E7EB'
						}
					},
				},
				stroke: { show: true, lineCap: roundOptions, width: 0.2 },
				labels: dataChart.map(data => data.labelValue),
				colors: ['#008AFF', '#F98080'],
			})
		}
	}, [dataChart])

	return <div>
		{(Object.keys(options).length > 0 && dataChart[0].label !== '' && dataChart[0].value >= 0 && dataChart[1].value >= 0) &&
			<Chart id='radialBar' options={options}
				series={series}
				type='radialBar'
				width={80}
				height={135}
			/>}
	</div>
}

export default ChartTotalSLAs