import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import Avatar from 'src/components/atoms/avatar/Avatar.Atom'
import { IOption } from 'src/types/Ihome_state'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import chevronDown from '../../../assets/images/chevron-down.svg'

interface IProps {
  dropdownLabel: string
  options: IOption[]
  selectedOption: IOption
  setSelectedOption: (val: IOption, dateCustom?: boolean) => void
  withAvatar?: boolean
  optionRenderer: (
    option: IOption,
    selected: boolean,
    active: boolean,
    showAvatar?: boolean,
  ) => ReactElement
  customClass?: string
  classInput?: string
  datePicker?: {
    datePicker: boolean
    dateTo: string
    dateFrom: string
  }
  disabled?: boolean
  setDateRangeSLA?: (value: string) => void
  customWidth?: string
  typeAnd?: boolean
}

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ')
}

export const defaultOptionRenderer = (
  option: IOption,
  selected: boolean,
  active: boolean,
  showAvatar = false,
) => {
  return (
    <>
      <div className='flex items-center'>
        {showAvatar && (
          <Avatar
            firstName={option?.firstName || ''}
            lastName={option?.lastName || ''}
            imgURL={option?.avatar || ''}
            className={'h-[32px] w-[32px] flex-shrink-0 rounded-full'}
            available={{
              userAvailability: option?.userAvailability,
              officeStatus: option?.office_status
                ? option?.office_status
                : false,
            }}
          />
        )}
        <span
          className={classNames(
            selected ? 'font-semibold' : 'font-normal',
            `${showAvatar && 'ml-3'} block truncate`,
          )}
        >
          {option.label}
        </span>
      </div>

      {selected ? (
        <span
          className={classNames(
            active ? 'text-white' : 'text-[#0062FF]',
            'absolute inset-y-0 right-0 flex items-center pr-4',
          )}
        >
          <CheckIcon className='h-5 w-5' aria-hidden='true' />
        </span>
      ) : null}
    </>
  )
}

const DropdownCustom: React.FC<IProps> = ({
  dropdownLabel,
  options,
  selectedOption,
  setSelectedOption,
  withAvatar = false,
  optionRenderer,
  customClass = '',
  classInput = '',
  datePicker = {
    datePicker: false,
    dateFrom: '',
    dateTo: '',
  },
  customWidth = 'w-[15rem]',
  disabled = false,
  setDateRangeSLA,
  typeAnd = false,
}) => {
  const [selected, setSelected] = useState(selectedOption || options[0] || null)

  const [typeInputFrom, setTypeInputFrom] = useState<string>('text')
  const [typeInputTo, setTypeInputTo] = useState<string>('text')

  const [dateFrom, setDateFrom] = useState<string>(datePicker.dateFrom)
  const [dateTo, setDateTo] = useState<string>(datePicker.dateTo)
  const [params, setParams] = useSearchParams()
  const [dateEndGreater, setDateEndGreater] = useState<boolean>(false)

  const handleSelect = (v: IOption) => {
    setDateTo('')
    setDateFrom('')
    setSelected(v)
    setSelectedOption(v)
  }

  useEffect(() => {
    if (
      params.has('startDate') &&
      params.has('endDate') &&
      datePicker.datePicker &&
      setDateRangeSLA
    ) {
      const valueStart = params.get('startDate')
      const valueEnd = params.get('endDate')
      if (valueEnd && valueStart) {
        setDateRangeSLA(valueStart + ' / ' + valueEnd)
        setSelected({
          id: 2,
          label: valueStart.split('T')[0] + ' / ' + valueEnd.split('T')[0],
        })

        setDateFrom(valueStart)
        setDateTo(valueEnd)
      }
    } else {
      if (dateTo === '' && dateFrom === '') {
        setSelected(selectedOption || options[0])
      } else {
        setSelected({
          id: 2,
          label: dateFrom.split('T')[0] + ' / ' + dateTo.split('T')[0],
        })
      }
    }
  }, [options, selectedOption])

  const applyRange = () => {
    const range: IOption = {
      id: 0,
      label:
        moment(dateFrom).startOf('day').toISOString() +
        '/' +
        moment(dateTo).endOf('day').toISOString(),
    }
    setSelectedOption(range, true)
  }

  const changeFrom = (event: any) => {
    setSelected({
      id: 0,
      label: '',
    })
    setDateEndGreater(false)
    if (dateTo !== '') {
      const endDate = new Date(dateTo)
      const startDate = new Date(event.target.value)
      if (startDate > endDate) {
        setDateEndGreater(true)
        return
      }
    }
    setSelected({
      id: 1,
      label: event.target.value.split('T')[0] + ' / ' + dateTo.split('T')[0],
    })
    setDateFrom(event.target.value)
  }

  const changeTo = (event: any) => {
    setDateEndGreater(false)
    if (dateFrom !== '') {
      const startDate = new Date(dateFrom)
      const endDate = new Date(event.target.value)
      if (startDate > endDate) {
        setDateEndGreater(true)
        return
      }
    }
    setSelected({
      id: 2,
      label: dateFrom.split('T')[0] + ' / ' + event.target.value.split('T')[0],
    })
    setDateTo(event.target.value)
  }

  return (
    <div className={`${customClass} flex flex-col ${customWidth} mt-2`}>
      <Listbox value={selected} onChange={handleSelect}>
        {({ open }) => (
          <>
            <Listbox.Label className='block text-sm font-medium text-gray-800 leading-[21px]'>
              {dropdownLabel}
            </Listbox.Label>
            <div
              className={`relative mt-[5px] ${
                typeAnd && 'flex justify-center'
              }`}
            >
              {typeAnd && (
                <div
                  className={`hidden md:flex justify-between items-center z-10 border border-gray-300 bg-green-50 p-[10px] divide-y divide-gray-100 rounded-l-lg h-[100%] w-[76px]`}
                >
                  <div className='h-5 w-5'>
                    <img src={chevronDown} alt='' />
                  </div>
                  <label className='text-green-500 text-sm font-medium uppercase'>
                    and
                  </label>
                </div>
              )}
              <Listbox.Button
                className={`relative w-full cursor-default ${
                  typeAnd ? 'rounded-r-lg' : 'rounded-md'
                }  border border-gray-300 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus:ring-1 sm:text-sm h-[43px] ${classInput}`}
              >
                <span className='flex items-center'>
                  {withAvatar && (
                    <Avatar
                      firstName={selected?.firstName || ''}
                      lastName={selected?.lastName || ''}
                      imgURL={selected?.avatar || ''}
                      className={'w-[20px] h-[20px] flex-shrink-0 rounded-full'}
                      available={{
                        userAvailability: selected?.userAvailability,
                        officeStatus: selected?.office_status
                          ? selected?.office_status
                          : false,
                      }}
                    />
                  )}
                  <span
                    className={`${
                      withAvatar && 'ml-3'
                    } block truncate text-gray-500`}
                  >
                    {selected ? selected.label : ''}
                  </span>
                </span>
                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                  <ChevronDownIcon
                    className='h-5 w-5 text-gray-500'
                    aria-hidden='true'
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={disabled ? false : open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options className='absolute z-[11] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white pl-0 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm px-2 py-2'>
                  {options &&
                    options.map((option) => (
                      <Listbox.Option
                        key={option.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'text-white bg-[#0062FF]'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            {optionRenderer(
                              option,
                              selected,
                              active,
                              withAvatar,
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  {datePicker.datePicker && (
                    <div className='w-[208px]'>
                      <span className='mx-2 mt-2 inline-block w-[200px] text-sm leading-[14px] text-gray-900 font-semibold border-t-[2px] border-gray-200 pt-[9px]'>
                        Custom Date Range
                      </span>
                      <div className='flex'>
                        <div className='relative'>
                          {typeInputFrom === 'text' && (
                            <div className='absolute z-20 inset-y-0 left-0 flex items-center pl-3 pointer-events-none mt-2'>
                              <svg
                                className='w-4 h-4 text-gray-500 dark:text-gray-400'
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='currentColor'
                                viewBox='0 0 20 20'
                              >
                                <path d='M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z' />
                              </svg>
                            </div>
                          )}
                          <input
                            type={typeInputFrom}
                            onFocus={() => setTypeInputFrom('datetime-local')}
                            onBlur={() => setTypeInputFrom('text')}
                            className={`h-[37px] mr-2 relative border border-gray-300 bg-white flex text-left focus:outline-none focus:ring-1 sm:text-sm rounded-[8px] text-gray-500 mt-[9px] items-center w-[100px] ${
                              typeInputFrom === 'text' && 'pl-[35px]'
                            }`}
                            placeholder={dateFrom === '' ? 'From' : dateFrom}
                            onChange={(event) => changeFrom(event)}
                          />
                        </div>

                        <div className='relative'>
                          {typeInputTo === 'text' && (
                            <div className='absolute z-20 inset-y-0 left-0 flex items-center pl-3 pointer-events-none mt-2'>
                              <svg
                                className='w-4 h-4 text-gray-500 dark:text-gray-400'
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='currentColor'
                                viewBox='0 0 20 20'
                              >
                                <path d='M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z' />
                              </svg>
                            </div>
                          )}
                          <input
                            type={typeInputTo}
                            onFocus={() => setTypeInputTo('datetime-local')}
                            onBlur={() => setTypeInputTo('text')}
                            className={`h-[37px] mr-2 relative border border-gray-300 bg-white flex text-left focus:outline-none focus:ring-1 sm:text-sm rounded-[8px] text-gray-500 mt-[9px] items-center w-[100px] ${
                              typeInputTo === 'text' && 'pl-[35px]'
                            }`}
                            placeholder={dateTo === '' ? 'To' : dateTo}
                            onChange={(event) => changeTo(event)}
                          />
                        </div>
                      </div>
                      {dateEndGreater && (
                        <span className='text-red-500 text-[10px] mx-2 text-left'>
                          The end date must be greater
                        </span>
                      )}
                      <button
                        className={`px-[20px] py-[10px] text-white text-[14px] rounded-lg self-start mt-3 w-[208px] mb-1 ${
                          dateEndGreater ? 'bg-gray-500' : 'bg-routera-primary'
                        }`}
                        disabled={dateEndGreater}
                        onClick={applyRange}
                      >
                        Apply Custom Range
                      </button>
                    </div>
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

export default DropdownCustom
