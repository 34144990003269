import { useEffect, useState } from 'react'
import FiltersDashboard from 'src/components/molecules/filtersDashboard/filtersDashboard'
import ChartRoutedOverview from 'src/components/organisms/chartRoutedOverview/chartRoutedOverview'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import WidgetsDashboard from 'src/components/organisms/widgetsDashboard/widgetsDashboard'
import ConversionOverviewDashboard from 'src/components/organisms/conversionOverviewDashboard/conversionOverview'
import { FrequencyType } from 'src/types/dashboard'
import { DropDownSearch } from 'src/types/dashboard'
import UsersTableDashboard from 'src/components/organisms/usersTableDashboard/usersTableDashboard'
import { IOption } from 'src/types/Ihome_state'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'

export const FREQUENCY_TYPES: FrequencyType[] = [
  { id: 1, label: 'Daily', value: 'daily' },
  { id: 2, label: 'Weekly', value: 'weekly' },
  { id: 3, label: 'Monthly', value: 'monthly' },
  { id: 4, label: 'Yearly', value: 'yearly' },
]

const Dashbord = () => {
  const ability = useAbility(AbilityContext)

  if (!ability.can('access', 'dashboard')) {
    return <NoPermission value='access' width='300px' />
  } else {
    if (!ability.can('read', 'dashboard')) {
      return <NoPermission value='read' />
    }
  }

  const [objectType, setObjectType] = useState<HUBSPOT_OBJECT_TYPES>(
    HUBSPOT_OBJECT_TYPES.CONTACTS,
  )
  const [fraquencyOptions, setFraquencyOptions] =
    useState<FrequencyType[]>(FREQUENCY_TYPES)

  const [dateRangeSLA, setDateRangeSLA] = useState<string>('Today')

  const [selectedFrequencyValue, setSelectedFrequencyValue] =
    useState<string>('daily')
  const [changeRange, setChangeRange] = useState<number>(0)
  const [conversionsSelected, setConversionsSelected] =
    useState<DropDownSearch>()
  const [selectedFrequency, setSelectedFrequency] = useState<FrequencyType>(
    fraquencyOptions[0],
  )
  const [currentPageUser, setCurrentPageUser] = useState<number>(1)
  const [currentPageSLAsItems, setCurrentPageSLAsItems] = useState<number>(1)

  const [limitSLAsItems, setLimitSLAsItems] = useState<number>(5)

  const [orderBySLADetail, setOrderBySLADetail] = useState<number>(1)

  const [usersSelected, setUsersSelected] = useState<IOption[]>([])
  const [routersSelected, setRoutersSelected] = useState<IOption[]>([])

  const [limitConversionsUsersItems, setLimitConversionsUsersItems] =
    useState<number>(5)
  const [limitUsersTableItems, setLimitUsersTableItems] = useState<number>(5)
  const [limitConversionsRoutersItems, setLimitConversionsRoutersItems] =
    useState<number>(5)

  useEffect(() => {
    setFraquencyOptions(FREQUENCY_TYPES)
    if (changeRange >= 30) {
      setFraquencyOptions([
        { id: 1, label: 'Weekly', value: 'weekly' },
        { id: 2, label: 'Monthly', value: 'monthly' },
        { id: 3, label: 'Yearly', value: 'yearly' },
      ])
    } else if (changeRange > 90) {
      setFraquencyOptions([
        { id: 1, label: 'Monthly', value: 'monthly' },
        { id: 2, label: 'Yearly', value: 'yearly' },
      ])
    } else if (changeRange > 365) {
      setFraquencyOptions([{ id: 2, label: 'Yearly', value: 'yearly' }])
    }
  }, [changeRange])

  return (
    <section
      className={`min-h-full pb-2 max-w-[1440px] m-auto bg-gray-100 ${
        (!ability.can('access', 'dashboard') ||
          !ability.can('read', 'dashboard')) &&
        'overflow-hidden max-h-full'
      }`}
    >
      <div className='h-[67px] w-full justify-start items-center flex px-4'>
        <span className='font-semibold text-2xl text-gray-700'>Dashboards</span>
      </div>
      <FiltersDashboard
        setObjectType={setObjectType}
        setChangeRange={setChangeRange}
        selectedFrequency={selectedFrequencyValue}
        conversionsSelected={conversionsSelected}
        setSelectedFrequency={setSelectedFrequency}
        setUsersSelected={setUsersSelected}
        setRoutersSelected={setRoutersSelected}
        limitUsersItems={limitUsersTableItems}
        currentPageUser={currentPageUser}
        setDateRangeSLA={setDateRangeSLA}
        limitSLAsItems={limitSLAsItems}
        currentPageSLAsItems={currentPageSLAsItems}
        orderBySLADetail={orderBySLADetail}
      />
      <WidgetsDashboard
        objectType={objectType}
        dateRangeSLA={dateRangeSLA}
        setLimitSLAsItems={setLimitSLAsItems}
        limitSLAsItems={limitSLAsItems}
        currentPageSLAsItems={currentPageSLAsItems}
        setCurrentPageSLAsItems={setCurrentPageSLAsItems}
        setOrderBySLADetail={setOrderBySLADetail}
        orderBySLADetail={orderBySLADetail}
      />
      <ChartRoutedOverview
        objectType={objectType}
        fraquencyOptions={fraquencyOptions}
        setSelectedFrequencyValue={setSelectedFrequencyValue}
        setSelectedFrequency={setSelectedFrequency}
        selectedFrequency={selectedFrequency}
      />
      {ability.can('access', 'dashboard') && ability.can('read', 'dashboard') && (
        <>
          <ConversionOverviewDashboard
            conversionsSelected={conversionsSelected}
            setConversionsSelected={setConversionsSelected}
            setLimitConversionsUsersItems={setLimitConversionsUsersItems}
            limitConversionsUsersItems={limitConversionsUsersItems}
            setLimitConversionsRoutersItems={setLimitConversionsRoutersItems}
            limitConversionsRoutersItems={limitConversionsRoutersItems}
            usersSelected={usersSelected}
            routersSelected={routersSelected}
          />
          <UsersTableDashboard
            limitUsersItems={limitUsersTableItems}
            setLimitUsersItems={setLimitUsersTableItems}
            currentPageUser={currentPageUser}
            setCurrentPageUser={setCurrentPageUser}
            objectType={objectType}
          />
        </>
      )}
    </section>
  )
}

export default Dashbord
