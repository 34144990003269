import LoginForm from '../../components/LogIn/index'
import { logindataTypes } from '../../types/auth'
import { useActions } from '../../actions'
import { useNavigate } from 'react-router-dom'
import toastBuilder from '../../utils/toastBuilder'
import { registerCurrentUser } from '../..'
import registerUserWithLogRocket from '../../utils/LogRocket'
import { getTimeZone } from 'src/utils'
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useCallback, useEffect, useState } from 'react'

function User() {
  const navigate = useNavigate()
  const [loadingLogin, setLoadingLogin] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { userloginAction, fetchSubscriptionStatus } = useActions()

  const handleFormSubmit = async (
    formValues: logindataTypes,
    { resetForm, setSubmitting }: any,
  ) => {
    setLoadingLogin(true)

    if (!executeRecaptcha) return

    const tokenLogin = await executeRecaptcha('login')

    if (tokenLogin) {
      const newPayload = {
        ...formValues,
        timeZone: getTimeZone(),
        token: tokenLogin,
      }
      const action: any = await userloginAction(newPayload)
      if (action.payload.success) {
        setLoadingLogin(false)
        const { user } = action.payload.data
        registerUserWithLogRocket(user)
        // Fetch user subscription status
        const subAction: any = await fetchSubscriptionStatus()
        if (subAction.payload.success) {
          navigate('/settings/profile')
          toastBuilder('success', 'Logged in successfully!')
          registerCurrentUser()
          setSubmitting(false)
          resetForm()
        } else {
          setLoadingLogin(false)
          toastBuilder('error', 'Unable to load subscriptions')
        }
      } else {
        setLoadingLogin(false)
      }
      return
    }

    setLoadingLogin(false)
  }

  return (
    <>
      <LoginForm />
    </>
  )
}

export default User
