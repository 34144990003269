import { createReducerBuilder } from 'src/utils/actions'
import { createSlice } from '@reduxjs/toolkit'
import {
  getTeamsList,
  getTeamById,
} from './teams.actions'
import { TeamsState } from 'src/types/teams'

const TeamDefault = {
  _id: '',
  name: '',
  account_id: '',
  user_ids: [],
  manager_ids: [],
  updatedAt: new Date(),
  createdAt: new Date(),
  created_by: '',
  updated_by: ''
}

const initialState: TeamsState = {
  allTeams: [],
  specificTeamDetail: TeamDefault,
}

const reducerBuilder = createReducerBuilder<TeamsState>()

const getAllTeams = reducerBuilder(getTeamsList, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.allTeams = payload
    }
  },
})

const getTeamByIdState = reducerBuilder(getTeamById, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.specificTeamDetail = payload
    }
  },
})

const TeamsService = createSlice({
  name: 'teamsSlice',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    getAllTeams(builder)
    getTeamByIdState(builder)
  },
})

export default TeamsService.reducer
