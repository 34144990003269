import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'
const selectedreducer = (state: RootState) => state.customerstate

export const getallusers = createDraftSafeSelector(selectedreducer, (users) => {
  return users.users ?? []
})

export const usewokingTime = createDraftSafeSelector(
  selectedreducer,
  (users) => users.wokingTime,
)

export const useWorkTimeError = createDraftSafeSelector(
  selectedreducer,
  (users) => users.WorkTimeError,
)

export const getSubscriptionStatus = createDraftSafeSelector(
  selectedreducer,
  (users) => users.subscriptionStatus,
)

export const getAccountById = createDraftSafeSelector(
  selectedreducer,
  (account) => account.account,
)
