import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { RootState } from 'src/store'
const selectedreducer = (state: RootState) => state.conversionsState

export const uselistofAllConversions = createDraftSafeSelector(
  selectedreducer,
  (conversionsS) => conversionsS.allConversions,
)

export const usespecificConversionDetail = createDraftSafeSelector(
  selectedreducer,
  (routesState) => routesState.specificConversionDetail,
)
