import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'
import SlaList from 'src/components/organisms/slaList/slaList'
import { useState } from 'react'
import CreateleadRoutes from '../../components/LeadRoutes/leadRoutes'

const title = 'Add Service Level Agreements'
const description =
  'Set time based rules and re-assign records if the rule is not met by the initial assignee. For example:'
const boldDescription =
  'A phone call must be made 1 hour after the assignment, if not notify the manager and re-assign the record to the next available rep.'

const descriptionModal =
  'A trigger is an event that enrolls an object into your service level agreement (SLA)'

const Sla = () => {
  const ability = useAbility(AbilityContext)
  const [show, setShow] = useState(false)

  if (!ability.can('access', 'router.sla')) {
    return (
      <NoPermission
        title={title}
        description={description}
        boldDescription={boldDescription}
        value='access'
        width='323px'
      />
    )
  } else {
    if (!ability.can('read', 'router.sla')) {
      return <NoPermission value='read' />
    }
  }

  return (
    <section className='h-full'>
      <div className='h-[67px] justify-start items-center flex px-4'>
        <span className='text-2xl font-semibold text-gray-700'>
          Service Level Agreements
        </span>
      </div>
      <CreateleadRoutes
        setShow={setShow}
        show={show}
        description={descriptionModal}
      />
      <SlaList setShow={setShow} />
    </section>
  )
}

export default Sla
