import { createReducerBuilder } from 'src/utils/actions'
import { createSlice } from '@reduxjs/toolkit'
import {
  getConversionsList,
  getConversionById,
  createConversion,
} from './conversions.actions'
import { ConversionsState } from 'src/types/conversions'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'

const conversionDefault = {
  _id: '',
  name: '',
  updatedAt: new Date(),
  is_active: false,
  conversionRule: {
    objectType: HUBSPOT_OBJECT_TYPES.CONTACTS,
    value: '',
    fieldName: '',
    fieldLabel: '',
    operator: '',
  },
}

const initialState: ConversionsState = {
  allConversions: [],
  specificConversionDetail: conversionDefault,
}

const reducerBuilder = createReducerBuilder<ConversionsState>()

const getAllConversions = reducerBuilder(getConversionsList, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.allConversions = payload
    }
  },
})

const getConversionByIdState = reducerBuilder(getConversionById, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.specificConversionDetail = payload
    }
  },
})

const ConversionsService = createSlice({
  name: 'conversionsSlice',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    getAllConversions(builder)
    getConversionByIdState(builder)
  },
})

export default ConversionsService.reducer
