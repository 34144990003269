import { IFormJson } from 'src/types/form'

export const addConversionJSON: IFormJson = {
	id: 'createConversion',
	label: 'Create Conversion',
	fields: [
		{
			id: 'fieldType',
			label: 'Field',
			type: 'select',
			value: '',
			options: [],
			validationRegex: '',
			requiredError: '',
		},
		{
			id: 'operatorType',
			label: 'Operator',
			type: 'select',
			value: '',
			options: [],
			width: 'w-[28.3rem]',
			required: false,
			validationRegex: '',
			requiredError: 'error_text',
			showError: false,
		},
		{
			id: 'value',
			label: 'Value',
			type: 'string',
			value: '',
			options: [],
			customStyle: 'w-[28.3rem]',
			width: 'w-[28.3rem]',
			required: false,
			validationRegex: '',
			requiredError: '',
		},
	],
}