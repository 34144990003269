import { TimeSlot, TimeSlots } from './TimeSlotPicker.Molecule'
import { DateTime } from 'luxon'

export const isTimeSlotsOverlapping = (
  timeSlots: TimeSlot[],
  newSlot: TimeSlot,
): boolean => {
  // Check if the new slot conflicts with any of the existing slots
  return timeSlots.some(
    (existingSlot) =>
      existingSlot.startTime <= newSlot.endTime &&
      existingSlot.endTime > newSlot.startTime,
  )
}

export const isTimeSlotsValid = (
  timeSlots: TimeSlots,
): { valid: boolean; msg?: string } => {
  for (const day in timeSlots) {
    const slotsForDay = timeSlots[day]
    if (slotsForDay.some((slot) => isEndTimeEarlier(slot))) {
      return {
        valid: false,
        msg: 'End time cannot be earlier than start time. Please check',
      }
    }
    for (const timeSlotIndex in slotsForDay) {
      const timeSlotToCheck = slotsForDay[timeSlotIndex]
      const timeSlotsExcludingCurrent = slotsForDay.filter(
        (slotForDay) =>
          slotForDay.startTime !== timeSlotToCheck.startTime &&
          slotForDay.endTime !== timeSlotToCheck.endTime,
      )
      if (isTimeSlotsOverlapping(timeSlotsExcludingCurrent, timeSlotToCheck)) {
        return {
          valid: false,
          msg: 'Looks like there are overlapping working hours. Please check.',
        }
      }
    }
  }
  return {
    valid: true,
  }
}

export const isEndTimeEarlier = (slot: TimeSlot): boolean => {
  const [startHour, startMin] = slot.startTime.split(':')
  const dateTimeStartTime = DateTime.now().set({
    hour: +startHour,
    minute: +startMin,
  })
  const [endHour, endMin] = slot.endTime.split(':')

  const dateTimeEndTime = DateTime.now().set({
    hour: +endHour,
    minute: +endMin,
  })
  return dateTimeEndTime < dateTimeStartTime
}
