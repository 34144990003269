import { HttpService } from './base.service'

class SubscriptionService extends HttpService {
  private readonly prefix = 'subscriptions'
  viewBillingPortal = () => this.get(`${this.prefix}/view-billing`)
  fetchUserSubscription = () =>
    this.get(`${this.prefix}/current-subscription-status`)
  getCurrentPlan = () => this.get(`${this.prefix}/current-plan`)
  getStoredCards = () => this.get(`${this.prefix}/get-cards`)
  addSeats = (data: any) => this.post(`${this.prefix}/add-seats`, data)
  addCardSession = (data: any) => this.post(`${this.prefix}/add-card`, data)
  upgradePlan = (data: any) => this.post(`${this.prefix}/upgrade-plan`, data)
}

export const subscriptonService = new SubscriptionService()
