import { HttpService } from './base.service'

class dashboardService extends HttpService {
  private readonly prefix = 'routers'
  private readonly prefix_leads = 'leads'
  private readonly prefixDashboard = 'activityLogs/dashboard'

  getDashboardData = (data: unknown) =>
    this.get(`${this.prefix}/dashboard`, data)

  get_dashbard_stats = (data: unknown) =>
    this.get(`${this.prefix}/dashboard-stats`, data)

  delete_dashbard_item = (data: any) =>
    this.put(`${this.prefix_leads}/deleteLeads`, data)

  getTotalRouted = async (data: Record<string, any>) =>
    this.post(`${this.prefixDashboard}/total_leads`, data)
  getTotalCapacity = async (data: Record<string, any>) =>
    this.post(`${this.prefixDashboard}/total-capacity`, data)
  getTotalSLAs = async (data: Record<string, any>) =>
    this.post(`${this.prefixDashboard}/total-slas`, data)
  getDetailSLAs = async (data: Record<string, any>) =>
    this.post(`${this.prefixDashboard}/list-slas`, data)
  getRoutedOverview = async (data: Record<string, any>) =>
    this.post(`${this.prefixDashboard}/total_leads_overview`, data)
  getConversionList = async (data: Record<string, any>) =>
    this.post(`${this.prefixDashboard}/conversion-overview`, data)

  get_dashbard_data = (data: unknown) =>
    this.get(`${this.prefix}/dashboard`, data)
  getListRoutedLeads = async (data: Record<string, any>) =>
    this.post(`${this.prefixDashboard}/list-routed-leads`, data)
}

export const DashboardService = new dashboardService()
