import { createAsyncThunk } from '@reduxjs/toolkit'
import { TeamsService } from 'src/services/teams.service'
import { Teams } from 'src/types/teams'

enum actiontype {
	getTeamsList = 'getTeamsList',
	createTeam = 'createTeam',
	getTeamById = 'getTeamById',
	updateTeam = 'updateTeam',
	addUsersTeam = 'addUsers',
	deleteTeams = 'deleteTeams'
}

export const getTeamsList = createAsyncThunk(
	actiontype.getTeamsList,
	async () => {
		const resp = await TeamsService.getTeamsList()
		return resp.data
	},
)

export const createTeam = createAsyncThunk(
	actiontype.createTeam,
	async (payload: { name: string }) => {
		const response = await TeamsService.createTeam(payload)
		return response;
	},
)

export const getTeamById = createAsyncThunk(
	actiontype.getTeamById,
	async (teamId: string) => {
		const resp = await TeamsService.getTeamById(teamId)
		return resp.data
	},
)

export const updateTeam = createAsyncThunk(
	actiontype.updateTeam,
	async (payload: { id: string; data: { name: string } }) => {
		return await TeamsService.updateTeam(payload.id, payload.data)
	},
)

export const addUsersTeam = createAsyncThunk(
	actiontype.addUsersTeam,
	async (payload: { id: string; data: { users: string[], isManagers: boolean } }) => {
		return await TeamsService.addUsers(payload.id, payload.data)
	},
)

export const deleteTeams = createAsyncThunk(
	actiontype.deleteTeams,
	async (data: string[]) => {
		return await TeamsService.deleteTeams(data)
	},
)