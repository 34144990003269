import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { Combobox } from '@headlessui/react'
import clsx from 'clsx'
import { Formik, Form } from 'formik'
import { getTitleCase } from 'src/utils/common/string.common'
import IconRoutera from 'src/components/atoms/icons/icons'
interface Props<Model> {
  label: string | React.ReactNode
  description?: string | React.ReactNode
  modelName: string
  initialValue: string
  options: string[]
  footerDescription?: string | React.ReactNode
  setValue?: Dispatch<SetStateAction<string>>
  shouldSubmit?: boolean
  handleOnSubmit: (model: Partial<Model>) => void
  disabled?: boolean
}

export const ComboBox = <Model,>({
  modelName,
  initialValue,
  label,
  description,
  options,
  setValue,
  footerDescription,
  shouldSubmit = true,
  disabled = false,
  handleOnSubmit,
}: Props<Model>) => {
  const [query, setQuery] = useState('')
  const initialModel = useMemo(
    () => ({
      [modelName]: initialValue || '',
    }),
    [initialValue],
  )

  const fieldName = `['${modelName}']`

  const filteredOptions = useMemo(
    () =>
      query === ''
        ? options
        : options.filter((option) => {
          return option.toLowerCase().includes(query.toLowerCase())
        }),
    [query],
  )

  return (
    <Formik
      onSubmit={(model) => {
        if (shouldSubmit) {
          handleOnSubmit(model as unknown as Partial<Model>)
        }
      }}
      initialValues={initialModel}
    >
      {({ handleSubmit, handleChange, values, submitForm }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            className='flex justify-start items-center'
          >
            <Combobox
              as='div'
              value={values[modelName]}
              name={fieldName}
              disabled={disabled}
              onChange={(value: string) => {
                if (setValue) {
                  setValue(value)
                }
                handleChange({
                  target: { name: fieldName, value: value },
                })
                submitForm()
              }}
            >
              <Combobox.Label className='block text-xl font-medium text-gray-700'>
                {label}
              </Combobox.Label>
              {description && (
                <div className={clsx('mt-2 text-lg text-gray-700')}>
                  {description}
                </div>
              )}

              <div className='relative mt-2'>
                <Combobox.Input
                  as='input'
                  className={clsx(
                    'w-full rounded-lg border border-gray-300 text-gray-500 text-sm bg-white py-2 pl-3 pr-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500',
                    disabled && 'opacity-50',
                  )}
                  onChange={(event: any) => setQuery(event.target.value)}
                  displayValue={() => getTitleCase(values[modelName])}
                />
                <Combobox.Button
                  as='button'
                  className='absolute h-[40px] inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'
                >
                  <IconRoutera
                    iconName='ChevronDownIcon'
                    className='h-[18px] w-[18px] text-gray-500'
                  />
                </Combobox.Button>

                {filteredOptions.length > 0 && (
                  <Combobox.Options
                    className={`z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white pl-0 py-1 text-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm absolute`}
                  >
                    {filteredOptions.map((option, index) => (
                      <Combobox.Option
                        key={`${option}-${index}`}
                        value={option}
                        className={({ active }) =>
                          clsx(
                            'relative cursor-default select-none py-2 pl-3 pr-8 text-lg w-full',
                            active && 'bg-blue-600',
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <span
                              className={clsx(
                                'block truncate text-gray-500 text-[14px] leading-[17px]',
                                active ? 'text-white' : 'text-gray-500',
                                selected && 'font-semibold',
                              )}
                            >
                              {getTitleCase(option)}
                            </span>

                            {selected && (
                              <span
                                className={clsx(
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                  active ? 'text-white' : 'text-blue-600',
                                )}
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
              {footerDescription && (
                <div className='mt-2 text-gray-500'>{footerDescription}</div>
              )}
            </Combobox>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ComboBox
