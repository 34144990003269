import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'

interface IProps {
  name: string
  description: string
  logo: string
  hasSettings: boolean
  isConnected: boolean
  onConnect: () => void
  onDisconnect?: () => void
  onSettingsPress: () => void
}

const IntergrationCard: React.FC<IProps> = ({
  name,
  description,
  logo,
  hasSettings,
  isConnected,
  onConnect,
  onSettingsPress,
  onDisconnect,
}) => {

  const ability = useAbility(AbilityContext)

  return (
    <div className='card-container flex flex-col border shadow-sm rounded p-4 hover:scale-105 transition-transform'>
      <div className='flex justify-center items-center'>
        <img src={logo} className='h-16 w-16 mr-3' />
        <span className='text-2xl'>{name}</span>
      </div>
      <span className='text-[16px] leading-[24px] font-normal text-gray-500 block my-4 px-4'>{description}</span>
      <div className='flex justify-around flex-wrap gap-3'>
        {hasSettings && (
          <button
            className={`px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg bg-routera-primary self-start w-[113px] ${!ability.can('update', 'setting.integration') && 'cursor-not-allowed'}`}
            onClick={onSettingsPress}
            disabled={!ability.can('update', 'setting.integration')}
          >
            Settings
          </button>
        )}
        {!isConnected && (
          <button
            className={`px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg bg-routera-primary self-start ${!ability.can('create', 'setting.integration') && 'cursor-not-allowed'}`}
            onClick={onConnect}
            disabled={!ability.can('create', 'setting.integration')}
          >
            Connect
          </button>
        )}
        {isConnected && (
          <button
            className={`bg-red-500 px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg self-start w-[113px] ${!ability.can('delete', 'setting.integration') && 'cursor-not-allowed'}`}
            onClick={onDisconnect}
            disabled={!ability.can('delete', 'setting.integration')}
          >
            Disconnect
          </button>
        )}
      </div>
    </div>
  )
}

export default IntergrationCard
