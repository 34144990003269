export enum ROUTER_TRIGGER_TYPES {
  WORKFLOW_ACTION = 'WORKFLOW_ACTION',
  FIELD_UPDATE = 'FIELD_UPDATE',
  NEW_RECORD = 'NEW_RECORD',
}

export enum ROUTER_TRIGGER_VALUE_TYPES {
  ANY_VALUE = 'ANY_VALUE',
  SPECIFIC_VALUE = 'SPECIFIC_VALUE',
}

export const TriggerTypeToText = {
  WORKFLOW_ACTION: 'Workflow Action',
  FIELD_UPDATE: 'Field Update',
  NEW_RECORD: 'New Record',
}
