import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '../../store'
const selectedreducer = (state: RootState) => state.slaState

export const useListOfAllSLAs = createDraftSafeSelector(
  selectedreducer,
  (slaState) => {
    return slaState.slas
  },
)
