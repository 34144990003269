import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import { useAppSelector } from 'src/store'
import { usespecificRouterDetail } from 'src/container/RouterDetail/routerDetail.selector'
import Icons from 'src/assets/images/icons/Icons'
import CreateRule from './CreateRule'
import Table, {
  TableColumn,
} from 'src/components/organisms/tables/Table.Organism'
import { routerDetailService } from 'src/services/router_detail.service'
import toastBuilder from 'src/utils/toastBuilder'
import { useNavigate } from 'react-router-dom'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'

const title = 'Add Account Matching'
const description =
  'For B2B sales teams you may need to first check to see if the incoming lead has an associated company that already has an owner.'
const boldDescription =
  'Use account matching to check if there is an existing account owner before making the assignment.'

const AccountMatching: React.FC<any> = forwardRef((props, ref) => {
  const ability = useAbility(AbilityContext)

  if (!ability.can('access', 'router.account_matching')) {
    return (
      <NoPermission
        title={title}
        description={description}
        boldDescription={boldDescription}
        classHeight='h-[60vh]'
        value='access'
        width='300px'
      />
    )
  } else {
    if (!ability.can('read', 'router.account_matching')) {
      return <NoPermission classHeight='h-[60vh]' value='read' />
    }
  }

  const [showSlider, setShowSlider] = useState<boolean>(false)
  const [hasRule, setHasRule] = useState<boolean>(false)
  const [currRuleEditData, setCurrRuleEditData] = useState()

  const specificRouterDetail = useAppSelector(usespecificRouterDetail)
  const navigate = useNavigate()

  useEffect(() => {
    if (specificRouterDetail.account_matching) {
      setHasRule(true)
    }
  }, [specificRouterDetail])

  const onDeleteRule = () => {
    routerDetailService
      .setRules(specificRouterDetail._id, { account_matching: null })
      .then((res: any) => {
        if (res?.success) {
          toastBuilder('success', 'Account Matching deleted')
          setHasRule(false)
        } else {
          toastBuilder('error', 'Something went wrong')
        }
      })
      .catch((err) => {
        toastBuilder('error', 'Something went wrong')
      })
  }

  const tableColumns: TableColumn[] = [
    { label: '', className: 'w-[1rem]' },
    {
      label: 'Name',
      field: 'ruleName',
      className: 'w-[5rem]',
    },
    { label: 'Field', field: 'field' },
    {
      label: '',
      render: () => {
        return (
          <button
            type='button'
            className={`inline-flex items-center ${
              !ability.can('update', 'router.account_matching') &&
              'cursor-not-allowed'
            }`}
            onClick={() => setShowSlider(true)}
            disabled={!ability.can('update', 'router.account_matching')}
          >
            <Icons name='edit-pencil-blue' />
          </button>
        )
      },
      className: 'w-[5rem]',
    },
    {
      label: '',
      render: () => {
        return (
          <div
            onClick={
              ability.can('delete', 'router.account_matching')
                ? onDeleteRule
                : () => {
                    return
                  }
            }
            className={`text-2xl rounded-full hover:cursor-pointer hover:bg-slate-200 flex items-center justify-center text-slate-500 peer-checked:bg-slate-100 peer-checked:text-slate-900 ${
              !ability.can('delete', 'router.account_matching') &&
              'cursor-not-allowed'
            }`}
          >
            <i className='fa-solid fa-trash text-lg'></i>
          </div>
        )
      },
      className: 'w-[5rem]',
    },
  ]

  const rows = [
    {
      ruleName: specificRouterDetail?.account_matching?.name,
      field:
        specificRouterDetail?.account_matching?.fieldName ||
        specificRouterDetail?.account_matching?.fieldType,
    },
  ]

  useImperativeHandle(ref, () => ({
    onRefSave() {
      navigate(`/routers/${specificRouterDetail._id}/review-publish`)
    },
  }))

  return (
    <div className={`${!specificRouterDetail?.slas && 'h-full'}`}>
      <div className='mt-[1.3rem]'>
        <span className='text-lg font-medium flex-grow-1'>
          Account Matching
        </span>
      </div>
      <div className='mt-2 mb-2.5 flex flex-row justify-between items-center align-middle'>
        <div className='flex justify-center items-center'>
          <Icons name='info' />
          <span className='ml-1.5 text-bs flex-grow-1'>
            {
              'Create account matching to ensure leads are routed to the right users'
            }
          </span>
        </div>
      </div>
      <CreateRule
        hasData={hasRule}
        showSlider={showSlider}
        setShowSlider={setShowSlider}
        currRuleEditData={currRuleEditData}
        disabled={!ability.can('update', 'router.account_matching')}
      />
      {hasRule && <Table columns={tableColumns} rows={rows} />}
    </div>
  )
})

AccountMatching.displayName = 'AccountMatching'

export default AccountMatching
