import { createAsyncThunk } from '@reduxjs/toolkit'
import { authService } from '../../services/auth.service'
import {
  logindataTypes,
  RegisterTypes,
  forgetdataTypes,
} from '../../types/auth'

import { ApiClient } from '../../index'

enum actiontype {
  Login_USER = 'login user action type',
  logout = 'logout user action type',
  SIGNUP_USER = 'SIGNUP_USER user action type',
  FORGET_USER = 'FORGET_USER user action type',
  ResetPasswordAction = 'ResetPasswordAction user action type',
  loginwithGoogle = 'loginwithGoogle user action type',
  updateuserDataAction = 'updateuserDataAction user data',
  UploadProfileAction = 'UploadProfileAction image user',
  changeoffice_status = 'changeoffice_statuschangeoffice_status',
  listselected = 'listselected listselected',
  changeoffice_status_for_team_member = 'changeoffice_status_for_team_memberchangeoffice_status_for_team_member',
  getUserDetails = 'GET_USER_DETAILS',
  getCalendarAction = 'getCalendar',
  getUserPermissions = 'getUserPermissions',
  loginWithAuth0 = 'loginWithAuth0',
  updateUserFromHubspot = 'updateUserFromHubspot',
}
export const userloginAction = createAsyncThunk(
  actiontype.Login_USER,
  async (payload: logindataTypes) => {
    return await authService.login(payload)
  },
)

export const logoutAction = createAsyncThunk(actiontype.logout, async () => {
  // Right now do nothing
  return null
})

export const userSignupAction = createAsyncThunk(
  actiontype.SIGNUP_USER,
  async (payload: RegisterTypes) => {
    return await authService.signup(payload)
  },
)

export const ForgetAction = createAsyncThunk(
  actiontype.FORGET_USER,
  async (payload: forgetdataTypes) => {
    return await authService.forgetpassword(payload)
  },
)

export const getCalendarAction = createAsyncThunk(
  actiontype.getCalendarAction,
  async () => {
    return await authService.getCalendar()
  },
)

export const ResetPasswordAction = createAsyncThunk(
  actiontype.ResetPasswordAction,
  async (data: any) => {
    return await authService.resetPassword(data.token, data.data)
  },
)

export const loginwithGoogle = createAsyncThunk(
  actiontype.loginwithGoogle,
  async (data: any) => {
    return await authService.googlelogin(data)
  },
)

export const updateuserDataAction = createAsyncThunk(
  actiontype.updateuserDataAction,
  async (payload: any) => {
    return await authService.updateuserData(payload)
  },
)

export const UploadProfileAction = createAsyncThunk(
  actiontype.UploadProfileAction,
  async (payload: any) => {
    return await authService.uplodprofileimage(payload)
  },
)

export const changeoffice_status = createAsyncThunk(
  actiontype.changeoffice_status,
  async (payload: { office_status: boolean; user_id: string }) => {
    return await authService.changeMyOfficeStatus(payload)
  },
)

export const listselected = createAsyncThunk(
  actiontype.listselected,
  async (list: { source_id: string; list_id: number }, tunkapi) => {
    const reuslt = await ApiClient.post('/hubsport/assign_list_to_source', list)
    if (reuslt) {
      return reuslt
    } else {
      tunkapi.rejectWithValue('')
    }
  },
)

export const getUserDetailsAction = createAsyncThunk(
  actiontype.getUserDetails,
  async () => {
    return await authService.getUserDetails()
  },
)

export const getUserPermissionsAction = createAsyncThunk(
  actiontype.getUserPermissions,
  async () => {
    return await authService.getUserPermissions()
  },
)

export const loginWithAuth0 = createAsyncThunk(
  actiontype.loginWithAuth0,
  async (payload: { token: string }) => {
    return await authService.loginWithAuth0(payload)
  },
)

export const updateUserFromHubspot = createAsyncThunk(
  actiontype.updateUserFromHubspot,
  async (payload: { email: string }) => {
    return await authService.updateUserFromHubspot(payload)
  },
)
