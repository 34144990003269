import { useEffect, useState } from 'react'

export interface ItemsTableProps {
  setLimitItems: (value: number) => void
  totalItems: number
  startLimit: number
  endLimit: number
  loading?: {
    setLoadingData: (value: boolean) => void
    loadingData: boolean
  }
}
interface IProps {
  limitItems: ItemsTableProps
}

const SelectItems: React.FC<IProps> = (props) => {
  const { limitItems } = props
  const [totalItems, setTotalItems] = useState<number>(limitItems.totalItems)
  const [limit, setLimit] = useState<number>(5)

  useEffect(() => {
    if (limitItems) {
      setTotalItems(limitItems.totalItems)
    }
  }, [limitItems])

  const selectLimit = (value: number) => {
    limitItems.setLimitItems(value)
    setLimit(value)
    if (limitItems.loading) {
      limitItems.loading.setLoadingData(true)
    }
  }

  return (
    <div className='relative overflow-hidden rounded-b-lg'>
      <nav
        className='flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0'
        aria-label='Table navigation'
      >
        <div className='flex items-center space-x-3'>
          <label
            htmlFor='rows'
            className='text-sm font-normal text-gray-500 dark:text-gray-400'
          >
            Rows per page
          </label>
          <select
            id='rows'
            className={`border-none focus:border-none bg-transparent text-gray-900 rounded-lg focus:ring-primary-500 block p-0 px-4 ml-0 text-sm pl-0 ${limitItems.loading &&
              limitItems.loading.loadingData &&
              'cursor-progress'
              }`}
            onChange={(v: any) => selectLimit(parseInt(v.target.value))}
            disabled={limitItems.loading && limitItems.loading.loadingData}
          >
            <option selected value='5'>
              5
            </option>
            <option value='10'>10</option>
            <option value='15'>15</option>
            <option value='20'>20</option>
          </select>
          <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
            <span className='mr-2'>Show</span>
            <span className='font-semibold text-gray-900 dark:text-white'>
              {limitItems.startLimit}-{limitItems.endLimit}
            </span>
            <span className='mx-[6px]'>of</span>
            <span className='font-semibold text-gray-900 dark:text-white'>
              {totalItems}
            </span>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default SelectItems
