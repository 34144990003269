import { HttpService } from './base.service'

class SLAService extends HttpService {
  private readonly prefix = 'sla'
  addSLA = (data: any) => this.post(`${this.prefix}`, data)
  getSLA = (slaId: string) => this.get(`${this.prefix}/${slaId}`)
  updateSLA = (slaId: string, data: any) =>
    this.patch(`${this.prefix}/${slaId}`, data)
  deleteSLA = (slaId: string) => this.delete(`${this.prefix}/${slaId}`)
  getAllSLAs = (objectType?: string) => {
    if (objectType) {
      return this.get(`${this.prefix}?objectType=${objectType}`)
    } else {
      return this.get(`${this.prefix}`)
    }
  }
  deleteSLAs = (sla_ids: string[]) =>
    this.delete(`${this.prefix}/`, {}, { sla_ids })
  setRules = (slaId: string, data: any) =>
    this.patch(`${this.prefix}/${slaId}/set-rules`, data)
}

export const slaService = new SLAService()
