import Pagination, {
	Props as PaginationProps,
} from 'src/components/organisms/pagination/Pagination.Organism'

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}

export interface TableColumn {
	label: string
	field?: string
	className?: string | string[]
	render?: (props: any) => JSX.Element
}

export type OnRowClick = (row: Record<string, any>) => void

interface Props {
	columns: TableColumn[]
	rows: Record<string, any>[]
	onRowClick?: OnRowClick
	pagination?: PaginationProps
	className?: string
}

export const TableActions: React.FC<Props> = ({
	columns,
	rows,
	onRowClick,
	pagination,
	className
}) => {
	return (
		<div className={`flex flex-col ${className}`}>
			<div className='-my-2 overflow-x-auto'>
				<div className='inline-block min-w-full py-2 align-middle'>
					<div className='relative overflow-hidden ring-1 ring-black ring-opacity-5 rounded border border-gray-50 border-solid'>
						<table
							className='min-w-full divide-y divide-gray-300 font-inter'
							style={{ borderSpacing: 0 }}
						>
							<thead className='bg-gray-50 '>
								<tr>
									{columns.map((column, index) => (
										<th
											key={`${column.label}-${index}`}
											scope='col'
											className={`${column.className} sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-500 uppercase backdrop-blur backdrop-filter sm:pl-6`}
										>
											{column.label}
										</th>
									))}
								</tr>
							</thead>
							<tbody className='divide-y divide-gray-200 bg-white'>
								{rows.map((row, index) => (
									<tr key={index} className={row?.disabled && 'bg-gray-100'}>
										{columns.map((column, index) => (
											<td
												key={`${index}-${column.label}`}
												className={classNames(
													'whitespace-nowrap py-3 pl-6 text-left text-xs text-gray-500 font-normal',
													onRowClick ? 'cursor-pointer' : '',
												)}
												onClick={() => (onRowClick ? onRowClick(row) : null)}
											>
												{column.render ? (
													<column.render {...row} />
												) : column.field ? (
													row[column.field]
												) : (
													''
												)}
											</td>
										))}
									</tr>
								))}
								{rows.length < 1 && (
									<tr className='bg-white'>
										<td
											colSpan={columns.length + 1}
											className='text-center py-24 text-lg text-gray-500'
										>
											There is no data to display
										</td>
									</tr>
								)}
							</tbody>
						</table>
						{pagination && <Pagination {...pagination} />}
					</div>
				</div>
			</div>
		</div>
	)
}

export default TableActions
