import IconRoutera from 'src/components/atoms/icons/icons'
import PercentageBar from 'src/components/atoms/percentageBar/percentageBar'
import { TotalCapacity } from 'src/types/dashboard'
import { useEffect, useState } from 'react'
import { Divider } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

interface IProps {
  totalCapacity: TotalCapacity | null
  classWidget?: string
  freePlan: boolean
}

const WidgetTotalCapacity: React.FC<IProps> = (props) => {
  const { totalCapacity, classWidget, freePlan } = props

  const navigate = useNavigate()

  const redirectBillingPage = () => {
    navigate('/settings/billing')
  }

  return (
    <div
      className={`bg-white mt-4 p-3 flex flex-col shadow-sm rounded-lg ${classWidget}`}
    >
      <IconRoutera
        iconName='HandIcon'
        className='h-6 w-6 text-gray-400'
        aria-hidden='true'
      />
      <span className='capitalize text-gray-500 text-base font-semibold'>
        Total Open Capacity
      </span>
      {totalCapacity && !freePlan ? (
        <div>
          <span className='text-gray-700 text-3xl font-bold mb-2'>
            {totalCapacity.totalCurrentCapacity}
          </span>
          <PercentageBar
            value={totalCapacity.totalCurrentCapacity}
            total={totalCapacity.totalMaxCapacity}
            className='max-w-[260px]'
            heightBarClass='h-[7px]'
          />
          <span className='text-gray-500 text-xs font-normal mb-2 mt-2'>
            {totalCapacity.totalCurrentCapacity} of{' '}
            {totalCapacity.totalMaxCapacity}
          </span>
        </div>
      ) : (
        <>
          {freePlan ? (
            <div>
              <div className='flex items-baseline'>
                <IconRoutera
                  iconName='LockClosedIcon'
                  className='w-[14px] h-[14px] mr-1'
                />
                <span className='text-gray-500 text-base font-normal ml-2 mt-2'>
                  Upgrade to unlock Capacity
                </span>
              </div>
              <button
                onClick={redirectBillingPage}
                className='px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start mt-[10px]'
              >
                Upgrade Now
              </button>
            </div>
          ) : (
            <div>
              <span className='text-gray-700 text-3xl font-bold mb-2 mt-2'>
                No data*
              </span>
              <div className='bg-routera-50 py-[2px] px-[10px] rounded-md w-fit mt-2'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://help.routera.io/article/17-set-user-capacities'
                >
                  <span className='flex text-routera-700 text-xs font-medium leading-[18px] items-center cursor-pointer'>
                    <IconRoutera
                      iconName='InformationCircleIcon'
                      className='w-[14px] h-[14px] mr-1'
                    />
                    How does this widget work?
                    <IconRoutera
                      iconName='ArrowRightIcon'
                      className='w-[14px] h-[14px] ml-1'
                    />
                  </span>
                </a>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default WidgetTotalCapacity
