import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { RootState } from 'src/store'
const selectedreducer = (state: RootState) => state.routerDetailState

export const usespecificRouterDetail = createDraftSafeSelector(
  selectedreducer,
  (routesState) => routesState.specific_router_detail,
)

export const useListOfAllTeamMembers = createDraftSafeSelector(
  selectedreducer,
  (routesState) => routesState.allTeamMembers,
)

export const useListOfAssignedTeamMembers = createDraftSafeSelector(
  selectedreducer,
  (routesState) => {
    return routesState.assignedTeamMembers
  },
)

export const useListOfAllSources = createDraftSafeSelector(
  selectedreducer,
  (routesState) => {
    return routesState.allSources
  },
)

export const useListOfAssignableSources = createDraftSafeSelector(
  selectedreducer,
  (routesState) => {
    return routesState.assignableSources
  },
)

export const useassigned_user_full_details = createDraftSafeSelector(
  selectedreducer,
  (routesState) => {
    return routesState.assigned_user_full_details
  },
)

export const useweb_hook_address = createDraftSafeSelector(
  selectedreducer,
  (routesState) => {
    return routesState.web_hook_address
  },
)
export const use_specific_source = createDraftSafeSelector(
  selectedreducer,
  (routesState) => {
    return routesState.specifisource
  },
)

export const use_defaultuser = createDraftSafeSelector(
  selectedreducer,
  (routesState) => {
    return routesState.defaultuser
  },
)

export const usesourceUserList = createDraftSafeSelector(
  selectedreducer,
  (routesState) => {
    return routesState.sourceUserList
  },
)
