import Tabs from 'src/components/molecules/tabs/tabs'
import IntegrationHubspot from 'src/components/organisms/integrationHubspot/integrationHubspot'
import { DataTab } from 'src/types/tabs'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'

const Integrations = () => {

  const ability = useAbility(AbilityContext)

  if (!ability.can('read', 'setting.integration')) {
    return <NoPermission value='read' />
  }

  const dataTabs: DataTab[] = [
    {
      label: 'Integrations',
      id: 'integrations-tab',
      dataContentTab: <IntegrationHubspot />
    },
  ]

  return (
    <section className='border-l border-gray-200'>
      <div className='p-4 px-5 bg-gray-100'>
        <h3 className='text-2xl block'>Settings</h3>
      </div>
      <Tabs dataTabs={dataTabs} classContentTab='min-h-[calc(100vh-210px)] bg-routera-gray' />
    </section>
  )
}

export default Integrations
