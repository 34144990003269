// @ts-nocheck
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import { PERCENTAGE_MODEL } from '../../config/constants'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Table, { TableColumn } from '../organisms/tables/Table.Organism'
import { User } from './TeamMembersList/types'
import Avatar from '../atoms/avatar/Avatar.Atom'
import { IRouterDetail } from 'src/types/routerDetail'
import DropdownCustom, {
  defaultOptionRenderer,
} from 'src/components/atoms/Dropdown/DropdownCustom.atom'
import { IOption } from 'src/types/Ihome_state'
import Icons from 'src/assets/images/icons/Icons'
import { useActions } from 'src/actions'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'

type IDistributionModel = {
  assigned_user_full_details: User[]
  onSave: (current_percentage: any, percentage: any) => void
  percentagemodel: { model: string; error: boolean }
  setpercentagemodel: (arguemnt: any) => void
  specificRouterDetail: IRouterDetail
  setNextDisabled: (val: boolean) => void
  isFreePlan: boolean
}

const title = 'Percentage Model Distribution'
const description =
  'Distribute incoming leads to users based on set percentages. For example:'
const boldDescription = '25% of leads will go to Rep A and 75% to Rep B.'

const ModelDetail: React.FC<IDistributionModel> = forwardRef((props, ref) => {
  const ability = useAbility(AbilityContext)

  if (!ability.can('read', 'router.model')) {
    return <NoPermission classHeight='h-[60vh]' value='read' />
  }

  const { getspecificRouterDetail } = useActions()

  const {
    assigned_user_full_details,
    onSave,
    percentagemodel,
    setpercentagemodel,
    specificRouterDetail,
    setNextDisabled,
    isFreePlan,
  } = props

  useEffect(() => {
    setUsers(assigned_user_full_details)
  }, [assigned_user_full_details])

  const [users, setUsers] = useState([])
  const [dataOptions, setDataOptions] = useState<IOption>([
    { id: 1, label: 'Round Robin', value: 'roundRobin' },
    { id: 2, label: 'Percentage', value: 'percentage' },
  ])

  const handleDragEnd = (e) => {
    if (!e.destination) return
    const tempData = Array.from(users)
    const [source_data] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
    setUsers(tempData)
  }

  const orderNumber = users.map((p, index) => {
    return {
      ...p,
      order: index,
    }
  })

  const [current_percentage, set_current_percentage] = useState<boolean>(false)
  const [userIdBeingUpdated, setUserIdBeingUpdated] = useState<number | null>(
    null,
  )
  const [selected_option, set_selected_option] = useState<number>(0)

  const [percentage, setpercentage] = useState<
    Array<{
      percentage: number
      userId: string
      office_status: boolean
      isDefaultForLeads: boolean
      orderNumber: number
    }>
  >([])

  const handlePercentageChange = (
    id: string,
    value: number,
    office_status: boolean,
    isDefaultForLeads: any,
  ) => {
    if (value > 0 && value < 1) {
      set_current_percentage(true)
      return
    }

    // Find the team member who got updated
    const teamMemberIndexToUpdate = percentage.findIndex(
      (percent) => percent.userId === id,
    )
    if (teamMemberIndexToUpdate !== -1) {
      percentage[teamMemberIndexToUpdate] = {
        userId: id,
        percentage: value,
        office_status: office_status,
        isDefaultForLeads:
          isDefaultForLeads === undefined ? false : isDefaultForLeads,
      }
      setUserIdBeingUpdated(id)

      let number = 0
      percentage.forEach((single_percentage) => {
        number += single_percentage.percentage
      })

      setpercentage([...percentage])
      if (number > 0 && number < 1) {
        set_current_percentage(true)
      }

      if (Math.round(number) !== 100) {
        set_current_percentage(true)
        return
      }
      set_current_percentage(false)
    }
  }

  useEffect(() => {
    setPercentageModelF()
    if (percentagemodel.model !== '') {
      getspecificRouterDetail(specificRouterDetail._id)
    }
  }, [])

  useEffect(() => {
    const result = users.map((user: any) => ({
      percentage:
        specificRouterDetail.assignedUsers.find(
          (assignedUser) => assignedUser.userId === user._id,
        )?.userStrategyData?.percentage || 0,
      userId: user._id,
      office_status: user.office_status,
      isDefaultForLeads: user.isDefaultForLeads,
      orderNumber: user.order,
    }))
    setpercentage(result)
    setPercentageModelF()
  }, [users, specificRouterDetail])

  useEffect(() => {
    setNextDisabled(
      percentagemodel.model === PERCENTAGE_MODEL.PERCENTAGE
        ? current_percentage
        : '',
    )
  }, [current_percentage])

  const setPercentageModelF = () => {
    set_selected_option(0)
    setpercentagemodel({
      error: false,
      model: PERCENTAGE_MODEL.ROUNDROBIN,
    })

    if (percentagemodel.model === PERCENTAGE_MODEL.PERCENTAGE) {
      set_selected_option(1)
      setpercentagemodel({
        error: false,
        model: PERCENTAGE_MODEL.PERCENTAGE,
      })
    }
  }

  useImperativeHandle(ref, () => ({
    onRefSave() {
      return onSave(current_percentage, percentage, orderNumber)
    },
  }))

  const columns: TableColumn[] = [
    {
      label: 'User',
      render: ({
        avatar,
        firstName,
        lastName,
        userAvailability,
        office_status,
      }: any) => {
        return (
          <div className='mr-2 flex items-center'>
            <Avatar
              className='w-[32px] h-[32px] text-sm mr-2'
              firstName={firstName}
              lastName={lastName}
              imgURL={avatar}
              available={{
                userAvailability: userAvailability,
                officeStatus: office_status ? office_status : false,
              }}
            />
            <span> {`${firstName} ${lastName}`}</span>
          </div>
        )
      },
    },
    {
      label: 'Percentage',
      render: (rowData: any) => {
        return (
          <div>
            <div className='percentage-box'>
              <input
                type='number'
                autoFocus={userIdBeingUpdated === rowData._id}
                value={
                  percentage.find((user) => user.userId === rowData._id)
                    ?.percentage ?? 0
                }
                min={0}
                onChange={(event) =>
                  handlePercentageChange(
                    rowData._id,
                    +event.target.value,
                    rowData.office_status,
                    rowData.isDefaultForLeads,
                  )
                }
                disabled={!ability.can('update', 'router.model')}
              />
            </div>
          </div>
        )
      },
    },
  ]

  const handleSelectedOption = (option: any) => {
    set_selected_option(option.id - 1)
    setpercentagemodel(null)

    option.value === 'roundRobin'
      ? setpercentagemodel({
          error: false,
          model: PERCENTAGE_MODEL.ROUNDROBIN,
        })
      : setpercentagemodel({
          error: false,
          model: PERCENTAGE_MODEL.PERCENTAGE,
        })
  }

  return (
    <section className='model-tab-sec h-full'>
      <div className='my-[1.3rem]'>
        <h4>Model</h4>
        <div className='flex justify-center items-center my-3'>
          <Icons name='info' />
          <span className='ml-1.5 text-bs flex-grow-1'>
            Select how leads get assigned to the selected users
          </span>
        </div>
        <div className='flex justify-between items-center tablet:flex-wrap'>
          <DropdownCustom
            dropdownLabel=''
            options={dataOptions}
            selectedOption={dataOptions[selected_option]}
            setSelectedOption={handleSelectedOption}
            optionRenderer={defaultOptionRenderer}
            customClass='mr-2'
            disabled={!ability.can('update', 'router.model')}
          />
        </div>
        {percentagemodel.error ? <div>required</div> : null}
      </div>

      <div className='col-md-12 mb-5'>
        {percentagemodel.model === PERCENTAGE_MODEL.ROUNDROBIN ? (
          <div>
            <table className='tailwind-table table-fixed'>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Order No</th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='droppable-1'>
                  {(provider) => (
                    <tbody
                      className='text-capitalize'
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                    >
                      {users?.map((user, index) => {
                        return (
                          <Draggable
                            key={user._id}
                            draggableId={user._id}
                            index={index}
                            isDragDisabled={
                              !ability.can('update', 'router.model')
                            }
                          >
                            {(provider) => (
                              <tr
                                {...provider.draggableProps}
                                ref={provider.innerRef}
                                {...provider.dragHandleProps}
                              >
                                <td>
                                  <div className='mr-2 flex items-center'>
                                    <Avatar
                                      className='w-[32px] h-[32px] text-sm mr-2'
                                      firstName={user.firstName}
                                      lastName={user.lastName}
                                      imgURL={user.avatar}
                                      available={{
                                        userAvailability: user.userAvailability,
                                        officeStatus: user.office_status
                                          ? user.office_status
                                          : false,
                                      }}
                                    />
                                    <span className='overflow-hidden whitespace-nowrap overflow-ellipsis mobile:w-[88px]'>
                                      {`${user.firstName} ${user.lastName}`}
                                    </span>
                                  </div>
                                </td>
                                <td className='px-1'>
                                  <span className='orderNo'>{index + 1}</span>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        )
                      })}
                      {provider.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          </div>
        ) : null}

        {percentagemodel.model === PERCENTAGE_MODEL.PERCENTAGE &&
        !isFreePlan ? (
          <>
            <h4>User Percentages</h4>
            <Table columns={columns} rows={assigned_user_full_details} />

            {current_percentage ? (
              <div style={{ textAlign: 'end' }} className='text-danger mt-3'>
                Please make sure the distribution tallies to 100%
              </div>
            ) : null}
          </>
        ) : null}

        {percentagemodel.model === PERCENTAGE_MODEL.PERCENTAGE && isFreePlan ? (
          <NoPermission
            title={title}
            description={description}
            boldDescription={boldDescription}
            classHeight='h-[45vh]'
            value='access'
            width='300px'
          />
        ) : null}
      </div>
    </section>
  )
})
ModelDetail.displayName = 'ModelDetail'

export default ModelDetail
