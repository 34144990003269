import React, { useEffect, useState } from 'react'

import { useAppSelector } from 'src/store'
import { useActions } from 'src/actions'
import { getrunagain } from 'src/container/Loader/loader.selector'
import toastBuilder from 'src/utils/toastBuilder'
import { User } from 'src/types/customers'
import SelectableTable, {
  TableColumn,
  SELECTION_MODES,
} from 'src/components/organisms/tables/SelectableTable.Organism'
import Avatar from 'src/components/atoms/avatar/Avatar.Atom'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import { Teams } from 'src/types/teams'
import AddUsersModal from './addUsersModal'

const zIndex = [30, 20, 10, 0]

const TeamTable: React.FC<{ 
  setShowModalTeam: any, 
  userTab?: boolean, 
  allTeams: Teams[], 
  allUsers: any, 
  onTeams?: any, 
  selectedTeams?: Teams[], 
  isTeamSelected?: any }> = (props) => {

  const { setShowModalTeam, userTab, allTeams, allUsers, onTeams, selectedTeams, isTeamSelected } = props
  const [rowsToShow, setRowsToShow] = useState<Teams[]>(allTeams)
  const [showAddUsersModal, setShowAddUsersModal] = useState(false)
  const [currentUsers, setCurrentUsers] = useState<[]>([])
  const [currentTeams, setCurrentTeams] = useState<[]>([])
  const [isManagerTeam, setIsManagerTeam] = useState(false)

  const {
    getTeamsList,
    deleteTeams
  } = useActions()

  const runagain = useAppSelector(getrunagain)
  const ability = useAbility(AbilityContext)

  useEffect(() => {
    setRowsToShow(allTeams)
  }, [allTeams])

  useEffect(() => {
    if (userTab) {
      getTeamsList()
    }
  }, [userTab,runagain])

  const onSearchTextChange = (searchText: string) => {
    if (searchText === '') {
      setRowsToShow(
        allTeams.map((team: Teams) => ({
          ...team,
          name: team.name,
        })),
      )
      return
    }
    setRowsToShow(
      allTeams
        .map((team: Teams) => ({
          ...team,
          name: team.name,
        }))
        .filter((team: any) =>
          team.name.toLowerCase().includes(searchText.toLowerCase()),
        ),
    )
  }

  const columns: Array<TableColumn> = [
    {
      label: 'Name',
      field: 'name',
      render: ({ name }: any) => {
        return (
          <div className='font-normal text-[14px] leading-[21px] text-gray-500'>
            {name}
          </div>
        )
      },
    },
    {
      label: 'Users',
      render: (rowData: any) => {
        const users = rowData.user_ids.map(
          (user: any) => user,
        )

        const addedUsers = allUsers.filter((user : any) =>
          users.includes(user.id),
        )

        return (
          <div className='flex -space-x-4 w-[113px]'>
            {addedUsers.length > 0 &&
              addedUsers.map(
                (data: User, index: number) =>
                  index < 4 && (
                    <Avatar
                      key={'dataUsers ' + data._id + zIndex[index]}
                      className={
                        'w-[32px] h-[32px] dark:border-gray-800 mr-[2px] border-2 border-white rounded-full'
                      }
                      firstName={data.firstName}
                      classDiv={`z-${zIndex[index]}`}
                      lastName={data.lastName}
                      imgURL={data.avatar}
                      available={{
                        userAvailability: data.userAvailability,
                        officeStatus: data.office_status
                          ? data.office_status
                          : false,
                      }}
                    />
                  ),
              )}
          </div>
        )
      },
    },
    {
      label: 'Managers',
      render: (rowData: any) => {
        const users = rowData.manager_ids.map(
          (user: any) => user,
        )

        const addedUsers = allUsers.filter((user : any) =>
          users.includes(user.id),
        )

        return (
          <div className='flex -space-x-4 w-[113px]'>
            {addedUsers.length > 0 &&
              addedUsers.map(
                (data: User, index: number) =>
                  index < 4 && (
                    <Avatar
                      key={'dataUsers ' + data._id + zIndex[index]}
                      className={
                        'w-[32px] h-[32px] dark:border-gray-800 mr-[2px] border-2 border-white rounded-full'
                      }
                      firstName={data.firstName}
                      classDiv={`z-${zIndex[index]}`}
                      lastName={data.lastName}
                      imgURL={data.avatar}
                      available={{
                        userAvailability: data.userAvailability,
                        officeStatus: data.office_status
                          ? data.office_status
                          : false,
                      }}
                    />
                  ),
              )}
          </div>
        )
      },
    },
  ]

  const handleDelete = async (selectedTeams: Array<Teams>) => {
    const payload =  selectedTeams.map((team) => team._id)
    const response: any = await deleteTeams(payload)

    if (response?.payload?.success) {
      toastBuilder('success', response.payload.msg)
    }

    getTeamsList()
  }

  const handleAddUsers = async (selectedTeams: Array<Teams>, manager: boolean) => {
    const users: any = [];
    const teams: any = [];

    selectedTeams.map((team) => {
      manager ? users.push(...team.manager_ids) : users.push(...team.user_ids);
      teams.push(team._id);
    });

    const addedUsers = allUsers.filter((user : any) =>
      users.includes(user.id),
    )

    setCurrentUsers(addedUsers);
    setIsManagerTeam(manager)
    setCurrentTeams(teams);
    setShowAddUsersModal(true);
  }

  return (
    <>
      <div className={`${userTab && 'px-5 py-7'}`}>
        {userTab && (<div
          className={`col-md-12 mb-5 d-flex justify-end tablet:flex-col tablet:items-start ${!ability.can('create', 'setting.user.team') && 'cursor-not-allowed'
            }`}
        >
          <div className='flex space-x-2 tablet:flex-col mobile:items-start gap-2'>
            <button
              disabled={!ability.can('create', 'setting.user.team')}
              className={`flex flex-row m-0 items-center px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg bg-routera-primary self-start ${!ability.can('create', 'setting.user.team') && 'cursor-not-allowed'
                }`}
              onClick={() => { setShowModalTeam(true) }}
            >
              <i className='fa-solid fa-plus text-white inline-block mr-2 text-[14px]'></i>
              <span className='ml-2 inline-block  text-[14px]'>Add Team</span>
            </button>
          </div>
        </div>)}
        <div className=''>
          {userTab ? (
            <SelectableTable
              columns={columns}
              rows={rowsToShow}
              initialSelection={[]}
              selectionType={SELECTION_MODES.SELECT_MANY}
              showHeaderMeta={true}
              showSelectionType={true}
              borderTable={true}
              onSearchChange={onSearchTextChange}
              onDelete={handleDelete}
              addUsersTeam={handleAddUsers}
              permissions={{
                delete: ability.can('delete', 'setting.user.team'),
                update: ability.can('update', 'setting.user.team'),
              }}
            />
          ) : (
            <SelectableTable
              columns={columns}
              rows={rowsToShow}
              initialSelection={selectedTeams}
              selectionType={SELECTION_MODES.SELECT_MANY}
              onSearchChange={onSearchTextChange}
              onSelectedTeams={onTeams}
              isRowSelected={isTeamSelected}
              permissions={{
                update: ability.can('update', 'setting.user.team'),
              }}
            />
          )}
        </div>
        {userTab && showAddUsersModal && (
          <AddUsersModal
            show={showAddUsersModal}
            setShow={setShowAddUsersModal}
            users={currentUsers}
            teams={currentTeams}
            isManagerTeam={isManagerTeam}
          />
      )}
      </div>
    </>
  )
}

export default TeamTable
