import { ProfileProps } from 'src/types/profile'
import DatePicker from 'react-datepicker'
import IconRoutera from 'src/components/atoms/icons/icons'

const DateRange = (props: ProfileProps) => {
  const {
    valueStartDate,
    valueEndDate,
    minDate,
    onChangeStartDate,
    onChangeEndDate,
    onClick,
    permissions,
  } = props

  const today: Date = new Date()
  const tomorrow: Date = new Date()
  today.setDate(today.getDate())
  tomorrow.setDate(tomorrow.getDate() + 1)

  return (
    <div className='flex flex-wrap items-center py-1 mt-2'>
      <div className='flex items-center'>
        <div className='w-[38px] h-10 mt-3 mr-3 font-normal text-[14px] text-gray-700'>From</div>
        <div className='w-[225px]'>
          <DatePicker
            selected={valueStartDate}
            onChange={onChangeStartDate}
            minDate={minDate ? today : null}
            disabled={!permissions?.update}
            showTimeSelect
            value={valueStartDate
              .toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour12: true,
                hour: "numeric",
                minute: "2-digit"
              })
              .toString()}
            className={`rounded w-[100%] text-sm tablet:text-xs text-center h-[39px] tablet:h-[29px] text-gray-500 tablet:p-1 tablet:px-1 bg-white border border-gray-300 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${!permissions?.update ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          />
        </div>
      </div>
      <div className='flex items-center'>
        <div className='w-[50px] h-10 mt-3 text-center font-normal text-[14px] text-gray-700'>to</div>
        <div className='w-[225px]'>
          <DatePicker
            selected={valueEndDate}
            minDate={minDate ? tomorrow : null}
            disabled={!permissions?.update}
            showTimeSelect
            value={valueEndDate
              .toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour12: true,
                hour: "numeric",
                minute: "2-digit"
              })
              .toString()}
            onChange={onChangeEndDate}
            className={`rounded w-[100%] text-sm tablet:text-xs text-center h-[39px] tablet:h-[29px] text-gray-500 tablet:p-1 tablet:px-1 bg-white border border-gray-300 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${!permissions?.update ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          />
        </div>
        <div
          onClick={!permissions?.update ? () => { return } : onClick}
          className={`text-2xl w-8 h-8 rounded-full flex items-center justify-center text-slate-500 peer-checked:bg-slate-100 peer-checked:text-slate-900 ml-1 ${!permissions?.update ? 'cursor-not-allowed' : 'hover:cursor-pointer hover:bg-slate-200'}`}
        >
          <IconRoutera
            iconName='TrashIcon'
            className='h-[21px] w-[19px] text-gray-500'
          />
        </div>
      </div>
    </div>
  )
}

export default DateRange
