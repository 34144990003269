import Chart from 'react-apexcharts'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import DropdownCustom, {
  defaultOptionRenderer,
} from 'src/components/atoms/Dropdown/DropdownCustom.atom'
import { useEffect, useState } from 'react'
import { FrequencyType, RoutedOverview } from 'src/types/dashboard'
import { routedOverviewData } from 'src/container/dashboard/dashboard.selector'
import { useAppSelector } from 'src/store'
import IconRoutera from 'src/components/atoms/icons/icons'
import moment from 'moment'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'

interface IProps {
  objectType: HUBSPOT_OBJECT_TYPES
  fraquencyOptions: FrequencyType[]
  setSelectedFrequencyValue: (range: string) => void
  selectedFrequency: FrequencyType
  setSelectedFrequency: (frequency: FrequencyType) => void
}

const ChartRoutedOverview: React.FC<IProps> = (props) => {
  const {
    objectType,
    fraquencyOptions,
    setSelectedFrequencyValue,
    selectedFrequency,
    setSelectedFrequency,
  } = props

  const routedOverview = useAppSelector(routedOverviewData)
  const [seriesData, setSeriesData] = useState<number[]>([0])
  const [categoriesData, setCategoriesData] = useState<string[]>([''])
  const [noData, setNoData] = useState<boolean>(false)
  const [columnWidth, setColumnWidth] = useState<string>('78%')
  const ability = useAbility(AbilityContext)

  useEffect(() => {
    const labels: string[] = []
    const values: number[] = []
    let frequency = ''
    setNoData(false)

    if (selectedFrequency.value === 'weekly') {
      frequency = 'Week '
    } else if (selectedFrequency.value === 'monthly') {
      frequency = 'Month'
    } else if (selectedFrequency.value === 'yearly') {
      frequency = 'Year '
    }

    if (routedOverview.length > 0) {
      const objectData: any = [...routedOverview]
      objectData.sort((a: RoutedOverview, b: RoutedOverview) => (new Date(a.date) < new Date(b.date)) ? -1 : 1)

      objectData.map((item: RoutedOverview) => {
        if (frequency === 'Month') {
          labels.push(moment().month(item.date).format('MMMM'))
        } else {
          labels.push(frequency + item.date)
        }
        values.push(item.total)
      })
      if (objectData.length === 1) {
        setColumnWidth('25%')
      } else if (objectData.length < 4) {
        setColumnWidth('45%')
      } else {
        setColumnWidth('75%')
      }
    } else {
      setNoData(true)
    }
    setSeriesData(values)
    setCategoriesData(labels)
  }, [routedOverview])

  const borderRadius: 'around' | 'end' = 'end'

  const series = [
    {
      data: seriesData,
      name:
        objectType[0].toUpperCase() +
        objectType.slice(1).toLowerCase() +
        ' Routed',
    },
  ]

  const options = {
    chart: {
      id: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: columnWidth,
        endingShape: 'rounded',
        borderRadius: 10,
        borderRadiusApplication: borderRadius,
        dataLabels: {
          position: 'top',
        },
      },
    },
    colors: ['#008AFF'],
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#111928'],
        fontWeight: 500,
        fontFamily: 'inter',
      },
    },
    grid: { show: false },
    xaxis: {
      categories: categoriesData,
      labels: {
        style: {
          colors: '#9CA3AF',
          fontSize: '14px',
          fontFamily: 'inter',
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#9CA3AF'],
          fontSize: '14px',
          fontFamily: 'inter',
          fontWeight: 400,
        },
      },
    },
    fill: {
      opacity: 1,
    },
  }

  const getFrequencyOption = (selectedFrequencyOption: any): FrequencyType => {
    const valueFind = fraquencyOptions.find(
      (opt: any) => opt.value === selectedFrequencyOption.value,
    )

    if (!valueFind) {
      return fraquencyOptions[0]
    }
    return valueFind
  }

  const handleFrequencyChange = (option: any) => {
    setSelectedFrequency(option)
    setSelectedFrequencyValue(option.value)
  }

  return (
    <div className='bg-white mt-4 p-3 flex flex-col shadow-sm rounded-lg mx-4'>
      <div className='flex items-center justify-between border-b-[1px] border-gray-200 pb-4 mb-3 '>
        <span className='text-gray-900 text-2xl font-bold leading-[30px] capitalize m-4'>
          {objectType} Routed Overview
        </span>
        <DropdownCustom
          dropdownLabel='Frequency'
          options={fraquencyOptions}
          selectedOption={getFrequencyOption(selectedFrequency)}
          setSelectedOption={handleFrequencyChange}
          optionRenderer={defaultOptionRenderer}
          classInput='h-[46px] rounded-[8px] mt-1'
          customClass='mr-8'
        />
      </div>
      <div className='relative'>
        {noData && (
          <div className='flex flex-col items-center absolute w-[100%] top-[200px]'>
            <span className='text-gray-700 text-3xl font-bold mb-2 mt-2'>
              No data*
            </span>
            <div className='bg-routera-50 py-[2px] px-[10px] rounded-md w-fit mt-2 z-10'>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://help.routera.io/article/5-create-a-router'
              >
                <span className='flex text-routera-700 text-xs font-medium leading-[18px] items-center cursor-pointer'>
                  <IconRoutera
                    iconName='InformationCircleIcon'
                    className='w-[14px] h-[14px] mr-1'
                  />
                  How does this graph work?
                  <IconRoutera
                    iconName='ArrowRightIcon'
                    className='w-[14px] h-[14px] ml-1'
                  />
                </span>
              </a>
            </div>
          </div>
        )}
        <Chart
          id='bar'
          options={options}
          series={series}
          type='bar'
          width='98%'
          height={600}
        />
      </div>
    </div>
  )
}

export default ChartRoutedOverview
