import { defineAbility } from '@casl/ability'
import { createContext } from 'react'
import { createContextualCan } from '@casl/react'
import { getPermissionsUser } from 'src/utils/localstorage'
import { PermissionsType } from 'src/types/roles'

export const ability = defineAbility((can) => {
  const permissions = getPermissionsUser()
  if (permissions) {
    permissions.forEach((element: PermissionsType) => {
      can(element.action, element.subject)
    })
  }
})

export const getAbilities = async (getUserPermissionsAction: any) => {
  return defineAbility(async (can) => {
    const response = await getUserPermissionsAction()
    if (!response.payload.data) {
      return null
    }
    const permissions: PermissionsType[] = response.payload.data.permissions.role[0].role.permissions
    permissions.forEach((element: PermissionsType) => {
      can(element.action, element.subject)
    })
  });
}

export const AbilityContext = createContext(ability)
export const Can = createContextualCan(AbilityContext.Consumer)