import Tabs from 'src/components/molecules/tabs/tabs'
import RolesList from 'src/components/organisms/rolesList/rolesList'
import { DataTab } from 'src/types/tabs'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'

const RolesIndex = () => {

	const ability = useAbility(AbilityContext)

	if (!ability.can('access', 'setting.role')) {
		return <NoPermission value='access' />
	} else {
		if (!ability.can('read', 'setting.role')) {
			return <NoPermission value='read' />
		}
	}

	const dataTabs: DataTab[] = [
		{
			label: 'Role Management',
			id: 'roles-list',
			dataContentTab: <RolesList />
		},
	]

	return (
		<section className='border-l border-gray-200'>
			<div className='p-4 px-5 bg-gray-100'>
				<h3 className='text-2xl block'>Settings</h3>
			</div>
			<Tabs dataTabs={dataTabs} classContentTab='min-h-[calc(100vh-201px)] bg-routera-gray' />
		</section>
	)
}

export default RolesIndex