import React from 'react'
import Slider from '../../molecules/Slider/Slider.molecule'
import CreateRuleForm from './createRuleForm'

interface IProps {
  showSlider: boolean
  setShowSlider: (value: boolean) => void
  hasData: boolean
  currRuleEditData: any
  disabled?: boolean
}

const CreateRule: React.FC<IProps> = ({
  showSlider = false,
  setShowSlider,
  hasData = false,
  currRuleEditData,
  disabled = false
}) => {
  return (
    <div className='h-full'>
      {!hasData && (
        <div className='py-6 sm:px-0'>
          <div className='h-[50vh] rounded-lg border-4 border-dashed border-gray-200 text-center justify-center items-center flex'>
            <div>
              <div className=''>
                <span className='text-lg font-medium flex-grow-1'>
                  No Account Matching Created
                </span>
              </div>
              <button
                onClick={disabled ? () => { return } : (() => setShowSlider(true))}
                disabled={disabled}
                className={`icon-btn mt-3 w-42 px-4 py-[12px] bg-routera-700 rounded text-white ${disabled && 'cursor-not-allowed'}`}
              >
                Create Account Matching
              </button>
            </div>
          </div>
        </div>
      )}
      <Slider showSlider={showSlider} setShowSlider={setShowSlider}>
        <CreateRuleForm
          setShowSlider={setShowSlider}
          currRuleEditData={currRuleEditData}
        />
      </Slider>
    </div>
  )
}

export default CreateRule
