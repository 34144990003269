interface IProps {
	className?: string
	value: number
	total: number
	heightBarClass?: string
	changesBarColors?: boolean
	backgroundColor?: string
	numberIsPercentage?: boolean
}

const PercentageBar: React.FC<IProps> = (props) => {

	const {
		className,
		value,
		total,
		heightBarClass,
		changesBarColors,
		numberIsPercentage = false
	} = props

	let percentage = 100

	if (total && !numberIsPercentage) {
		percentage = (value * 100) / total
	}

	if (numberIsPercentage) {
		percentage = value
	}

	let bgColor = 'bg-routera-600'
	if (changesBarColors) {
		if (percentage <= 24) {
			bgColor = 'bg-orange-400'
		} else if (percentage >= 25 && percentage <= 49) {
			bgColor = 'bg-yellow-300'
		} else if (percentage >= 50 && percentage <= 74) {
			bgColor = 'bg-routera-700'
		} else {
			bgColor = 'bg-green-500'
		}
	}

	if (value > total) {
		bgColor = 'bg-red-500'
	}

	return <div className={className}>
		<div className={`w-full bg-gray-200 rounded-full dark:bg-gray-700 ${heightBarClass ? heightBarClass : 'h-2.5'}`}>
			<div
				className={`rounded-full ${heightBarClass ? heightBarClass : 'h-2.5'} ${bgColor}`}
				style={{ width: total === 0 ? 0 : (percentage > 100 ? 100 : percentage) + '%' }}
			/>
		</div>
	</div>
}

export default PercentageBar