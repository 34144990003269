import ComboBox from 'src/components/molecules/inputs/comboBox/ComboBox.Molecule'
import { User } from './types'

type props = {
  selectedUsers: User[]
  initialSelectedUserId?: string
  handleDefaultUserChange: any
  disabled?: boolean
}

const DefaultUserSelection: React.FC<props> = ({
  selectedUsers,
  initialSelectedUserId,
  handleDefaultUserChange,
  disabled = false,
}) => {
  const userLabels = selectedUsers.map(
    (user) => user.firstName + ' ' + user.lastName,
  )

  const initialMappedSelectedUser = selectedUsers.find(
    (user) => initialSelectedUserId === user._id,
  )

  const options = {
    label: '',
    description: '',
    modelName: 'name',
    initialValue: initialMappedSelectedUser
      ? initialMappedSelectedUser?.firstName +
      ' ' +
      initialMappedSelectedUser?.lastName
      : 'Choose a user',
    options: userLabels,
    isDisabled: false,
    handleOnSubmit: (model: Record<string, any>) => {
      const selectedUser = selectedUsers?.find(
        ({ firstName, lastName }) =>
          firstName + ' ' + lastName === model['name'],
      )
      if (selectedUser) {
        handleDefaultUserChange(selectedUser)
      } else {
        handleDefaultUserChange(null)
      }
    },
  }

  if (selectedUsers.length) {
    return (
      <div>
        <ComboBox {...options} disabled={disabled} />
      </div>
    )
  }
  return <></>
}

export default DefaultUserSelection
