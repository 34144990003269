import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'src/store'
import { selectUser } from 'src/container/auth/auth.selector'
import toastBuilder from 'src/utils/toastBuilder'
import Tabs from 'src/components/molecules/tabs/tabs'
import { DataTab } from 'src/types/tabs'
import { useActions } from 'src/actions'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'

const Account = () => {

  const ability = useAbility(AbilityContext)

  if (!ability.can('read', 'setting.account')) {
    return <NoPermission value='read' />
  }

  const user = useAppSelector(selectUser)
  const { updateuserDataAction } = useActions()

  const [initalValue, setinitalValue] = useState({
    firstName: '',
    lastName: '',
    phone: '+1',
    companyName: '',
  })

  useEffect(() => {
    setinitalValue({
      firstName: user.firstName,
      lastName: user.lastName,
      companyName: user.companyName,
      phone: user.phone ? user.phone : '+1',
    })
  }, [user])

  const handleFormSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any,
  ) => {
    const action: any = await updateuserDataAction({
      ...formValues,
    })

    if (action.payload.success) {
      toastBuilder('success', 'Profile information updated successfully')
      setSubmitting(false)
      resetForm()
    }
  }

  const dataTabs: DataTab[] = [
    {
      label: 'Account Setting',
      id: 'account-setting',
      dataContentTab: (
        <>
          <div className='accountform mb-2 mt-0'>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initalValue}
              enableReinitialize={true}
            >
              {({ handleSubmit, handleChange, handleBlur, values, errors }) => {
                return (
                  <form
                    className='settingform'
                    onSubmit={handleSubmit}
                    id='accountSetting'
                  >
                    <div className='bg py-0'>
                      <div className='px-5 p-4'>
                        <div className='col-md-12 mb-4'>
                          <label
                            htmlFor='companyName'
                            className='block mb-2 text-gray-800 dark:text-white'
                          >
                            <span className='text-[14px] font-medium'>Company Name</span>
                          </label>
                          <input
                            className={`block h-[45px] w-[100%] lg:w-[45%] md:w-[50%] appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${!ability.can('update', 'setting.account') && 'cursor-not-allowed'}`}
                            type='text'
                            name='companyName'
                            value={values.companyName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!ability.can('update', 'setting.account')}
                          />
                          {errors.companyName && (
                            <span
                              id='companyName'
                              className='text-[red] text-[9px] capitalize'
                            >
                              {errors.companyName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                )
              }}
            </Formik>
          </div>
        </>
      ),
    },
  ]

  return (
    <section className='border-l border-gray-200'>
      <div className='p-4 px-5 bg-gray-100'>
        <h3 className='text-2xl block'>Settings</h3>
      </div>
      <Tabs dataTabs={dataTabs} />
      <div className='pt-[11px] -my-[12px] px-4 bg-white'>
        <button
          type='submit'
          form='accountSetting'
          disabled={!ability.can('update', 'setting.account')}
          className={`py-[12px] px-[24px] rounded-lg bg-routera-primary d-block ms-auto w-[143px] text-white ${!ability.can('update', 'setting.account') && 'cursor-not-allowed'}`}
        >
          Save
        </button>
      </div>
    </section>
  )
}

export default Account
