import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { RootState } from 'src/store'
const selectedreducer = (state: RootState) => state.teamsState

export const listAllTeams = createDraftSafeSelector(
	selectedreducer,
	(teamsS) => teamsS.allTeams,
)

export const specificTeamById = createDraftSafeSelector(
	selectedreducer,
	(rolesS) => rolesS.specificTeamDetail,
)
