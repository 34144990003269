import { useEffect, useState } from 'react'
import { useActions } from 'src/actions'
import { useAppSelector } from 'src/store'
import IconRoutera from 'src/components/atoms/icons/icons'
import FilterIcon from 'src/assets/images/filter-icon.svg'
import SelectableTable, {
  TableColumn,
  SELECTION_MODES,
} from 'src/components/organisms/tables/SelectableTable.Organism'
import { Link } from 'react-router-dom'
import { timeformater } from 'src/utils'
import { useNavigate } from 'react-router-dom'
import { Conversions } from 'src/types/conversions'
import { uselistofAllConversions } from 'src/container/conversions/conversions.selector'
import toastBuilder from 'src/utils/toastBuilder'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'

const ConversionsLits = () => {
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const navigation = useNavigate()
  const {
    createConversion,
    getConversionsList,
    deleteConversion,
    updateConversion,
  } = useActions()
  const [loadingCreateConversion, setLoadingCreateConversion] = useState(false)
  const conversionsList = useAppSelector(uselistofAllConversions)
  const [rowsToShow, setRowsToShow] = useState<Conversions[]>(conversionsList)

  const ability = useAbility(AbilityContext)

  useEffect(() => {
    getConversionsList()
  }, [])

  useEffect(() => {
    setRowsToShow(conversionsList)
  }, [conversionsList])

  const columnsInConverionsTable: TableColumn[] = [
    {
      label: 'Status',
      field: 'conversionStatus',
      render: ({ is_active }: any) => {
        return (
          <div className='mb-0 name-with_status'>
            {is_active ? (
              <div className='py-[4px] px-[12px] bg-green-100 flex items-center rounded-[99px]'>
                <div className='bg-green-500 w-[12px] h-[12px] rounded mr-2' />
                <span className='text-green-800 text-xs font-medium'>
                  Published
                </span>
              </div>
            ) : (
              <div className='py-[4px] px-[12px] bg-red-100 flex items-center rounded-[99px]'>
                <div className='bg-red-500 w-[12px] h-[12px] rounded mr-2' />
                <span className='text-red-800 text-xs font-medium'>Draft</span>
              </div>
            )}
          </div>
        )
      },
    },
    {
      label: 'Name',
      render: ({ _id, name }: any) => {
        return (
          <div>
            <div className='mb-0 name-with_status' style={{ color: '#44444f' }}>
              <Link to={`/conversions/${_id}?tab=conversions-type`}>
                <div className='text-blue-500'>{name}</div>
              </Link>
            </div>
          </div>
        )
      },
    },
    {
      label: 'Type',
      field: 'type',
      render: ({ conversionRule }) => {
        return (
          <div className='capitalize'>
            {conversionRule && conversionRule.objectType
              ? conversionRule.objectType
              : '-'}
          </div>
        )
      },
    },
    {
      label: 'Updated at',
      render: ({ updatedAt }: any) => {
        return <div>{timeformater(updatedAt)}</div>
      },
    },
  ]

  const addConversion = async () => {
    setLoadingCreateConversion(true)
    let idConversion = ''
    const result: any = await createConversion({})
    if (result.payload.success) {
      idConversion = result.payload.data._id
      navigation(`/conversions/${idConversion}?tab=conversions-type`)
    } else {
      setLoadingCreateConversion(false)
    }
  }

  const handleDelete = async (conversionsListDelete: Array<any>) => {
    const data: string[] = conversionsListDelete.map(
      (conversion) => conversion._id,
    )
    const response: any = await deleteConversion(data)
    if (response.payload.success) {
      toastBuilder('success', 'The item(s) were removed')
      getConversionsList()
    }
  }

  const handleUnpublish = async (conversionsListUnPublish: Array<any>) => {
    const data = {
      is_active: false,
    }
    for (const conversion of conversionsListUnPublish) {
      await updateConversion({ id: conversion._id, data })
    }
    toastBuilder(
      'success',
      conversionsListUnPublish.length +
        ' Conversion(s) unpublished successfully',
    )
    getConversionsList()
  }

  const onSearchTextChange = (searchText: string) => {
    setIsSearch(true)
    if (searchText === '') {
      setRowsToShow(conversionsList)
      setIsSearch(false)
      return
    }
    setRowsToShow(
      conversionsList.filter((conversion) =>
        conversion.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    )
  }

  const createConversionButton: JSX.Element = (
    <button
      className={`flex flex-row items-center justify-center px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start ${
        loadingCreateConversion && 'w-[191px] h-[46px]'
      } ${!ability.can('create', 'conversion') && 'cursor-not-allowed'}`}
      onClick={addConversion}
      disabled={loadingCreateConversion || !ability.can('create', 'conversion')}
    >
      {!loadingCreateConversion ? (
        <>
          <IconRoutera
            iconName='PlusSmallIcon'
            className='w-[19px] mr-2 mt-[2px]'
          />{' '}
          Create Conversion
        </>
      ) : (
        <div className='spinner-border spinner-border-sm' role='status' />
      )}
    </button>
  )

  return (
    <div className='px-4 mb-5 min-h-[calc(100vh-275px)]'>
      {/* <div className='flex flex-col border-dashed border-4 border-gray-200 rounded-lg align-items-center justify-center mt-12 py-16 h-[70vh]'>
        <img
          src={FilterIcon}
          alt='filter'
          className='w-[30px] h-[30px] mb-2'
        />
        <span className='font-semibold text-sm leading-[21px] mb-2 text-gray-500'>
          Conversions - In maintenance
        </span>
      </div> */}
      {rowsToShow.length > 0 || isSearch ? (
        <div className='mt-2 w-full relative'>
          <div className='section-header w-full flex justify-end'>
            <div className='flex items-center justify-between w-full mobile:flex-col'>
              <span className='text-gray-700 text-[18px] font-medium leading-[27px] mr-4 mobile:mb-3 mobile:w-full mobile:px-3'>
                Conversions List
              </span>
              {createConversionButton}
            </div>
          </div>
          <div>
            <SelectableTable
              columns={columnsInConverionsTable}
              rows={rowsToShow}
              initialSelection={[]}
              selectionType={SELECTION_MODES.SELECT_MANY}
              onSearchChange={onSearchTextChange}
              onDelete={handleDelete}
              unpublish={handleUnpublish}
              permissions={{
                delete: ability.can('delete', 'conversion'),
                unPublish: ability.can('update', 'conversion'),
                update: true,
              }}
            />
          </div>
        </div>
      ) : (
        <div className='flex flex-col border-dashed border-4 border-gray-200 rounded-lg align-items-center justify-center mt-12 py-16 h-[70vh]'>
          <img
            src={FilterIcon}
            alt='filter'
            className='w-[30px] h-[30px] mb-2'
          />
          <span className='font-semibold text-sm leading-[21px] mb-2 text-gray-500'>
            No Conversions Created
          </span>
          <span className='font-semibold text-sm leading-[21px] uppercase mb-4 text-gray-500'>
            Make sure you have done the integration first
          </span>
          <div className='mx-auto'>{createConversionButton}</div>
        </div>
      )}
    </div>
  )
}

export default ConversionsLits
