import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { orderBy } from 'lodash'

import SearchInput from 'src/components/molecules/inputs/search/Search.Molecule'
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog.Organism'
import Pagination, {
  Props as PaginationProps,
} from 'src/components/organisms/pagination/Pagination.Organism'
import { getcurrentuser } from 'src/utils/localstorage'
import IconRoutera from 'src/components/atoms/icons/icons'
import { Switch } from '@headlessui/react'
import PercentageBar from 'src/components/atoms/percentageBar/percentageBar'
import SelectItems, {
  ItemsTableProps,
} from 'src/components/atoms/selectItems/selectItems'
import { ModalFB } from 'src/components/atoms/Modal/ModalFB.atom'
import { RolesListType } from 'src/types/roles'
import { IOption } from 'src/types/Ihome_state'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export interface TableColumn {
  label: string
  field?: string
  className?: string | string[]
  render?: (props: any) => JSX.Element
  colName?: string
  canSort?: boolean
}

export enum SELECTION_MODES {
  SELECT_MANY = 'select-many',
  SELECT_ONE = 'select-one',
}

export type OnSelectionChange = (selection: any | any[]) => void
export type OnRowClick = (row: Record<string, any>) => void

interface Props {
  columns: TableColumn[]
  rows: Record<string, any>[]
  initialSelection?: Record<string, any> | Record<string, any>[]
  onSelectionChange?: OnSelectionChange
  onRowClick?: OnRowClick
  pagination?: PaginationProps
  selectionType?: SELECTION_MODES
  showSelectionType?: boolean
  onSearchChange?: (searchText: string) => void
  onDelete?: (...args: any) => void
  isRowSelected?: (row: any, selected?: boolean, rows?: any) => boolean
  showHeaderMeta?: boolean
  sort?: boolean
  unpublish?: (...args: any) => void
  addRole?: {
    handleAddRole: (...args: any) => void
    rolList: RolesListType[] | undefined
    changeSelectType: (...args: any) => void
    changeValue: string
    setUsersPermissions: (val: string[]) => void
  }
  seats?: number
  users?: number
  objectType?: string | number | undefined
  isEnable?: boolean
  borderTable?: boolean
  limitItems?: ItemsTableProps
  loadingTable?: boolean
  className?: string
  permissions?: {
    delete?: boolean
    add?: boolean
    unPublish?: boolean
    update?: boolean
  }
  pipelineType?: IOption
  noItemsCheck?: string[]
  orderBySLA?: {
    setOrderBySLADetail: (value: number) => void,
    orderBySLADetail: number
  } | undefined
  addUsersTeam?:  (...args: any) => void
  onSelectedTeams?: (...args: any) => void
}

const CONSTANTS = {
  COLORS: {
    BG_PRIMARY: 'bg-blue-600',
    BG_SECONDARY: 'bg-blue-500',
    TEXT_PRIMARY: 'text-blue-600',
    RING_SECONDARY: 'ring-blue-500',
  },
}

export const SelectableTable: React.FC<Props> = ({
  columns,
  rows,
  initialSelection,
  onSelectionChange,
  pagination,
  selectionType = SELECTION_MODES.SELECT_MANY,
  onSearchChange,
  onDelete,
  onRowClick,
  isRowSelected,
  showHeaderMeta = true,
  sort = false,
  unpublish,
  addRole,
  seats,
  users = 5,
  objectType,
  isEnable = false,
  showSelectionType = true,
  borderTable = true,
  limitItems,
  loadingTable = false,
  className,
  permissions,
  noItemsCheck = [],
  orderBySLA = undefined,
  pipelineType,
  addUsersTeam,
  onSelectedTeams
}) => {
  const checkbox = useRef<any>()
  const [checked, setChecked] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [indeterminate, setIndeterminate] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [rowsToShow, setRowsToShow] = useState<any[]>(rows)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false)
  const [showUnpublishConfirmationDialog, setShowUnpublishConfirmationDialog] =
    useState(false)

  const isSelectionMany = useMemo(
    () => selectionType === SELECTION_MODES.SELECT_MANY,
    [selectionType],
  )

  const [selectedRows, setSelectedRows] = useState<any[]>(
    isSelectionMany && Array.isArray(initialSelection) ? initialSelection : [],
  )

  const [checkRows, setCheckRows] = useState<any[]>(
    isSelectionMany && Array.isArray(initialSelection) ? initialSelection : [],
  )
  const [selectedRow, setSelectedRow] = useState<any>(
    !isSelectionMany ? initialSelection : null,
  )

  const resetSelection = isSelectionMany
    ? () => setSelectedRows([])
    : () => setSelectedRow(null)

  useEffect(() => {
    if (isSelectionMany && initialSelection) {
      setSelectedRows(initialSelection as Record<string, any>[])
      setCheckRows(initialSelection as Record<string, any>[])
    } else {
      setSelectedRow(initialSelection || null)
    }
  }, [initialSelection])

  useEffect(() => {
    setRowsToShow(rows || [])
  }, [rows])

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedRows.length > 0 && selectedRows.length < rowsToShow.length
    setChecked(
      selectedRows.length !== 0 &&
      rowsToShow.length !== 0 &&
      selectedRows.length === rowsToShow.length,
    )
    setIndeterminate(isIndeterminate)
    if (checkbox?.current?.indeterminate) {
      checkbox.current.indeterminate = isIndeterminate
    }
  }, [selectedRows])

  const toggleAll = () => {

    let rowsAux = rowsToShow.filter((row) => row._id !== getcurrentuser()._id)

    if (noItemsCheck.length > 1) {
      rowsAux = rowsToShow.filter((row) => !noItemsCheck.includes(row.role.id))
    } else {
      rowsAux = rowsToShow
    }

    if (isSelectionMany && rowsAux.length > 0) {
      setSelectedRows(
        checked || indeterminate ? [] : rowsAux.filter((row) => !row?.disabled),
      )
      setChecked(!checked && !indeterminate)
      setIndeterminate(false)
    }
  }

  const getIsRowSelected = (row: any) => {
    if (isSelectionMany) {
      return isRowSelected ? isRowSelected(row) : selectedRows.includes(row)
    } else {
      return isRowSelected ? isRowSelected(row) : selectedRow === row
    }
  }

  const changeRow = (event: any, index: number) => {
    if (objectType) {
      const capacitiesClone = structuredClone(rowsToShow[index].capacities)
      if (objectType === 'contacts') {
        capacitiesClone.contacts.max = event.target.valueAsNumber
      } else {
        if (pipelineType && pipelineType.value && pipelineType.value in capacitiesClone.deals) {
          capacitiesClone.deals[pipelineType.value].max = event.target.valueAsNumber
        }
      }
      rowsToShow[index].capacities = capacitiesClone
      const rowsToShowClone = structuredClone(rowsToShow)

      setRowsToShow(rowsToShowClone)
    }
  }

  useEffect(() => {
    if (onSelectionChange) {
      if (isSelectionMany) {
        onSelectionChange(selectedRows)
      } else if (selectedRow) {
        onSelectionChange(selectedRow)
      }
    }

    if (onSelectedTeams) {
      if (isSelectionMany) {
        onSelectedTeams(selectedRows)
      }
      else if (selectedRow) {
        onSelectedTeams(selectedRow)
      }
    }
  }, [selectedRows, selectedRow])

  useEffect(() => {
    if (
      selectedRows &&
      isRowSelected &&
      selectedRows.length !== checkRows.length
    ) {
      isRowSelected(selectedRows[selectedRows.length - 1], true, selectedRows)
    }
  }, [selectedRows])

  useEffect(() => {
    onSearchChange && onSearchChange(searchText)
  }, [searchText])

  const onDeleteClick = () => setShowDeleteConfirmationDialog(true)
  const onUnpublishClick = () => setShowUnpublishConfirmationDialog(true)
  const handleDelete = () => {
    if (onDelete) {
      onDelete(isSelectionMany ? selectedRows : selectedRow)
      resetSelection()
      setShowDeleteConfirmationDialog(false)
    }
  }

  const handleUnpublish = () => {
    if (unpublish) {
      unpublish(isSelectionMany ? selectedRows : selectedRow)
      resetSelection()
      setShowUnpublishConfirmationDialog(false)
    }
  }

  const onSort = (colName: string) => {

    if (orderBySLA) {
      orderBySLA.setOrderBySLADetail(orderBySLA.orderBySLADetail * (-1))
      return
    }
    const rowsCopy = { ...rowsToShow }
    const sortedRows = orderBy(rowsCopy, [colName], [sortOrder])
    setRowsToShow(sortedRows)
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc')
  }

  const getIdsIdsItemsCheck = (data: any, index: number) => {
    if (noItemsCheck.length > 1) {
      return !noItemsCheck.includes(data.role.id)
    } else {
      return data._id !== getcurrentuser()._id
    }
  }

  const actionCloseAddRole = () => {
    setOpenModal(false)
  }

  const setPermissions = () => {
    setOpenModal(true)
    if (addRole && addRole.rolList) {
      const value = addRole.rolList.find(
        (item) => item.role?.name === addRole.changeValue,
      )

      if (value && value.role) {
        addRole.changeSelectType(
          value.role.name,
          isSelectionMany ? selectedRows : selectedRow,
        )
      }
    }
  }

  const handleCheckboxChange = (e: any, row : any) => {
    if (isSelectionMany) {
      let newSelectedRows;

      if (row._id) {
        newSelectedRows = selectedRows.find(selectedRow => selectedRow._id == row._id)
          ? selectedRows.filter(selectedRow => selectedRow._id != row._id)
          : [...selectedRows, row];
      } else {
        newSelectedRows = selectedRows.includes(row) 
        ? selectedRows.filter(selectedRow => selectedRow !== row) 
        : [...selectedRows, row];
      }

      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRow(e.target.checked ? row : null);
    }
  };

  const modalAddRole: JSX.Element = (
    <ModalFB
      id='openAddRole'
      titleModal='Add Role'
      buttonCancel={'Cancel'}
      className='max-w-[672px]'
      buttonSave={
        addRole && {
          action: addRole.handleAddRole,
          title: 'Add Role',
          disabled: false,
        }
      }
      actionClose={actionCloseAddRole}
      buttonOpen={
        <div>
          <button
            data-modal-target='openAddRole'
            data-modal-toggle='openAddRole'
            disabled={permissions ? !permissions.add : false}
            className={`${(isSelectionMany ? selectedRows.length > 0 : selectedRow)
              ? 'visible opacity-100'
              : 'invisible opacity-0'
              } transition-opacity duration-200 ease-in-out pl-4 flex justify-content-center items-center mb-2 ${permissions && !permissions.add && 'cursor-not-allowed'
              }`}
            onClick={setPermissions}
          >
            <IconRoutera
              iconName='KeyIcon'
              className='h-5 w-5 text-routera-700 ml-3 mr-2'
              aria-hidden='true'
            />
            <span className='mb-0 font-medium text-[15px] text-routera-700'>
              Add Role
            </span>
          </button>
        </div>
      }
    >
      <div className='p-0 mt-3'>
        {addRole && (
          <div>
            <span className='mb-2 capitalize font-medium text-[14.6px] inline-block text-gray-700'>
              Select Role
            </span>
            <div>
              <select
                id='countries'
                value={addRole.changeValue}
                className='bg-gray-50 border border-gray-300 text-gray-500 w-[250px] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5w-[400px] font-normal'
                onChange={(value) =>
                  addRole.changeSelectType(
                    value.target.value,
                    isSelectionMany ? selectedRows : selectedRow,
                  )
                }
              >
                {addRole.rolList &&
                  addRole.rolList.map((data: RolesListType) => (
                    <option key={data.role?.id} value={data.role?.name}>
                      {data.role?.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}
        <div className='bg-orange-100 rounded-md p-4 mt-4'>
          <div className='flex items-center'>
            <IconRoutera
              iconName='InformationCircleIcon'
              className='h-[16px] w-[16px] text-orange-500'
            />
            <span className='text-orange-500 ml-2 capitalize font-semibold text-sm'>
              Overwrite Warning
            </span>
          </div>
          <span className='text-orange-800 capitalize font-normal mt-1inline-block'>
            If the selected user already has a role assigned, it will be
            replacedby the selected one.
          </span>
        </div>
      </div>
    </ModalFB>
  )

  const handleAddUsersTeam = () => {
    if (addUsersTeam) {
      addUsersTeam(selectedRows, false)
    }
  }

  const handleAddManagersTeam = () => {
    if (addUsersTeam) {
      addUsersTeam(selectedRows, true)
    }
  }

  return (
    <div className='mt-8 flex flex-col'>
      <div className='-my-2 overflow-x-auto'>
        <div
          className={`inline-block align-middle w-full ${className ? className : 'py-2 md:px-6 lg:px-6'
            }`}
        >
          <div
            className={`relative overflow-x-auto sm:rounded-lg rounded-[8px] w-full ${borderTable ? 'border-gray-200 border-[1px]' : ''
              }`}
          >
            {!loadingTable ? (
              <>
                {showHeaderMeta && (
                  <div className={`text-gray-500 pl-4 pt-2 pb-2 bg-gray-50 ${!openModal ? 'sm:sticky sm:left-0' : 'relative'}`}>
                    <div className='flex justify-between items-center'>
                      <div className='flex justify-start items-end'>
                        {onSearchChange && (
                          <SearchInput
                            searchText={searchText}
                            setSearchText={setSearchText}
                          />
                        )}
                        <div className='flex pl-[10px] items-center mb-[20px]'>
                          {(isSelectionMany
                            ? selectedRows.length > 0
                            : selectedRow) && (
                              <div className='pl-4 mb-2 text-routera-700'>
                                <span className='text-[15px] font-medium flex justify-content-center items-center mb-0 normal-case'>
                                  {isSelectionMany ? selectedRows.length : 1}{' '}
                                  Selected
                                </span>
                              </div>
                            )}
                          {noItemsCheck.length > 0 && <div>
                            {addRole && modalAddRole}
                          </div>}
                          {onDelete && 
                            <button
                              className={`${(
                                isSelectionMany
                                  ? selectedRows.length > 0
                                  : selectedRow
                              )
                                ? 'visible opacity-100'
                                : 'invisible opacity-0'
                                } transition-opacity duration-200 ease-in-out pl-4 flex justify-content-center items-center mb-2 text-routera-700 normal-case ml-2 ${permissions &&
                                !permissions.delete &&
                                'cursor-not-allowed'
                                }`}
                              onClick={onDeleteClick}
                              disabled={
                                permissions ? !permissions.delete : false
                              }
                            >
                              <IconRoutera
                                iconName='TrashIcon'
                                className='h-[21px] w-[19px]'
                              />
                              <span className='ml-2 mb-0 font-medium text-[15px]'>
                                Delete
                              </span>
                            </button>
                          }
                          {addUsersTeam && 
                            <button
                              className={`${( selectedRows.length === 1 )
                                ? 'visible opacity-100'
                                : 'invisible opacity-0'
                                } transition-opacity duration-200 ease-in-out pl-4 flex justify-content-center items-center mb-2 text-routera-700 normal-case ml-2 ${permissions &&
                                !permissions.update &&
                                'cursor-not-allowed'
                                }`}

                              onClick={handleAddUsersTeam}
                              disabled={
                                permissions ? !permissions.update : false
                              }
                            >
                              <IconRoutera
                                iconName='UserPlusIcon'
                                className='h-[21px] w-[19px]'
                              />
                              <span className='ml-2 mb-0 font-medium text-[15px]'>
                                Add/Remove Users
                              </span>
                            </button>
                          }
                          {addUsersTeam && 
                            <button
                              className={`${( selectedRows.length === 1 )
                                ? 'visible opacity-100'
                                : 'invisible opacity-0'
                                } transition-opacity duration-200 ease-in-out pl-4 flex justify-content-center items-center mb-2 text-routera-700 normal-case ml-2 ${permissions &&
                                !permissions.update &&
                                'cursor-not-allowed'
                                }`}

                              onClick={handleAddManagersTeam}
                              disabled={
                                permissions ? !permissions.update : false
                              }
                            >
                              <IconRoutera
                                iconName='KeyIcon'
                                className='h-5 w-5 text-routera-700'
                                aria-hidden='true'
                              />
                              <span className='ml-2 mb-0 font-medium text-[15px]'>
                                Add/Remove Managers
                              </span>
                            </button>
                          }
                          {unpublish && (
                            <button
                              className={`${(
                                isSelectionMany
                                  ? selectedRows.length > 0
                                  : selectedRow
                              )
                                ? 'visible opacity-100'
                                : 'invisible opacity-0'
                                } transition-opacity duration-200 ease-in-out pl-4 flex justify-content-center items-center mb-2 ${(permissions && !permissions?.unPublish) &&
                                'cursor-not-allowed'
                                }`}
                              onClick={onUnpublishClick}
                              disabled={(permissions && !permissions?.unPublish)}
                            >
                              <i className='pr-2 fa-solid fa-low-vision text-xl'></i>
                              <span className='mb-0 font-medium text-[15px]'>
                                Unpublish
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                      {seats && (
                        <div className='w-[250px] my-4 mx-5'>
                          <div className='mb-2 flex justify-between'>
                            <span className='text-gray-500 leading-[18px] text-[12px] font-semibold'>
                              SEATS
                            </span>
                            <span className='text-gray-500 leading-[18px] text-[12px] font-medium'>
                              {users}/{seats}
                            </span>
                          </div>
                          <PercentageBar value={users} total={seats} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <table className='min-w-full w-full text-sm text-left text-gray-500'>
                  <thead
                    className={`text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded ${borderTable ? 'border-gray-200' : ''
                      }`}
                  >
                    <tr>
                      {showSelectionType &&
                        (isSelectionMany ? (
                          <th
                            scope='col'
                            className={`${showHeaderMeta && 'border-t'
                              } sticky top-0 z-10 border-b  border-gray-300 bg-gray-50 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8`}
                          >
                            {isEnable ? (
                              <span className='text-gray-500 text-xs leading-4'>
                                Enable
                              </span>
                            ) : (
                              <input
                                type='checkbox'
                                className={`absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 ${CONSTANTS.COLORS.TEXT_PRIMARY
                                  } focus:ring-0 focus:outline-none hover:cursor-pointer sm:left-${columns.length > 3 ? 4 : 6
                                  }`}
                                ref={checkbox}
                                checked={checked}
                                onChange={toggleAll}
                              />
                            )}
                          </th>
                        ) : (
                          <th
                            scope='col'
                            className='whitespace-nowrap px-3 w-10'
                          />
                        ))}
                      {columns.map((column, index) => (
                        <th
                          key={`${column.label}-${index}`}
                          scope='col'
                          className={`${column?.className} ${showHeaderMeta && 'border-t'
                            } sticky top-0 z-10 border-b border-gray-300 bg-gray-50 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6`}
                        >
                          <span className='flex items-center text-[12px] text-gray-500 font-semibold leading-[18px]'>
                            {column.label}
                            {((sort && !orderBySLA) || (column.canSort && orderBySLA)) && (
                              <span
                                onClick={() => {
                                  onSort &&
                                    column.colName &&
                                    onSort(column.colName)
                                }}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  className='w-3 h-3 ml-1 cursor-pointer'
                                  aria-hidden='true'
                                  fill='#9CA3AF'
                                  viewBox='0 0 320 512'
                                >
                                  <path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z' />
                                </svg>
                              </span>
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {rowsToShow.map((row, indexRow) => (
                      <tr
                        key={indexRow}
                        className={`${getIsRowSelected(row) ? 'bg-gray-50' : undefined
                          } ${row?.disabled && 'opacity-50'}`}
                      >
                        {showSelectionType && (
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell relative'>
                            {getIsRowSelected(row) && (
                              <div
                                className={`absolute inset-y-0 left-0 w-0.5 ${CONSTANTS.COLORS.BG_PRIMARY} focus:ring-0 focus:outline-none hover:cursor-pointer`}
                              />
                            )}
                            {noItemsCheck.length === 0 ? (
                              isEnable ? (
                                <div>
                                  <Switch
                                    checked={getIsRowSelected(row)}
                                    onChange={(val: boolean) => {
                                      isSelectionMany
                                        ? setSelectedRows(
                                          val
                                            ? [...selectedRows, row]
                                            : selectedRows.filter(
                                              (r) => r._id !== row._id,
                                            ),
                                        )
                                        : setSelectedRow(val ? row : null)
                                    }}
                                    className={classNames(
                                      getIsRowSelected(row)
                                        ? 'bg-[#0062FF]'
                                        : 'bg-gray-200',
                                      'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out cursor-pointer',
                                    )}
                                    disabled={row?.disabled || false}
                                  >
                                    <span className='sr-only'>Use setting</span>
                                    <span
                                      className={classNames(
                                        getIsRowSelected(row)
                                          ? 'translate-x-5'
                                          : 'translate-x-0',
                                        'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                      )}
                                    >
                                      <span
                                        className={classNames(
                                          getIsRowSelected(row)
                                            ? 'opacity-0 ease-out duration-100'
                                            : 'opacity-100 ease-in duration-200',
                                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                                        )}
                                        aria-hidden='true'
                                      ></span>
                                      <span
                                        className={classNames(
                                          getIsRowSelected(row)
                                            ? 'opacity-100 ease-in duration-200'
                                            : 'opacity-0 ease-out duration-100',
                                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                                        )}
                                        aria-hidden='true'
                                      ></span>
                                    </span>
                                  </Switch>
                                </div>
                              ) : (
                                showSelectionType && (
                                  <input
                                    type='checkbox'
                                    className={`absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 ${CONSTANTS.COLORS.TEXT_PRIMARY
                                      } focus:ring-0 focus:outline-none sm:left-${columns.length > 3 ? 4 : 6
                                      } ${(permissions && !permissions?.update)
                                        ? 'cursor-not-allowed'
                                        : 'hover:cursor-pointer'
                                      }`}
                                    value={row._id}
                                    checked={getIsRowSelected(row)}
                                    onChange={(e) => handleCheckboxChange(e,row)}
                                    disabled={
                                      row?.disabled ||
                                      false ||
                                      (permissions && !permissions?.update)
                                    }
                                  />
                                )
                              )
                            ) : (
                              getIdsIdsItemsCheck(row, indexRow) && (
                                <input
                                  type='checkbox'
                                  className={`absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 ${CONSTANTS.COLORS.TEXT_PRIMARY
                                    } focus:ring-0 focus:outline-none hover:cursor-pointer sm:left-${columns.length > 3 ? 4 : 6
                                    }`}
                                  value={row._id}
                                  checked={getIsRowSelected(row)}
                                  onChange={(e) => handleCheckboxChange(e,row)}
                                  disabled={row?.disabled || false}
                                />
                              )
                            )}
                          </td>
                        )}
                        {columns.map((column, index) => (
                          <td
                            key={`${index}-${column.label}`}
                            className={classNames(
                              'whitespace-nowrap py-3 pl-6 text-left text-sm text-gray-400 font-normal pr-[25px] border-gray-200',
                              getIsRowSelected(row)
                                ? `${CONSTANTS.COLORS.TEXT_PRIMARY}`
                                : 'text-gray-900',
                              onRowClick ? 'cursor-pointer' : '',
                            )}
                            onClick={() => (onRowClick ? onRowClick(row) : null)}
                            onChange={(event) => changeRow(event, indexRow)}
                          >
                            {column.render ? (
                              <column.render {...row} />
                            ) : column.field ? (
                              row[column.field]
                            ) : (
                              ''
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                    {rowsToShow.length < 1 && (
                      <tr className='bg-white'>
                        <td
                          colSpan={columns.length + 1}
                          className='text-center py-24 text-lg text-gray-500'
                        >
                          There is no data to display
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </>
            ) : (
              <div className='w-full flex justify-center h-[380px] items-center'>
                <svg
                  aria-hidden='true'
                  className='w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='currentColor'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentFill'
                  />
                </svg>
              </div>
            )}
            <div className='flex justify-between bg-gray-50 pr-4'>
              {limitItems && <SelectItems limitItems={limitItems} />}
              {pagination && rowsToShow.length > 0 && (
                <Pagination {...pagination} />
              )}
            </div>
            {showDeleteConfirmationDialog && (
              <ConfirmationDialog
                title='Delete'
                description={`Are you sure you want to delete ${isSelectionMany ? selectedRows.length : 1
                  } row${isSelectionMany && selectedRows.length > 1 ? 's' : ''}?`}
                onConfirm={handleDelete}
                isOpen={true}
                onDialogClose={() => setShowDeleteConfirmationDialog(false)}
                onCancel={() => {
                  setShowDeleteConfirmationDialog(false)
                }}
              />
            )}
            {showUnpublishConfirmationDialog && (
              <ConfirmationDialog
                title='Unpublish'
                description={`Are you sure you want to unpublish ${isSelectionMany ? selectedRows.length : 1
                  } row${isSelectionMany && selectedRows.length > 1 ? 's' : ''}?`}
                onConfirm={handleUnpublish}
                isOpen={true}
                onDialogClose={() => setShowUnpublishConfirmationDialog(false)}
                onCancel={() => {
                  setShowUnpublishConfirmationDialog(false)
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectableTable
