import React from 'react'
import { UseFormSetValue, FieldValues, Control } from 'react-hook-form'

import ElementPicker from './ElementPicker'

interface IProps {
  formJSON: Record<string, any>
  setValue: UseFormSetValue<FieldValues>
  control: Control<FieldValues, any>
}

const FormBuilder: React.FC<IProps> = ({ formJSON, setValue, control }) => {
  return (
    <>
      {formJSON?.fields &&
        formJSON.fields.map((field: any) => (
          <ElementPicker
            key={field.id}
            element={field}
            setValue={setValue}
            control={control}
          />
        ))}
    </>
  )
}

export default FormBuilder
