import React, { useEffect, useState } from 'react'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import {
  integrationsService,
  INTEGRATION_QUERY_KEYS,
} from 'src/services/integrations.service'
import toastBuilder from 'src/utils/toastBuilder'
import hobspotLogo from 'src/assets/images/hubspot-logo.svg'
import hubspotLogoPNG from 'src/assets/images/hubspot-logo.png'
import ComboBox from 'src/components/molecules/inputs/comboBox/ComboBox.Molecule'
import { useLocation, useNavigate } from 'react-router-dom'
import DropdownCustom, {
  defaultOptionRenderer,
} from 'src/components/atoms/Dropdown/DropdownCustom.atom'
import { IOption } from 'src/types/Ihome_state'
import IconRoutera from 'src/components/atoms/icons/icons'
import { ModalFB } from 'src/components/atoms/Modal/ModalFB.atom'
import { initFlowbite } from 'flowbite'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import { useActions } from 'src/actions'
interface IProps {
  onDisconnect?: () => void
}

const HubspotSettingsTemplate: React.FC<IProps> = ({ onDisconnect }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [propertyMapping, setPropertyMapping] = useState<
    Array<{ label: string; value: string; mappedValue: string }>
  >([])
  const [currStatusType, setCurrStatusType] = useState('')
  const [dealPipelineOptions, setDealPipelineOptions] = useState<IOption[]>([])
  const [pipelineType, setPipelineType] = useState<any>({})
  const [loadingChanges, setLoadingChanges] = useState<boolean>(false)

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const integrationId = params.get('integration')
  const { getPipelines } = useActions()

  const ability = useAbility(AbilityContext)

  if (!integrationId) {
    navigate({
      search: `?tab=integrations`,
    })
    return <></>
  }

  useEffect(() => {
    initFlowbite()
  }, [])

  const { isSuccess, isRefetching, data, refetch } = useQuery(
    [INTEGRATION_QUERY_KEYS.GET_PROPERTY_MAPPINGS],
    () => integrationsService.getPropertyMappings(integrationId || ''),
    { retry: false },
  )

  useEffect(() => {
    if (isSuccess && data?.data && currStatusType === 'Deal') {
      if (dealPipelineOptions.length > 0 && dealPipelineOptions[0]) {
        setPipelineType(dealPipelineOptions[0])
        const selectedPipeline = dealPipelineOptions[0].value || ''
        const mappingData = data?.data?.dealstage?.[selectedPipeline]
        setPropertyMapping(mappingData?.dealStages)
      }
    }
  }, [dealPipelineOptions])

  useEffect(() => {
    if (isSuccess && data?.data && currStatusType) {
      let mappingData
      if (currStatusType === 'Lead') {
        mappingData = data?.data?.hs_lead_status
      } else if (currStatusType === 'Deal') {
        if (pipelineType) {
          mappingData = data?.data?.dealstage?.[pipelineType.value]
        }
      }
      setPropertyMapping(mappingData)
      const pipeLineOptions: any = Object.keys(data?.data?.dealstage).map(
        (key) => {
          return {
            label: data?.data?.dealstage[key].label,
            value: data?.data?.dealstage[key].value,
            id: data?.data?.dealstage[key].value,
          }
        },
      )
      setDealPipelineOptions(pipeLineOptions)
    }
  }, [isSuccess, isRefetching, currStatusType])

  const updateIntegrationMutation = useMutation(
    integrationsService.updateIntegration,
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries([
          INTEGRATION_QUERY_KEYS.GET_PROPERTY_MAPPINGS,
        ])
        toastBuilder('success', 'Integration settings updated successfully!')
        setCurrStatusType('')
      },
      onError: (error) => {
        console.error('Error while updating integration settings', error)
        toastBuilder('error', 'Oops. Seems to be an error while')
      },
    },
  )

  const onSave = async () => {
    const payload: any = {}

    payload.mappingData = propertyMapping
    if (currStatusType === 'Lead') {
      payload.objectType = 'contacts'
      payload.propertyName = 'hs_lead_status'
    } else if (currStatusType === 'Deal') {
      payload.objectType = 'deals'
      payload.propertyName = 'dealstage'
      payload.pipeLineValue = pipelineType.value
    }

    updateIntegrationMutation.mutate({
      id: integrationId,
      dataToUpdate: payload,
    })
  }

  const onPipeLineChange = (option: IOption) => {
    setPipelineType(option)
    if (isSuccess && data?.data && currStatusType === 'Deal') {
      if (dealPipelineOptions.length > 0 && option.value) {
        const mappingData = data?.data?.dealstage?.[option.value]
        setPropertyMapping(mappingData?.dealStages)
      }
    }
  }

  const synchronize = async () => {
    setLoadingChanges(true)
    const resp: any = await getPipelines(integrationId)

    if (resp.payload.success && pipelineType) {
      const dataDeals = resp.payload.data.configuration_data.mapping_data.deals
      const mappingData = dataDeals.dealstage?.[pipelineType.value].dealStages
      setPropertyMapping(mappingData)
      const pipeLineOptions = Object.keys(dataDeals.dealstage).map((key) => {
        return {
          label: dataDeals.dealstage[key].label,
          value: dataDeals.dealstage[key].value,
          id: dataDeals.dealstage[key].value,
        }
      })
      setDealPipelineOptions(pipeLineOptions)
      if (data) {
        data.data.dealstage = dataDeals.dealstage
      }
    }
    setLoadingChanges(false)
  }

  return (
    <section className='mb-10'>
      <div className='header flex justify-start mobile:flex-wrap items-center gap-[40px] mobile:gap-[20px]'>
        <img className='p-1' src={hobspotLogo} alt='Hubspot Logo' />
        <button
          className={`bg-red-500 px-[20px] py-[10px] mt-4 text-white text-[14px] rounded-lg self-start ${!ability.can('delete', 'setting.integration') &&
            'cursor-not-allowed'
            }`}
          onClick={onDisconnect}
          disabled={!ability.can('delete', 'setting.integration')}
        >
          Disconnect
        </button>
      </div>
      <span className='block text-sm mt-[20px] text-gray-500 text-[16px]'>
        Settings to control your object assignments in HubSpot
      </span>
      <div className='mt-10 flex flex-col'>
        <ModalFB
          id='openLeadStatusMappins'
          titleModal={currStatusType + ' Status Mapping'}
          className='max-w-[672px]'
          buttonCancel={'Cancel'}
          buttonSave={{ action: onSave, title: 'Save', disabled: false }}
          buttonOpen={
            <button
              className={`bg-routera-700 text-white px-1 py-2 rounded mb-4 w-[200px] sm:w-[250px] flex items-center ${!ability.can('update', 'setting.integration') &&
                'cursor-not-allowed'
                }`}
              data-modal-target='openLeadStatusMappins'
              data-modal-toggle='openLeadStatusMappins'
              onClick={() => setCurrStatusType('Lead')}
              disabled={!ability.can('update', 'setting.integration')}
            >
              <IconRoutera
                iconName='PencilIcon'
                className='text-white mx-2 w-5 h-5'
              />
              Edit Lead Status Mappings
            </button>
          }
        >
          {currStatusType && (
            <>
              <section className='mapping-section mt-4'>
                <div className='status-mapping-list'>
                  {currStatusType &&
                    propertyMapping &&
                    propertyMapping.length &&
                    propertyMapping.map((mapping) => {
                      const mappingSelectionCombo = {
                        label: '',
                        modelName: mapping.value,
                        initialValue: mapping.mappedValue || 'Open',
                        options: ['Open', 'Closed'],
                        handleOnSubmit: (selectedObj: any) => {
                          const updatedPropertyMapping = Object.assign(
                            propertyMapping,
                            [],
                          )
                          // Find the associated mapping
                          const objToUpdate = updatedPropertyMapping.find(
                            (mappingElement) =>
                              mappingElement.value === mapping.value,
                          )
                          if (objToUpdate) {
                            objToUpdate.mappedValue = selectedObj[mapping.value]
                            setPropertyMapping([...updatedPropertyMapping])
                          }
                        },
                      }
                      return (
                        <div
                          key={mapping.value}
                          className='mapping flex flex-start items-center flex-start justify-between'
                        >
                          <div className='status-to-be-mapped bg-white flex items-center py-2 px-2'>
                            <img
                              className='p-1 w-[35px] h-[35px]'
                              src={hubspotLogoPNG}
                              alt='Hubspot Logo'
                            />
                            <span className='text-[16px] leading-[24px] text-gray-500 ml-4'>
                              {mapping.label}
                            </span>
                          </div>
                          <div className='flex items-center'>
                            <span className='block mx-4 text-gray-700'>
                              &lt; &gt;
                            </span>
                            <ComboBox
                              key={mapping.value}
                              {...mappingSelectionCombo}
                            />
                          </div>
                        </div>
                      )
                    })}
                </div>
              </section>
            </>
          )}
        </ModalFB>
        <ModalFB
          id='openDealStatusMappins'
          titleModal={currStatusType + ' Status Mapping'}
          className='max-w-[672px]'
          buttonCancel={'Cancel'}
          buttonSave={{ action: onSave, title: 'Save', disabled: false }}
          buttonOpen={
            <button
              className={`bg-routera-700 text-white px-1 py-2 rounded mb-4 w-[200px] sm:w-[250px] flex items-center ${!ability.can('update', 'setting.integration') &&
                'cursor-not-allowed'
                }`}
              data-modal-target='openDealStatusMappins'
              data-modal-toggle='openDealStatusMappins'
              onClick={() => setCurrStatusType('Deal')}
              disabled={!ability.can('update', 'setting.integration')}
            >
              <IconRoutera
                iconName='PencilIcon'
                className='text-white mx-2 w-5 h-5'
              />
              Edit Deal Status Mappings
            </button>
          }
        >
          {currStatusType && isSuccess ? (
            <>
              <section className='mapping-section mt-4'>
                {currStatusType === 'Deal' && (
                  <div className='my-4 flex justify-between items-end'>
                    <DropdownCustom
                      dropdownLabel='Pipeline'
                      options={dealPipelineOptions}
                      selectedOption={pipelineType}
                      setSelectedOption={onPipeLineChange}
                      optionRenderer={defaultOptionRenderer}
                      withAvatar={false}
                      disabled={loadingChanges}
                    />
                    <button
                      className={`py-[12px] px-[24px] h-[45px] rounded-lg d-block text-white font-normal text-sm w-[128px] ${loadingChanges
                          ? 'bg-gray-300 cursor-not-allowed'
                          : 'bg-routera-primary'
                        }`}
                      onClick={synchronize}
                      disabled={loadingChanges}
                    >
                      {!loadingChanges ? (
                        'Refresh'
                      ) : (
                        <div
                          className='spinner-border spinner-border-sm'
                          role='status'
                        />
                      )}
                    </button>
                  </div>
                )}
                <div className='status-mapping-list'>
                  {currStatusType &&
                    propertyMapping &&
                    propertyMapping.length &&
                    propertyMapping.map((mapping) => {
                      const mappingSelectionCombo = {
                        label: '',
                        modelName: mapping.value,
                        initialValue: mapping.mappedValue || 'Open',
                        options: ['Open', 'Closed'],
                        handleOnSubmit: (selectedObj: any) => {
                          const updatedPropertyMapping = Object.assign(
                            propertyMapping,
                            [],
                          )
                          // Find the associated mapping
                          const objToUpdate = updatedPropertyMapping.find(
                            (mappingElement) =>
                              mappingElement.value === mapping.value,
                          )
                          if (objToUpdate) {
                            objToUpdate.mappedValue = selectedObj[mapping.value]
                            setPropertyMapping([...updatedPropertyMapping])
                          }
                        },
                      }
                      return (
                        <div
                          key={mapping.value}
                          className='mapping flex flex-start items-center flex-start justify-between'
                        >
                          <div className='status-to-be-mapped bg-white flex items-center py-2 px-2'>
                            <img
                              className='p-1 w-[35px] h-[35px]'
                              src={hubspotLogoPNG}
                              alt='Hubspot Logo'
                            />
                            <span className='text-[16px] leading-[24px] text-gray-500 ml-4'>
                              {mapping.label}
                            </span>
                          </div>
                          <div className='flex items-center'>
                            <span className='block mx-4 text-gray-700'>
                              &lt; &gt;
                            </span>
                            <ComboBox
                              key={mapping.value}
                              {...mappingSelectionCombo}
                            />
                          </div>
                        </div>
                      )
                    })}
                </div>
              </section>
            </>
          ) : (
            !isSuccess && (
              <div className='flex justify-center py-4'>
                <div
                  className='spinner-border spinner-border-sm'
                  role='status'
                />
              </div>
            )
          )}
        </ModalFB>
      </div>
    </section>
  )
}

export default HubspotSettingsTemplate
