import LogRocket from "logrocket";

function registerUserWithLogRocket(user: any) {
   LogRocket.identify(user?._id, {
    name: user?.firstName + " " + user?.lastName,
    email: user?.email,

    subscriptionType: "pro",
  });
}

export default registerUserWithLogRocket;
