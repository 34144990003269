import { useEffect, useState } from 'react'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import Card from '../card/Card'
import hubspotIcon from '../../../assets/images/hubspot-logo.png'

const Trigger = ({
  valueSelect,
  setObjectType,
  setThereAreChanges,
  trigger,
}: any) => {
  const [changeValue, setChangeValue] = useState(valueSelect)

  useEffect(() => {
    setChangeValue(valueSelect)
  }, [valueSelect])

  const changeSelectType = (value: string) => {
    setChangeValue(value)
    setObjectType(value)
    setThereAreChanges(true)
  }

  return (
    <div className='px-4 p-4'>
      <span className='text-gray-700 text-xl leading-[30px]'>Trigger</span>
      <p className='text-gray-700 text-sm leading-[21px] mt-2 mb-4'>
        Select what an assignee needs to do to meet the SLA
      </p>

      <Card icon={hubspotIcon} title='HubSpot Trigger'>
        <div className='flex tablet:flex-col justify-start items-start'>
          <div className='pb-3'>
            <select
              id='integration_type'
              value={trigger}
              className='w-full md:w-[258px] rounded-lg border border-gray-300 text-gray-500 text-sm bg-white py-2 pl-3 pr-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500'
            >
              <option value='workflow_action'>Workflow Action</option>
            </select>
          </div>
          <div className='md:ml-4'>
            <select
              id='objectTypes'
              value={changeValue}
              className='w-full md:w-[258px] rounded-lg border border-gray-300 text-gray-500 text-sm bg-white py-2 pl-3 pr-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500'
              onChange={(value) => changeSelectType(value.target.value)}
            >
              <option value={HUBSPOT_OBJECT_TYPES.CONTACTS}>Contacts</option>
              <option value={HUBSPOT_OBJECT_TYPES.DEALS}>Deals</option>
              <option value={HUBSPOT_OBJECT_TYPES.COMPANIES}>Companies</option>
              <option value={HUBSPOT_OBJECT_TYPES.TICKETS}>Tickets</option>
            </select>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Trigger
