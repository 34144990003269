import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface Props {
  label?: string | React.ReactNode
  description?: string | React.ReactNode
  onDateChange: (date: Date | null) => void
  initialValue: Date
}

const DatePickerInput = ({
  label,
  description,
  onDateChange,
  initialValue,
}: Props) => {
  const [date, setDate] = useState(initialValue)
  const onDateEdit = (date: Date | null) => {
    setDate(date || new Date())
    onDateChange(date)
  }
  return (
    <div className='py-4'>
      {label && (
        <label className='block text-xl font-medium text-gray-700'>
          {label}
        </label>
      )}
      {description && (
        <div className='mt-2 text-lg text-gray-700'>{description}</div>
      )}
      {/* FIXME - Change this to tailwind based date picker component */}
      <ReactDatePicker selected={date} onChange={(date) => onDateEdit(date)} />
    </div>
  )
}

export default DatePickerInput
