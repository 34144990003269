import { createAsyncThunk } from '@reduxjs/toolkit'
import { RolesService } from 'src/services/roles.service'
import { PermissionsType, RolesListType, SpecificRole } from 'src/types/roles'

enum actiontype {
	getRolesList = 'getRolesList',
	createRole = 'createRole',
	getRoleById = 'getRoleById',
	updateRole = 'updateRole',
	getPermissions = 'getPermissions',
	updatePermissions = 'updatePermissions',
	deleteRoles = 'deleteRoles'
}

export const getRolesList = createAsyncThunk(
	actiontype.getRolesList,
	async () => {
		const resp = await RolesService.getRolesList()
		return resp.data as RolesListType[]
	},
)

export const createRole = createAsyncThunk(
	actiontype.createRole,
	async () => {
		return await RolesService.createRole()
	},
)

export const getRoleById = createAsyncThunk(
	actiontype.getRoleById,
	async (roleId: string) => {
		const resp = await RolesService.getRoleById(roleId)
		return resp.data as SpecificRole[]
	},
)

export const updateRole = createAsyncThunk(
	actiontype.updateRole,
	async (payload: { id: string; data: { name: string } }) => {
		return await RolesService.updateRole(payload.id, payload.data)
	},
)

export const getPermissions = createAsyncThunk(
	actiontype.getPermissions,
	async () => {
		const resp = await RolesService.getPermissions()
		return resp.data as PermissionsType[]
	},
)

export const updatePermissions = createAsyncThunk(
	actiontype.updatePermissions,
	async (payload: { id: string; data: { permissions: string[] } }) => {
		return await RolesService.updatePermissions(payload.id, payload.data)
	},
)

export const deleteRole = createAsyncThunk(
	actiontype.deleteRoles,
	async (data: string[]) => {
		return await RolesService.deleteRoles(data)
	},
)