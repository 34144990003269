import {
  UserIcon,
  UserGroupIcon,
  HandRaisedIcon,
  ChevronDownIcon,
  CreditCardIcon,
  BuildingOfficeIcon,
  KeyIcon,
  CircleStackIcon,
  Cog8ToothIcon,
  PencilIcon,
  TrashIcon,
  CalendarIcon,
  InformationCircleIcon,
  PlusSmallIcon,
  ChartPieIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  TicketIcon,
  CheckIcon,
  XMarkIcon,
  StarIcon,
  UserPlusIcon,
  LockClosedIcon,
  ArrowPathIcon,
  EnvelopeIcon,
  ChatBubbleBottomCenterTextIcon,
} from '@heroicons/react/20/solid'

interface IconsType {
  iconName: string
  className?: string
  onClick?: () => void
}

const IconRoutera: React.FC<IconsType> = (props) => {
  const { iconName, className, onClick } = props

  let showIcon: JSX.Element
  const classCustom = 'h-6 w-6' + className

  switch (iconName) {
    case 'UserGroupIcon':
      showIcon = <UserGroupIcon className={classCustom} />
      break
    case 'HandIcon':
      showIcon = <HandRaisedIcon className={classCustom} />
      break
    case 'ChevronDownIcon':
      showIcon = <ChevronDownIcon className={classCustom} />
      break
    case 'CreditCardIcon':
      showIcon = <CreditCardIcon className={classCustom} />
      break
    case 'OfficeBuildingIcon':
      showIcon = <BuildingOfficeIcon className={classCustom} />
      break
    case 'KeyIcon':
      showIcon = <KeyIcon className={classCustom} />
      break
    case 'CircleStackIcon':
      showIcon = <CircleStackIcon className={classCustom} />
      break
    case 'CogIcon':
      showIcon = <Cog8ToothIcon className={classCustom} />
      break
    case 'PencilIcon':
      showIcon = <PencilIcon className={classCustom} />
      break
    case 'TrashIcon':
      showIcon = <TrashIcon className={classCustom} />
      break
    case 'CalendarIcon':
      showIcon = <CalendarIcon className={classCustom} />
      break
    case 'InformationCircleIcon':
      showIcon = <InformationCircleIcon className={classCustom} />
      break
    case 'PlusSmallIcon':
      showIcon = <PlusSmallIcon className={classCustom} />
      break
    case 'ChartPieIcon':
      showIcon = <ChartPieIcon className={classCustom} />
      break
    case 'ArrowUpIcon':
      showIcon = <ArrowUpIcon className={classCustom} />
      break
    case 'ArrowDownIcon':
      showIcon = <ArrowDownIcon className={classCustom} />
      break
    case 'TicketIcon':
      showIcon = <TicketIcon className={classCustom} />
      break
    case 'ArrowRightIcon':
      showIcon = <ArrowRightIcon className={classCustom} />
      break
    case 'CheckIcon':
      showIcon = <CheckIcon className={classCustom} />
      break
    case 'XMarkIcon':
      showIcon = <XMarkIcon className={classCustom} />
      break
    case 'StarIcon':
      showIcon = <StarIcon className={classCustom} />
      break
    case 'UserPlusIcon':
      showIcon = <UserPlusIcon className={classCustom} />
      break
    case 'LockClosedIcon':
      showIcon = <LockClosedIcon className={classCustom} />
      break
    case 'ArrowPathIcon':
      showIcon = <ArrowPathIcon className={classCustom} />
      break
    case 'EnvelopeIcon':
      showIcon = <EnvelopeIcon className={classCustom} />
      break
    case 'ChatBubbleBottomCenterTextIcon':
      showIcon = <ChatBubbleBottomCenterTextIcon className={classCustom} />
      break
    default:
      showIcon = <UserIcon className={classCustom} />
  }

  return <div onClick={onClick && onClick}>{showIcon}</div>
}

export default IconRoutera
