import { createAsyncThunk } from '@reduxjs/toolkit'
import { basicService } from '../../services/basic.service'

enum actiontype {
  GET_PRODUCT_METRICS_INFO = 'GET_PRODUCT_METRICS_INFO',
}

export const getProductMetricsInfosAction = createAsyncThunk(
  actiontype.GET_PRODUCT_METRICS_INFO,
  async () => {
    const response = await basicService.getProductMetricsInfo()
    return response
  },
)
