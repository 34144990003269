import { Formik } from 'formik'
import { useMemo, useState } from 'react'
import editIcon from '../../../../assets/images/icons/edit-icon.png'

interface Props<Model> {
  currentName: string
  handleOnSubmit: (model: Partial<Model>) => void
  disabled?: boolean
}

const EditNameHeader = <Model,>({
  currentName,
  handleOnSubmit,
  disabled = false
}: Props<Model>) => {
  const [isEditing, setIsEditing] = useState(false)

  const initialValue = useMemo(
    () => ({
      name: currentName,
    }),
    [currentName],
  )

  return (
    <Formik
      onSubmit={(model) => {
        setIsEditing(false)
        handleOnSubmit(model as unknown as Partial<Model>)
      }}
      initialValues={initialValue}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isSubmitting,
        dirty,
        errors,
      }) => {
        return (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
            className='mainheading font-semibold text-base flex justify-start items-center mt-4 text-[#5c5c5c] p-0'
          >
            {isEditing ? (
              <div>
                <input
                  name='name'
                  type='text'
                  value={values.name}
                  onChange={handleChange}
                />
                {touched.name && errors.name && <span>required</span>}
                <button type='submit' disabled={!dirty || isSubmitting}>
                  Save
                </button>
              </div>
            ) : (
              <>
                {values.name}
                <a className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}>
                  <img
                    src={editIcon}
                    onClick={disabled ? () => { return } : () => setIsEditing(true)}
                    alt=''
                    className='editPencil-icon'
                  />
                </a>
              </>
            )}
          </form>
        )
      }}
    </Formik>
  )
}

export default EditNameHeader
