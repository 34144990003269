import React, { ChangeEvent } from 'react'
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form'

import Input from '../../atoms/Input/Input.atom'
import Dropdown from '../../atoms/Dropdown/Dropdown.Atom'
import Radio from '../../atoms/RadioGroup/Radio.atom'
import TimeInterval from 'src/components/atoms/TimeInterval/TimeInterval.atom'

interface IProps {
  element: Record<string, any>
  setValue: UseFormSetValue<FieldValues>
  control: Control<FieldValues, any>
}

const ElementPicker: React.FC<IProps> = ({ element, setValue, control }) => {
  let elementController = null;
  switch (element.type) {
    case 'string':
    case 'number':
    case 'array':
      elementController = (
        <Controller
          control={control}
          name={element.id}
          defaultValue=''
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => (
            <Input
              type={element.type === 'number' ? element.type : 'text'}
              handleChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
              value={value}
              label={element.label}
              //   @ts-ignore
              error={error}
              errorText={element.requiredError}
              customClass='w-[100%]'
              disabled={element?.disabled || false}
            />
          )}
        />
      )
      break
    case 'select':
    case 'enumeration':
    case 'bool':
      elementController = (
        <Controller
          control={control}
          name={element.id}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <Dropdown
              options={element.options}
              handleChange={(v) => onChange(v)}
              value={value}
              label={element.label}
              customClass={element.customStyle}
              width={element?.width}
              disabled={element?.disabled}
            />
          )}
        />
      )
      break
    case 'radio':
      elementController = (
        <Controller
          control={control}
          name={element.id}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <Radio
              selectedValue={value}
              handleChange={(v) => onChange(v)}
              options={element.options}
              setValue={setValue}
              control={control}
              customClass={element.customStyle}
              label={element.label}
              disabled={element.disabled}
            />
          )}
        />
      )
      break
    case 'datetime':
      elementController = (
        <Controller
          control={control}
          name={element.id}
          render={({ field: { onChange, value } }) => (
            <div>
              <div className='text-sm font-medium mt-4'>Value:</div>
              <input
                type='date'
                onChange={(date) => {
                  onChange(date)
                }}
                value={value}
                className='rounded w-[100%] z-40 mt-1 text-sm tablet:text-xs h-[39px] tablet:h-[29px] text-gray-500 tablet:p-1 tablet:px-1 bg-white border border-gray-300 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              />
            </div>
          )}
        />
      )
      break
    case 'time-interval':
      elementController = (
        <Controller
          control={control}
          name={element.id}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => (
            <TimeInterval
              handleChange={(v: string) => onChange(v)}
              label={element.label}
              value={value}
            />
          )}
        />
      )
      break
    default:
      return null
  }
  return <div id={`elementController-${element.id}`}>
    {elementController}
  </div>

}

export default ElementPicker
