import { createReducerBuilder } from 'src/utils/actions'
import { createSlice } from '@reduxjs/toolkit'
import {
	getRolesList,
	getRoleById,
	getPermissions,
} from './roles.actions'
import { RolesState } from 'src/types/roles'

const initialState: RolesState = {
	allRoles: [{
		access: undefined,
		role: undefined
	}],
	specificRoleById: [{
		canBeRemoved: false,
		name: '',
		_id: '',
		permissions: []
	}],
	listAllPermissions: [{
		action: '',
		description: '',
		slug: '',
		subject: '',
		_id: '',
		name: '',
	}]
}

const reducerBuilder = createReducerBuilder<RolesState>()

const getAllRolesList = reducerBuilder(getRolesList, {
	fulfilled: (state, { payload }) => {
		if (payload) {
			state.allRoles = payload
		}
	},
})

const getRoleByIdS = reducerBuilder(getRoleById, {
	fulfilled: (state, { payload }) => {
		if (payload) {
			state.specificRoleById = payload
		}
	},
})

const getPermissionsList = reducerBuilder(getPermissions, {
	fulfilled: (state, { payload }) => {
		if (payload) {
			state.listAllPermissions = payload
		}
	},
})

const RolesService = createSlice({
	name: 'rolesSlice',
	initialState,
	reducers: {},

	extraReducers: (builder) => {
		getAllRolesList(builder)
		getRoleByIdS(builder)
		getPermissionsList(builder)
	},
})

export default RolesService.reducer
