import Tabs from 'src/components/molecules/tabs/tabs'
import Capacities from 'src/components/MyAccount/Capacities'
import { DataTab } from 'src/types/tabs'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'

const title = 'Set Capacities for each user'
const description =
  'Starting to see bottlenecks in your sales pipeline? Maybe you’re assigning too many leads and sales reps are having a hard time tackling new leads.'
const boldDescription =
  'Now you can set Capacities for each rep and once a rep gets to their maximum they won’t receive any more leads until they close them based on their status.'

const CapacitiesPage = () => {
  const ability = useAbility(AbilityContext)

  if (!ability.can('access', 'setting.capacities')) {
    return (
      <NoPermission
        title={title}
        description={description}
        boldDescription={boldDescription}
        value='access'
        width='323px'
      />
    )
  } else {
    if (!ability.can('read', 'setting.capacities')) {
      return <NoPermission value='read' />
    }
  }

  const dataTabs: DataTab[] = [
    {
      label: 'Capacities List',
      id: 'capacities-list',
      dataContentTab: <Capacities />,
    },
  ]

  return (
    <section className='border-l border-gray-200'>
      <div className='p-4 px-5 bg-gray-100'>
        <h3 className='text-2xl block'>Settings</h3>
      </div>
      <Tabs
        dataTabs={dataTabs}
        classContentTab='min-h-[calc(100vh-201px)] bg-routera-gray'
      />
    </section>
  )
}

export default CapacitiesPage
