import React from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

interface Props {
  type: string
  handleChange: any
  value: string | number
  label: string
  error?: boolean
  errorText?: string
  customClass: string
  showLabel: boolean
  disabled?: boolean
  min?: number
}

const Input: React.FC<Props> = ({
  type,
  handleChange,
  value,
  label,
  error = false,
  errorText = 'Error',
  customClass = '',
  showLabel = true,
  disabled = false,
  min = 0,
}) => {
  return (
    <div className={`mt-3 mb-[2rem] ${customClass}`}>
      {showLabel && (
        <label
          htmlFor='value'
          className='block text-sm font-medium text-gray-900'
        >
          {label}
        </label>
      )}
      <div className='mt-1'>
        <input
          type={type}
          name='value'
          id={`input-${label}`}
          className={`${
            disabled && 'bg-gray-100 cursor-not-allowed'
          } block w-full rounded-md border-gray-300 sm:text-sm`}
          placeholder=''
          onChange={handleChange}
          value={value}
          disabled={disabled}
          min={min}
        />
        {/* <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
          <ExclamationCircleIcon
            className='h-5 w-5 text-red-500'
            aria-hidden='true'
          />
        </div> */}
      </div>
      {error && (
        <p className='mt-2 text-sm text-red-600' id='email-error'>
          {errorText}
        </p>
      )}
    </div>
  )
}

export default Input
