import { useEffect, useMemo, useState } from 'react'
import ComboBox from 'src/components/molecules/inputs/comboBox/ComboBox.Molecule'
import DatePickerInput from 'src/components/molecules/inputs/dateTime/DatePickerInput.Molecule'
import RulesInput from 'src/components/molecules/inputs/rulesInput/RulesInput.Molecule'
import {
  AVAILABLE_OPERATORS,
  getLabelFromOperatorValue,
  LITERAL_TYPES,
  OperatorConfig,
} from 'src/components/organisms/forms/inputs/jsonOperator/getJsonOperator'
import JsonOperator from 'src/components/organisms/forms/inputs/jsonOperator/JsonOperator.Organism'
import { Source } from 'src/types/sources'
import toastBuilder from 'src/utils/toastBuilder'
import { getHubspotFieldType } from '../getHubspotFieldType'
import { HandleUpdate } from '../HubspotQueryBuilder.Template'
import {
  DispatchQueryStepChange,
  ObjectProperty,
  QUERY_STEPS,
} from '../StepBuilder.Template'

interface Props {
  currentSource: Source
  handleUpdate: HandleUpdate
  dispatchQueryStepChange: DispatchQueryStepChange
  labelOptions?: string[]
  getPropertyFromLabel: (label: string) => ObjectProperty | undefined
  currentSourceObjectProperty?: Record<string, any> & { label: string }
}

export const MODEL_NAME = 'subscription_configs.object_properties'
const RULE_INPUT_MODEL_NAME = 'setPropertyRulesInput'

const SetPropertyStep: React.FC<Props> = ({
  currentSource,
  handleUpdate,
  dispatchQueryStepChange,
  labelOptions,
  getPropertyFromLabel,
  currentSourceObjectProperty,
}) => {
  const [propertyValue, setPropertyValue] = useState<string>(
    currentSourceObjectProperty?.label || '',
  )

  const currentObjectPropertyConfig = useMemo(() => {
    return getPropertyFromLabel(propertyValue)
  }, [propertyValue])
  // To show the operator values as dropdown in case of enumeration types or whenever available
  const selectableOperatorValues = useMemo(() => {
    return currentObjectPropertyConfig
      ? currentObjectPropertyConfig.options
      : []
  }, [currentObjectPropertyConfig])

  const savedConditions = useMemo(() => {
    let savedOperator: AVAILABLE_OPERATORS | '' = ''
    let savedRuleInput = ''
    if (
      currentObjectPropertyConfig &&
      currentObjectPropertyConfig.name &&
      currentSource?.conditions &&
      Object.keys(currentSource.conditions).length > 0
    ) {
      const currentConditions = currentSource?.conditions
      for (const conditionKey in currentSource.conditions) {
        const conditionsArray = currentConditions[conditionKey as 'all' | 'or']
        if (!conditionsArray || conditionsArray.length < 1) {
          return null
        }
        const matchingCondition = conditionsArray.find(
          (condition) => condition.fact === currentObjectPropertyConfig.name,
        )
        if (matchingCondition) {
          savedOperator = matchingCondition.operator
          savedRuleInput = matchingCondition.value
        }
      }
    }
    if (savedOperator) {
      const savedOperatorValue = {
        value: savedOperator,
        label: savedOperator && getLabelFromOperatorValue(savedOperator),
      }

      return {
        operator: savedOperatorValue,
        ruleInput: savedRuleInput,
      }
    }
    return null
  }, [
    currentObjectPropertyConfig &&
      currentObjectPropertyConfig.name &&
      currentSource?.conditions,
  ])

  const [operatorValue, setOperatorValue] = useState<OperatorConfig | null>(
    savedConditions && savedConditions?.operator,
  )

  const [ruleInputValue, setRuleInputValue] = useState<string>(
    savedConditions ? savedConditions?.ruleInput : '',
  )

  const description = useMemo(() => {
    return (
      currentObjectPropertyConfig && currentObjectPropertyConfig.description
    )
  }, [propertyValue])

  const hasMappedFieldType = (label?: string) => {
    const foundProperty = getPropertyFromLabel(label || propertyValue)
    if (foundProperty) {
      return getHubspotFieldType(foundProperty.type)
    }
  }

  const mappedFieldType = useMemo<LITERAL_TYPES | undefined>(
    hasMappedFieldType,
    [currentSourceObjectProperty?.label, propertyValue],
  )

  useEffect(() => {
    const savedOperator = savedConditions && savedConditions?.operator
    const savedRule = savedConditions && savedConditions?.ruleInput

    const hasObjectProperty = !!(
      currentObjectPropertyConfig && currentObjectPropertyConfig.name
    )

    const hasOperatorSet = !!operatorValue

    const hasMatchingSavedOperator = !!(
      savedOperator &&
      operatorValue &&
      savedOperator.value === operatorValue?.value
    )

    const hasRuleInputSet = !!ruleInputValue
    const hasMatchingSavedRule = !!(
      savedRule &&
      ruleInputValue &&
      savedRule === ruleInputValue
    )

    const operatorHasBeenUpdated = hasOperatorSet && !hasMatchingSavedOperator

    const ruleInputHasBeenUpdated = hasRuleInputSet && !hasMatchingSavedRule

    const shouldUpdate =
      !!hasObjectProperty && (operatorHasBeenUpdated || ruleInputHasBeenUpdated)

    if (shouldUpdate) {
      handleUpdate({
        $set: {
          conditions: {
            all: [
              {
                fact: currentObjectPropertyConfig.name,
                operator: operatorValue?.value, // reference the operator name in the rule
                value: ruleInputValue,
              },
            ],
          },
        },
      } as Partial<Source>)

      dispatchQueryStepChange(QUERY_STEPS.SET_PROPERTY)
    }
  }, [
    currentObjectPropertyConfig && currentObjectPropertyConfig.name,
    operatorValue,
    ruleInputValue,
  ])

  const options = {
    label: 'Set Property Type',
    description: `Please select a property type from your Hubspot CRM Account.`,
    modelName: MODEL_NAME,
    initialValue: propertyValue || '',
    options: labelOptions || [],
    setValue: setPropertyValue,
    footerDescription: description,
    handleOnSubmit: (model: Partial<Source> & Record<string, any>) => {
      const foundProperty = getPropertyFromLabel(model[MODEL_NAME])
      if (foundProperty) {
        // TODO: Reset conditions as well
        handleUpdate({
          $set: {
            [`${MODEL_NAME}.${foundProperty.name}`]: foundProperty,
          },
          $unset: {
            [`${MODEL_NAME}.${currentSourceObjectProperty?.name as string}`]:
              '',
            'conditions.all': '',
          },
        } as Partial<Source>)

        dispatchQueryStepChange(QUERY_STEPS.SET_PROPERTY)
      } else {
        toastBuilder('error', 'The property selected is an invalid property.')
      }
    },
  }

  const handleOperatorUpdate = (operator: OperatorConfig) => {
    setOperatorValue(operator)
  }

  const handleRulesInput = ({ [RULE_INPUT_MODEL_NAME]: ruleInput }: any) => {
    setRuleInputValue(ruleInput)
  }

  const operatorValueToChooseFromOptions = {
    label: `${propertyValue}`,
    description: `Choose a value for ${propertyValue}`,
    modelName: 'name',
    initialValue: ruleInputValue
      ? selectableOperatorValues.find(
          (operatorValue: any) => operatorValue.value === ruleInputValue,
        )?.label
      : '',
    options:
      selectableOperatorValues.map(
        (operatorValue: any) => operatorValue.label,
      ) || [],
    handleOnSubmit: (model: Partial<Source> & Record<string, any>) => {
      const valueOfOperator = selectableOperatorValues.find(
        (selectableOperatorValue: any) =>
          selectableOperatorValue.label === model['name'],
      )
      if (valueOfOperator) {
        setRuleInputValue(valueOfOperator.value)
      } else {
        toastBuilder('error', 'The value selected seems to be invalid')
      }
    },
  }

  const onDateChange = (date: Date | null) => {
    setRuleInputValue(date ? date.toISOString() : new Date().toISOString())
  }

  return (
    <>
      {labelOptions && labelOptions.length > 0 && (
        <div className='py-4'>
          <ComboBox<Source> {...options} />
          {propertyValue && mappedFieldType && (
            <div className='py-4'>
              <JsonOperator
                initialValue={operatorValue}
                fieldType={mappedFieldType}
                handleOperatorUpdate={handleOperatorUpdate}
              />
              {selectableOperatorValues && selectableOperatorValues.length > 0 && (
                <div className='py-4'>
                  <ComboBox {...operatorValueToChooseFromOptions} />
                </div>
              )}
              {mappedFieldType === LITERAL_TYPES.DATETIME && (
                <DatePickerInput
                  label='Enter a condition value'
                  description='Please select a date.'
                  onDateChange={onDateChange}
                  initialValue={
                    ruleInputValue ? new Date(ruleInputValue) : new Date()
                  }
                />
              )}
              {operatorValue &&
                selectableOperatorValues &&
                selectableOperatorValues.length === 0 &&
                mappedFieldType !== LITERAL_TYPES.DATETIME && (
                  <RulesInput
                    label='Enter a condition value'
                    description='Please enter a value that corresponds to the property and operator selected.'
                    fieldType={operatorValue.value}
                    handleOnSubmit={handleRulesInput}
                    modelName={RULE_INPUT_MODEL_NAME}
                    initialValue={ruleInputValue}
                  />
                )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default SetPropertyStep
