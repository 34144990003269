import { useState } from 'react'
import Header from 'src/components/Header'
import SourcesHeaderTemplate from './header/SourcesHeader.Template'
import SourcesModalTemplate from './modal/SourcesModal.Template'
import SourcesTableTemplate, {
  SOURCE_QUERY_KEYS,
} from './table/SourcesTable.Template'
import toastBuilder from '../../../utils/toastBuilder'
import { useNavigate } from 'react-router-dom'
// import { useActions } from '../../../actions'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { sourceService } from '../../../services/sources.service'
import { Source } from 'src/types/sources'

const SourcesTemplate: React.FC = () => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const createSourceMutation = useMutation(sourceService.createSource, {
    onSuccess: ({ data }: { data: { source: Source } }) => {
      // Invalidate and refetch
      queryClient.invalidateQueries([SOURCE_QUERY_KEYS.GET_SOURCES])
      if (data.source) {
        setShowCreateModal(false)
        toastBuilder('success', 'Source added successfully!')
        navigate(`/sources/${data.source._id}`)
      }
    },
  })

  const handleFormSubmit = async (payload: {
    name: string
    integrationId: string
  }) => {
    createSourceMutation.mutate(payload)
  }

  return (
    <section className='bg-white minh-100vh'>
      <div className='px-6 mx-4'>
        <SourcesModalTemplate
          showCreateModal={showCreateModal}
          setShowCreateModal={setShowCreateModal}
          onSubmit={handleFormSubmit}
        />
        <SourcesHeaderTemplate setShowCreateModal={setShowCreateModal} />
        <SourcesTableTemplate />
      </div>
    </section>
  )
}

export default SourcesTemplate
