import { PermissionsUser } from 'src/types/roles'
import { Iuser } from '../types/auth'

export function setcurrentuserandtoken(userObject: Iuser, token: string) {
  const user = JSON.stringify(userObject)
  window.localStorage.setItem('user', user)
  window.localStorage.setItem('token', token)
}

export function setPermissionsUser(permissions: PermissionsUser) {
  if (permissions && permissions.permissions.role[0].role) {
    const permissionsUser = JSON.stringify(permissions.permissions.role[0].role.permissions)
    window.localStorage.setItem('permissionsUser', permissionsUser)
  }
}

export function getPermissionsUser() {
  const permissionsUser = window.localStorage.getItem('permissionsUser')
  if (permissionsUser) {
    return JSON.parse(permissionsUser)
  } else {
    return false
  }
}

export function setcurrentuser(userObject: any) {
  const user = JSON.stringify(userObject)
  window.localStorage.setItem('user', user)
}

export function getcurrentuser() {
  const user = window.localStorage.getItem('user')
  const token = window.localStorage.getItem('token')

  if (user && token) {
    return JSON.parse(user)
  } else {
    return false
  }
}

export function setsourceinfo(sourceId: string) {
  window.localStorage.setItem('sourceId', sourceId)
}

export function getsourceinfo(): string {
  return window.localStorage.getItem('sourceId') || ''
}
