import { HttpService } from './base.service'

const productMetricsInfo = [
  {
    _id: '1',
    title: 'Did you know?',
    text: 'Contacting a prospect within 1 hour increases the chances of converting by seven times',
  },
  {
    _id: '2',
    title: 'Did you know?',
    text: '46% of customers expect to hear from a company they contact within 4 hours',
  },
  {
    _id: '3',
    title: 'Did you know?',
    text: 'Fast response times is the most important attribute to a good customer experience',
  },
]

class BasicService extends HttpService {
  private readonly prefix = 'basic'
  //   getProductMetricsInfo = () => this.get(`${this.prefix}/metrics-info`)
  getProductMetricsInfo = () => productMetricsInfo
}

export const basicService = new BasicService()
