import * as yup from 'yup'

export const MyAccountValidation = yup.object().shape({
  firstName: yup.string().required('required'),
  lastName: yup.string().required('required'),
  phone: yup.string().required('required'),
})

export const createNewCustomerValidation = yup.object().shape({
  email: yup.string().email('must be a valid email').required('required'),
  firstName: yup.string().required('required'),
  lastName: yup.string().required('required'),
})

export const createRouterValidation = yup.object().shape({
  name: yup.string().required('Router should have a name'),
})

export const createTeamValidation = yup.object().shape({
  name: yup.string().required('Team should have a name'),
})