import { useEffect, useState } from 'react'
import IconRoutera from 'src/components/atoms/icons/icons'
import Tabs from 'src/components/molecules/tabs/tabs'
import ConversionBuilder from 'src/components/organisms/tabsConversions/conversionBuilder'
import TypeConversions from 'src/components/organisms/tabsConversions/typeConversion'
import ConversionReviewPublish from 'src/components/organisms/tabsConversions/conversionReviewPublish'
import { DataTab } from 'src/types/tabs'
import { useParams } from 'react-router-dom'
import { useActions } from 'src/actions'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { initFlowbite } from 'flowbite'
import toastBuilder from 'src/utils/toastBuilder'
import { Conversions } from 'src/types/conversions'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import EditName from 'src/components/molecules/editName/editName'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'

const conversionDefault = {
  _id: '',
  name: '',
  updatedAt: new Date(),
  is_active: false,
  conversionRule: {
    objectType: HUBSPOT_OBJECT_TYPES.CONTACTS,
    value: '',
    fieldName: '',
    fieldLabel: '',
    operator: '',
  },
}

const ConversionsDetail = () => {
  const [conversionName, setConversionName] =
    useState<string>('Unnamed Conversion')
  const [editName, setEditName] = useState<boolean>(false)
  const [hasMounted, setHasMounted] = useState(false)
  const [thereAreChanges, setThereAreChanges] = useState<boolean>(false)
  const [objectType, setObjectType] = useState<HUBSPOT_OBJECT_TYPES>(
    conversionDefault.conversionRule.objectType,
  )
  const [currentConversion, setCurrentConversion] =
    useState<Conversions>(conversionDefault)
  const [currentTab, setCurrentTab] = useState(0)
  const { getConversionById, updateConversion, getConversionsList } =
    useActions()
  const location = useLocation()
  const navigation = useNavigate()
  const ability = useAbility(AbilityContext)

  const { conversionId } = useParams()

  useEffect(() => {
    if (!hasMounted && conversionId) {
      getConversionData(conversionId)
      setHasMounted(true)
    }
  }, [hasMounted])

  const getConversionData = async (id: string) => {
    const resp: any = await getConversionById(id)
    if (resp.payload) {
      setCurrentConversion(resp.payload)
      setConversionName(resp.payload.name)
      if ('conversionRule' in resp.payload) {
        setObjectType(resp.payload.conversionRule.objectType)
      }
    }
  }

  const getconversionData = async (id: string) => {
    const resp: any = await getConversionById(id)
    if (resp.payload) {
      if (
        'conversionRule' in resp.payload &&
        resp.payload.conversionRule.fieldLabel &&
        resp.payload.conversionRule.fieldName !== ''
      ) {
        setCurrentConversion(resp.payload)
      }
    }
  }

  useEffect(() => {
    if (conversionId && location.search === '?tab=review-publish') {
      getconversionData(conversionId)
    }
  }, [location])

  const dataTabs: DataTab[] = [
    {
      label: 'Type',
      id: 'conversions-type',
      dataContentTab: (
        <TypeConversions
          valueSelect={currentConversion?.conversionRule?.objectType}
          setObjectType={setObjectType}
          setThereAreChanges={setThereAreChanges}
        />
      ),
    },
    {
      label: 'Conversion Builder',
      id: 'conversion-builder',
      dataContentTab: (
        <ConversionBuilder
          currentObjectType={objectType}
          thereAreChanges={thereAreChanges}
        />
      ),
    },
    {
      label: 'Review & Publish',
      id: 'review-publish',
      dataContentTab: (
        <ConversionReviewPublish
          conversionData={currentConversion.conversionRule}
        />
      ),
    },
  ]

  const changeTab = (action?: string, id?: string) => {
    if (id) {
      const currentTabIndex = dataTabs.findIndex((tab) => {
        return tab.id == id
      })
      setCurrentTab(currentTabIndex)
      navigation(location.pathname + '?tab=' + id)
      return
    }

    const currentTabIndex = dataTabs.findIndex((tab) => {
      return tab.id == location.search.split('=')[1]
    })

    switch (action) {
      case 'prev':
        navigation(
          location.pathname + '?tab=' + dataTabs[currentTabIndex - 1].id,
        )
        setCurrentTab(currentTabIndex - 1)
        break
      case 'next':
        navigation(
          location.pathname + '?tab=' + dataTabs[currentTabIndex + 1].id,
        )
        setCurrentTab(currentTabIndex + 1)
        break
      default:
        navigation(location.pathname + '?tab=' + dataTabs[currentTabIndex].id)
        setCurrentTab(currentTabIndex)
    }
  }

  useEffect(() => {
    changeTab()
  }, [])

  useEffect(() => {
    initFlowbite()
  }, [currentTab])

  const onPrevTab = () => {
    if (currentTab > 0) {
      changeTab('prev')
    }
  }

  const onNextTab = async () => {
    setEditName(false)
    if (currentTab < dataTabs.length - 1) {
      changeTab('next')
      if (conversionId && thereAreChanges) {
        setThereAreChanges(false)
        const data = {
          is_active: false,
          conversionRule: {
            objectType: objectType,
            fieldName: '',
          },
        }
        const resp: any = await updateConversion({ id: conversionId, data })
        if (resp.payload.success) {
          setCurrentConversion(resp.payload.data)
          toastBuilder('success', 'Object type changed successfully')
        } else {
          toastBuilder('error', 'Failed to change object type')
        }
      }
    }
    if (location.search === '?tab=review-publish' && conversionId) {
      const data = {
        is_active: true,
      }
      if (
        'conversionRule' in currentConversion &&
        'fieldName' in currentConversion.conversionRule &&
        currentConversion.conversionRule.fieldName !== ''
      ) {
        const resp: any = await updateConversion({ id: conversionId, data })
        if (resp.payload.success) {
          getConversionsList()
          if (location.search === '?tab=review-publish') {
            toastBuilder('success', 'Conversion created/updated successfully')
          } else {
            toastBuilder('success', 'Object type changed successfully')
          }

          navigation('/conversions')
        }
      } else {
        toastBuilder('error', 'The conversion builder data is required')
      }
    }
  }

  const saveName = async () => {
    if (conversionId) {
      const data = {
        name: conversionName,
      }
      setEditName(false)
      const resp: any = await updateConversion({ id: conversionId, data })
      if (resp.payload.success) {
        toastBuilder('success', 'Conversion name updated successfully')
      } else {
        toastBuilder('error', 'Failed to change conversion name')
      }
    }
  }

  return (
    <section className='bg-gray-100 min-h-full'>
      <div className='p-4 px-4'>
        <h3 className='text-2xl block'>New Conversion</h3>
      </div>
      <EditName placeholder='Conversion Name' editName={editName} name={conversionName} setEditName={setEditName} setName={setConversionName} editNameChange={saveName} disabled={!ability.can('update', 'conversion')} />
      <Tabs
        dataTabs={dataTabs}
        classContentTab='min-h-[calc(100vh-297px)] bg-routera-gray'
        classTabs='px-4'
        idProp={currentTab > 0 ? dataTabs[currentTab].id : ''}
        onChangeTab={changeTab}
      />
      <div className='py-[8px] -my-[6px] px-4 bg-white flex'>
        <button
          className={`btn btn-primary px-4 d-block w-[143px] bg-white border border-gray-200 ${!ability.can('update', 'conversion') && 'cursor-not-allowed'}`}
          onClick={onPrevTab}
          disabled={!ability.can('update', 'conversion')}
        >
          <span className='text-gray-800'>Back</span>
        </button>
        <button
          className={`btn btn-primary px-4 d-block ms-auto w-[143px] ${!ability.can('update', 'conversion') && 'cursor-not-allowed'}`}
          onClick={onNextTab}
          disabled={!ability.can('update', 'conversion')}
        >
          {location.search === '?tab=review-publish' ? 'Publish' : 'Next'}
        </button>
      </div>
    </section>
  )
}

export default ConversionsDetail
