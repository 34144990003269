import ConversionsLits from 'src/components/organisms/conversionsList/conversionsList'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'

const title = 'Measure conversions for each rep'
const description =
  'Our conversion feature allows you to quickly implement reporting for each user who is receiving incoming leads.'
const boldDescription =
  'Track specific actions that reps need to make after being assigned a lead. See conversions by assignee, router and team quickly and easily in one dashboard view.'

const Conversions = () => {
  const ability = useAbility(AbilityContext)

  if (!ability.can('access', 'conversion')) {
    return (
      <NoPermission
        title={title}
        description={description}
        boldDescription={boldDescription}
        value='access'
        width='323px'
      />
    )
  } else {
    if (!ability.can('read', 'conversion')) {
      return <NoPermission value='read' />
    }
  }

  return (
    <section className='h-full'>
      <div className='h-[67px] justify-start items-center flex px-4'>
        <span className='text-2xl font-semibold text-gray-700'>
          Conversions
        </span>
      </div>
      <ConversionsLits />
    </section>
  )
}

export default Conversions
