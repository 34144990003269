import { createAsyncThunk } from '@reduxjs/toolkit'

import { slaService } from '../../services/sla.service'

enum actiontype {
  ADD_SLA = 'ADD_SLA',
  GET_SLA = 'GET_SLA',
  UPDATE_SLA = 'UPDATE_SLA',
  GET_ALL_SLAS = 'GET_ALL_SLAS',
  DELETE_SLA = 'DELETE_SLA',
  SET_RULES = 'SET_RULES',
}

export const addSLAAction = createAsyncThunk(
  actiontype.ADD_SLA,
  async (payload: any, thunkApi: any) => {
    const response = await slaService.addSLA(payload)
    if (response) {
      return response
    }
    return thunkApi.rejectWithValue('')
  },
)

export const getOneSLAAction = createAsyncThunk(
  actiontype.GET_SLA,
  async (slaId: string, thunkApi) => {
    const response = await slaService.getSLA(slaId)
    if (response) return response
    else return thunkApi.rejectWithValue('')
  },
)

export const updateOneSLAAction = createAsyncThunk(
  actiontype.UPDATE_SLA,
  async (
    payload: {
      slaId: string
      updateSLAPayload: any
    },
    thunkApi,
  ) => {
    const response = await slaService.updateSLA(
      payload.slaId,
      payload.updateSLAPayload,
    )
    if (response) return response
    else return thunkApi.rejectWithValue('')
  },
)

export const getAllSLAsAction = createAsyncThunk(
  actiontype.GET_ALL_SLAS,
  async (objectType?: string | undefined) => {
    const response = await slaService.getAllSLAs(objectType)
    if (response) return response.data
  },
)

export const deleteSLA = createAsyncThunk(
  actiontype.DELETE_SLA,
  async (data: string[]) => {
    return await slaService.deleteSLAs(data)
  },
)

export const setRulesSLA = createAsyncThunk(
  actiontype.SET_RULES,
  async (
    payload: {
      slaId: string
      data: any
    },
    thunkApi,
  ) => {
    const response = await slaService.setRules(payload.slaId, payload.data)
    if (response) return response
    else return thunkApi.rejectWithValue('')
  },
)
