import { AVAILABLE_OPERATORS } from 'src/components/organisms/forms/inputs/jsonOperator/getJsonOperator'

export interface HubspotSubscriptionConfigs {
  object_type?: string
  object_properties?: Record<string, any>
  hubspot_event_id?: string
}

export interface JsonRulesCondition {
  fact: string
  operator: AVAILABLE_OPERATORS
  value: string
}

export interface Source {
  _id: string
  name: string
  account_id: string
  router_id: string
  integration_id: string
  subscription_configs: HubspotSubscriptionConfigs
  conditions: {
    all?: JsonRulesCondition[]
    or?: JsonRulesCondition[]
  }
  updatedAt: Date
}

export enum HUBSPOT_OBJECT_TYPES {
  CONTACTS = 'contacts',
  COMPANIES = 'companies',
  DEALS = 'deals',
  TICKETS = 'tickets',
}

export enum HUBSPOT_PROPERTY_STRATEGIES {
  ALL = 'all',
  PIPELINE = 'pipeline',
}
export interface HubspotIntegrationParams {
  type: HUBSPOT_PROPERTY_STRATEGIES
  objectType: HUBSPOT_OBJECT_TYPES
}

export type IntegrationParams = HubspotIntegrationParams

export interface GetSourcePropertiesDto {
  sourceId: string
  integrationParams: IntegrationParams
}
