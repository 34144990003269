import { useEffect, useState } from 'react'
import { useActions } from 'src/actions'
import { useAppSelector } from 'src/store'
import IconRoutera from 'src/components/atoms/icons/icons'
import SelectableTable, {
  TableColumn,
  SELECTION_MODES,
} from 'src/components/organisms/tables/SelectableTable.Organism'
import { Link } from 'react-router-dom'
import { timeformater } from 'src/utils'
import { ISLA } from 'src/types/sla'
import toastBuilder from 'src/utils/toastBuilder'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import { useListOfAllSLAs } from 'src/container/SLA/SLA.selector'
import { CircleStackIcon } from '@heroicons/react/24/outline'

const SlaList: React.FC<{ setShow: any }> = (props) => {
  const { setShow } = props
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const { getAllSLAsAction, deleteSLA, updateOneSLAAction } = useActions()
  const allSLAs = useAppSelector(useListOfAllSLAs)
  const [rowsToShow, setRowsToShow] = useState<ISLA[]>(allSLAs)

  const ability = useAbility(AbilityContext)

  useEffect(() => {
    getAllSLAsAction()
  }, [])

  useEffect(() => {
    setRowsToShow(allSLAs)
  }, [allSLAs])

  const columnsInConverionsTable: TableColumn[] = [
    {
      label: 'Status',
      field: 'slaStatus',
      render: ({ is_active }: any) => {
        return (
          <div className='mb-0 name-with_status'>
            {is_active ? (
              <div className='py-[4px] px-[12px] bg-green-100 flex items-center rounded-[99px]'>
                <div className='bg-green-500 w-[12px] h-[12px] rounded mr-2' />
                <span className='text-green-800 text-xs font-medium'>
                  Published
                </span>
              </div>
            ) : (
              <div className='py-[4px] px-[12px] bg-red-100 flex items-center rounded-[99px]'>
                <div className='bg-red-500 w-[12px] h-[12px] rounded mr-2' />
                <span className='text-red-800 text-xs font-medium'>Draft</span>
              </div>
            )}
          </div>
        )
      },
    },
    {
      label: 'Name',
      render: ({ _id, name }: any) => {
        return (
          <div>
            <div className='mb-0 name-with_status' style={{ color: '#44444f' }}>
              <Link to={`/slas/${_id}?tab=trigger`}>
                <div className='text-blue-500'>{name}</div>
              </Link>
            </div>
          </div>
        )
      },
    },
    {
      label: 'Type',
      field: 'type',
      render: ({ object_type }) => {
        return (
          <div className='capitalize'>{object_type ? object_type : '-'}</div>
        )
      },
    },
    {
      label: 'Updated at',
      render: ({ updatedAt }: any) => {
        return <div>{timeformater(updatedAt)}</div>
      },
    },
  ]

  const handleDelete = async (conversionsListDelete: Array<any>) => {
    const data: string[] = conversionsListDelete.map(
      (conversion) => conversion._id,
    )
    const response: any = await deleteSLA(data)
    if (response.payload.success) {
      toastBuilder('success', 'The item(s) were removed')
      getAllSLAsAction()
    }
  }

  const handleUnpublish = async (slaListUnPublish: Array<any>) => {
    const updateSLAPayload = {
      is_active: false,
    }
    for (const sla of slaListUnPublish) {
      await updateOneSLAAction({ slaId: sla._id, updateSLAPayload })
    }
    toastBuilder(
      'success',
      slaListUnPublish.length + ' Sla(s) unpublished successfully',
    )
    getAllSLAsAction()
  }

  const onSearchTextChange = (searchText: string) => {
    setIsSearch(true)
    if (searchText === '') {
      setRowsToShow(allSLAs)
      setIsSearch(false)
      return
    }
    setRowsToShow(
      allSLAs.filter((sla: ISLA) =>
        sla.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    )
  }

  const createSLAModal = () => {
    setShow(true)
  }

  const createSLAButton: JSX.Element = (
    <button
      className={`flex flex-row items-center px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start ${
        !ability.can('create', 'router.sla') && 'cursor-not-allowed'
      }`}
      onClick={createSLAModal}
      disabled={!ability.can('create', 'router.sla')}
    >
      <IconRoutera
        iconName='PlusSmallIcon'
        className='w-[19px] mr-2 mt-[2px]'
      />{' '}
      Create SLA
    </button>
  )

  return (
    <div className='px-4 mb-5 min-h-[calc(100vh-275px)]'>
      {rowsToShow.length > 0 || isSearch ? (
        <div className='mt-2 w-full relative'>
          <div className='section-header w-full flex justify-end'>
            <div className='flex items-center justify-between w-full mobile:flex-col'>
              <span className='text-gray-700 text-[18px] font-medium leading-[27px] mr-4 mobile:mb-3 mobile:w-full mobile:px-3'>
                SLA List
              </span>
              {createSLAButton}
            </div>
          </div>
          <div>
            <SelectableTable
              columns={columnsInConverionsTable}
              rows={rowsToShow}
              initialSelection={[]}
              selectionType={SELECTION_MODES.SELECT_MANY}
              onSearchChange={onSearchTextChange}
              onDelete={handleDelete}
              unpublish={handleUnpublish}
              permissions={{
                delete: ability.can('delete', 'router.sla'),
                unPublish: ability.can('update', 'router.sla'),
                update: true,
              }}
            />
          </div>
        </div>
      ) : (
        <div className='flex flex-col border-dashed border-4 border-gray-200 rounded-lg align-items-center justify-center mt-12 py-16 h-[70vh]'>
          <CircleStackIcon className='w-[30px] h-[30px] mb-2 text-gray-500'></CircleStackIcon>
          <span className='font-semibold text-sm leading-[21px] mb-2 text-gray-500'>
            No Service Level Agreements (SLA) Created
          </span>
          <span className='font-semibold text-sm leading-[21px] uppercase mb-4 text-gray-500'>
            Make sure you have done the integration first
          </span>
          <div className='mx-auto'>{createSLAButton}</div>
        </div>
      )}
    </div>
  )
}

export default SlaList
