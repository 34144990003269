import React from 'react'
import ReactDOM from 'react-dom'
import axios, { AxiosResponse } from 'axios'
import { ThemeProvider } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core/styles'
import { BrowserRouter } from 'react-router-dom'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store'
import Qs from 'qs'
import 'flowbite'
import 'dotenv/config'
// import { ErrorBoundary } from './General/ErrorBoundary';
import { ErrorHanlder } from './utils/ErrorHanlder'
import { setloader } from './container/Loader/loader.slice'
import { getcurrentuser } from './utils/localstorage'
import { settokenAndUser } from './container/auth/auth.slice'
import Layout from 'src/layout'
import { title } from './config/constants'
import socket from './socket'
import { config } from './config/constants'
import {
  new_lead_post,
  registercurrentuserwithsocket,
} from './container/RouterDetail/routerDetail.actions'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.scss'
import { fetchSubscriptionStatus } from './container/my_account/account.actions'
import jwtdecode from "jwt-decode"
import { Auth0ProviderWithNavigate } from './components/molecules/Auth0ProviderWithNavigate/Auth0ProviderWithNavigate'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const { SERVER_URL, LOGROCKET_ID } = config

document.title = title

if (LOGROCKET_ID) {
  LogRocket.init(LOGROCKET_ID)
}

setupLogRocketReact(LogRocket)

const socketClient = new socket(SERVER_URL)

axios.interceptors.request.use(
  function (req) {
    req.paramsSerializer = (params) => {
      // Qs is not included in the Axios package
      return Qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      })
    }

    const token = store.getState().userstate.token
    req.url = SERVER_URL + '/api/v1' + req.url

    if (token) {
      validateToken(token)
      req.headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      }
    }
    store.dispatch(setloader(true))
    return req
  },
  function (err) {
    store.dispatch(setloader(false))

    return new ErrorHanlder(err)

    // return Promise.reject(err);
  },
)

axios.interceptors.response.use(
  function (res: AxiosResponse) {
    // @ts-ignore

    store.dispatch(setloader(false))

    return res.data
  },
  function (err) {
    store.dispatch(setloader(false))

    return new ErrorHanlder(err)
    // return Promise.reject(err);
  },
)

function registerCurrentUser() {
  store.dispatch(
    registercurrentuserwithsocket({
      userId: store.getState().userstate.user._id,
    }),
  )
}

const validateToken = (token: string) => {

  let decodedToken: any
  try {
    decodedToken = jwtdecode(token)
  } catch (e) {
    localStorage.clear()
    window.location.href = "/"
  }

  const currentDate = new Date()

  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    localStorage.clear()
    window.location.href = "/"
  }
}

const token = window.localStorage.getItem('token')
if (token) {
  validateToken(token)
  const user = getcurrentuser()
  store.dispatch(new_lead_post())
  store.dispatch(fetchSubscriptionStatus())

  store.dispatch(settokenAndUser({ token, user }))

  registerCurrentUser()
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#2783b6',
    },
    secondary: {
      main: '#2783b6',
    },
  },
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: 'none',
      },
    },
    MuiTableSortLabel: {
      icon: {
        color: '#b7b7c0',
        opacity: '1',
      },
    },
    MuiTablePagination: {
      root: {},
    },
    MuiIconButton: {
      root: {
        '& .MuiSvgIcon-root': {
          width: '1.5em',
          height: '1.5em',
          '& path': {
            color: '#ededed',
          },
        },
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#f1f1f5',
        '& th': {
          backgroundColor: '#f1f1f5',
        },
      },
    },
    MuiTable: {
      root: {
        whiteSpace: 'nowrap',
        // border: "1px solid #ededed !important",
      },
    },
    MuiSvgIcon: {
      root: {
        color: '#a5a2a2',
      },
    },
    MuiIcon: {
      root: {
        fontSize: '22px',
      },
      fontSizeSmall: {
        fontSize: '22px',
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '16px',
      },
    },
    MuiTypography: {
      caption: {
        fontSize: '16px',
      },
    },
    MuiTableCell: {
      root: {
        color: '#ededed',
        fontSize: '16px !important',
        fontWeight: 'bold',
        borderBottom: '0px',
        padding: '16px 5px',
        '& p.Mui-error': {
          position: 'absolute',
          bottom: '-20px',
        },
        '& div': {
          fontSize: '16px',
        },
      },
      head: {
        color: '#ededed',
        padding: '10px 5px',
        fontWeight: 'bold',
        lineHeight: '18px',
        '& div': {
          fontSize: '14px',
          color: '#B7B7C0',
        },
      },
      body: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#3a3938',
      },
    },
  },
})
// Remove console.logs on prod
if (process.env.REACT_APP_NODE_ENV === 'production') {
  console.log = () => {
    // Noop
  }
  console.error = () => {
    // Noop
  }
  console.debug = () => {
    // Noop
  }
}

ReactDOM.render(
  // <ErrorBoundary>
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Auth0ProviderWithNavigate>
              <Layout>
                <App />
              </Layout>
            </Auth0ProviderWithNavigate>
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  // </ErrorBoundary>
  document.getElementById('root'),
)

export { axios as ApiClient, store, socketClient, registerCurrentUser }

// exporting axios to use it in base service
