import { useNavigate, useLocation } from 'react-router-dom'
import { initFlowbite } from 'flowbite'
import { useEffect, useState } from 'react'
import IconRoutera from 'src/components/atoms/icons/icons'
import { selectUser } from 'src/container/auth/auth.selector'
import { useAppSelector } from 'src/store'

const Menu = () => {
  const itemsMenu = [
    {
      id: 1,
      icon: 'UserIcon',
      label: 'Profile',
      href: '/settings/profile',
      dropdown: [],
      notification: '',
      hidden: false
    },
    {
      id: 2,
      icon: 'OfficeBuildingIcon',
      label: 'Account',
      href: '/settings/account',
      dropdown: [],
      notification: '',
      hidden: false
    },
    {
      id: 3,
      icon: 'KeyIcon',
      label: 'Roles',
      href: '/settings/roles',
      dropdown: [],
      notification: '',
    },
    {
      id: 4,
      icon: 'UserGroupIcon',
      label: 'Users',
      href: '/settings/users',
      dropdown: [
        /*{ id: 1, label: 'Users list', href: '/my-account?tab=users' },
        { id: 2, label: 'Schedule', href: '/my-account?tab=users' },*/
      ],
      notification: '',
      hidden: false
    },
    {
      id: 5,
      icon: 'HandIcon',
      label: 'Capacities',
      href: '/settings/capacities',
      dropdown: [],
      notification: '',
      hidden: false
    },
    {
      id: 6,
      icon: 'CircleStackIcon',
      label: 'Integrations',
      href: '/settings/integrations',
      dropdown: [],
      notification: '',
      hidden: false
    },
    {
      id: 7,
      icon: 'CreditCardIcon',
      label: 'Billing',
      href: '/settings/billing',
      dropdown: [],
      notification: '',
      hidden: false
    },
  ]

  const navigation = useNavigate()
  const location = useLocation()
  const current_user = useAppSelector(selectUser)
  const [filterItems, setFilterItems] = useState(itemsMenu)
  useEffect(() => {
    initFlowbite()
  })

  return (
    <aside
      id='sidebar-double'
      className='flex z-40 translate-x-0'
      aria-label='Sidebar'
    >
      <div className='overflow-y-auto z-30 py-2 px-3 lg:px-0 bg-white dark:bg-gray-800 w-[70px] lg:w-64 h-full dark:border-gray-700'>
        <ul className='p-0 -ml-[2px]'>
          {filterItems.map((item) => {
            return (!item.hidden &&
              <div key={`${item.id}-itemsMenu`}>
                <li
                  className='p-[6px] pl-[2px] w-[54px]'
                  data-tooltip-target={`${item.label}-${item.id}`}
                  data-tooltip-placement='right'
                >
                  <span
                    onClick={() => navigation(item.href)}
                    className={`flex items-center p-2 text-gray-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-[#0075FF] text-base font-normal cursor-pointer w-[fit-content] lg:w-[225px] lg:max-w-[225px] ${item.href.includes(location.pathname) && 'text-[#0075FF]'
                      } ${typeof item.notification === 'number' && 'justify-between'
                      }`}
                  >
                    <IconRoutera iconName={item.icon} />
                    <div className='hidden lg:visible lg:inline'>
                      <span className='lg:ml-3 font-medium'>{item.label}</span>
                    </div>
                  </span>
                </li>

                <div className='lg:hidden'>
                  <div
                    id={`${item.label}-${item.id}`}
                    role='tooltip'
                    className='ml-4 absolute z-[58] right-16 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-600 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700 w-[fit-content] lg:hidden'
                  >
                    {item.label}
                    <div className='tooltip-arrow' data-popper-arrow />
                  </div>
                </div>
              </div>
            )
          })}
        </ul>
      </div>
    </aside>
  )
}

export default Menu
