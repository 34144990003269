import SelectableTable, {
	TableColumn,
} from 'src/components/organisms/tables/SelectableTable.Organism'
import { useAppSelector } from 'src/store'
import { getallusers } from 'src/container/my_account/account.selector'
import { useState, useEffect } from 'react'
import { User } from 'src/types/customers'
import Avatar from 'src/components/atoms/avatar/Avatar.Atom'
import { routedLeadsData } from 'src/container/dashboard/dashboard.selector'
import { RoutedLeads } from 'src/types/dashboard'
import { uselistofAllroutes } from 'src/container/leadRouter/leadRoutes.selector'
import { IRoute } from 'src/types/leadRoutes'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import IconRoutera from 'src/components/atoms/icons/icons'

interface IProps {
	limitUsersItems: number
	setLimitUsersItems: (value: number) => void
	setCurrentPageUser: (value: number) => void
	currentPageUser: number
	objectType: HUBSPOT_OBJECT_TYPES
}

const UsersTableDashboard: React.FC<IProps> = (props) => {

	const {
		limitUsersItems,
		setLimitUsersItems,
		setCurrentPageUser,
		currentPageUser,
		objectType
	} = props

	const listRoutes = useAppSelector(uselistofAllroutes)
	const [loadingTable, setLoadingTable] = useState<boolean>(false)

	const allUsers = useAppSelector(getallusers)
	const [users, setUsers] = useState<User[]>()
	const [labelCol1, setLabelCol1] = useState<string>('Email')
	const [routers, setRouters] = useState<IRoute[]>([])
	const [startLimitUsers, setStartLimitUsers] = useState<number>(0)
	const [endLimitUsers, setEndLimitUsers] = useState<number>(5)
	const data = useAppSelector(routedLeadsData)

	useEffect(() => {
		if (allUsers.length > 0) {
			setUsers(allUsers)
		}
	}, [allUsers])

	useEffect(() => {
		if (objectType) {
			getLabelCol()
		}
	}, [])

	useEffect(() => {
		if (listRoutes) {
			setRouters(listRoutes)
		}
	}, [listRoutes])

	const getLabelCol = () => {
		if ((objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.CONTACTS) || (objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.TICKETS)) {
			setLabelCol1('Email')
		} else if (objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.DEALS) {
			setLabelCol1('Pipeline')
		} else if (objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.COMPANIES) {
			setLabelCol1('Domain')
		}
	}

	useEffect(() => {
		if (objectType) {
			getLabelCol()
		}
	}, [objectType])

	const getUserInfo = (id: string): User[] | undefined => {
		return users?.filter((item: User) => item._id === id)
	}

	const getRouterInfo = (id: string): IRoute[] | undefined => {
		return routers?.filter((item: IRoute) => item._id === id)
	}

	const [rowsToShowUsers, setRowsToShowUsers] = useState<RoutedLeads[]>([])

	useEffect(() => {
		setRowsToShowUsers([])
		if (data && data.length > 0 && data[0].leads && data[0].leads._id !== '') {
			const dataAux = [...data]
			setRowsToShowUsers(dataAux)
			setLoadingTable(false)
		}
	}, [data])

	const columnsUserTable: TableColumn[] = [
		{
			label: 'Name',
			field: 'nameUsers',
			render: ({ leads }: any) => {
				return (
					<div className='capitalize'>
						{objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.CONTACTS && ((leads.properties && leads.properties.firstname) ? (leads.properties.firstname + ' ' + leads.properties.lastname) : <span className='text-gray-500 text-sm font-normal italic mr-2'>
							No data*
						</span>)}
						{objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.DEALS && (leads.properties && leads.properties.dealname ? leads.properties.dealname : <span className='text-gray-500 text-sm font-normal italic mr-2'>
							No data*
						</span>)}
						{objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.COMPANIES && ((leads.properties && leads.properties.name) ? leads.properties.name : <span className='text-gray-500 text-sm font-normal italic mr-2'>
							No data*
						</span>)}
						{objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.TICKETS && ((leads.properties && leads.properties.subject) ? leads.properties.subject : <span className='text-gray-500 text-sm font-normal italic mr-2'>
							No data*
						</span>)}
					</div>
				)
			},
		},
		{
			label: labelCol1,
			field: 'labelCol1',
			render: ({ leads }: any) => {
				return (
					<div className='capitalize'>
						{objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.CONTACTS && ((leads.properties && leads.properties.email) ? (leads.properties.email) : <span className='text-gray-500 text-sm font-normal italic mr-2'>
							No data*
						</span>)}
						{objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.DEALS && ((leads.properties && leads.properties.pipeline) ? (leads.properties.pipeline) : <span className='text-gray-500 text-sm font-normal italic mr-2'>
							No data*
						</span>)}
						{objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.COMPANIES && ((leads.properties && leads.properties.domain) ? (leads.properties.domain) : <span className='text-gray-500 text-sm font-normal italic mr-2'>
							No data*
						</span>)}
						{objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.TICKETS && ((leads.properties && leads.properties.email) ? (leads.properties.email) : <span className='text-gray-500 text-sm font-normal italic mr-2'>
							No data*
						</span>)}
					</div>
				)
			},
		},
		{
			label: 'assigned User',
			render: ({ leads }: any) => {
				const userData = getUserInfo(leads.assignedUser)
				return (
					<div>
						{userData && userData.length > 0 && (
							<div className='mr-2 flex items-center'>
								<Avatar
									className='text-sm mr-2 w-[32px] h-[32px]'
									firstName={userData[0].firstName}
									lastName={userData[0].lastName}
									imgURL={userData[0].avatar}
									available={{
										userAvailability: userData[0].userAvailability,
										officeStatus: userData[0].office_status
											? userData[0].office_status
											: false,
									}}
								/>
								<span className='font-normal text-[14px] leading-[21px] text-gray-700'>
									{' '}
									{`${userData[0].firstName} ${userData[0].lastName}`}
								</span>
							</div>
						)}
					</div>
				)
			}
		},
		{
			label: 'assigned Date',
			render: ({ leads }: any) => {
				return (
					<div>
						{leads.dateAssigned}
					</div>
				)
			},
		},
		{
			label: 'router',
			render: ({ leads }: any) => {
				const routerData = getRouterInfo(leads.router)
				return (
					<div className='capitalize'>
						{routerData && routerData[0].name}
					</div>
				)
			},
		}
	]

	const onPaginationChangeUsers = (
		startOffSet: number,
		endOffSet: number,
		currentPage: number,
	) => {
		setStartLimitUsers(startOffSet)
		setEndLimitUsers(endOffSet)
		setCurrentPageUser(currentPage)
	}

	return (
		<div className='bg-white p-3 flex flex-col shadow-sm rounded-lg m-4'>
			<span className='border-b-[1px] border-gray-200 pb-3 mx-4 mt-4 text-gray-900 text-2xl font-bold leading-[30px]'>
				Assignment History
			</span>
			{rowsToShowUsers.length > 0 ? <SelectableTable
				columns={columnsUserTable}
				rows={rowsToShowUsers}
				initialSelection={[]}
				showSelectionType={false}
				showHeaderMeta={false}
				borderTable={false}
				loadingTable={loadingTable}
				limitItems={{
					setLimitItems: setLimitUsersItems,
					totalItems: (rowsToShowUsers.length > 0 ? rowsToShowUsers[0].totalRecords : 5),
					startLimit: startLimitUsers,
					endLimit: currentPageUser === 1 ? rowsToShowUsers.length : endLimitUsers,
					loading: {
						setLoadingData: setLoadingTable,
						loadingData: loadingTable
					}
				}}
				pagination={{
					pageSize: limitUsersItems,
					handlePageChange: onPaginationChangeUsers,
					fetchedDataSize: rowsToShowUsers.length,
					totalDataSize: (rowsToShowUsers.length > 0 ? rowsToShowUsers[0].totalRecords : 0),
					totalPages: Math.ceil(
						(rowsToShowUsers ? (rowsToShowUsers.length > 0 ? rowsToShowUsers[0].totalRecords : 0) : 0) / limitUsersItems,
					),
					loading: {
						setLoadingData: setLoadingTable,
						loadingData: loadingTable
					}
				}}
			/> :
				<div className='flex flex-col items-center justify-center h-full py-[130px] bg-white mt-6 m-4 rounded-lg'>
					<span className='text-gray-700 text-3xl font-bold mb-2 mt-2'>
						No data*
					</span>
					<div className='bg-routera-50 py-[2px] px-[10px] rounded-md w-fit mt-2 z-10'>
						<a
							target='_blank'
							rel='noreferrer'
							href='https://help.routera.io/article/5-create-a-router'
						>
							<span className='flex text-routera-700 text-xs font-medium leading-[18px] items-center cursor-pointer'>
								<IconRoutera
									iconName='InformationCircleIcon'
									className='w-[14px] h-[14px] mr-1'
								/>
								How does this list work?
								<IconRoutera
									iconName='ArrowRightIcon'
									className='w-[14px] h-[14px] ml-1'
								/>
							</span>
						</a>
					</div>
				</div>}
		</div>
	)
}

export default UsersTableDashboard