import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  dashboard: [],
};

const hub_sport = createSlice({
  name: "hub_sport",
  initialState,
  reducers: {},
});

// export const {} = hub_sport.actions;

export default hub_sport.reducer;
