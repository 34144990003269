import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

interface IProps {
  showModal: boolean
  setShowModal: (val: boolean) => void
  showCancelBtn: boolean
  children: React.ReactNode
  handleSave?: ((val: any) => void) | null
  closeOnOut?: boolean
  resetHelper?: () => void
  buttonText?: string
}

export const ModalAtom: React.FC<IProps> = ({
  showModal,
  setShowModal,
  showCancelBtn = true,
  children,
  handleSave,
  closeOnOut = false,
  resetHelper,
  buttonText,
}) => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-50'
        initialFocus={cancelButtonRef}
        onClose={() => {
          closeOnOut && setShowModal(false)
          resetHelper && resetHelper()
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 '>
                {children}
                <div className='bg-gray-50 px-4 py-2.5 sm:flex sm:flex-row-reverse sm:px-6 h-[58px]'>
                  {handleSave && (
                    <button
                      type='button'
                      className='inline-flex w-full justify-center rounded-md border border-transparent bgblue px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={handleSave}
                    >
                      {buttonText ? buttonText : 'Save'}
                    </button>
                  )}
                  {showCancelBtn ? (
                    <button
                      type='button'
                      className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={() => {
                        setShowModal(false)
                        resetHelper && resetHelper()
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
