import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useActions } from '../../actions'
import { useNavigate } from 'react-router-dom'
import toastBuilder from '../../utils/toastBuilder'
import { registerCurrentUser } from '../..'
import registerUserWithLogRocket from '../../utils/LogRocket'
import { useProductMetricsInfo } from 'src/container/Basic/basic.selector'
import { useAppSelector } from 'src/store'
import logoRoutera from 'src/assets/images/logo-routera.png'

export const CallbackUrl = () => {
  const navigate = useNavigate()
  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const { loginWithAuth0, fetchSubscriptionStatus } = useActions()
  const productMetricsInfo = useAppSelector(useProductMetricsInfo)
  const [emailVerified, setEmailVerified] = useState(false)
  const [userEmail, setUserEmail] = useState('')

  useEffect(() => {
    if (isAuthenticated && user) {
      if (user.email_verified) {
        setEmailVerified(true)
      }

      if (user.email) {
        setUserEmail(user.email)
      }
    }
  }, [isAuthenticated, user])

  useEffect(() => {
    const fetchAccessToken = async () => {
      const newAccessToken = await getAccessTokenSilently()
      setToken(newAccessToken)
    }

    if (isAuthenticated) {
      fetchAccessToken()
    }
  }, [getAccessTokenSilently, isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      window.localStorage.setItem('token', token)
      if (token && emailVerified) {
        handleLogin(token)
      }
    }
  }, [isAuthenticated, token])

  const handleLogin = async (token: string) => {
    setLoading(true)

    if (!token) return

    const payload = {
      firstName: user?.given_name ? user?.given_name : user?.name,
      lastName: user?.family_name ? user?.family_name : '',
      email: user?.email,
      companyName: user?.nickname,
      token,
      emailVerified: user?.email_verified,
      avatar: user?.picture,
      sub: user?.sub,
    }

    const action: any = await loginWithAuth0(payload)
    if (action.payload.success) {
      setLoading(false)
      const { user } = action.payload.data
      registerUserWithLogRocket(user)
      navigate('/settings/profile')
      toastBuilder('success', 'Logged in successfully!')
      registerCurrentUser()
      window.location.reload()
    } else {
      setLoading(false)
    }
  }

  const goHome = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('user')
    navigate('/')
    window.location.reload()
  }

  return (
    <>
      {!emailVerified && userEmail && (
        <div className='bg-white w-full h-screen flex justify-center items-center'>
          <div className='bg-white w-96 h-[421px] rounded-xl shadow-lg'>
            <div className='flex justify-center items-center m-12'>
              <img
                className='hidden sm:block shadow-md cursor-pointer'
                src={logoRoutera}
                alt=''
                width={73}
                height={73}
                onClick={goHome}
              />
              <img
                className='sm:hidden block shadow-md cursor-pointer'
                src={logoRoutera}
                alt=''
                width={73}
                height={73}
                onClick={goHome}
              />
            </div>
            <div>
              <div>
                <div className='flex justify-end'>
                  <span className='flex w-4/5 text-center mb-4 text-gray-900 text-3xl font-normal'>
                    Check your Email
                  </span>
                </div>
                <div className='flex justify-center'>
                  <span className='flex w-4/5 text-center mb-4 text-gray-500 text-base font-medium'>
                    Please check the email address {userEmail} for instructions
                    to reset you password
                  </span>
                </div>
                <div className='flex justify-center'>
                  <button
                    onClick={goHome}
                    className='flex w-4/5 justify-center rounded-lg  border border-transparent hover:opacity-100 opacity-80 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 bg-routera-700'
                  >
                    Log In
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
