import React, {
  useState,
  useEffect,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react'
import { setDefaultUser as setDefaultUserSlice } from '../../../container/RouterDetail/routerDetail.slice'
import { useAppSelector } from '../../../store'
import SelectableTable, {
  TableColumn,
  SELECTION_MODES,
} from '../../organisms/tables/SelectableTable.Organism'
import DefaultUserSelection from './DefaultUserSelection'
import { IProps, User } from './types'
import { useActions } from 'src/actions'
import { usesourceUserList } from 'src/container/RouterDetail/routerDetail.selector'
import {
  IRouterDetail,
  IRouterTriggerMetadata,
  ITeamMember,
} from 'src/types/routerDetail'
import toastBuilder from 'src/utils/toastBuilder'
import { useNavigate, useParams } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import Avatar from 'src/components/atoms/avatar/Avatar.Atom'
import IconRoutera from 'src/components/atoms/icons/icons'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'
import { config } from 'src/config/constants'
import { getcurrentuser } from 'src/utils/localstorage'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import { Iuser } from 'src/types/auth'
import conversionsSlice from 'src/container/conversions/conversions.slice'
import TeamTable from 'src/components/organisms/teams/teamTable'
import { Teams } from 'src/types/teams'

const TeamMembersList: React.FC<IProps> = forwardRef((props, ref) => {
  const ability = useAbility(AbilityContext)

  const { FIELD_USER_ROUTER } = config
  const CUSTOM_OWNER_FIELDS = FIELD_USER_ROUTER && FIELD_USER_ROUTER.split(',')

  const [showSelectorField, setShowSelectorField] = useState<boolean>(false)
  const [fields, setFields] = useState([])
  const [objectTypeField, setObjectTypeField] = useState<boolean>(true)
  const [triggerType, setTriggerType] = useState<string>('')

  if (!ability.can('read', 'router.users')) {
    return <NoPermission classHeight='h-[60vh]' value='read' />
  }

  const { teamMembers, specificRouterDetail, teams } = props
  const [disableDefaultUserSelection, setDisableDefaultUserSelection] =
    useState<boolean>(false)

  const {
    getspecificRouterDetail,
    getOwnerFieldsByObjectType,
    upsertTrigger,
    edit_router_action,
  } = useActions()

  const [selectedUsers, setSelectedUsers] = useState<
    Array<Partial<ITeamMember> & { _id: string }>
  >([])
  const [defaultUserId, setDefaultUserId] = useState<string | undefined>(
    specificRouterDetail.defaultUserForAssignment,
  )

  const { routerId = '' } = useParams()

  const [customOwnerField, setCustomOwnerField] = useState<string>('')
  const [valueObjectTypeUsers, setValueObjectTypeUsers] = useState<string>(
    HUBSPOT_OBJECT_TYPES.CONTACTS,
  )
  const [selectedRadioButton, setSelectedRadioButton] = useState('users')
  const [showModalTeam, setShowModalTeam] = useState(false)
  const [selectedUsersTeams, setSelectedUsersTeams] = useState<User[]>([])
  const [currentTeams, setCurrentTeams] = useState<Teams[]>([])
  const [selectedTeams, setSelectedTeams] = useState<Teams[]>([])
  const [disableWorkingHours, setDisableWorkingHours] = useState(
    specificRouterDetail.disableWorkingHours,
  )
  const [defaultAssignmentType, setDefaultAssignmentType] = useState<string>(
    specificRouterDetail.defaultAssignmentType,
  )
  const [defaultTeam, setDefaultTeam] = useState<string | undefined>(
    specificRouterDetail.defaultTeamAssignment,
  )
  const [showToolTipDisableWorkingHours, setShowToolTipDisableWorkingHours] =
    useState<boolean>(false)
  const [showToolTipDisableDefaultUser, setShowToolTipDisableDefaultUser] =
    useState<boolean>(false)

  useEffect(() => {
    setDefaultUserId(specificRouterDetail.defaultUserForAssignment)
    setDisableDefaultUserSelection(
      specificRouterDetail.disable_default_assignment,
    )

    setDefaultAssignmentType(
      specificRouterDetail.defaultAssignmentType
        ? specificRouterDetail.defaultAssignmentType
        : 'user',
    )

    if (specificRouterDetail.defaultTeamAssignment) {
      setDefaultTeam(specificRouterDetail.defaultTeamAssignment)
    } else if (teams && teams.length) {
      setDefaultTeam(teams[0]._id)
    }
  }, [specificRouterDetail])

  useEffect(() => {
    if (specificRouterDetail._id) {
      setCurrentTeams(specificRouterDetail.teams || [])
    }

    if (specificRouterDetail.teams.length > 0) {
      setSelectedRadioButton('teams')
    }
  }, [specificRouterDetail.teams.length])

  useEffect(() => {
    if (!specificRouterDetail.trigger_metadata && specificRouterDetail._id) {
      getspecificRouterDetail(specificRouterDetail._id)
    }
  }, [])

  useEffect(() => {
    if (specificRouterDetail._id) {
      setSelectedUsers(specificRouterDetail.assignedUsersInfo || [])
    }
  }, [specificRouterDetail.assignedUsersInfo.length])

  const {
    getListOfDefaultUsersFromSource,
    updateAssignedTeamMembersList,
    set_default_user,
  } = useActions()
  const allAvailableSourceUsers = useAppSelector(usesourceUserList)

  const navigate = useNavigate()

  const handleSave = async () => {
    // check if need to update or not
    if (
      JSON.stringify(specificRouterDetail.assignedUsersInfo) ===
        JSON.stringify(selectedUsers) &&
      JSON.stringify(selectedUsersTeams) &&
      specificRouterDetail.defaultAssignmentType === defaultAssignmentType &&
      specificRouterDetail.defaultUserForAssignment === defaultUserId &&
      specificRouterDetail.defaultTeamAssignment === defaultTeam &&
      specificRouterDetail.disable_default_assignment ===
        disableDefaultUserSelection &&
      specificRouterDetail.trigger_metadata?.customOwnerField ===
        customOwnerField
    ) {
      // don't update
      if (!selectedUsers.length && !selectedUsersTeams.length) {
        toastBuilder('error', 'Please select a user for assignment')
        return true
      }
    } else {
      if (defaultAssignmentType == 'user' && !defaultUserId) {
        toastBuilder('error', 'Please select a default user for assignment')
        return true
      }
      if (!selectedUsers.length && !selectedUsersTeams.length) {
        toastBuilder('error', 'Please select a user for assignment')
        return true
      }

      if (defaultAssignmentType == 'team' && !defaultTeam) {
        toastBuilder('error', 'Please select a default team for assignment')
        return true
      }

      let data
      if (selectedRadioButton === 'users') {
        setSelectedTeams([])
        setSelectedUsersTeams([])

        data = {
          userIdsArray: selectedUsers.map((object: any) => object._id),
          routerId: specificRouterDetail._id,
          disable_default_assignment: disableDefaultUserSelection,
          defaultAssignmentType: defaultAssignmentType,
          defaultTeamAssignment: defaultTeam,
          teams: [],
        }
      } else {
        setSelectedUsers([])

        data = {
          userIdsArray: selectedUsersTeams,
          routerId: specificRouterDetail._id,
          disable_default_assignment: disableDefaultUserSelection,
          defaultAssignmentType: defaultAssignmentType,
          defaultTeamAssignment: defaultTeam,
          defaultUserId: defaultUserId,
          teams: selectedTeams.map((team: any) => team._id),
        }
      }

      const assignedUsersAction = await updateAssignedTeamMembersList(data)

      try {
        unwrapResult(assignedUsersAction as any)
        // Users Assigned Successfully
      } catch (err) {
        // Users Assignment Failed
        toastBuilder('error', 'Failed to assign users')
        return true
      }

      if (defaultUserId) {
        const defaultUserAction = await set_default_user({
          routerId: specificRouterDetail._id,
          userId: defaultUserId,
        })

        try {
          // Default User Assigned Successfully
          unwrapResult(defaultUserAction as any)
          setDefaultUserSlice(defaultUserId)
        } catch (err) {
          // Default User Assignment Failed
          toastBuilder('error', 'Failed to set default user')
          return true
        }
      }

      if (customOwnerField !== '') {
        const payload: any = {}
        payload.trigger_metadata = {
          customOwnerField,
          objectType: valueObjectTypeUsers,
        }
        payload.trigger_type = triggerType
        await upsertTrigger({
          routerId,
          triggerPayload: payload,
        })
      }
    }

    // Refetch Router Details
    getspecificRouterDetail(specificRouterDetail._id)
    navigate(`/routers/${specificRouterDetail._id}/model`)
  }

  const [teamMembersDisplay, setTeamMembersDisplay] = useState<User[]>([])
  const [showToolTip, setShowToolTip] = useState<boolean>(false)

  useEffect(() => {
    const teamMembersDisplayInitial: User[] = teamMembers.map((teamMember) => {
      return {
        _id: teamMember._id,
        firstName: teamMember.firstName,
        lastName: teamMember.lastName,
        tableData: { checked: false },
        email: teamMember.email,
        avatar: teamMember.avatar,
        teamName: teamMember.teamName,
        office_status: teamMember.office_status,
        userAvailability: teamMember.userAvailability,
      }
    })
    setTeamMembersDisplay(teamMembersDisplayInitial)
  }, [teamMembers])

  useEffect(() => {
    if (
      CUSTOM_OWNER_FIELDS &&
      CUSTOM_OWNER_FIELDS.includes(getcurrentuser().account_id) &&
      specificRouterDetail._id !== ''
    ) {
      setShowSelectorField(true)
      if (specificRouterDetail.trigger_metadata) {
        getDataField(
          specificRouterDetail.trigger_metadata,
          specificRouterDetail,
        )
      }
    }
  }, [specificRouterDetail])

  const getDataField = async (
    triggerMetadata: IRouterTriggerMetadata,
    specificRouter: IRouterDetail,
  ) => {
    setFields([])
    if (
      triggerMetadata.objectType &&
      specificRouter.trigger_type !== 'WORKFLOW_ACTION'
    ) {
      const resp: any = await getOwnerFieldsByObjectType(
        triggerMetadata.objectType,
      )
      if (resp.payload.success) {
        setFields(resp.payload.data)
        if (
          'customOwnerField' in triggerMetadata &&
          triggerMetadata.customOwnerField
        ) {
          setValueObjectTypeUsers(triggerMetadata.objectType)
          setCustomOwnerField(triggerMetadata.customOwnerField)
        } else {
          setValueObjectTypeUsers(triggerMetadata.objectType)
          setCustomOwnerField(resp.payload.data[0].name)
        }
      }
    } else {
      if (specificRouter.trigger_type === 'WORKFLOW_ACTION') {
        setObjectTypeField(false)
        if (!triggerMetadata.objectType) {
          getField(HUBSPOT_OBJECT_TYPES.CONTACTS, false)
        }
        if (triggerMetadata.customOwnerField && triggerMetadata.objectType) {
          setCustomOwnerField(triggerMetadata.customOwnerField)
          getField(triggerMetadata.objectType, false)
        }
      }
    }
    if (specificRouter.trigger_type) {
      setTriggerType(specificRouter.trigger_type)
    }
  }

  const getField = async (
    objectTypeUsers: string,
    changesSelector: boolean,
  ) => {
    setValueObjectTypeUsers(objectTypeUsers)
    const resp: any = await getOwnerFieldsByObjectType(objectTypeUsers)
    if (resp.payload.success) {
      setFields(resp.payload.data)
      if (changesSelector) {
        setCustomOwnerField(resp.payload.data[0].name)
      }
    }
  }

  const onSearchTextChange = (searchText: string) => {
    // FIXME - Change this to server side search as per need
    if (searchText === '') {
      setTeamMembersDisplay(
        teamMembers.map((teamMember) => {
          return {
            _id: teamMember._id,
            firstName: teamMember.firstName,
            lastName: teamMember.lastName,
            tableData: { checked: false },
            email: teamMember.email,
            avatar: teamMember.avatar,
            teamName: teamMember.teamName,
            office_status: teamMember.office_status,
            userAvailability: teamMember.userAvailability,
          }
        }),
      )
      return
    }
    setTeamMembersDisplay(
      teamMembers.filter((teamMember: any) =>
        teamMember.firstName.toLowerCase().includes(searchText.toLowerCase()),
      ) as unknown as User[],
    )
  }

  const onSelectionChange = async (rows: User[]) => {
    setSelectedUsers(rows)
  }

  useEffect(() => {
    if (specificRouterDetail._id) {
      getListOfDefaultUsersFromSource(specificRouterDetail._id)
    }
  }, [specificRouterDetail._id])

  const defaultUsersList = useMemo(() => {
    if (!allAvailableSourceUsers || !allAvailableSourceUsers.length) {
      return []
    }

    if (allAvailableSourceUsers.length > 0 && !defaultUserId) {
      const userAdministrator = allAvailableSourceUsers.find(
        (user: Iuser) => user.role === 'Administrator',
      )
      if (userAdministrator) {
        //setDefaultUserId(userAdministrator._id)
      } else {
        setDefaultUserId(allAvailableSourceUsers[0]._id)
      }
    }

    return (
      (allAvailableSourceUsers as unknown as User[]).filter((user) => {
        return teamMembers.some((teamMember) => teamMember._id === user._id)
      }) || []
    )
  }, [
    allAvailableSourceUsers && allAvailableSourceUsers.length,
    selectedUsers && selectedUsers.length,
  ])

  const handleDefaultUserChange = (user: User) => {
    if (user && user._id) {
      setDefaultUserId(user._id)
      setDefaultTeam(undefined)
    } else {
      setDefaultUserId('')
    }
  }

  const columns: Array<TableColumn> = [
    {
      label: 'Name',
      render: ({
        avatar = '',
        firstName = '',
        lastName = '',
        userAvailability,
        office_status,
      }: any) => {
        return (
          <>
            <div className='mr-2 flex items-center'>
              <Avatar
                className='w-8 h-8 text-sm mr-2'
                firstName={firstName}
                lastName={lastName}
                imgURL={avatar}
                available={{
                  userAvailability: userAvailability,
                  officeStatus: office_status ? office_status : false,
                }}
              />
              <span> {`${firstName} ${lastName}`}</span>
            </div>
          </>
        )
      },
    },
    {
      label: 'Team',
      field: 'teamName',
    },
    {
      label: 'Email',
      field: 'email',
    },
    {
      label: 'Phone number',
      field: 'phone',
    },
  ]

  const isTeamMemberSelected = (row: ITeamMember) => {
    if (row) {
      return selectedUsers.map((user) => user._id).includes(row._id)
    }
    return false
  }

  useImperativeHandle(ref, () => ({
    onRefSave() {
      return handleSave()
    },
  }))

  const selectField = (event: any) => {
    setCustomOwnerField(event)
  }

  const changeSelectType = (value: string) => {
    getField(value, true)
  }

  const changeDefaultAssignmentType = (value: string) => {
    setDefaultAssignmentType(value)
  }

  const changeDefaultTeam = (value: string) => {
    setDefaultTeam(value)
  }

  // useEffect(() => {
  //   if (defaultAssignmentType === 'team') {
  //     if (teams && teams.length) {
  //       if (!defaultTeam) {
  //         setDefaultTeam(teams[0]._id)
  //       }
  //     }
  //   }
  // }, [defaultAssignmentType])

  const handleChange = (event: any) => {
    setSelectedRadioButton(event.target.value)
  }

  const handleSelectedTeams = async (teams: Teams[]) => {
    const users: any = []

    teams.forEach((team) => {
      users.push(...team.user_ids)
    })

    setSelectedTeams(teams)
    setSelectedUsersTeams(users)
  }

  const isTeamSelected = (row: Teams) => {
    if (row) {
      return selectedTeams.map((team) => team._id).includes(row._id)
    }
    return false
  }

  const handleChangeDisableWorkingHours = async () => {
    const response: any = await edit_router_action({
      routerId,
      disableWorkingHours: !disableWorkingHours,
    })

    if (response && response.payload) {
      toastBuilder('success', 'Updated Disable working hours')
    }

    setDisableWorkingHours(!disableWorkingHours)
  }

  const handleChangeDisableDefaultUser = async () => {
    const response: any = await edit_router_action({
      routerId,
      disable_default_assignment: !disableDefaultUserSelection,
    })

    if (response && response.payload) {
      toastBuilder('success', 'Updated disable default user')
    }

    setDisableDefaultUserSelection(!disableDefaultUserSelection)
  }

  return (
    <div className='mt-4 h-full mb-10'>
      <div className='mt-2 mb-3  flex justify-between flex-wrap items-start tablet:flex-col'>
        <div className='flex flex-col'>
          <span className='text-lg font-medium flex-grow-1'>Users</span>
          <span className='text-bs flex-grow-1 mt-2 mb-2'>
            Select the users that will be included in this rotation
          </span>
        </div>
        <div className='flex tablet:flex-col'>
          <div className='tablet:mt-4'>
            {showToolTip && (
              <div className='shadow-md max-w-[192px] text-center text-base bg-gray-700 rounded-md z-10 absolute -my-28 -ml-2 mr-2 flex flex-col items-start p-3'>
                <span className='text-white text-xs'>Default User</span>
                <span className='text-gray-400 text-xs text-left mt-1'>
                  Select a default user in case that all users are marked as
                  unavailable.
                </span>
              </div>
            )}
            <div className='flex flex-col md:flex-row justify-between items-start'>
              <div className='ml-0 tablet:mt-4 mr-4 mb-2'>
                <div className='flex items-center'>
                  <div>
                    <IconRoutera
                      iconName='InformationCircleIcon'
                      className='h-[16px] w-[16px] text-gray-500'
                    />
                  </div>
                  <span className='ml-1 text-sm text-gray-800 font-medium flex-grow-1'>
                    Default assignment type
                  </span>
                </div>
                <select
                  disabled={
                    !ability.can('update', 'router.users') ||
                    disableDefaultUserSelection
                  }
                  id='default-assignment-type'
                  value={defaultAssignmentType}
                  className={`rounded-lg border w-[210px] border-gray-300 text-gray-500 text-sm bg-white py-2 pl-3 pr-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 mt-[8px] ${
                    !ability.can('update', 'router.users') ||
                    disableDefaultUserSelection
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                  }`}
                  onChange={(value) =>
                    changeDefaultAssignmentType(value.target.value)
                  }
                >
                  <option value='user'>User</option>
                  <option value='team'>Team</option>
                </select>
              </div>
              {defaultAssignmentType == 'user' ? (
                <div className='mr-2'>
                  <div className='flex items-center'>
                    <div
                      onMouseEnter={() => setShowToolTip(true)}
                      onMouseLeave={() => setShowToolTip(false)}
                    >
                      <IconRoutera
                        iconName='InformationCircleIcon'
                        className='h-[16px] w-[16px] text-gray-500'
                      />
                    </div>
                    <span className='ml-1 text-sm text-gray-800 font-medium flex-grow-1'>
                      Default User
                    </span>
                  </div>
                  <DefaultUserSelection
                    initialSelectedUserId={defaultUserId}
                    selectedUsers={defaultUsersList}
                    handleDefaultUserChange={handleDefaultUserChange}
                    disabled={
                      !ability.can('update', 'router.users') ||
                      disableDefaultUserSelection
                    }
                  />
                </div>
              ) : (
                <div className='mr-2'>
                  <div className='flex items-center'>
                    <div
                      onMouseEnter={() => setShowToolTip(true)}
                      onMouseLeave={() => setShowToolTip(false)}
                    >
                      <IconRoutera
                        iconName='InformationCircleIcon'
                        className='h-[16px] w-[16px] text-gray-500'
                      />
                    </div>
                    <span className='ml-1 text-sm text-gray-800 font-medium flex-grow-1'>
                      Default Team
                    </span>
                  </div>
                  <select
                    id='rows'
                    disabled={
                      !ability.can('update', 'router.users') ||
                      disableDefaultUserSelection
                    }
                    onChange={(v: any) => changeDefaultTeam(v.target.value)}
                    className={`rounded-lg border w-[256px] border-gray-300 text-gray-500 text-sm bg-white py-2 pl-3 pr-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 mt-[8px] ${
                      !ability.can('update', 'router.users') ||
                      disableDefaultUserSelection
                        ? 'cursor-not-allowed opacity-50'
                        : 'cursor-pointer'
                    }`}
                    value={defaultTeam}
                  >
                    {teams.map((option: Teams, index: number) => {
                      return (
                        <option
                          value={option._id}
                          key={`${option.name}_${index}`}
                        >
                          {option.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              )}
            </div>

            <div className='flex mb-4'>
              <div className='flex items-center mr-2 mt-2'>
                <input
                  checked={disableDefaultUserSelection}
                  onChange={handleChangeDisableDefaultUser}
                  id='disable-default-user'
                  type='checkbox'
                  disabled={!ability.can('update', 'router')}
                  className={`h-4 w-4 rounded border-gray-300 focus:ring-0 focus:outline-none sm:left-6 ${
                    !ability.can('update', 'router')
                      ? 'cursor-not-allowed'
                      : 'hover:cursor-pointer'
                  }`}
                ></input>
                <label
                  htmlFor='disable-default-user'
                  className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                >
                  Disable default user
                </label>
              </div>
              <div className='flex items-center mt-2'>
                <div
                  onMouseEnter={() => setShowToolTipDisableDefaultUser(true)}
                  onMouseLeave={() => setShowToolTipDisableDefaultUser(false)}
                >
                  <IconRoutera
                    iconName='InformationCircleIcon'
                    className='h-[16px] w-[16px] text-gray-500'
                  />
                </div>
              </div>
              {showToolTipDisableDefaultUser && (
                <div className='shadow-md max-w-[192px] text-center text-base bg-gray-700 rounded-md z-10 absolute mt-[1rem] -mb-28 ml-[200px] mr-2 flex flex-col items-start p-3'>
                  <span className='text-white text-xs'>
                    Disable default user
                  </span>
                  <span className='text-gray-400 text-xs text-left mt-1'>
                    Enabling this option will not assign the default user when
                    routing.
                  </span>
                </div>
              )}
            </div>
          </div>
          {showSelectorField && (
            <>
              {!objectTypeField && fields.length > 0 && (
                <div className='ml-4 tablet:ml-0 tablet:mt-4'>
                  <div className='flex items-center'>
                    <div>
                      <IconRoutera
                        iconName='InformationCircleIcon'
                        className='h-[16px] w-[16px] text-gray-500'
                      />
                    </div>
                    <span className='ml-1 text-sm text-gray-800 font-medium flex-grow-1'>
                      Object Type
                    </span>
                  </div>
                  <select
                    disabled={!ability.can('update', 'router.users')}
                    id='objectTypeUsers'
                    value={valueObjectTypeUsers}
                    className={`rounded-lg border w-[210px] border-gray-300 text-gray-500 text-sm bg-white py-2 pl-3 pr-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 mt-[8px] ${
                      !ability.can('update', 'router.users')
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    onChange={(value) => changeSelectType(value.target.value)}
                  >
                    <option value={HUBSPOT_OBJECT_TYPES.CONTACTS}>
                      Contact
                    </option>
                    <option value={HUBSPOT_OBJECT_TYPES.DEALS}>Deal</option>
                    <option value={HUBSPOT_OBJECT_TYPES.COMPANIES}>
                      Company
                    </option>
                    <option value={HUBSPOT_OBJECT_TYPES.TICKETS}>Ticket</option>
                  </select>
                </div>
              )}
              {fields.length > 0 && (
                <div className='ml-4 tablet:ml-0 tablet:mt-4'>
                  <div className='flex items-center'>
                    <div>
                      <IconRoutera
                        iconName='InformationCircleIcon'
                        className='h-[16px] w-[16px] text-gray-500'
                      />
                    </div>
                    <span className='ml-1 text-sm text-gray-800 font-medium flex-grow-1'>
                      Custom Owner Field
                    </span>
                  </div>
                  <select
                    id='rows'
                    disabled={!ability.can('update', 'router.users')}
                    onChange={(v: any) => selectField(v.target.value)}
                    className={`rounded-lg border w-[210px] border-gray-300 text-gray-500 text-sm bg-white py-2 pl-3 pr-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 mt-[8px] ${
                      !ability.can('update', 'router.users')
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    value={customOwnerField}
                  >
                    {fields.map((option: any, index: number) => {
                      return (
                        <option
                          value={option.name}
                          key={`${option.value}_${index}`}
                        >
                          {option.label}
                        </option>
                      )
                    })}
                  </select>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className=''>
        <div className='flex flex-wrap mb-4'>
          <div className='flex items-center'>
            <input
              checked={selectedRadioButton === 'users'}
              onChange={handleChange}
              id='users'
              type='radio'
              value='users'
              name='users'
              className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
            ></input>
            <label
              htmlFor='users'
              className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              Users
            </label>
          </div>
          <div className='flex items-center ml-9'>
            <input
              checked={selectedRadioButton === 'teams'}
              onChange={handleChange}
              id='teams'
              type='radio'
              value='teams'
              name='teams'
              className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
            ></input>
            <label
              htmlFor='teams'
              className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              Teams
            </label>
          </div>
        </div>

        {selectedRadioButton === 'users' ? (
          <SelectableTable
            columns={columns}
            rows={teamMembersDisplay}
            initialSelection={selectedUsers}
            selectionType={SELECTION_MODES.SELECT_MANY}
            onSearchChange={onSearchTextChange}
            onSelectionChange={onSelectionChange}
            isRowSelected={isTeamMemberSelected}
            showSelectionType={ability.can('update', 'router.users')}
            permissions={{
              update: ability.can('update', 'router.users'),
            }}
          />
        ) : (
          <TeamTable
            setShowModalTeam={setShowModalTeam}
            userTab={false}
            allTeams={teams}
            allUsers={teamMembers}
            onTeams={handleSelectedTeams}
            selectedTeams={currentTeams}
            isTeamSelected={isTeamSelected}
          />
        )}

        <div className='tablet:mt-4'>
          <div className='flex flex-wrap mb-4 m-[1.25rem]'>
            <div className='flex items-center mr-2'>
              <input
                checked={disableWorkingHours}
                onChange={handleChangeDisableWorkingHours}
                id='disable-working-hours'
                type='checkbox'
                disabled={!ability.can('update', 'router')}
                className={`h-4 w-4 rounded border-gray-300 focus:ring-0 focus:outline-none sm:left-6 ${
                  !ability.can('update', 'router')
                    ? 'cursor-not-allowed'
                    : 'hover:cursor-pointer'
                }`}
              ></input>
              <label
                htmlFor='disable-working-hours'
                className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
              >
                Disable working hours when all users are unavailable
              </label>
            </div>
            {showToolTipDisableWorkingHours && (
              <div className='shadow-md max-w-[192px] text-center text-base bg-gray-700 rounded-md z-10 absolute -mt-[9rem] -mb-28 ml-[305px] mr-2 flex flex-col items-start p-3'>
                <span className='text-white text-xs'>
                  Disable working hours
                </span>
                <span className='text-gray-400 text-xs text-left mt-1'>
                  Enabling this option will only take into consideration Out of
                  Office dates set when all users are outside Working Hours.
                </span>
              </div>
            )}
            <div className='flex items-center'>
              <div
                onMouseEnter={() => setShowToolTipDisableWorkingHours(true)}
                onMouseLeave={() => setShowToolTipDisableWorkingHours(false)}
              >
                <IconRoutera
                  iconName='InformationCircleIcon'
                  className='h-[16px] w-[16px] text-gray-500'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

TeamMembersList.displayName = 'TeamMembersList'

export default TeamMembersList
