import { HttpService } from './base.service'

class AuthService extends HttpService {
  private readonly prefix = 'users'
  login = (data: any) => this.post(`${this.prefix}/login`, data)

  signup = (data: any) => this.post(`${this.prefix}/signup`, data)
  forgetpassword = (data: any) =>
    this.post(`${this.prefix}/forget-password`, data)

  resetPassword = (token: string, data: any) =>
    this.patch(`${this.prefix}/reset-password/${token}`, data)
  getCalendar = () => this.get(`${this.prefix}/get-calendars`)
  googlelogin = (data: any) =>
    this.post(`${this.prefix}/login-with-google`, data)
  updateuserData = (data: any) => this.patch(`${this.prefix}/update-user`, data)

  uplodprofileimage = (data: any) =>
    this.post(`${this.prefix}/upload-profile-picture`, data)

  setuserpassword = (data: any) =>
    this.post(`${this.prefix}/set-user-password`, data)

  changeoffice_status = (data: any) =>
    this.post(`${this.prefix}/update-office-status`, data)

  updateTeamMemberWorkingHours = (data: any) =>
    this.patch(`${this.prefix}/set-working-hours/${data._id}`, data)

  changeMaxLoad = (data: any) =>
    this.post(`${this.prefix}/update-max-load`, data)

  changeMyOfficeStatus = (data: any) =>
    this.post(`${this.prefix}/update-my-office-status`, data)

  setCapacities = (id: string, data: any) =>
    this.patch(`${this.prefix}/set-user-capacties/${id}`, data)

  getDealPipelines = () => this.get(`${this.prefix}/get-deal-pipeline-labels`)
  getUserDetails = () => this.get(`${this.prefix}/get-user-details`)

  getUserPermissions = () => this.get(`${this.prefix}/get-user-permissions`)

  loginWithAuth0 = (data: any) => this.post(`${this.prefix}/login-auth`, data)

  updateUserFromHubspot = (data: { email: string }) =>
    this.post(`${this.prefix}/user-details-hubspot`, data)
}

export const authService = new AuthService()
