import { createSlice } from '@reduxjs/toolkit';
import { IleadRoutesState } from '../../types/leadRoutes';
import { createReducerBuilder } from '../../utils/actions';
import { getallRoutes, registernewRoute } from './leadRoutes.actions';

const initialState: IleadRoutesState = {
  allroutes: [],
};

const reducerBuilder = createReducerBuilder<IleadRoutesState>();

const create_new_route = reducerBuilder(registernewRoute, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.allroutes = [payload, ...state.allroutes];
    }
  },
});

const get_all_routes = reducerBuilder(getallRoutes, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.allroutes = payload;
    }
  },
});

const customerservice = createSlice({
  name: 'customerslice',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    create_new_route(builder);
    get_all_routes(builder);
  },
});

export default customerservice.reducer;
