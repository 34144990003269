import { useEffect, useState } from "react"
import { initFlowbite } from 'flowbite'
import IconRoutera from "src/components/atoms/icons/icons"
import { DropDownSearch } from "src/types/dashboard"

interface IProps {
	setData: (data: DropDownSearch[]) => void
	dataFilter?: () => DropDownSearch[]
	data: DropDownSearch[]
	setItemSelected: (data: DropDownSearch) => void
	itemSelected?: DropDownSearch
	zIndex?: string
	idDropDown: string
	labelDropdown: string
}

const DropdownSearch: React.FC<IProps> = (props) => {

	const { setData, dataFilter, data, setItemSelected, itemSelected, zIndex, idDropDown, labelDropdown } = props

	const [valueSearch, setValueSearch] = useState<string>('')
	const [hiddenSelect, setHiddenSelect] = useState<string>('hidden')

	useEffect(() => {
		initFlowbite()
	}, [])

	const selectItem = (item: DropDownSearch) => {

		setHiddenSelect('hidden')
		setItemSelected(item)
		if (dataFilter) {
			setData(dataFilter())
		}
		setValueSearch('')
	}

	const onSearchTextChange = (searchText: string) => {
		setValueSearch(searchText)

		if (searchText === '') {
			if (dataFilter) {
				setData(dataFilter())
			}
			return
		}

		setData(
			data.filter((item) =>
				item.label.toLowerCase().includes(searchText.toLowerCase()),
			),
		)
	}

	const openSelect = () => {
		setHiddenSelect('block')
	}

	return <div className="mr-8 mt-2 w-[229px]">
		<div onClick={openSelect}>
			<label htmlFor="dropdownsearch" className='block text-sm font-medium text-gray-800 leading-[21px]'>{labelDropdown}</label>
			<div id={'dropdownSearchComponent' + idDropDown} data-dropdown-toggle={idDropDown} data-dropdown-placement="bottom" className={`h-[37px] relative w-full border border-gray-300 bg-white flex pl-3 pr-10 text-left focus:outline-none focus:ring-1 sm:text-sm rounded-[8px] text-gray-500 mt-[9px] items-center capitalize ${data.length > 0 && 'cursor-pointer'}`}>
				{data.length === 0 ? labelDropdown : itemSelected && (itemSelected.label.length > 19 ? (itemSelected.label.substring(0, 19) + ' ' + '...') : itemSelected.label)}
				<span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
					<IconRoutera
						iconName='ChevronDownIcon'
						className='h-5 w-5 text-gray-500'
						aria-hidden='true'
					/>
				</span>
			</div>
		</div>
		<div id={idDropDown} className={`bg-white rounded-lg shadow w-60 ${zIndex ? zIndex : 'z-10'} ${hiddenSelect}`}>
			<div className="p-3">
				<label htmlFor={'input-group-search' + idDropDown} className="sr-only">Search</label>
				<div className="relative">
					<input type="text" id={'input-group-search' + idDropDown} className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder={"Search"} onChange={(evt) => {
						onSearchTextChange(evt.currentTarget.value)
					}} value={valueSearch} />
				</div>
			</div>
			{data.length > 0 ? <ul className={`h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700`} aria-labelledby={'dropdownSearchComponent' + idDropDown}>
				{(data.map((item: DropDownSearch, index: number) => (
					<li key={item.id + 'search'} onClick={() => selectItem(item)} >
						<div className={`flex items-center pl-2 rounded hover:bg-gray-100 ${itemSelected && itemSelected.id === item.id && 'bg-gray-100'}`}>
							<label htmlFor={item.label + item.id + 'searchDropdown'} className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded capitalize">{item.label}</label>
						</div>
					</li>
				)))
				}
			</ul> : <span className='text-gray-500 flex justify-center mb-2'>No data</span>}
		</div>

	</div>
}

export default DropdownSearch