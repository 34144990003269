import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'src/store'
import { timeformater } from 'src/utils'
import SelectableTable, {
  SELECTION_MODES,
} from 'src/components/organisms/tables/SelectableTable.Organism'
import { useQuery } from '@tanstack/react-query'
import { sourceService } from 'src/services/sources.service'
import { selectIntegrations } from 'src/container/integrations/integrations.selector'
import { getTitleCase } from 'src/utils/common/string.common'
import toastBuilder from 'src/utils/toastBuilder'
import { Source } from 'src/types/sources'

export enum SOURCE_QUERY_KEYS {
  GET_SOURCES = 'getSources',
}

const SourcesTableTemplate = () => {
  const [query, setQuery] = useState<Record<string, any>>({})
  const integrations = useAppSelector(selectIntegrations)

  const { isSuccess, isRefetching, data, refetch } = useQuery(
    [SOURCE_QUERY_KEYS.GET_SOURCES, Object.values(query)],
    () => sourceService.getAllSources(query),
    { retry: false },
  )

  const sourceRows = useMemo(() => {
    if (isSuccess) {
      const { sources } = data?.data
      return sources || []
    }
    return []
  }, [isSuccess, isRefetching])

  const navigate = useNavigate()

  const onRowClick = (_event: unknown, row: unknown): void => {
    const integrationId = (row as { [key: string]: string }).integration_id
    const foundIntegration = Object.keys(integrations).find(
      (integrationKey) =>
        (integrations as any)[integrationKey]._id === integrationId,
    )
    if (foundIntegration) {
      const sourceId = (row as { [key: string]: string })._id
      navigate(`/sources/${sourceId}`)
    }
  }

  const onDelete = async (rowData: Source[]) => {
    const metapayload = rowData.map((source) => source._id)
    const payload = {
      sourceIds: metapayload,
    }
    const response: any = await sourceService.deleteSources(payload)
    const toastType = response.success ? 'success' : 'error'
    toastBuilder(toastType, response.message)
    refetch()
  }

  const onSearchChange = (searchText: string) => {
    if (searchText && searchText.length > 0) {
      setQuery({
        name: {
          $regex: searchText,
          $options: 'gi',
        },
      })
    } else {
      setQuery({})
    }
  }

  const columns = [
    {
      label: 'Name',
      field: 'name',
      render: (sourceRow: Source) => {
        return (
          <div>
            <p
              className='inline-block hover:text-blue-500 hover:cursor-pointer transition-colors duration-150 ease-in-out mb-0'
              onClick={(evt) => onRowClick(evt, sourceRow)}
            >
              {sourceRow.name}
            </p>
          </div>
        )
      },
    },
    {
      label: 'Integration Type',
      field: 'integration_id',
      render: ({ integration_id }: Source) => (
        <div>
          <p className='mb-0'>
            {getTitleCase(
              Object.keys(integrations).find(
                (integrationKey) =>
                  (integrations as any)[integrationKey]._id === integration_id,
              ) || 'Integration Disconnected',
            )}
          </p>
        </div>
      ),
    },
    {
      label: 'Updated at',
      field: 'updatedAt',
      render: ({ updatedAt }: Source) => {
        return (
          <div>
            <p className='mb-0'>{timeformater(updatedAt)}</p>
          </div>
        )
      },
    },
  ]

  return (
    <SelectableTable
      columns={columns}
      rows={sourceRows}
      selectionType={SELECTION_MODES.SELECT_MANY}
      onSearchChange={onSearchChange}
      onDelete={onDelete}
    />
  )
}

export default SourcesTableTemplate
