import { ApiClient } from '../index'

export class HttpService {
  protected get = (url: string, params?: any) => {
    return ApiClient.get(`/${url}`, {
      params,
    })
  }

  protected post = (url: string, body: any, options = {}) =>
    ApiClient.post(`/${url}`, body, {
      ...options,
    })

  protected delete = (url: string, params?: any, data?: any) =>
    ApiClient.delete(`/${url}`, { params, data })

  protected put = (url: string, body?: any, params?: any) =>
    ApiClient.put(`/${url}`, body, {
      ...params,
    })
  protected patch = (url: string, body?: any, params?: any) =>
    ApiClient.patch(`/${url}`, body, {
      ...params,
    })
}
