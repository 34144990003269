import WidgetTotalCapacity from 'src/components/molecules/widgetTotalCapacity/widgetTotalCapacity'
import WidgetTotalRouted from 'src/components/molecules/widgetTotalRouted/widgetTotalRouted'
import WidgetTotalSLAs from 'src/components/molecules/widgetTotalSLAs/widgetTotalSLAs'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import { useAppSelector } from 'src/store'
import {
  totalRoutedData,
  totalCapacityData,
} from 'src/container/dashboard/dashboard.selector'
import { useEffect, useState } from 'react'
import { useActions } from 'src/actions'
import { getAccountById } from 'src/container/my_account/account.selector'
import { selectUser } from 'src/container/auth/auth.selector'
import { config } from 'src/config/constants'

const { FREE_PLAN_MONTHLY } = config

interface IProps {
  objectType: HUBSPOT_OBJECT_TYPES
  dateRangeSLA: string
  setLimitSLAsItems: (value: number) => void
  limitSLAsItems: number
  setCurrentPageSLAsItems: (value: number) => void
  currentPageSLAsItems: number
  setOrderBySLADetail: (value: number) => void
  orderBySLADetail: number
}

const WidgetsDashboard: React.FC<IProps> = (props) => {
  const {
    objectType,
    dateRangeSLA,
    setLimitSLAsItems,
    limitSLAsItems,
    setCurrentPageSLAsItems,
    currentPageSLAsItems,
    setOrderBySLADetail,
    orderBySLADetail,
  } = props

  const { getAccount } = useActions()
  const totalRouted = useAppSelector(totalRoutedData)
  const totalCapacities = useAppSelector(totalCapacityData)
  const currentUser = useAppSelector(selectUser)
  const account = useAppSelector(getAccountById)
  const [isObjectCDType, setIsObjectCDType] = useState<boolean>(false)
  const [freePlan, setFreePlan] = useState(false)

  useEffect(() => {
    if (currentUser && currentUser.account_id) {
      getAccount(currentUser.account_id)
    }
  }, [])

  useEffect(() => {
    if (account && account.plan_id === FREE_PLAN_MONTHLY) {
      setFreePlan(true)
    }
  }, [account])

  useEffect(() => {
    setIsObjectCDType(false)
    if (
      objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.CONTACTS ||
      objectType.toLowerCase() === HUBSPOT_OBJECT_TYPES.DEALS
    ) {
      setIsObjectCDType(true)
    }
  }, [objectType])

  return (
    <div
      className={`flex items-center lg:items-start -mt-4 tablet:mx-5 ${
        isObjectCDType
          ? 'flex-wrap justify-around'
          : 'md:flex-row flex-col justify-between'
      }`}
    >
      <WidgetTotalRouted
        objectType={objectType}
        totalRouted={totalRouted}
        classWidget={`h-[156px] ${
          isObjectCDType
            ? 'sm:w-[439px] lg:w-[30%] tablet:w-[100%] mx-3 '
            : 'w-[48%] tablet:w-[100%] mx-[25px]'
        }`}
      />
      {isObjectCDType && (
        <WidgetTotalCapacity
          totalCapacity={totalCapacities ? totalCapacities[0] : null}
          classWidget='sm:w-[439px] lg:w-[30%] h-[156px] tablet:w-[100%] mx-3'
          freePlan={freePlan}
        />
      )}
      <WidgetTotalSLAs
        setLimitSLAsItems={setLimitSLAsItems}
        limitSLAsItems={limitSLAsItems}
        dateRangeSLA={dateRangeSLA}
        classWidget={`h-[156px] ${
          isObjectCDType
            ? 'mx-3  sm:w-[439px] lg:w-[30%] tablet:w-[100%]'
            : 'mx-[25px] w-[48%] tablet:w-[100%]'
        }`}
        currentPageSLAsItems={currentPageSLAsItems}
        setCurrentPageSLAsItems={setCurrentPageSLAsItems}
        setOrderBySLADetail={setOrderBySLADetail}
        orderBySLADetail={orderBySLADetail}
        freePlan={freePlan}
      />
    </div>
  )
}

export default WidgetsDashboard
