import { ChangeEventHandler, useEffect, useMemo, useState } from 'react'

export type HandlePageChange = (
  startOffSet: number,
  endOffSet: number,
  currentPage: number,
) => void

export interface Props {
  pageSize: number
  pageSizeOptions?: number[]
  handlePageChange: HandlePageChange
  isNextButtonDisabled?: boolean
  isPreviousButtonDisabled?: boolean
  fetchedDataSize: number
  totalDataSize?: number
  className?: string
  totalPages?: number
  loading?: {
    setLoadingData: (value: boolean) => void
    loadingData: boolean
  }
}

export const defaultPageSizeOptions: Array<number> = [10, 25, 100]

const Pagination: React.FC<Props> = ({
  handlePageChange,
  pageSize,
  isNextButtonDisabled,
  isPreviousButtonDisabled,
  pageSizeOptions,
  fetchedDataSize,
  totalDataSize = 10,
  className,
  totalPages = 1,
  loading
}) => {
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [startOffSet, setstartOffSet] = useState<number>(0)
  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSize)
  const [currentPageNum, setCurrentPageNum] = useState<number>(1)

  const getLastPage = useMemo(() => {
    return Math.ceil(totalDataSize / currentPageSize)
  }, [totalDataSize, currentPageSize])

  const incrementPage = () => {
    if (currentPageNum < totalPages && !isNextButtonDisabled) {
      if (loading) {
        loading.setLoadingData(true)
      }
      setstartOffSet(startOffSet + currentPageSize)
      setPageNumber(pageNumber + 1)
      setCurrentPageNum(currentPageNum + 1)
    }
  }

  const decrementPage = () => {
    if (loading) {
      loading.setLoadingData(true)
    }
    if (!isPreviousButtonDisabled && pageNumber > 0) {
      setstartOffSet(
        startOffSet - currentPageSize < 0 ? 0 : startOffSet - currentPageSize,
      )
      setPageNumber(pageNumber - 1)
      setCurrentPageNum(currentPageNum - 1)
    }
  }

  useEffect(() => {
    let start = startOffSet + 1
    let end = startOffSet + currentPageSize
    if (currentPageNum === 1 && startOffSet !== 0) {
      start = startOffSet
    }

    if (currentPageNum === totalPages) {
      end = totalDataSize
    }

    handlePageChange(start, end, currentPageNum)
  }, [startOffSet])

  useEffect(() => {
    setCurrentPageSize(pageSize)
  }, [pageSize])

  useEffect(() => {
    setCurrentPageNum(1)
    handlePageChange(1, pageSize, 1)
  }, [totalPages])

  const CLASSES = {
    ENABLED:
      'border-blue-400 text-gray-500 hover:bg-gray-100 hover:text-gray-700',
    DISABLED: 'border-gray-300 bg-white text-gray-300',
  }

  const handlePageSizeUpdate: ChangeEventHandler<HTMLSelectElement> = (
    event,
  ) => {
    const newPageSize = parseInt(event?.target?.value)
    setCurrentPageSize(newPageSize)
    handlePageChange(startOffSet, startOffSet + newPageSize, currentPageNum)
  }

  //   get page numbers array with '...'
  const getPages = (current_page: number, last_page: number, onSides = 1) => {
    // pages
    const pages = []
    // Loop through
    for (let i = 1; i <= last_page; i++) {
      // Define offset
      const offset = i == 1 || last_page ? onSides + 1 : onSides
      if (
        i == 1 ||
        (current_page - offset <= i && current_page + offset >= i) ||
        i == current_page ||
        i == last_page
      ) {
        pages.push(i)
      } else if (
        i == current_page - (offset + 1) ||
        i == current_page + (offset + 1)
      ) {
        pages.push('...')
      }
    }
    return pages
  }

  const getStartOffset = (page: number, pageSize: number) => {
    return page * pageSize - pageSize
  }

  const onPageChange = (page: number) => {
    if (page)
      if (loading && page !== currentPageNum) {
        loading.setLoadingData(true)
      }
    const offset = getStartOffset(page, currentPageSize)
    setstartOffSet(offset)
    setPageNumber(page - 1)
    setCurrentPageNum(page)
  }

  return (
    <nav className={`flex items-center justify-end ${className}`}>
      <div className='-mt-px flex'>
        <button
          disabled={
            isPreviousButtonDisabled ||
            pageNumber < 1 ||
            (loading && loading.loadingData)
          }
          onClick={decrementPage}
          className={`px-3 py-2 text-sm ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white font-medium ${isPreviousButtonDisabled || pageNumber < 1
            ? CLASSES.DISABLED
            : CLASSES.ENABLED
            } 
            ${loading && loading.loadingData && 'cursor-progress'}
            `}
        >
          Prev
        </button>
      </div>
      <div className='hidden md:-mt-px md:flex'>
        {getPages(currentPageNum, getLastPage).map((page, index) =>
          typeof page === 'number' ? (
            <button
              className={`px-3 py-2 text-sm leading-tight text-gray-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 font-medium dark:hover:text-white
              ${loading && loading.loadingData && 'cursor-progress'}
			${currentPageNum === page
                  ? 'text-routera-600 bg-blue-50 border-2 border-routera-600'
                  : 'hover:border-gray-300 hover:bg-blue-200 bg-white border border-gray-300 hover:text-gray-700'
                }
			`}
              onClick={() => {
                onPageChange(page)
              }}
              key={`${page}-${index}`}
              disabled={loading && loading.loadingData}
            >
              {page}
            </button>
          ) : (
            <span
              className='inline-flex items-center border-t-2 border-transparent px-4 py-2 text-sm font-medium text-gray-500'
              key={`${page}-${index}`}
            >
              ...
            </span>
          ),
        )}
      </div>
      <div className='-mt-px flex'>
        <button
          disabled={
            fetchedDataSize < currentPageSize ||
            (loading && loading.loadingData)
          }
          onClick={incrementPage}
          className={`px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white font-medium 
		  ${fetchedDataSize < currentPageSize ? CLASSES.DISABLED : CLASSES.ENABLED}
      ${loading && loading.loadingData && 'cursor-progress'}
		  `}
        >
          Next
        </button>
      </div>
      {/*<select
        id='page-size'
        name='page-size'
        className='block pl-3 pr-10 py-1 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md text-left'
        defaultValue={currentPageSize}
        onChange={handlePageSizeUpdate}
      >
        {pageSizeOptions.map((value, index) => (
          <option key={`${value}-${index}`}>{value}</option>
        ))}
      </select>*/}
    </nav>
  )
}

export default Pagination
