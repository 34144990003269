import React from 'react'
import { Link } from 'react-router-dom'

const Register = (props: any) => {
  return (
    <>
      <section className='signuppage loginpage'>
        <div className='container-fluid' style={{ height: '100vh' }}>
          <div className='row'>
            <div className='col-md-12'>
              <div className='logo'>
                <Link to='/'>
                  <span className='text-white'>Routera</span>
                </Link>
              </div>
              <h2 className='mt-5 text-center text-white text-3xl'>
                Privacy Policy
              </h2>
            </div>
            {/* FIXME - Tailwind class not getting applied */}
            <div className='privacy-content'>
              <p>
                Protecting your private information is our priority. This
                Statement of Privacy applies to Routera, and Routera LLC and
                governs data collection and usage. For the purposes of this
                Privacy Policy, unless otherwise noted, all references to
                Routera LLC include www.routera.io and Routera. The Routera
                application is a lead routing application. By using the Routera
                application, you consent to the data practices described in this
                statement. &nbsp;&nbsp;
              </p>
              <p>Collection of your Personal Information&nbsp;</p>
              <p>
                In order to better provide you with products and services
                offered, Routera may collect personally identifiable
                information, such as your: &nbsp; &nbsp;
              </p>
              <p>-&nbsp; &nbsp; &nbsp; &nbsp;First and Last Name &nbsp;</p>
              <p>-&nbsp; &nbsp; &nbsp; &nbsp;E-mail Address&nbsp;</p>
              <p>&nbsp;-&nbsp; &nbsp; &nbsp; Phone Number &nbsp;</p>
              <p>-&nbsp; &nbsp; &nbsp; &nbsp;Job Title &nbsp;&nbsp;</p>
              <p>
                If you purchase Routera&apos;s products and services, we collect
                billing and credit card information. This information is used to
                complete the purchase transaction. &nbsp;&nbsp;
              </p>
              <p>
                Routera may also collect anonymous demographic information,
                which is not unique to you, such as your: &nbsp; &nbsp;
              </p>
              <p>-&nbsp; &nbsp; &nbsp; &nbsp;Age &nbsp;</p>
              <p>-&nbsp; &nbsp; &nbsp; &nbsp;Gender &nbsp;&nbsp;</p>
              <p>
                We do not collect any personal information about you unless you
                voluntarily provide it to us. However, you may be required to
                provide certain personal information to us when you elect to use
                certain products or services. These may include: (a) registering
                for an account; (b) entering a sweepstakes or contest sponsored
                by us or one of our partners; (c) signing up for special offers
                from selected third parties; (d) sending us an email message;
                (e) submitting your credit card or other payment information
                when ordering and purchasing products and services. To wit, we
                will use your information for, but not limited to, communicating
                with you in relation to services and/or products you have
                requested from us. We also may gather additional personal or
                non-personal information in the future. &nbsp;&nbsp;
              </p>
              <p>Use of your Personal Information&nbsp;</p>
              <p>
                Routera collects and uses your personal information to operate
                and deliver the services you have requested. &nbsp;&nbsp;
              </p>
              <p>
                Routera may also use your personally identifiable information to
                inform you of other products or services available from Routera
                and its affiliates. &nbsp;&nbsp;
              </p>
              <p>Sharing Information with Third Parties&nbsp;</p>
              <p>
                Routera does not sell, rent or lease its customer lists to third
                parties. &nbsp;&nbsp;
              </p>
              <p>
                Routera may, from time to time, contact you on behalf of
                external business partners about a particular offering that may
                be of interest to you. In those cases, your unique personally
                identifiable information (e-mail, name, address, telephone
                number) is transferred to the third party. Routera may share
                data with trusted partners to help perform statistical analysis,
                send you email or postal mail, provide customer support, or
                arrange for deliveries. All such third parties are prohibited
                from using your personal information except to provide these
                services to Routera, and they are required to maintain the
                confidentiality of your information. &nbsp;&nbsp;
              </p>
              <p>
                Routera may disclose your personal information, without notice,
                if required to do so by law or in the good faith belief that
                such action is necessary to: (a) conform to the edicts of the
                law or comply with legal process served on Routera or the site;
                (b) protect and defend the rights or property of Routera; and/or
                (c) act under exigent circumstances to protect the personal
                safety of users of Routera, or the public. &nbsp;&nbsp;
              </p>
              <p>
                Opt-Out of Disclosure of Personal Information to Third
                Parties&nbsp;
              </p>
              <p>
                In connection with any personal information we may disclose to a
                third party for a business purpose, you have the right to
                know:&nbsp;
              </p>
              <p>
                &bull;&nbsp; &nbsp; &nbsp; &nbsp;The categories of personal
                information that we disclosed about you for a business purpose.
                &nbsp;&nbsp;
              </p>
              <p>
                You have the right under the California Consumer Privacy Act of
                2018 (CCPA) and certain other privacy and data protection laws,
                as applicable, to opt-out of the disclosure of your personal
                information. If you exercise your right to opt-out of the
                disclosure of your personal information, we will refrain from
                disclosing your personal information, unless you subsequently
                provide express authorization for the disclosure of your
                personal information. To opt-out of the disclosure of your
                personal information, visit this Web page www.routera.io.
                &nbsp;&nbsp;
              </p>
              <p>Right to Deletion&nbsp;</p>
              <p>
                Subject to certain exceptions set out below, on receipt of a
                verifiable request from you, we will:&nbsp;
              </p>
              <p>
                &bull;&nbsp; &nbsp; &nbsp; &nbsp;Delete your personal
                information from our records; and&nbsp;
              </p>
              <p>
                &bull;&nbsp; &nbsp; &nbsp; &nbsp;Direct any service providers to
                delete your personal information from their records.{' '}
              </p>
              <p>
                Please note that we may not be able to comply with requests to
                delete your personal information if it is necessary to:
              </p>
              <p>
                &nbsp;&bull;&nbsp; &nbsp; &nbsp; &nbsp; Complete the transaction
                for which the personal information was collected, fulfill the
                terms of a written warranty or product recall conducted in
                accordance with federal law, provide a good or service requested
                by you, or reasonably anticipated within the context of our
                ongoing business relationship with you, or otherwise perform a
                contract between you and us;&nbsp;
              </p>
              <p>
                &bull; &nbsp; &nbsp; &nbsp; Detect security incidents, protect
                against malicious, deceptive, fraudulent, or illegal activity;
                or prosecute those responsible for that activity;&nbsp;
              </p>
              <p>
                &bull;&nbsp; &nbsp; &nbsp; &nbsp;Debug to identify and repair
                errors that impair existing intended functionality;&nbsp;
              </p>
              <p>
                &bull;&nbsp; &nbsp; &nbsp; &nbsp;Exercise free speech, ensure
                the right of another consumer to exercise his or her right of
                free speech, or exercise another right provided for by
                law;&nbsp;
              </p>
              <p>
                &bull;&nbsp; &nbsp; &nbsp; &nbsp;Comply with the California
                Electronic Communications Privacy Act;&nbsp;
              </p>
              <p>
                &bull;&nbsp; &nbsp; &nbsp; &nbsp;Engage in public or
                peer-reviewed scientific, historical, or statistical research in
                the public interest that adheres to all other applicable ethics
                and privacy laws, when our deletion of the information is likely
                to render impossible or seriously impair the achievement of such
                research, provided we have obtained your informed consent;&nbsp;
              </p>
              <p>
                &bull;&nbsp; &nbsp; &nbsp; &nbsp;Enable solely internal uses
                that are reasonably aligned with your expectations based on your
                relationship with us;&nbsp;
              </p>
              <p>
                &bull;&nbsp; &nbsp; &nbsp; &nbsp;Comply with an existing legal
                obligation; or&nbsp;
              </p>
              <p>
                &bull;&nbsp; &nbsp; &nbsp; &nbsp;Otherwise use your personal
                information, internally, in a lawful manner that is compatible
                with the context in which you provided the information.
                &nbsp;&nbsp;
              </p>
              <p>Children Under Thirteen&nbsp;</p>
              <p>
                Routera does not knowingly collect personally identifiable
                information from children under the age of thirteen. If you are
                under the age of thirteen, you must ask your parent or guardian
                for permission to use this application. &nbsp;&nbsp;
              </p>
              <p>
                Disconnecting your Routera Account from Third Party Websites You
                will be able to connect your Routera account to third party
                accounts.&nbsp;
              </p>
              <p>
                BY CONNECTING YOUR ROUTERA ACCOUNT TO YOUR THIRD PARTY ACCOUNT,
                YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE
                CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN
                ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY
                SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING
                PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER,
                DO NOT USE THIS FEATURE.&nbsp;
              </p>
              <p>
                You may disconnect your account from a third party account at
                any time. Users may learn how to disconnect their accounts from
                third-party websites by visiting their &quot;My Account&quot;
                page. Users may also contact us via email or telephone.
                &nbsp;&nbsp;
              </p>
              <p>
                Opt-Out &amp; Unsubscribe from Third Party Communications We
                respect your privacy and give you an opportunity to opt-out of
                receiving announcements of certain information. Users may
                opt-out of receiving any or all communications from third-party
                partners of Routera by contacting us here: - Web page:
                www.routera.io &nbsp; E-mail Communications From time to time,
                Routera may contact you via email for the purpose of providing
                announcements, promotional offers, alerts, confirmations,
                surveys, and/or other general communication. In order to improve
                our Services, we may receive a notification when you open an
                email from Routera or click on a link therein. &nbsp; If you
                would like to stop receiving marketing or promotional
                communications via email from Routera, you may opt out of such
                communications by clicking on the UNSUBSCRIBE button.. &nbsp;
                Changes to this Statement Routera reserves the right to change
                this Privacy Policy from time to time. We will notify you about
                significant changes in the way we treat personal information by
                sending a notice to the primary email address specified in your
                account, by placing a prominent notice on our application,
                and/or by updating any privacy information. Your continued use
                of the application and/or Services available after such
                modifications will constitute your: (a) acknowledgment of the
                modified Privacy Policy; and (b) agreement to abide and be bound
                by that Policy. &nbsp; Contact Information Routera welcomes your
                questions or comments regarding this Statement of Privacy. If
                you believe that Routera has not adhered to this Statement,
                please contact Routera at: &nbsp;&nbsp;
              </p>
              <p>
                Routera LLC 1 Esty Road Mendon, Massachusetts 01756 &nbsp; Email
                Address: noreply@routera.io &nbsp;&nbsp;
              </p>
              <p>Telephone number: 7745701026 &nbsp;&nbsp;</p>
              <p>Effective as of May 26, 2022 &nbsp;</p>
              <p>
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Register
