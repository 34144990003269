import { Conversions } from 'src/types/conversions'
import { HttpService } from './base.service'

class ConversionsService extends HttpService {
  private readonly prefix = 'conversions'
  getConversionsList = () => this.get(`${this.prefix}`)
  getConversionById = (id: string) => this.get(`${this.prefix}/${id}`)
  createConversion = async (data: Record<string, any>) =>
    this.post(`${this.prefix}/`, data)
  updateConversion = (id: string, body: { name: string }) =>
    this.patch(`${this.prefix}/${id}`, body)
  deleteConversion = (conversions_ids: string[]) =>
    this.delete(`${this.prefix}/`, {}, { conversions_ids })
}

export const conversionsService = new ConversionsService()
