import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { useActions } from '../../actions'
import toastBuilder from '../../utils/toastBuilder'
import { timeZonesNames } from '@vvo/tzdb'
import TimeSlotPicker, {
  TimeSlot,
  TimeSlots,
} from '../organisms/time-slot-picker/TimeSlotPicker.Molecule'
import { DataDate, DateString } from 'src/types/profile'
import DatesSection from 'src/components/organisms/datesSection/DatesSection.Organism'
import moment from 'moment'
import { Toggle } from '../atoms/Toggle/Toggle.atom'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import IconRoutera from '../atoms/icons/icons'
import { registerCurrentUser } from '../..'
import { start } from 'repl'

const EditUserWorkingHours: React.FC<{
  show: boolean
  setShow: any
  userId: string
  userName: string
  userTimeZone: string
  workingHours: any
  outOfficeDates?: DateString[]
  email?: string
}> = (props) => {
  const {
    show,
    setShow,
    workingHours,
    userName,
    userId,
    userTimeZone: userTz,
    outOfficeDates,
    email,
  } = props
  const [userWorkingHours, setUserWorkingHours] = useState<TimeSlots>({})
  const [dataDates, setDataDates] = useState<DataDate[]>([])
  const [showToolTip, setShowToolTip] = useState<boolean>(false)
  const [showWorkingHours, setShowWorkingHours] = useState<boolean>(true)
  const [userTimeZone, setuserTimeZone] = useState(
    timeZonesNames.find((stdTz) => {
      return stdTz.toLowerCase().includes(userTz?.toLowerCase())
    }) || timeZonesNames[0],
  )
  const [loading, setLoading] = useState(false)
  const { setTeamMemberWorkingHours, updateUserFromHubspot } = useActions()
  const ability = useAbility(AbilityContext)

  useEffect(() => {
    setUserWorkingHours(workingHours)
    setuserTimeZone(
      timeZonesNames.find((stdTz) => {
        return stdTz.toLowerCase().includes(userTz?.toLowerCase())
      }) || timeZonesNames[0],
    )

    const datesUser: DataDate[] = []

    outOfficeDates &&
      outOfficeDates.forEach((element: any) => {
        const startDate = DateTime.fromISO(element.startDate)
          .setZone(userTz)
          .toUTC()
        const endDate = DateTime.fromISO(element.endDate)
          .setZone(userTz)
          .toUTC()
        datesUser.push({
          endDate: endDate.toJSDate(),
          startDate: startDate.toJSDate(),
        })
      })
    setDataDates(datesUser)
  }, [])

  useEffect(() => {
    const daysWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]
    let cantDays = 0

    daysWeek.forEach((day: string) => {
      if (
        Object.keys(workingHours).length > 0 &&
        workingHours[day].length > 0 &&
        workingHours[day][0].endTime === '23:59' &&
        workingHours[day][0].startTime === '00:00'
      ) {
        cantDays++
      }
    })

    if (cantDays === 7) {
      setShowWorkingHours(false)
    }
  }, [workingHours])

  const getDate = () => {
    const dates: DateString[] = []
    dataDates.forEach((element: any) => {
      element.startDate.setMilliseconds(0)
      element.endDate.setMilliseconds(0)
      const startDate = DateTime.fromISO(element.startDate.toISOString())
        .setZone(userTz)
        .toUTC()
      const endDate = DateTime.fromISO(element.endDate.toISOString())
        .setZone(userTz)
        .toUTC()

      dates.push({
        startDate: startDate.toISO(),
        endDate: endDate.toISO(),
      })
    })

    return [dates, isDateOverlapping()]
  }

  const isDateOverlapping = (): boolean => {
    let isDateOverLapping = false
    dataDates.forEach((element1, index1) => {
      dataDates.forEach((element2, index2) => {
        if (index1 !== index2) {
          if (
            (element1.startDate <= element2.startDate &&
              element2.endDate <= element1.endDate) ||
            (element1.startDate <= element2.startDate &&
              element2.startDate <= element1.endDate) ||
            (element1.startDate <= element2.endDate &&
              element2.endDate <= element1.endDate) ||
            (element1.startDate >= element2.startDate &&
              element2.endDate >= element1.endDate)
          ) {
            isDateOverLapping = true
          }
        }
      })
    })
    return isDateOverLapping
  }

  const getWorkingHours247 = () => {
    return {
      Monday: [{ startTime: '00:00', endTime: '23:59' }],
      Tuesday: [{ startTime: '00:00', endTime: '23:59' }],
      Wednesday: [{ startTime: '00:00', endTime: '23:59' }],
      Thursday: [{ startTime: '00:00', endTime: '23:59' }],
      Friday: [{ startTime: '00:00', endTime: '23:59' }],
      Saturday: [{ startTime: '00:00', endTime: '23:59' }],
      Sunday: [{ startTime: '00:00', endTime: '23:59' }],
    }
  }

  const onSave = async () => {
    if (getDate()[1]) {
      toastBuilder('error', 'Time slot seem to overlap. Please check')
      return
    }

    let dataWorkingHours: TimeSlots = userWorkingHours

    if (!showWorkingHours) {
      dataWorkingHours = getWorkingHours247()
    }

    const payload = {
      _id: userId,
      workingHours: dataWorkingHours,
      timeZone: userTimeZone,
      outOfficeDates: getDate()[0],
    }

    const action: any = await setTeamMemberWorkingHours(payload)

    if (action?.payload?.success) {
      toastBuilder(
        'success',
        'Users working hours / out of office dates changed',
      )
    }

    setShow(false)
  }

  const onWorkingHoursChange = (timeSlots: TimeSlots) => {
    const sortedSlots = Object.keys(timeSlots).reduce((acc, day: string) => {
      acc[day] = [...timeSlots[day]].sort((slot1: TimeSlot, slot2: TimeSlot) =>
        slot1.startTime < slot2.startTime ? -1 : 1,
      )
      return acc
    }, {} as TimeSlots)
    setUserWorkingHours(sortedSlots)
  }

  const handleTimeZoneChange = (event: any) => {
    setuserTimeZone(event.target.value)
  }

  const handleStatusChange247 = () => {
    setShowWorkingHours(!showWorkingHours)
  }

  const handleUpdateUser = async () => {
    if (email) {
      setLoading(true)
      const payload = {
        email: email,
      }

      const action: any = await updateUserFromHubspot(payload)

      if (action?.payload?.success) {
        registerCurrentUser()
        toastBuilder('success', 'Updated user')
      }
      setLoading(false)
    }
  }

  // const workingHoursHubspot = (obj: any) => {
  //   const workingHours = []
  //   let days: any = {}

  //   for (const day in obj) {
  //     if (Object.prototype.hasOwnProperty.call(obj, day)) {
  //       if (obj[day].length > 0) {
  //         const dates = Object.values(obj[day][0])
  //         const startMinute = DateTime.fromISO(dates[0] as string)
  //         days = {
  //           days: day.toLocaleUpperCase(),
  //           startMinute: dates[0],
  //           endMinute: dates[1],
  //         }
  //         workingHours.push(days)
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   workingHoursHubspot(userWorkingHours)
  // }, [userWorkingHours])

  return (
    <>
      {show ? (
        <Modal
          show={true}
          onHide={() => setShow(false)}
          centered
          className='edit-working-hours-modal'
        >
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>
              {`Edit availability of ${userName}`}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className=''>
              <div className='user-time-zone-container'>
                <div className='className'>
                  <button
                    className='flex justify-center items-center mt-2 mb-3'
                    onClick={() => handleUpdateUser()}
                    disabled={!ability.can('update', 'setting.user') || loading}
                  >
                    {loading ? (
                      <div
                        className='spinner-border spinner-border-sm text-routera-800'
                        role='status'
                      />
                    ) : (
                      <IconRoutera
                        iconName='ArrowPathIcon'
                        className='h-5 w-5 text-routera-800'
                      ></IconRoutera>
                    )}

                    <span className='ml-2 text-sm font-medium hover:text-routera-primary'>
                      Refresh full name from HubSpot
                    </span>
                  </button>
                  <label
                    htmlFor='userTimeZone'
                    className='block text-sm font-medium text-gray-700'
                  >
                    User Time Zone
                  </label>
                  <select
                    id='userTimeZone'
                    name='userTimeZone'
                    className={`mt-1 block w-3/4 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${
                      !ability.can('update', 'setting.user')
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    value={userTimeZone}
                    onChange={handleTimeZoneChange}
                    disabled={!ability.can('update', 'setting.user')}
                  >
                    {timeZonesNames.map((timeZone: any, index: number) => {
                      return (
                        <option value={timeZone} key={index}>
                          {timeZone.replace(/_/g, ' ')}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className='flex items-center justify-evenly'>
                <div className='mt-1 flexitems-center text-sm'>
                  <div className='flex mt-3 mb-4 w-[466px] tablet:w-[260px]'>
                    <Toggle
                      initialValue={!showWorkingHours}
                      data={''}
                      handleChange={handleStatusChange247}
                      className='w-[45px] mr-2'
                      disabled={!ability.can('update', 'setting.user')}
                    />
                    <span className='text-base text-gray-700 leading-6'>
                      24/7 Availability
                    </span>
                  </div>
                  {showWorkingHours && (
                    <TimeSlotPicker
                      selectedSlots={userWorkingHours}
                      onChange={onWorkingHoursChange}
                      permissions={{
                        delete: ability.can('delete', 'setting.user'),
                        update: ability.can('update', 'setting.user'),
                        create: ability.can('create', 'setting.user'),
                      }}
                    />
                  )}
                  {showToolTip && (
                    <div className='shadow-md max-w-[190px] text-center text-base bg-[#e8e8e8] p-2 rounded-md z-10 absolute -my-60 mx-6'>
                      <span>
                        Out of the office is a feature that will help you to
                        control user assignment status, we automatically switch
                        this status one time per day at 00:00 in your timezone
                        depending of the dates defined bu you.
                      </span>
                    </div>
                  )}
                  <div className='mt-4 flexitems-center text-sm'>
                    <div className='flex items-center'>
                      <h4>Out of Office Dates</h4>
                      <div
                        className={
                          dataDates.length === 0 ? '-mt-2 ml-2' : 'ml-2 mb-1'
                        }
                        onMouseEnter={() => setShowToolTip(true)}
                        onMouseLeave={() => setShowToolTip(false)}
                      >
                        <i className='fa-solid fa-info-circle text-lg'></i>
                      </div>
                    </div>
                    <DatesSection
                      stateDates={setDataDates}
                      dataDefaultDates={dataDates}
                      permissions={{
                        delete: ability.can('delete', 'setting.user'),
                        update: ability.can('update', 'setting.user'),
                        create: ability.can('create', 'setting.user'),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-row justify-end'>
              <button
                className='mt-2 px-4 py-2 bg-routera-700 rounded text-white disabled:opacity-50'
                disabled={!ability.can('update', 'setting.user')}
                onClick={
                  ability.can('update', 'setting.user')
                    ? onSave
                    : () => {
                        return
                      }
                }
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  )
}

export default EditUserWorkingHours
