import { createAsyncThunk } from '@reduxjs/toolkit'
import { DashboardService } from 'src/services/dashboard.service'
import { DashbordData } from 'src/types/Ihome_state'
import {
  TotalRoutedType,
  TotalCapacity,
  TotalSLAs,
  RoutedOverview,
  ConversionData,
  RoutedLeads,
  DetailSLAs,
  RespDataDetailSLA
} from 'src/types/dashboard'
import { ISLA } from 'src/types/sla'

enum actiontype {
  getTotalRouted = 'getTotalRouted',
  getTotalCapacity = 'getTotalCapacity',
  getTotalSLAs = 'getTotalSLAs',
  getDashboardDataAction = 'getDashboardDataAction',
  getRoutedOverview = 'getRoutedOverview',
  getConversionList = 'getConversionList',
  getListRoutedLeads = 'getListRoutedLeads',
  getDetailSLAs = 'getDetailSLAs'
}

export const getTotalRouted = createAsyncThunk(
  actiontype.getTotalRouted,
  async (payload: any) => {
    const resp = await DashboardService.getTotalRouted(payload)
    return resp.data as TotalRoutedType
  },
)

export const getTotalCapacity = createAsyncThunk(
  actiontype.getTotalCapacity,
  async (payload: any) => {
    const resp = await DashboardService.getTotalCapacity(payload)
    return resp.data as TotalCapacity[]
  },
)

export const getTotalSLAs = createAsyncThunk(
  actiontype.getTotalSLAs,
  async (payload: any) => {
    const resp = await DashboardService.getTotalSLAs(payload)
    return resp.data as TotalSLAs
  },
)

export const getRoutedOverview = createAsyncThunk(
  actiontype.getRoutedOverview,
  async (payload: any) => {
    const resp = await DashboardService.getRoutedOverview(payload)
    return resp.data as RoutedOverview[]
  },
)

export const getDetailSLAs = createAsyncThunk(
  actiontype.getDetailSLAs,
  async (payload: any) => {
    const resp = await DashboardService.getDetailSLAs(payload)
    return resp.data as RespDataDetailSLA
  }
)

export const getConversionList = createAsyncThunk(
  actiontype.getConversionList,
  async (payload: any) => {
    const resp = await DashboardService.getConversionList(payload)
    return resp.data as ConversionData
  },
)

export const getDashboardDataAction = createAsyncThunk(
  actiontype.getDashboardDataAction,
  async (payload: any, thunk_api) => {
    const result = await DashboardService.getDashboardData(payload)
    if (result) return result.data as DashbordData
    else thunk_api.rejectWithValue('')
  },
)

export const getListRoutedLeads = createAsyncThunk(
  actiontype.getListRoutedLeads,
  async (payload: any) => {
    const resp = await DashboardService.getListRoutedLeads(payload)
    return resp.data as RoutedLeads[]
  }
)
