import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'

import FormBuilder from 'src/components/molecules/FormBuilder/FormBuilder'
import { usespecificRouterDetail } from 'src/container/RouterDetail/routerDetail.selector'
import { createRuleFormJSON } from 'src/forms/createRuleFormJSON'
import { routerDetailService } from 'src/services/router_detail.service'
import { useAppSelector } from 'src/store'
import { IFormJson } from 'src/types/form'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import toastBuilder from 'src/utils/toastBuilder'

interface IProps {
  setShowSlider: (value: boolean) => void
  currRuleEditData: any
}

const contactOptions = [
  { value: HUBSPOT_OBJECT_TYPES.COMPANIES, label: 'Associated Company' },
  { value: HUBSPOT_OBJECT_TYPES.DEALS, label: 'Associated Deal' },
]
const companyOptions = [
  { value: HUBSPOT_OBJECT_TYPES.DEALS, label: 'Associated Deal' },
]
const dealOptions = [
  { value: HUBSPOT_OBJECT_TYPES.COMPANIES, label: 'Associated Company' },
]

const CreateRuleForm: React.FC<IProps> = ({
  setShowSlider,
  currRuleEditData,
}) => {
  const [ruleFormJSON, setRuleFormJSON] = useState<IFormJson>()

  const specificRouterDetail = useAppSelector(usespecificRouterDetail)
  const { routerId = '' } = useParams()
  const navigate = useNavigate()
  const defaultValues = {
    ruleName: specificRouterDetail?.account_matching?.name || '',
    objectType:
      specificRouterDetail?.account_matching?.objectType ||
      HUBSPOT_OBJECT_TYPES.COMPANIES,
    fieldType: specificRouterDetail?.account_matching?.fieldType || '',
    fieldName: specificRouterDetail?.account_matching?.fieldType || '',
    operatorType: '',
    value: '',
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, dirtyFields, touchedFields },
    reset,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
  } = useForm({ defaultValues })

  const { isLoading, isSuccess, isRefetching, data, refetch } = useQuery(
    ['getFieldProperties'],
    () =>
      routerDetailService.getFieldProperties(routerId, getValues().objectType),
    { retry: false, enabled: false },
  )

  const refetchHelper = async () => {
    getValues().objectType && (await refetch())
  }

  useEffect(() => {
    refetch()
    const formJSON = { ...createRuleFormJSON }
    if (
      specificRouterDetail?.trigger_metadata?.objectType ===
      HUBSPOT_OBJECT_TYPES.CONTACTS
    ) {
      formJSON.fields[1].options = contactOptions
    } else if (
      specificRouterDetail?.trigger_metadata?.objectType ===
      HUBSPOT_OBJECT_TYPES.COMPANIES
    ) {
      formJSON.fields[1].options = companyOptions
    } else if (
      specificRouterDetail?.trigger_metadata?.objectType ===
      HUBSPOT_OBJECT_TYPES.DEALS
    ) {
      formJSON.fields[1].options = dealOptions
    }
    createRuleFormJSON && setRuleFormJSON(formJSON)
    refetchHelper()

    return () => {
      reset(defaultValues)
    }
  }, [])

  useEffect(() => {
    if (
      Object.keys(createRuleFormJSON).length &&
      isLoading === false &&
      isSuccess
    ) {
      const { fieldProperties } = data?.data || []
      if (fieldProperties && fieldProperties.length) {
        const filteredOptions = fieldProperties.filter(
          (prop: any) =>
            Object.prototype.hasOwnProperty.call(
              prop,
              'referencedObjectType',
            ) && prop.referencedObjectType === 'OWNER',
        )
        const fieldOptions = filteredOptions.map((option: any) => ({
          label: option.label,
          value: option.name,
        }))
        const oldFormJSON = { ...createRuleFormJSON }
        //   @ts-ignore
        if (oldFormJSON?.fields.length) {
          //   @ts-ignore
          oldFormJSON.fields[2].options = fieldOptions
          setRuleFormJSON(oldFormJSON)

          // check if we need to set old value or newly fetched value
          // by checking if the selection changed
          if (
            getValues().objectType ===
              specificRouterDetail?.account_matching?.objectType &&
            getValues().fieldType ===
              specificRouterDetail?.account_matching?.fieldType
          ) {
            setValue(
              'fieldType',
              specificRouterDetail?.account_matching?.fieldType,
            )
            setValue(
              'fieldName',
              specificRouterDetail?.account_matching?.fieldName,
            )
          } else {
            setValue('fieldType', fieldOptions.length && fieldOptions[0]?.value)
            setValue('fieldName', fieldOptions.length && fieldOptions[0]?.label)
          }

          const operatorType = 'is equal to'
          const value = "Assignee's email address"
          setValue('operatorType', operatorType)
          setValue('value', value)
        }
      }
    }
  }, [isLoading, isRefetching])

  useEffect(() => {
    refetchHelper()
  }, [specificRouterDetail])

  useEffect(() => {
    const fieldOption = ruleFormJSON?.fields[2].options?.find(
      (option) => option.value === getValues().fieldType,
    )
    fieldOption && setValue('fieldName', fieldOption?.label)
  }, [getValues().fieldType])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'objectType' && type === 'change') {
        refetchHelper()
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const onSave = async () => {
    const values = getValues()
    if (!values.ruleName) {
      setError('ruleName', {})
      return
    } else {
      clearErrors()
    }
    const payload = {
      name: values.ruleName,
      objectType: values.objectType,
      fieldType: values.fieldType,
      fieldName: values.fieldName,
      operator: 'is_equal_to',
      value: 'email_address',
    }

    routerDetailService
      .setRules(routerId, { account_matching: payload })
      .then((res: any) => {
        if (res?.success) {
          setShowSlider(false)
          toastBuilder('success', 'Account Matching created successfully')
          navigate(`/routers/${routerId}/review-publish`)
        } else {
          toastBuilder('error', 'Something went wrong')
        }
      })
      .catch((err) => {
        toastBuilder('error', 'Something went wrong')
      })
  }

  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='overflow-y-auto p-8'>
        <div className='mb-[2.5rem]'>
          <span className='text-lg font-medium flex-grow-1'>
            Create Account Matching
          </span>
        </div>
        <form onSubmit={handleSubmit(onSave)}>
          <FormBuilder
            formJSON={ruleFormJSON || {}}
            // @ts-ignore
            setValue={setValue}
            // @ts-ignore
            control={control}
          />
        </form>
      </div>
      <div className='flex flex-shrink-0 justify-between px-4 py-[0.75rem] bg-gray-50'>
        <button
          type='button'
          className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#0062FF] focus:ring-offset-2'
          onClick={() => setShowSlider(false)}
        >
          Cancel
        </button>
        <button
          type='submit'
          className='ml-4 inline-flex justify-center rounded-md border border-transparent bg-[#0062FF] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#0062FF] focus:outline-none focus:ring-2 focus:ring-[#0062FF] focus:ring-offset-2' // add disabled styles
          onClick={onSave}
        >
          Save & close
        </button>
      </div>
    </div>
  )
}

export default CreateRuleForm
