import { useEffect, useState } from 'react'
import { useActions } from 'src/actions'
import { config } from 'src/config/constants'
import { useAppSelector } from 'src/store'
import { selectUser } from 'src/container/auth/auth.selector'
import CreateCustomer from 'src/components/MyAccount/CreateCustomer'
import ConfirmationDialog from 'src/components/organisms/confirmationDialog/ConfirmationDialog.Organism'
import { selectIntegrations } from 'src/container/integrations/integrations.selector'
import { IAvailableIntegration } from 'src/types/integration'
import hubspotLogo from 'src/assets/images/hubspot-logo.png'
import IntergrationCard from 'src/components/MyAccount/IntegrationCard'
import { useNavigate } from 'react-router-dom'
import HubspotLogo from 'src/components/MyAccount/ConnectwithHubSportOption'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import HubspotSettingsTemplate from 'src/components/templates/integrations/hubspot/settings/HubspotSettings.template'
import IntegrationsDetail from 'src/components/MyAccount/IntegrationsDetail'
import NoIntegrationFound from 'src/components/MyAccount/Integrations'

const IntegrationHubspot = () => {
  const [hasMounted, setHasMounted] = useState(false)
  const [show, setShow] = useState(false)
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false)
  const [loadingDisconnect, setLoadingDisconnect] = useState(false)
  const { send_hubsprot_token, getIntegrations, disconnectHubSpot } =
    useActions()
  const { search } = location ?? ''
  const current_user = useAppSelector(selectUser)
  const params = new URLSearchParams(search)
  const connection_step = params.get('step')
  const hub_sport_code = params.get('code')
  const [changeSet, setChangeSet] = useState<boolean>(true)
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const integrations = useAppSelector(selectIntegrations)
  const navigate = useNavigate()

  useEffect(() => {
    if (!hasMounted) {
      getIntegrations()
      setHasMounted(true)
    }
  }, [hasMounted])

  const { CLIENT_ID, REDIRECT_URL, SCOPE } = config

  const handlehubspotClick = () => {
    window.location.href = `https://app.hubspot.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&scope=${SCOPE}`
  }

  useEffect(() => {
    if (hub_sport_code) {
      const initalCalls = async () => {
        await send_hubsprot_token(hub_sport_code)
        getIntegrations()
        navigate({
          search: `?tab=integrations`,
        })
      }
      initalCalls()
    }
  }, [hub_sport_code])

  const onConfirmDisconnect = async () => {
    setLoadingDisconnect(true)
    const result: any = await disconnectHubSpot(integrations.hubspot?._id || '')
    setLoadingDisconnect(false)
    setIsConnected(false)
    setChangeSet(false)
    setShowDeleteConfirmationDialog(false)
  }

  useEffect(() => {
    if (changeSet) {
      if (
        !integrations.hubspot?._id &&
        Object.keys(integrations).length === 0
      ) {
        setIsConnected(false)
      } else {
        setIsConnected(true)
      }
    }
  }, [integrations])

  const handleBack = () => {
    navigate({
      search: ``,
    })
  }

  const BuildCompoment = () => {
    if (connection_step === '1' || !connection_step) {
      const availableIntegrations: Array<IAvailableIntegration> = [
        {
          label: 'HubSpot',
          value: 'hubspot',
          logoURL: hubspotLogo,
          isConnected: !!integrations.hubspot?._id,
          hasSettings: !!integrations.hubspot?._id,
          description:
            'Integrate with HubSpot and fully control all of your lead distribution and adhereance through Routera',
        },
      ]

      const handleIntegrationConnect = (integration: IAvailableIntegration) => {
        // Don't do anything for connection options other than hubspot
        if (integration.value !== 'hubspot') {
          return
        }
        if (
          !integrations.hubspot ||
          Object.values(integrations.hubspot).length < 1 ||
          !isConnected
        ) {
          navigate({
            search: `?step=3`,
          })
        }
      }

      const handleSettingsPress = () => {
        navigate({
          search: `?step=4&integration=${integrations.hubspot?._id}`,
        })
      }
      return (
        <div>
          <div className='max-w-[322px]'>
            {availableIntegrations.map((integration, index) => (
              <IntergrationCard
                key={index}
                name={integration.label}
                description={integration.description}
                logo={integration.logoURL}
                isConnected={isConnected}
                hasSettings={isConnected}
                onConnect={() => handleIntegrationConnect(integration)}
                onSettingsPress={handleSettingsPress}
                onDisconnect={() => setShowDeleteConfirmationDialog(true)}
              />
            ))}
          </div>
        </div>
      )
    } else if (connection_step === '2') {
      return (
        <div className='connIntegration-heading d-block'>
          <h3 className='text-2xl'>Available Integrations</h3>

          <HubspotLogo
            onclick={() => {
              if (
                !integrations.hubspot ||
                Object.values(integrations.hubspot).length < 1
              ) {
                navigate({
                  search: `?step=3`,
                })
              }
            }}
          />
        </div>
      )
    } else if (connection_step === '3') {
      return (
        <div className='mt-4'>
          <div className='flex justify-start mobile:flex-wrap items-center gap-[20px]'>
            <div
              onClick={handleBack}
              className='mt-2 back-btn-container border rounded-full h-8 w-8 hover: cursor-pointer text-routera-700 bg-white flex items-center justify-center'
            >
              <ArrowLeftIcon className='h-5 w-5' />
            </div>
            <span className='text-lg font-medium mt-2'>
              Integration Overview
            </span>
          </div>
          <IntegrationsDetail onConnectClick={handlehubspotClick} />
        </div>
      )
    } else if (connection_step === '4') {
      return (
        <div className='mt-1'>
          <div
            onClick={handleBack}
            className='my-4 back-btn-container border rounded-full h-8 w-8 hover: cursor-pointer text-routera-700 bg-white flex items-center justify-center'
          >
            <ArrowLeftIcon className='h-5 w-5' />
          </div>
          <HubspotSettingsTemplate
            onDisconnect={() => setShowDeleteConfirmationDialog(true)}
          />
        </div>
      )
    } else if (
      integrations.hubspot &&
      Object.values(integrations.hubspot).length > 0
    ) {
      navigate({
        search: `?step=1`,
      })
    } else {
      return (
        <NoIntegrationFound
          onConnectClick={() => {
            navigate({
              search: `?step=2`,
            })
          }}
        />
      )
    }
  }

  return (
    <div className='px-5 py-7'>
      {BuildCompoment()}
      <CreateCustomer setShow={setShow} show={show} />
      {showDeleteConfirmationDialog && (
        <ConfirmationDialog
          title='Disconnect Integration'
          description={`This will remove the integration and associated sources. Are you sure you want to continue ?`}
          onConfirm={onConfirmDisconnect}
          isOpen={true}
          onDialogClose={() => setShowDeleteConfirmationDialog(false)}
          onCancel={() => {
            setShowDeleteConfirmationDialog(false)
          }}
          loading={loadingDisconnect}
        />
      )}
    </div>
  )
}

export default IntegrationHubspot
