import { useEffect, useState } from 'react'
import TableActions, {
  TableColumn,
} from 'src/components/atoms/tableActions/tableActions'
import { ConversionRule } from 'src/types/conversions'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'

interface IProps {
  conversionData: ConversionRule
}

const conversionDefault = {
  objectType: HUBSPOT_OBJECT_TYPES.CONTACTS,
  value: '',
  fieldName: '',
  fieldLabel: '',
  operator: '',
}

const ConversionReviewPublish: React.FC<IProps> = ({ conversionData }) => {
  const [currentConversion, setCurrentConversion] =
    useState<ConversionRule>(conversionDefault)

  useEffect(() => {
    if (conversionData) {
      setCurrentConversion(conversionData)
    }
  }, [conversionData])

  const rows = [
    {
      [currentConversion.fieldLabel]: currentConversion.fieldLabel,
      [currentConversion.objectType]:
        currentConversion.objectType,
      [currentConversion.operator]: currentConversion.operator,
      [currentConversion.value]: currentConversion.value,
    },
  ]

  const tableColumnsConversionGlobal: TableColumn[] = [
    {
      label: 'Field',
      render: () => {
        return (
          <span>
            {currentConversion.fieldLabel}
          </span>
        )
      },
      className: 'w-[140px]',
    },
    {
      label: 'Operator',
      render: () => {
        return (
          <span className='capitalize'>
            {currentConversion.operator}
          </span>
        )
      },
      className: 'w-[140px]',
    },
    {
      label: 'Value',
      render: () => {
        return (
          <p className='capitalize-first text-xs m-0 text-gray-500'>
            {currentConversion.value}
          </p>
        )
      },
      className: 'w-[140px]',
    },
  ]

  const tableColumnsObjectType: TableColumn[] = [
    {
      label: 'Name',
      render: () => {
        return (
          <span className='capitalize'>
            {currentConversion.objectType}
          </span>
        )
      },
      className: 'w-[140px]',
    },
  ]

  return (
    <div className='px-4 p-4 flex flex-col'>
      <span className='text-gray-700 text-xl leading-[30px]'>
        Object Type
      </span>
      <TableActions
        columns={tableColumnsObjectType}
        rows={rows}
        className='mt-4'
      />
      <span className='text-gray-700 text-xl leading-[30px] mt-4'>
        Conversion Goal
      </span>
      <TableActions
        columns={tableColumnsConversionGlobal}
        rows={rows}
        className='mt-4'
      />
    </div>
  )
}

export default ConversionReviewPublish
