import { HttpService } from './base.service'

class CustomerService extends HttpService {
  private readonly prefix = 'accounts'
  createCustomer = (data: any) => this.post(`${this.prefix}/team-members`, data)
  getTeamMembersList = () => this.get(`${this.prefix}/team-members`)
  getCustomer = () => this.get(`${this.prefix}/team-members`)
  setRole = (data: any) => this.post(`${this.prefix}/set-role`, data)
  getAccount = (accountId: string) => this.get(`${this.prefix}/${accountId}`)
}

export const customService = new CustomerService()
