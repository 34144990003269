import React, { useEffect, useState } from 'react'
import { extractInitials } from '../../../utils/common/string.common'
import { UserAvailability } from 'src/components/LeadRouterDetail/TeamMembersList/types'
interface IProps {
  firstName: string
  lastName: string
  className?: string
  classDiv?: string
  imgURL: string
  available?: {
    officeStatus: boolean
    userAvailability?: UserAvailability
  }
}

const Avatar = ({
  firstName,
  lastName,
  className,
  imgURL,
  available,
  classDiv,
}: IProps) => {
  const [imgSrc, setImgSrc] = useState(imgURL)
  useEffect(() => {
    setImgSrc(imgURL)
  }, [imgURL])

  let availableHTML = (
    <img
      className={`${className} rounded-circle`}
      alt={firstName}
      src={imgSrc}
      loading='lazy'
      onError={() => setImgSrc('')}
    />
  )

  let pointColor = 'red'

  if (available && available.userAvailability) {
    const isAvailableBySchedule =
      available.userAvailability.isAvailableBySchedule
    const contacts = available.userAvailability.isAvailableByCapacities.contacts
    const deals = available.userAvailability.isAvailableByCapacities.deals
    const officeStatus = available.officeStatus

    if (isAvailableBySchedule && contacts && deals && officeStatus) {
      pointColor = 'green'
    }

    if (isAvailableBySchedule && officeStatus && (!contacts || !deals)) {
      pointColor = 'yellow'
    }

    if (!isAvailableBySchedule || !officeStatus || (!contacts && !deals)) {
      pointColor = 'red'
    }

    if (pointColor === 'green') {
      availableHTML = (
        <span className='-top-1 left-[22px] border-2 absolute w-3.5 h-3.5 bg-green-400 border-green-100 dark:border-gray-800 rounded-full' />
      )
    }
    if (pointColor === 'red') {
      availableHTML = (
        <span className='-top-1 left-[22px] border-2 absolute w-3.5 h-3.5 bg-red-400 border-red-100 dark:border-gray-800 rounded-full' />
      )
    }

    if (pointColor === 'yellow') {
      availableHTML = (
        <span className='-top-1 left-[22px] border-2 absolute w-3.5 h-3.5 bg-yellow-400 border-yellow-100 dark:border-gray-800 rounded-full' />
      )
    }
  }
  return (
    <>
      {imgSrc && (
        <div className={`relative ${classDiv ? classDiv : ''} ${className}`}>
          <img
            className='rounded-circle'
            alt={firstName}
            src={imgSrc}
            loading='lazy'
            onError={() => setImgSrc('')}
          />
          {available && available.userAvailability && availableHTML}
        </div>
      )}
      {!imgSrc && (
        <div
          className={` ${classDiv} relative flex justify-center items-center rounded-full bg-gray-500 text-white uppercase ${className}`}
        >
          {extractInitials(firstName, lastName).toUpperCase()}
          {available && available.userAvailability && availableHTML}
        </div>
      )}
    </>
  )
}

export default Avatar
