import { useAppSelector } from 'src/store'
import { useProductMetricsInfo } from 'src/container/Basic/basic.selector'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const productMetricsInfo = useAppSelector(useProductMetricsInfo)
  const navigate = useNavigate()
  const { loginWithRedirect } = useAuth0();
  const bearerToken = window.localStorage.getItem('token');

  const handleLoginWithAuth0 = async () => {
    await loginWithRedirect();
	};
  
  useEffect(() => {
    if (!bearerToken) {
      handleLoginWithAuth0();
    }
  }, [bearerToken]);

  return (
    <>
    </>
  )
}

export default Login
