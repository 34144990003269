import Tabs from 'src/components/molecules/tabs/tabs'
import { DataTab } from 'src/types/tabs'
import { useState } from 'react'
import CustomerTable from 'src/components/MyAccount/CustomerTable'
import CreateCustomer from 'src/components/MyAccount/CreateCustomer'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'
import TeamTable from 'src/components/organisms/teams/teamTable'
import CreateTeam from 'src/components/organisms/teams/createTeam'
import { getallusers } from '../my_account/account.selector'
import { useAppSelector } from 'src/store'
import { listAllTeams } from './teams.selector'

const Users = () => {

  const allTeams = useAppSelector(listAllTeams);
  const allUsers = useAppSelector(getallusers)
  const ability = useAbility(AbilityContext)

  if (!ability.can('read', 'setting.user')) {
    return <NoPermission value='read' />
  }

  const [show, setShow] = useState(false)
  const [showModalTeam, setShowModalTeam] = useState(false)

  const dataTabs: DataTab[] = [
    {
      label: 'User List',
      id: 'user-list',
      dataContentTab: (
        <div>
          <CustomerTable setShow={setShow} />
          <CreateCustomer setShow={setShow} show={show} />
        </div>
      ),
    },
    {
      label: 'Teams',
      id: 'team',
      dataContentTab: (
        <div>
          <TeamTable setShowModalTeam={setShowModalTeam} userTab={true} allTeams={allTeams} allUsers={allUsers} />
          <CreateTeam setShowModalTeam={setShowModalTeam} showModalTeam={showModalTeam} />
        </div>
      ),
    },
  ]

  return (
    <section className='border-l border-gray-200'>
      <div className='p-4 px-5 bg-gray-100'>
        <h3 className='text-2xl block'>Settings</h3>
      </div>
      <Tabs dataTabs={dataTabs} classContentTab='min-h-[calc(100vh-201px)] bg-routera-gray' />
    </section>
  )
}

export default Users
