import { useQuery, useMutation } from '@tanstack/react-query'
import { useState, useEffect, useMemo } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useActions } from 'src/actions'
import EditNameHeader from 'src/components/organisms/forms/editNameHeader/EditNameHeader.Organism'
import { userRoles } from 'src/config/constants'
import { selectUser } from 'src/container/auth/auth.selector'
import {
  AvailableIntegrationInterfaces,
  HubspotIntegrationInterface,
} from 'src/container/integrations/integrations.interface'
import { selectIntegrations } from 'src/container/integrations/integrations.selector'
import { sourceService } from 'src/services/sources.service'
import { useAppSelector } from 'src/store'
import { Source } from 'src/types/sources'
import toastBuilder from 'src/utils/toastBuilder'
import HubspotQueryBuilder from './queryBuilder/HubspotQueryBuilder.Template'

const SourceDetailsTemplate: React.FC = () => {
  const { sourceId = '', tab = 'query' } = useParams()

  const [hasMounted, setHasMounted] = useState(false)
  const [activeTab, setActiveTab] = useState(tab)
  const [currentSource, setCurrentSource] = useState<Source | null>(null)

  const { getIntegrations } = useActions()

  const navigate = useNavigate()

  const integrations = useAppSelector(selectIntegrations)
  const { role } = useAppSelector(selectUser)

  const {
    isSuccess,
    data,
    refetch: refetchSource,
    isRefetching,
  } = useQuery(
    [`getSource/${sourceId}`, sourceId],
    () => sourceService.getSource(sourceId),
    {},
  )

  const updateSourceMutation = useMutation(
    (payload: Partial<Source>) => sourceService.updateSource(sourceId, payload),
    {
      onSuccess: ({ data }: { data: { source: Source } }) => {
        if (data.source) {
          setCurrentSource(data.source)
        }
      },
    },
  )

  useEffect(() => {
    if (!hasMounted) {
      getIntegrations()
      setHasMounted(true)
    }
  }, [hasMounted])

  useEffect(() => {
    if (isSuccess && data?.data?.source) {
      setCurrentSource(data?.data.source)
    }
  }, [isSuccess, isRefetching])

  const onTabSelect = (newTab: string | null) => {
    if (newTab) navigate(`/sources/${sourceId}/${newTab}`)
  }

  const handleUpdate = (sourceModel: Partial<Source>) => {
    updateSourceMutation.mutate(sourceModel)
  }

  const associatedIntegration = useMemo<AvailableIntegrationInterfaces>(() => {
    const assignedIntegrationKey = Object.keys(integrations).find(
      (integrationKey) => {
        const integrationId = (integrations as any)[integrationKey]._id
        return currentSource?.integration_id === integrationId
      },
    )

    return (
      (assignedIntegrationKey &&
        (integrations as any)[assignedIntegrationKey]) ||
      null
    )
  }, [integrations, currentSource])

  const LoadingQueryBuilder = () => (
    <div className='py-16'>
      <p>Loading query builder...</p>
    </div>
  )

  const DefaultQueryBuilder = () => (
    <div className='py-16'>
      <p>No integration query builder found</p>
    </div>
  )

  const QueryBuilderComponent = useMemo(() => {
    let SelectedQueryBuilder = <LoadingQueryBuilder />

    const shouldRenderQueryBuilder = !!(
      currentSource &&
      associatedIntegration &&
      associatedIntegration._id
    )

    if (shouldRenderQueryBuilder) {
      const standardProps = {
        refetchSource,
        handleUpdate,
        currentSource,
      }
      switch (associatedIntegration.integration_type) {
        case 'hubspot':
          SelectedQueryBuilder = (
            <HubspotQueryBuilder
              {...standardProps}
              integration={associatedIntegration as HubspotIntegrationInterface}
            />
          )
          break
        default:
          SelectedQueryBuilder = <DefaultQueryBuilder />
          break
      }
    }

    const QueryBuilder = () => SelectedQueryBuilder
    return QueryBuilder
  }, [
    associatedIntegration,
    associatedIntegration && associatedIntegration?.integration_type,
    isSuccess,
    isRefetching,
    currentSource,
    currentSource?.subscription_configs &&
    currentSource?.subscription_configs.object_type,
  ])

  return (
    <section className='source-details'>
      <Container fluid className='custom-padding bg-darkGrey'>
        <Row>
          <Col md={12} className='lead-subNav'>
            {currentSource && currentSource.name ? (
              <EditNameHeader<Source>
                currentName={currentSource.name}
                handleOnSubmit={handleUpdate}
              />
            ) : (
              <h3 className='mt-12'>Loading...</h3>
            )}
            <Tabs
              activeKey={activeTab}
              id='uncontrolled-tab-example'
              onSelect={(newTab) => onTabSelect(newTab)}
              className='bluebordertabs'
            >
              <Tab eventKey='query' title='Query'></Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>

      <Container fluid className='custom-padding'>
        <Row>
          <Col md={12}>
            {activeTab === 'query' && <QueryBuilderComponent />}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SourceDetailsTemplate
