import hubspotIcon from '../../../assets/images/hubspot-logo.png'
import salesforceIcon from '../../../assets/images/icons/salesforce-icon.png'
import googleSheetsIcon from '../../../assets/images/icons/google-sheet-icon.png'
import pipedriveIcon from '../../../assets/images/icons/pipedrive-icon.png'
import zohoIcon from '../../../assets/images/icons/zoho-icon.png'
import mondayIcon from '../../../assets/images/icons/monday-icon.png'
import sugarIcon from '../../../assets/images/icons/sugar-icon.png'

interface IProps {
  addRouter: () => void,
  loading: boolean,
}

const ListIntegrations = (props : IProps) => {
  const { addRouter, loading } = props

  const items = [
    {
      id: 1,
      icon: hubspotIcon,
      label: 'Hubspot',
      WithOpacity: false,
	    disabled: false,
    },
    {
      id: 2,
      icon: salesforceIcon,
      label: 'Salesforce',
      WithOpacity: true,
	    disabled: true,
    },
    {
      id: 3,
      icon: googleSheetsIcon,
      label: 'Google Sheets',
      WithOpacity: true,
	    disabled: true,
    },
    {
      id: 4,
      icon: pipedriveIcon,
      label: 'Pipedrive',
      WithOpacity: true,
	    disabled: true,
    },
    {
      id: 5,
      icon: zohoIcon,
      label: 'Zoho CRM',
      WithOpacity: true,
	    disabled: true,
    },
    {
      id: 6,
      icon: mondayIcon,
      label: 'Monday CRM',
      WithOpacity: true,
	    disabled: true,
    },
    {
      id: 7,
      icon: sugarIcon,
      label: 'Sugar CRM',
      WithOpacity: true,
	    disabled: true,
    },
  ];

  return (
	<div className='p-3 lg:p-4 justify-evenly grid lg:gap-2 lg:grid-cols-2 lg:grid-rows-3 lg:grid-flow-row grid-rows-4 grid-flow-col gap-1'>
		{items && items.map((item) => 
			<div key={item.id} className={`flex justify-start pb-2 lg:pb-6 ${item.WithOpacity || loading ? 'opacity-25' : 'opacity-100'}`}>
				<button disabled={item.disabled || loading} onClick={addRouter} className='flex justify-center items-center'>
					<div className='h-10 w-10 bg-white border-solid border-gray-100 border-2 shadow-inner p-[2px] flex justify-center items-center'>
						<img
							src={item.icon}
							alt=''
						/>
					</div>
					<div className='flex justify-center items-center ml-3'>
						<span className='font-bold text-sm lg:text-base'>{item.label}</span>
					</div>
				</button>
			</div>
		)}
	</div>
  )
}

export default ListIntegrations
