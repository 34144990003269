import React, { useEffect, useState } from 'react'

import { useAppSelector } from 'src/store'
import { useActions } from 'src/actions'
import { getallusers } from 'src/container/my_account/account.selector'
import { getrunagain } from 'src/container/Loader/loader.selector'
import toastBuilder from 'src/utils/toastBuilder'
import { User } from 'src/types/customers'
import SelectableTable, {
  TableColumn,
  SELECTION_MODES,
} from 'src/components/organisms/tables/SelectableTable.Organism'
import Avatar from 'src/components/atoms/avatar/Avatar.Atom'
import { PencilIcon } from '@heroicons/react/24/solid'
import { selectIntegrations } from 'src/container/integrations/integrations.selector'
import { integrationsService } from 'src/services/integrations.service'
import { ROUTER_INTEGRATION_TYPES } from 'src/types/routerDetail'
import EditUserWorkingHours from './EditUserWorkingHours'
import ImportUsersFromCRM from './ImportUsersFromCRM'
import { Toggle } from 'src/components/atoms/Toggle/Toggle.atom'
import { selectUser } from 'src/container/auth/auth.selector'
import { customService as accountsService } from 'src/services/customer.service'
import { subscriptonService } from 'src/services/subscription.service'
import { listAllRoles } from 'src/container/roles/roles.selector'
import { RolesListType } from 'src/types/roles'
import { getcurrentuser } from 'src/utils/localstorage'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'

const CustomerTable: React.FC<{ setShow: any }> = (props) => {
  const { setShow } = props
  const [rowsToShow, setRowsToShow] = useState<[]>([])
  const [crmUsersList, setCRMusersList] = useState<[]>([])
  const rolesList = useAppSelector(listAllRoles)
  const [showImportUsersModal, setShowImportUsersModal] = useState(false)
  const [showWorkingHours, setShowWorkingHours] = useState(false)
  const [selectedTeamMember, setSelectedTeamMember] = useState<any>({})
  const [currentPlan, setCurrentPlan] = useState<number>(5)
  const [amountUser, setAmountUser] = useState<number>(5)
  const [rolList, setRolList] = useState<RolesListType[] | undefined>(undefined)
  const [changeValue, setChangeValue] = useState<string>('')
  const [usersPermissions, setUsersPermissions] = useState<string[]>([])
  const {
    getallcustomer,
    changeoffice_status_for_team_member,
    DeleteUser,
    getRolesList,
    getIntegrations,
  } = useActions()
  const runagain = useAppSelector(getrunagain)
  const allUsers = useAppSelector(getallusers)
  const integrations = useAppSelector(selectIntegrations)
  const currentUser = useAppSelector(selectUser)
  const ability = useAbility(AbilityContext)

  const [currentUserId, setCurrentUserId] = useState<string>('')

  const getCurrentPlanHelper = async () => {
    return await subscriptonService.getCurrentPlan()
  }

  useEffect(() => {
    if (rolesList && rolesList[0].role && rolesList[0].access) {
      setRolList(rolesList)
      setChangeValue(rolesList[0].role.name)
    }
  }, [rolesList])

  useEffect(() => {
    getCurrentPlanHelper().then((res) => {
      if (res && res.data && res.data.max_seats) {
        setCurrentPlan(res.data.max_seats)
      }
    })
    countUsers()
    getIntegrations()
    getRolesList()
    setCurrentUserId(getcurrentuser()._id)
  }, [])

  const countUsers = () => {
    let amountUserAux = 0

    allUsers.forEach((element: any) => {
      if (element.office_status) {
        amountUserAux++
      }
    })

    setAmountUser(amountUserAux)
  }

  useEffect(() => {
    getallcustomer()
  }, [runagain])

  useEffect(() => {
    setRowsToShow(
      allUsers.map((user: User) => ({
        ...user,
        name: user.firstName + ' ' + user.lastName,
      })),
    )

    countUsers()
  }, [allUsers])

  const onSearchTextChange = (searchText: string) => {
    // FIXME - Change this to server side search as per need
    if (searchText === '') {
      setRowsToShow(
        allUsers.map((user: User) => ({
          ...user,
          name: user.firstName + ' ' + user.lastName,
        })),
      )
      return
    }
    setRowsToShow(
      allUsers
        .map((user: User) => ({
          ...user,
          name: user.firstName + ' ' + user.lastName,
        }))
        .filter((teamMember: any) =>
          teamMember.name.toLowerCase().includes(searchText.toLowerCase()),
        ),
    )
  }

  const handleStatusChange = async (id: string, val: boolean) => {
    const payload = {
      office_status: val,
      user_id: id,
    }
    const result: any = await changeoffice_status_for_team_member(payload)
    if (result.payload.success) {
      toastBuilder('success', 'status changed successfully')
    }
  }

  const onEditPress = (user: any) => {
    setShowWorkingHours(true)
    setSelectedTeamMember(user)
  }

  const columns: Array<TableColumn> = [
    {
      label: 'NAME',
      render: ({
        avatar,
        firstName,
        lastName,
        userAvailability,
        office_status,
      }: any) => {
        return (
          <div className='mr-2 flex items-center'>
            <Avatar
              className='text-sm mr-2 w-[32px] h-[32px]'
              firstName={firstName}
              lastName={lastName}
              imgURL={avatar}
              available={{
                userAvailability: userAvailability,
                officeStatus: office_status ? office_status : false,
              }}
            />
            <span className='font-normal text-[14px] leading-[21px] text-gray-700'>
              {' '}
              {`${firstName} ${lastName}`}
            </span>
          </div>
        )
      },
    },
    {
      label: 'EMAIL',
      field: 'email',
      render: ({ email }: any) => {
        return (
          <div className='font-normal text-[14px] leading-[21px] text-gray-500'>
            {email}
          </div>
        )
      },
    },
    {
      label: 'TEAM',
      field: 'teamName',
      render: ({ teamName }: any) => {
        return (
          <div className='font-normal text-[14px] leading-[21px] text-gray-500'>
            {teamName}
          </div>
        )
      },
    },
    {
      label: 'Role',
      field: 'roleName',
      render: ({ role }: any) => {
        return (
          <div className='font-normal text-[14px] leading-[21px] text-gray-500'>
            <span className='capitalize text-gray-900 bg-gray-100 py-1 px-3 rounded-[6px] text-xs leading-[18px] font-medium'>
              {role}
            </span>
          </div>
        )
      },
    },
    {
      label: 'PHONE NUMBER',
      field: 'phone',
      render: ({ phone }: any) => {
        return (
          <div className='font-normal text-[14px] leading-[21px] text-gray-500'>
            {phone}
          </div>
        )
      },
    },
    {
      label: 'ASSIGNMENT STATUS',
      field: 'phone',
      render: (rowData: any) => {
        return (
          <Toggle
            initialValue={rowData.office_status}
            key={rowData._id}
            data={rowData._id}
            handleChange={handleStatusChange}
            className='w-[125px]'
            disabled={!ability.can('update', 'setting.user')}
          />
        )
      },
    },
    {
      label: 'WORKING HOURS / OUT OF OFFICE',
      field: 'working_hours',
      render: (rowData: any) => {
        return (
          <ul className='tg-list m-0 list-unstyled assignment-status'>
            <li className='tg-list-item flex self-center'>
              <PencilIcon
                className='h-4 w-4 text-blue-600 cursor-pointer'
                onClick={() => onEditPress(rowData)}
              />
            </li>
            <li className='font-normal text-[12px] leading-[18px] text-gray-500'>
              &nbsp;Updated by {rowData.workingHours_updated_by} at{' '}
              {rowData.workingHours_updated_at}
            </li>
          </ul>
        )
      },
    },
  ]

  const handleBulkDeleteRows = async (selectedUsers: Array<User>) => {
    const dataUsers = selectedUsers.map((user) => user._id)
    const filteredDataUsers = dataUsers.filter(
      (id: string) => id != currentUserId,
    )

    const payload = {
      userIdsArray: filteredDataUsers,
    }

    const action: any = await DeleteUser(payload)

    if (action?.payload?.success) {
      toastBuilder('success', action.payload.msg)
    }

    getallcustomer()
  }

  const onImportFromCRM = async () => {
    if (!integrations || !integrations[ROUTER_INTEGRATION_TYPES.HUBSPOT]?._id) {
      toastBuilder('warn', 'Please setup an integration first')
      return
    }
    if (allUsers && allUsers.length === currentUser.max_seats) {
      toastBuilder(
        'error',
        'You have reached your maximum users capacity. Please upgrade your plan.',
      )
      return
    }
    const users = await integrationsService.getCRMUsers(
      integrations &&
        integrations[ROUTER_INTEGRATION_TYPES.HUBSPOT] &&
        integrations[ROUTER_INTEGRATION_TYPES.HUBSPOT]?._id
        ? integrations[ROUTER_INTEGRATION_TYPES.HUBSPOT]?._id || ''
        : '',
    )
    setShowImportUsersModal(true)
    if (users) {
      setCRMusersList(users.data)
    }
  }

  const handleAddRole = async () => {
    let permissions: any = usersPermissions
    let value: any = changeValue

    if (usersPermissions.length === 0 && rolList) {
      permissions = [rolList[0].role?.id]
      value = rolList[0].role?.name
    }

    const payload = {
      user_ids: permissions,
      role: value,
    }

    const result: any = await accountsService.setRole(payload)
    if (result.success) {
      getallcustomer()
      toastBuilder('success', 'Role changed successfully')
    } else {
      toastBuilder('error', 'Error')
    }
    setUsersPermissions([])
  }

  const changeSelectType = (value: string, users: User[]) => {
    setChangeValue(value)
    if (users.length > 0) {
      const dataUsers: string[] = users.map((user: User) => user._id)
      const filteredDataUsers = dataUsers.filter(
        (id: string) => id != currentUserId,
      )
      setUsersPermissions(filteredDataUsers)
    }
  }

  return (
    <>
      <div className='px-5 py-7'>
        <div
          className={`col-md-12 mb-5 d-flex justify-end tablet:flex-col tablet:items-start ${
            !ability.can('create', 'setting.user') && 'cursor-not-allowed'
          }`}
        >
          <div className='flex space-x-2 tablet:flex-col mobile:items-start gap-2'>
            <button
              disabled={!ability.can('create', 'setting.user')}
              className={`px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg bg-routera-primary self-start ${
                !ability.can('create', 'setting.user') && 'cursor-not-allowed'
              }`}
              onClick={onImportFromCRM}
            >
              <span className='inline-block text-[14px]'>Import from CRM</span>
            </button>
            <button
              disabled={!ability.can('create', 'setting.user')}
              className={`flex flex-row m-0 items-center px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg bg-routera-primary self-start ${
                !ability.can('create', 'setting.user') && 'cursor-not-allowed'
              }`}
              onClick={() => {
                if (allUsers && allUsers.length === currentUser.max_seats) {
                  toastBuilder(
                    'error',
                    'You have reached your maximum users capacity. Please upgrade your plan.',
                  )
                  return
                }
                setShow(true)
              }}
            >
              <i className='fa-solid fa-plus text-white inline-block mr-2 text-[14px]'></i>
              <span className='ml-2 inline-block  text-[14px]'>Add User</span>
            </button>
          </div>
        </div>
        <div className=''>
          <SelectableTable
            columns={columns}
            rows={rowsToShow}
            initialSelection={[]}
            selectionType={SELECTION_MODES.SELECT_MANY}
            onSearchChange={onSearchTextChange}
            onDelete={handleBulkDeleteRows}
            noItemsCheck={[currentUserId]}
            seats={currentPlan}
            users={amountUser}
            addRole={{
              handleAddRole: handleAddRole,
              rolList: rolList,
              changeSelectType: changeSelectType,
              changeValue: changeValue,
              setUsersPermissions: setUsersPermissions,
            }}
            permissions={{
              delete: ability.can('delete', 'setting.user'),
              add: ability.can('update', 'setting.user'),
            }}
          />
        </div>
      </div>
      {showImportUsersModal && (
        <ImportUsersFromCRM
          show={showImportUsersModal}
          setShow={setShowImportUsersModal}
          users={crmUsersList}
        />
      )}
      {showWorkingHours && (
        <EditUserWorkingHours
          outOfficeDates={selectedTeamMember.outOfficeDates}
          userId={selectedTeamMember._id}
          userName={`${selectedTeamMember.firstName} ${selectedTeamMember.lastName}`}
          show={showWorkingHours}
          setShow={setShowWorkingHours}
          workingHours={selectedTeamMember.workingHours}
          userTimeZone={selectedTeamMember.timeZone}
          email={selectedTeamMember.email}
        />
      )}
    </>
  )
}

export default CustomerTable
