import { createSlice } from '@reduxjs/toolkit'
import {
  changeoffice_status_for_team_member,
  getallcustomer,
  get_working_hours,
  registerCustomer,
  fetchSubscriptionStatus,
  setTeamMemberWorkingHours,
  getAccount,
} from './account.actions'
import { findIndex } from '../../utils/updatedata'
import { logoutAction } from '../auth/auth.actions'

export enum SUBSCRIPTION_STATUS {
  ON_TRIAL = 'trial',
  ACTIVE = 'active',
  TRIAL_ABOUT_EXPIRE = 'trial about to expire',
  TRIAL_EXPIRED = 'trial expired',
  PLAN_EXPIRED = 'plan expired',
}

const initialState: any = {
  users: [],
  wokingTime: {},
  WorkTimeError: {
    isError: false,
    message: 'invalid data',
  },
  subscriptionStatus: '',
  account: {
    _id: '',
    user_ids: [],
    integration_ids: [],
    created_by_id: '',
    max_seats: 5,
    plan_id: '',
    max_routers: '',
    total_assignments: 0,
    allocation_limit_reached: false,
  },
}
const customerservice = createSlice({
  name: 'customerslice',
  initialState,
  reducers: {
    setWokingTime: (state, action) => {
      state.WorkTimeError = {
        isError: false,
        message: 'invalid date',
      }
      const { type, dayVlaue, selected, Time } = action.payload

      const updatedIndex = state.wokingTime.findIndex(
        /* eslint eqeqeq: 0 */
        (worktime: any) => dayVlaue == worktime.day,
      )

      if (type === 'handleDayChange') {
        // item already exist
        // if (selected) {
        // adding data only if day is selected
        state.wokingTime[updatedIndex] = {
          ...state.wokingTime[updatedIndex],
          day: dayVlaue,
          selected: selected,
        }
        // }
        //  else {
        //   //removing item because  user is turning it off
        //   state.wokingTime.splice(updatedIndex, 1);
        // }

        state.wokingTime = [...state.wokingTime]
      }
      // else if (updatedIndex <= -1 && type === "handleDayChange") {
      //   //creating new item day with out time
      //   state.wokingTime = [
      //     ...state.wokingTime,
      //     { day: dayVlaue, selected: selected },
      //   ];
      // }
      else if (type === 'onStartChange') {
        state.wokingTime[updatedIndex] = {
          ...state.wokingTime[updatedIndex],
          startHour: Time,
        }
        state.wokingTime = [...state.wokingTime]
      }
      // else if (type === "onStartChange" && updatedIndex <= -1) {
      //   // creating new item time
      //   // item already exist

      //   state.wokingTime = [
      //     ...state.wokingTime,
      //     {
      //       day: dayVlaue,
      //       startHour: Time,
      //     },
      //   ];
      // }
      else if (type === 'onEndTimeChange') {
        // item already exist
        state.wokingTime[updatedIndex] = {
          ...state.wokingTime[updatedIndex],
          endHour: Time,
        }
        state.wokingTime = [...state.wokingTime]
      }
      // else if (type === "onEndTimeChange" && updatedIndex <= -1) {
      //   state.wokingTime([
      //     ...state.wokingTime,
      //     {
      //       day: dayVlaue,
      //       endHour: Time,
      //     },
      //   ]);
      // }

      state.wokingTime.sort((a: any, b: any) => a.day - b.day)

      const newIndex = state.wokingTime.findIndex(
        /* eslint eqeqeq: 0 */
        (worktime: any) => dayVlaue == worktime.day,
      )

      if (newIndex > -1) {
        const { startHour, endHour } = state.wokingTime[newIndex]

        if (startHour && endHour) {
          const validTime = endHour > startHour
          if (!validTime) {
            state.wokingTime[newIndex] = {
              ...state.wokingTime[newIndex],
              error: false,
            }
          } else {
            state.wokingTime[newIndex] = {
              ...state.wokingTime[newIndex],
              error: false,
            }
          }
        }
      }
    },

    setWorkTimeError: (state, action) => {
      const flag = action.payload
      state.WorkTimeError = {
        isError: flag,
        message: 'invalid data',
      }
    },
  },

  extraReducers: {
    [registerCustomer.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        //sdfsd
        state.users = [...state.users, ...data]
      }
    },
    [logoutAction.fulfilled.toString()]: (state, action) => {
      state.subscriptionStatus = ''
    },

    [fetchSubscriptionStatus.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        state.subscriptionStatus = data.subscriptionStatus
      }
    },

    [getallcustomer.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload

      if (success) {
        state.users = data.result
        //sdfsd
      }
    },

    [setTeamMemberWorkingHours.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success && data) {
        // Find the index matching the user id
        const matchingIndex = state.users.findIndex(
          (user: any) => user._id === data.updatedUser._id,
        )
        if (matchingIndex !== -1) {
          const newUsersList = [...state.users]
          newUsersList[matchingIndex] = data.updatedUser
          state.users = newUsersList
        }
      }
    },

    [changeoffice_status_for_team_member.fulfilled.toString()]: (
      state,
      action,
    ) => {
      const { success, data } = action.payload
      const { updatedUser } = data
      if (success) {
        const index = findIndex(state.users, updatedUser._id)

        state.users[index] = {
          ...updatedUser,
          office_status: updatedUser.office_status,
        }
      }
    },

    [get_working_hours.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        const { workingHours } = data
        state.wokingTime = workingHours
      }
    },

    [getAccount.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        state.account = data
      }
    },
  },
})

export const { setWokingTime, setWorkTimeError } = customerservice.actions

export default customerservice.reducer
