import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { config } from '../../../config/constants'

export const Auth0ProviderWithNavigate = ({ children } : any) => {
  const navigate = useNavigate();

  const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_CALLBACK_URL, AUTH0_AUDIENCE, AUTH0_REDIRECT_URL} = config

  const onRedirectCallback = (appState : any) => {
    navigate(AUTH0_REDIRECT_URL);
  };

  if (!(AUTH0_DOMAIN && AUTH0_CLIENT_ID && AUTH0_CALLBACK_URL && AUTH0_AUDIENCE)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: AUTH0_CALLBACK_URL,
        audience: AUTH0_AUDIENCE
      }}
      //onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};