import { createSlice } from '@reduxjs/toolkit'
import { IHomestate } from '../../types/Ihome_state'
import { createReducerBuilder } from '../../utils/actions'
import {
  get_dashboard_data_action,
  get_dashboard_stats_action,
} from './home.actions'

// @ts-ignore
// TODO check this type
const initialState: IHomestate = {
  dashboard: {
    leads: [],
    totalDataSize: 10,
  },
  dashboardStats: {
    leadsAssignedStat: {
      currentDateRange: 0,
      prevDateRange: 0,
      percentageType: 'increase',
      percentage: 0,
    },
    graphDatasets: {
      currentData: {
        new: 0,
        open: 0,
        in_progress: 0,
        open_deal: 0,
        unqalified: 0,
        closed_won: 0,
        closed_lost: 0,
      },
      previousData: {
        new: 0,
        open: 0,
        in_progress: 0,
        open_deal: 0,
        unqalified: 0,
        closed_won: 0,
        closed_lost: 0,
      },
    },
  },
}

const reducerBuilder = createReducerBuilder<IHomestate>()

const get_dashbord_data_reducer = reducerBuilder(get_dashboard_data_action, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.dashboard = payload
    }
  },
})

const get_dashbord_stats_reducer = reducerBuilder(get_dashboard_stats_action, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.dashboardStats = payload
    }
  },
})

const home_reducer = createSlice({
  name: 'homereducer',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    get_dashbord_data_reducer(builder)
    get_dashbord_stats_reducer(builder)
  },
})

// export const {} = home_reducer.actions;

export default home_reducer.reducer
