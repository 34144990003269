import { ChangeEvent, useEffect, useState } from 'react'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import Card from '../card/Card'
import hubspotIcon from '../../../assets/images/hubspot-logo.png'
import {
  AVAILABLE_OPERATORS,
  JsonFieldTypeMap,
} from '../../organisms/forms/inputs/jsonOperator/getJsonOperator'
import { useActions } from 'src/actions'
import DropdownCustom, {
  defaultOptionRenderer,
} from 'src/components/atoms/Dropdown/DropdownCustom.atom'
import Input from 'src/components/atoms/Input/Input.atom'
import IconRoutera from 'src/components/atoms/icons/icons'
import { ability } from 'src/utils/permissionsCASL'
import { TrashIcon } from '@heroicons/react/24/outline'
import toastBuilder from 'src/utils/toastBuilder'

const title = 'HubSpot Rule'
const description = 'Refresh HubSpot fields'

const Rule = ({
  groups,
  objectType,
  setThereAreChanges,
  selectedRules,
  setSelectedRules,
}: any) => {
  const [fields, setFields] = useState([])
  const [operators, setOperators] = useState([])
  const [values, setValues] = useState([])
  const { getFieldsByObjectType } = useActions()
  const [fieldType, setFieldType] = useState('')

  const dataField = async (objectType: string) => {
    const resp: any = await getFieldsByObjectType(objectType)
    if (resp.payload.success) {
      setFields(resp.payload.data)
      return true
    }
  }

  const enumKeyToString = (key: string) => {
    const str = key.replaceAll('_', ' ')
    const splitStr = str.toLowerCase().split(' ')
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(' ')
  }

  const availableOperatorValues = Object.keys(AVAILABLE_OPERATORS).map(
    (key) => {
      return {
        // @ts-ignore
        value: AVAILABLE_OPERATORS[key],
        // @ts-ignore
        label: enumKeyToString(key),
      }
    },
  )

  const getOperatorValues = (type: string) => {
    const newOperatorValues: any = []
    availableOperatorValues.forEach((opVal) => {
      // @ts-ignore
      if (JsonFieldTypeMap[type]?.includes(opVal.value)) {
        newOperatorValues.push(opVal)
      }
    })
    return newOperatorValues
  }

  useEffect(() => {
    if (objectType) {
      dataField(objectType)
    }
    if (groups) {
      setSelectedRules(groups)
    }
    if (fields && fields.length > 0) {
      initialFields()
    }
  }, [])

  useEffect(() => {
    if (groups) {
      const operatorsByGroup: any = []
      const valueByGroup: any = []
      groups.map((group: any) => {
        const operatorsByField: any = []
        const valueByField: any = []
        group.map((rule: any) => {
          if (rule) {
            operatorsByField.push([{}])
            valueByField.push([{}])
          }
        })
        operatorsByGroup.push(operatorsByField)
        valueByGroup.push(valueByField)
      })
      setOperators(operatorsByGroup)
      setValues(valueByGroup)
      setSelectedRules(groups)
    }
  }, [groups])

  const onFieldChange = (index: number, indexRule: number, option: any) => {
    const updatedRule = [...selectedRules]
    updatedRule[index][indexRule].name = option.name
    updatedRule[index][indexRule].label = option.label

    const updatedOperators: any = [...operators]
    const newOperators = getOperatorValues(option.type)
    updatedOperators[index][indexRule] = newOperators

    updatedRule[index][indexRule].operator = newOperators[0]
    updatedRule[index][indexRule].type = option.type
    updatedRule[index][indexRule].showValueField = true
    setFieldType(option.type)

    const updatedValues: any = [...values]
    if (option.options && option.options.length > 0) {
      updatedValues[index][indexRule] = option.options
      updatedRule[index][indexRule].value = option.options[0]
      setValues(updatedValues)
    } else {
      updatedRule[index][indexRule].value = option.options
      updatedValues[index][indexRule] = []
    }

    setSelectedRules(updatedRule)
    setOperators(updatedOperators)
    setThereAreChanges(true)
  }

  const onOperatorChange = (index: number, indexRule: number, option: any) => {
    const updatedRule = [...selectedRules]
    updatedRule[index][indexRule].operator = option
    updatedRule[index][indexRule].showValueField = true

    if (option.value === 'isKnown' || option.value === 'isUnknown') {
      updatedRule[index][indexRule].showValueField = false
    }

    setSelectedRules(updatedRule)
    setThereAreChanges(true)
  }

  const onValueChange = (index: number, indexRule: number, option: any) => {
    const updatedRule = [...selectedRules]
    updatedRule[index][indexRule].value = option
    setSelectedRules(updatedRule)
    setThereAreChanges(true)
  }

  const initialFields = () => {
    if (groups && groups.length > 0) {
      groups.map((group: any, indexGroup: number) => {
        group.map((rule: any, indexRule: number) => {
          const find: any = fields.find((field: any) => field.name == rule.name)
          if (find) {
            const updatedRule = [...selectedRules]
            const updatedOperators: any = [...operators]
            const newOperators = getOperatorValues(find.type)
            updatedOperators[indexGroup][indexRule] = newOperators

            const operator = rule.operator ? rule.operator : newOperators[0]

            const showValueField =
              rule.operator &&
              rule.operator.value &&
              (rule.operator.value === 'isKnown' ||
                rule.operator.value === 'isUnknown')
                ? false
                : true

            updatedRule[indexGroup][indexRule].operator = operator
            updatedRule[indexGroup][indexRule].showValueField = showValueField
            updatedRule[indexGroup][indexRule].type = find.type
            setFieldType(find.type)

            const updatedValues: any = [...values]

            if (rule.value && rule.value.value) {
              updatedRule[indexGroup][indexRule].value = rule.value
            } else {
              if (
                rule.type &&
                (rule.type === 'enumeration' || rule.type === 'bool')
              ) {
                const values =
                  find.options && find.options.length > 0 ? find.options[0] : []
                updatedRule[indexGroup][indexRule].value = values
              }
            }

            const currentValues =
              find.options && find.options.length > 0 ? find.options : []
            updatedValues[indexGroup][indexRule] = currentValues

            setValues(updatedValues)
            setSelectedRules(updatedRule)
            setOperators(updatedOperators)
          }
        })
      })
    } else {
      const initialGroup = []
      const initialRule = []
      const initialField: any = fields[0]

      const initialOperators: any = []
      const operators: any = getOperatorValues(initialField.type)
      initialOperators.push([operators])
      setOperators(initialOperators)

      const initialValue: any = []
      const values = initialField.options
      initialValue.push([values])
      setValues(initialValue)

      const defaultRule = {
        label: initialField.label,
        name: initialField.name,
        value: initialValue,
        operators: initialOperators,
        type: initialField.type,
        showValueField: true,
      }

      initialRule.push(defaultRule)
      initialGroup.push(initialRule)
      setSelectedRules(initialGroup)
    }
  }

  useEffect(() => {
    if (fields && fields.length > 0) {
      initialFields()
    }
  }, [fields])

  useEffect(() => {
    if (objectType) {
      dataField(objectType)
    }
  }, [objectType])

  const onChange = (e: any, index: number, indexRule: number) => {
    const updatedRule = [...selectedRules]
    updatedRule[index][indexRule].value = e.target.value
    setSelectedRules(updatedRule)
    setThereAreChanges(true)
  }

  const onDeleteRule = (index: number, indexRule: number) => {
    const removeRule = [...selectedRules]
    removeRule[index].splice(indexRule, 1)

    if (removeRule[index].length === 0) {
      removeRule.splice(index, 1)
    }

    setSelectedRules(removeRule)
    setThereAreChanges(true)
  }

  const onDeleteGroup = (index: number) => {
    const removeRule = [...selectedRules]
    removeRule.splice(index, 1)
    setSelectedRules(removeRule)
    setThereAreChanges(true)
  }

  const onRefresh = async () => {
    if (objectType) {
      const refreshFields = await dataField(objectType)

      if (refreshFields) {
        toastBuilder('success', 'HubSpot fields updated successfully')
      }
    }
  }

  const onDuplicateGroup = (index: number) => {
    const groups = [...selectedRules]
    const duplicateGroup = JSON.parse(JSON.stringify(selectedRules[index]))
    const newGroup = []
    newGroup.push(...duplicateGroup)
    groups.push(newGroup)

    const currentOperators: any = [...operators]
    const duplicateOperators = JSON.parse(JSON.stringify(operators[index]))
    const newOperators: any = []
    newOperators.push(...duplicateOperators)
    currentOperators.push(newOperators)

    const currentValues: any = [...values]
    const duplicateValues = JSON.parse(JSON.stringify(values[index]))
    const newValues: any = []
    newValues.push(...duplicateValues)
    currentValues.push(newValues)

    setSelectedRules(groups)
    setOperators(currentOperators)
    setValues(currentValues)
    setThereAreChanges(true)
  }

  const addRule = (indexGroup: number) => {
    const groups = [...selectedRules]
    const newRule = []
    const initialField: any = fields[0]

    const currentOperators: any = [...operators]
    const newOperators = getOperatorValues(initialField.type)
    currentOperators[indexGroup].push(newOperators)

    const currentValues: any = [...values]
    let newValues = []
    if (initialField.options && initialField.options.length > 0) {
      newValues = initialField.options
      currentValues[indexGroup].push(newValues)
    }

    const defaultRule = {
      label: initialField.label,
      name: initialField.name,
      value: newValues,
      operators: newOperators,
      type: initialField.type,
      showValueField: true,
    }

    newRule.push(defaultRule)
    groups[indexGroup].push(...newRule)
    setSelectedRules(groups)
    setOperators(currentOperators)
    setValues(currentValues)
  }

  const addGroup = () => {
    const currentGroups = [...selectedRules]
    const currentOperators: any = [...operators]
    const currentValues: any = [...values]
    const initialGroup = []
    const initialRule = []
    const initialField: any = fields[0]

    const initialOperators: any = []
    const newOperators: any = getOperatorValues(initialField.type)
    initialOperators.push(newOperators)
    currentOperators.push(initialOperators)

    const initialValue: any = []
    const newValues = initialField.options
    initialValue.push(newValues)
    currentValues.push(initialValue)

    const defaultRule = {
      label: initialField.label,
      name: initialField.name,
      value: initialValue && initialValue.length > 0 ? initialValue[0] : [],
      operator: newOperators[0],
      type: initialField.type,
      showValueField: true,
    }

    initialRule.push(defaultRule)
    initialGroup.push(...initialRule)
    currentGroups.push(initialGroup)

    setValues(currentValues)
    setOperators(currentOperators)
    setSelectedRules(currentGroups)
  }

  return (
    <div className='px-4 p-4'>
      <span className='text-gray-700 text-xl leading-[30px]'>Rule</span>
      <p className='text-gray-700 text-sm leading-[21px] mt-2 mb-4'>
        Select what an assignee needs to do to meet the SLA
      </p>

      <Card
        icon={hubspotIcon}
        title={title}
        description={description}
        refreshIcon={true}
        onRefreshFields={onRefresh}
      >
        <div>
          {selectedRules &&
            selectedRules.length > 0 &&
            operators &&
            operators.length > 0 &&
            selectedRules.map((rules: any, index: number) => (
              <>
                <Card
                  key={`group-${index}`}
                  title={`Group #${(index + 1).toString()}`}
                  trashIcon={true}
                  duplicateIcon={true}
                  onDelete={onDeleteGroup}
                  indexGroup={index}
                  onDuplicate={onDuplicateGroup}
                >
                  <div className='mb-3 mx-3'>
                    {rules &&
                      rules.length > 0 &&
                      rules.map((rule: any, indexRule: number) => (
                        <div
                          id={`rules-${indexRule}`}
                          key={`Rules-${indexRule}`}
                          className='flex flex-wrap justify-evenly md:justify-between  mb-3 items-end'
                        >
                          <div>
                            <DropdownCustom
                              key={indexRule}
                              dropdownLabel='Field'
                              options={fields}
                              selectedOption={selectedRules[index][indexRule]}
                              setSelectedOption={(rule) =>
                                onFieldChange(index, indexRule, rule)
                              }
                              optionRenderer={defaultOptionRenderer}
                              withAvatar={false}
                              customClass={`w-[145px] ${
                                selectedRules[index][indexRule].showValueField
                                  ? 'md:w-[288px]'
                                  : 'md:w-[288px] lg:w-[570px]'
                              }`}
                              typeAnd={indexRule != 0}
                            />
                          </div>

                          <div className='flex flex-col h-[30px] mt-4 md:mt-2 text-gray-500'>
                            <IconRoutera iconName='ArrowRightIcon'></IconRoutera>
                          </div>

                          <div>
                            <DropdownCustom
                              key={indexRule}
                              dropdownLabel='Operator'
                              options={operators[index][indexRule]}
                              selectedOption={
                                selectedRules[index][indexRule].operator
                              }
                              setSelectedOption={(rule) =>
                                onOperatorChange(index, indexRule, rule)
                              }
                              optionRenderer={defaultOptionRenderer}
                              withAvatar={false}
                              customClass={`w-[145px] ${
                                selectedRules[index][indexRule].showValueField
                                  ? 'md:w-[288px]'
                                  : 'md:w-[288px] lg:w-[570px]'
                              }`}
                              typeAnd={false}
                            />
                          </div>

                          {selectedRules[index][indexRule].showValueField && (
                            <div className='flex flex-col h-[30px] mt-4 md:mt-2 text-gray-500'>
                              <IconRoutera iconName='ArrowRightIcon'></IconRoutera>
                            </div>
                          )}

                          {selectedRules[index][indexRule].showValueField &&
                            (selectedRules[index][indexRule].type ==
                              'enumeration' ||
                              selectedRules[index][indexRule].type == 'bool') &&
                            values &&
                            values.length > 0 && (
                              <div>
                                <DropdownCustom
                                  key={indexRule}
                                  dropdownLabel='Value'
                                  options={values[index][indexRule]}
                                  selectedOption={
                                    selectedRules[index][indexRule].value
                                  }
                                  setSelectedOption={(rule) =>
                                    onValueChange(index, indexRule, rule)
                                  }
                                  optionRenderer={defaultOptionRenderer}
                                  withAvatar={false}
                                  customClass='w-[145px] md:w-[288px]'
                                  typeAnd={false}
                                />
                              </div>
                            )}

                          {selectedRules[index][indexRule].showValueField &&
                            (selectedRules[index][indexRule].type == 'string' ||
                              selectedRules[index][indexRule].type ==
                                'number') && (
                              <Input
                                type={'text'}
                                handleChange={(
                                  e: ChangeEvent<HTMLInputElement>,
                                ) => onChange(e, index, indexRule)}
                                value={selectedRules[index][indexRule].value}
                                label={'Value'}
                                customClass={
                                  'w-[145px] md:w-[288px] flex flex-col w-[15rem] mt-2 mb-0'
                                }
                                showLabel={true}
                              />
                            )}

                          {selectedRules[index][indexRule].showValueField &&
                            selectedRules[index][indexRule].type ==
                              'datetime' && (
                              <div>
                                <div className='text-sm font-medium mt-4'>
                                  Value:
                                </div>
                                <input
                                  type='date'
                                  onChange={(date) => {
                                    onChange(date, index, indexRule)
                                  }}
                                  value={selectedRules[index][indexRule].value}
                                  className='rounded w-[145px] md:w-[288px] z-40 mt-1 text-sm tablet:text-xs h-[39px] tablet:h-[29px] text-gray-500 tablet:p-1 tablet:px-1 bg-white border border-gray-300 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                />
                              </div>
                            )}

                          <div className='mt-4 md:mt-2'>
                            <button
                              onClick={() => onDeleteRule(index, indexRule)}
                              className={`${
                                !ability.can('update', 'router.sla') &&
                                'cursor-not-allowed'
                              }`}
                            >
                              <TrashIcon className='h-5 w-5 text-gray-500' />
                            </button>
                          </div>
                        </div>
                      ))}
                    <div className='flex flex-wrap justify-start mt-[2rem] mb-3 items-start'>
                      <button
                        className={`flex flex-row items-center w-[160px] h-[41px] md:h-[37px] px-[8px] py-[12px] text-routera-700 text-[14px] border-[1px] border-routera-700 rounded-lg bg-routera-50 self-start ${
                          !ability.can('update', 'router.sla') &&
                          'cursor-not-allowed'
                        }`}
                        onClick={() => addRule(index)}
                      >
                        <IconRoutera
                          iconName='PlusSmallIcon'
                          className='w-[19px] mr-2 mt-[2px]'
                        />{' '}
                        Add Conditional
                      </button>
                    </div>
                  </div>
                </Card>
                <div className='w-full flex justify-between items-center my-4'>
                  <span className='w-[45px] h-[25px] bg-orange-100 text-orange-700 text-center flex justify-center items-center uppercase font-bold text-xs me-2 px-2.5 py-0.5 rounded-full'>
                    Or
                  </span>
                  <hr className='h-px m-0 bg-gray-200 border-[1px] w-[95%]'></hr>
                </div>
              </>
            ))}
        </div>

        <div className='flex flex-wrap justify-start my-4 items-start'>
          <button
            className={`flex flex-row w-[160px] h-[41px] justify-center items-center px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start ${
              !ability.can('update', 'router.sla') && 'cursor-not-allowed'
            }`}
            onClick={() => addGroup()}
          >
            <IconRoutera
              iconName='PlusSmallIcon'
              className='w-[19px] mr-2 mt-[2px]'
            />{' '}
            Add Group
          </button>
        </div>
      </Card>
    </div>
  )
}

export default Rule
