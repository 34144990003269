import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { HubspotIntegrationInterface } from 'src/container/integrations/integrations.interface'
import { sourceService } from 'src/services/sources.service'
import {
  HUBSPOT_OBJECT_TYPES,
  HUBSPOT_PROPERTY_STRATEGIES,
  Source,
} from 'src/types/sources'

interface Payload {
  currentSource: Source
  integration: HubspotIntegrationInterface
  objectType?: HUBSPOT_OBJECT_TYPES
}

const useGetSourceProperties = ({
  integration,
  currentSource,
  objectType,
}: Payload) => {
  const sourcePropertiesKey = `getSourceProps/${integration?.integration_type}/${currentSource._id}`

  const [lastFetchObjectType, setLastFetchObjectType] = useState<
    HUBSPOT_OBJECT_TYPES | undefined
  >()

  const getIsNewObjectType = () => objectType !== lastFetchObjectType

  // TODO: Reduce extra render-based additional fetch on update
  const shouldFetchSourceProps = useMemo(() => {
    const hasObjectType = !!(objectType && objectType.length > 0)
    const hasSource = !!currentSource._id
    const isNewObjectType = getIsNewObjectType()

    return hasObjectType && isNewObjectType && hasSource
  }, [objectType, currentSource._id, lastFetchObjectType])

  const query = useQuery(
    [sourcePropertiesKey, currentSource._id, objectType],
    () => {
      if (getIsNewObjectType()) {
        setLastFetchObjectType(objectType)
      }

      return sourceService.getSourceProperties(currentSource._id, {
        integrationParams: {
          objectType: objectType as HUBSPOT_OBJECT_TYPES,
          type: HUBSPOT_PROPERTY_STRATEGIES.ALL,
        },
      })
    },
    {
      retry: false,
      enabled: shouldFetchSourceProps,
    },
  )

  return query
}

export default useGetSourceProperties
