import { createSlice } from '@reduxjs/toolkit'

import { ISLA_STATE } from '../../types/sla'
import { createReducerBuilder } from '../../utils/actions'
import {
  addSLAAction,
  getAllSLAsAction,
  updateOneSLAAction,
} from './SLA.actions'

const initialState = {
  slas: [],
}

const reducerBuilder = createReducerBuilder<ISLA_STATE>()

const add_sla = reducerBuilder(addSLAAction, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.slas = [...state.slas, payload.data]
    }
  },
})

const get_all_SLAs = reducerBuilder(getAllSLAsAction, {
  fulfilled: (state, { payload }) => {
    if (payload) {
      // @ts-ignore
      state.slas = payload
    }
  },
})

const update_sla = reducerBuilder(updateOneSLAAction, {
  fulfilled: (state, { payload }) => {
    if (payload && payload.data) {
      const updatedSLAs = state.slas.map((sla) => {
        if (sla._id === payload.data._id) {
          sla = payload.data
        }
        return sla
      })
      state.slas = updatedSLAs
    }
  },
})

const slaService = createSlice({
  name: 'slaSlice',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    get_all_SLAs(builder)
    update_sla(builder)
    add_sla(builder)
  },
})

export default slaService.reducer
