import React, { useState } from 'react'
import hobspot from 'src/assets/images/hubspot-logo.svg'
import { StarIcon } from '@heroicons/react/24/solid'
import { CircleStackIcon } from '@heroicons/react/24/solid'

const IntegrationsDetail: React.FC<{ onConnectClick: any }> = ({
  onConnectClick,
}) => {
  const [activeTab, setactiveTab] = useState('Overview')

  return (
    <div className='connect-page connect-detail'>
      <div className='connect-wrapper'>
        <div className='flex justify-start mobile:flex-wrap items-center gap-[40px] mobile:gap-[20px]'>
          <img src={hobspot} alt='' />
          <button
            className='px-[20px] py-[10px] mt-3 text-white text-[14px] rounded-lg bg-routera-primary self-start'
            onClick={onConnectClick}
          >
            Connect
          </button>
        </div>
      </div>
      <span className='block text-sm mt-[20px] text-gray-500 text-[16px] max-w-[960px] leading-[150%]'>
        Integrate HubSpot with Routera instantly. This integration connects your
        HubSpot as a source for your Routers. It also allows advanced
        association tracking, by monitoring associated deals. Allowing sales
        managers to monitor marketing channel quality and sales individuals
        conversion rates. As well as giving individual sales reps a dedicated
        place to mark themselves online/offline for new lead assignments, see
        current performance, and track assignments.
      </span>
      <div className='flex justify-start mt-8 gap-[80px] tablet:flex-wrap tablet:gap-[20px]'>
        <div>
          <div className='bg-blue-100 rounded-[100%] p-[12px] h-12 w-12'>
            <StarIcon className='text-blue-600 h-6 w-6' />
          </div>
          <span className='text-base text-gray-700 font-semibold mt-3 mb-2 inline-block'>
            Features
          </span>
          <ul className='max-w-md space-y-1 text-gray-500 list-outside dark:text-gray-400 list-disc'>
            <li>Syncs new incoming contacts as a source connection.</li>
            <li>
              Reports on deal activity from contacts assigned through Routers.
            </li>
            <li>
              Adds timeline activity on contact, company and deal records.
            </li>
          </ul>
        </div>
        <div>
          <div className='bg-blue-100 rounded-[100%] p-[12px] h-12 w-12'>
            <CircleStackIcon className='text-blue-600 h-6 w-6' />
          </div>
          <span className='text-base text-gray-700 font-semibold mt-3 mb-2 inline-block'>
            Shared Data
          </span>
          <div>
            <span className='text-base text-gray-500 mb-2 inline-block'>
              Data synced from HubSpot:
            </span>
            <ul className='max-w-md space-y-1 text-gray-500 list-outside list-disc dark:text-gray-400'>
              <li>Lists</li>
              <li>Contacts</li>
            </ul>
            <span className='text-base text-gray-500 mb-2 inline-block'>
              Data synced from HubSpot: Data synced to HubSpot:
            </span>
            <ul className='max-w-md space-y-1 text-gray-500 list-outside list-disc dark:text-gray-400'>
              <li>Assigned user</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntegrationsDetail
