import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '../../store'
const selectedreducer = (state: RootState) => state.planState

export const useListPlans = createDraftSafeSelector(
  selectedreducer,
  (planState) => {
    return planState.plans
  },
)
