import moment from 'moment'
import { DateTime } from 'luxon'
export function timeformater(date: Date) {
  const stillUtc = moment.utc(date).toDate()
  const timec = moment(stillUtc).local()
  const check = moment(timec)
  const timeAM = check.format('hh:mm:ss A')
  const month = check.format('MMM')
  const daynum = check.format('D')
  const year = check.format('YYYY')

  // var dayword = check.format("dd");

  return `${month} ${daynum}, ${year} ${timeAM}`
}

export function lastmodified(date: any) {
  const stillUtc = moment.utc(date).toDate()
  const timec = moment(stillUtc).local()
  const check = moment(timec)
  const month = check.format('MMM')
  const daynum = check.format('D')
  const year = check.format('YYYY')

  return `${month} ${daynum}, ${year}`
}

export function getTimeZone() {
  return DateTime.local().zoneName
}

export const getfromToDateRange = (range: number) => {
  return {
    from: moment().subtract(range, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    range,
  }
}
