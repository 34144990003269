import { useEffect } from 'react'
import { initFlowbite } from 'flowbite'
interface IProps {
  children: React.ReactNode
  id: string
  buttonOpen: JSX.Element
  titleModal?: string
  className?: string
  buttonCancel?: string
  buttonSave?: {
    title: string
    action: () => void
    disabled: boolean
  }
  actionClose?: () => void
}

export const ModalFB: React.FC<IProps> = ({
  children,
  buttonOpen,
  id,
  titleModal,
  buttonSave,
  buttonCancel,
  className,
  actionClose
}) => {
  useEffect(() => {
    initFlowbite()
  }, [])

  return (
    <div>
      {buttonOpen}
      <div
        id={id}
        data-modal-backdrop='static'
        aria-hidden='true'
        tabIndex={-1}
        className={`fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full m-auto ${className}`}
      >
        <div className='relative w-full max-h-full'>
          <div className='px-3 relative bg-white rounded-lg shadow dark:bg-gray-700 py-3'>
            <div className='flex'>
              {titleModal && (
                <span className='text-lg font-semibold capitalize leading-[27px] text-gray-900'>
                  {titleModal}
                </span>
              )}
              <button
                type='button'
                className='flex text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto items-center dark:hover:bg-gray-600 dark:hover:text-white'
                data-modal-hide={id}
                onClick={actionClose ? actionClose : () => { return }}
              >
                <svg
                  aria-hidden='true'
                  className='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg>
                <span className='sr-only'>Close modal</span>
              </button>
            </div>
            <div>{children}</div>
            <div
              className={`flex justify-end p-6 space-x-2 rounded-b dark:border-gray-600 ${!buttonCancel && !buttonSave && 'p-0'
                }`}
            >
              {buttonCancel && (
                <button
                  onClick={actionClose ? actionClose : () => { return }}
                  data-modal-hide={id}
                  type='button'
                  className='bg-white border border-gray-200 text-gray-700 px-[20px] py-[10px] rounded-lg mr-4 w-[fit-content]  font-normal text-sm'
                >
                  {buttonCancel}
                </button>
              )}
              {buttonSave && (
                <button
                  data-modal-hide={id}
                  onClick={buttonSave.action}
                  disabled={buttonSave.disabled}
                  type='button'
                  className={`py-[12px] px-[24px] rounded-lg d-block w-[fit-content]  ${buttonSave.disabled
                    ? 'bg-gray-200 text-gray-400'
                    : 'bg-routera-primary text-white font-normal text-sm'
                    }`}
                >
                  {buttonSave.title}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
