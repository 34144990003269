import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form'

import ElementPicker from '../../molecules/FormBuilder/ElementPicker'

interface IProps {
  selectedValue: any
  handleChange: (val: any) => void
  options: Array<any>
  setValue: UseFormSetValue<FieldValues>
  control: Control<FieldValues, any>
  customClass: string
  label: string
  disabled?: boolean
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Radio: React.FC<IProps> = ({
  selectedValue,
  handleChange,
  options,
  setValue,
  control,
  customClass = '',
  label = '',
  disabled = false
}) => {
  return (
    <>
      {label && (
        <div className='mt-3 '>
          <span className='text-sm font-medium flex-grow-1'>{label}</span>
        </div>
      )}
      <RadioGroup
        value={selectedValue}
        onChange={handleChange}
        className={customClass}
      >
        <RadioGroup.Label className='sr-only'>{/* label */}</RadioGroup.Label>
        <div className='-space-y-px rounded-md bg-white'>
          {options.map((val, valueIdx) => (
            <RadioGroup.Option
              key={val.label}
              value={val.value}
              disabled={val.disabled}
              className={({ checked }) =>
                classNames(
                  valueIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  valueIdx === options.length - 1
                    ? 'rounded-bl-md rounded-br-md'
                    : '',
                  checked
                    ? 'border-indigo-200 z-10'
                    : 'bg-gray-100 border-gray-200',
                  'relative border py-3 px-[1.2rem] flex focus:outline-none [&>*:first-child]:w-[100%]',
                  val.disabled ? 'bg-gray-200 cursor-not-allowed' : 'cursor-pointer'
                )
              }
            >
              {({ active, checked }) => (
                <div>
                  <div className='flex'>
                    <span
                      className={classNames(
                        checked
                          ? 'bg-[#0062FF] border-transparent'
                          : 'bg-white border-gray-300',
                        active ? 'ring-[#0062FF]' : '',
                        'mt-0.5 h-4 w-4 shrink-0 rounded-full border flex items-center justify-center',
                        val.disabled ? 'bg-gray-200 cursor-not-allowed' : 'cursor-pointer'
                      )}
                      aria-hidden='true'
                    >
                      <span className='rounded-full bg-white w-1.5 h-1.5' />
                    </span>
                    <span className='ml-3 flex flex-col'>
                      <RadioGroup.Label
                        as='span'
                        className={classNames(
                          checked ? 'text-900' : 'text-gray-900',
                          'block text-sm font-medium',
                        )}
                      >
                        {val.label}
                      </RadioGroup.Label>
                      {val?.description && (
                        <RadioGroup.Description
                          as='span'
                          className={classNames(
                            checked ? 'text-gray-500' : 'text-gray-500',
                            'block text-sm',
                          )}
                        >
                          {val.description}
                        </RadioGroup.Description>
                      )}
                    </span>
                  </div>
                  <div>
                    {selectedValue === val?.id &&
                      val?.fields &&
                      val?.fields.length &&
                      val.fields.map((v: any) => (
                        <ElementPicker
                          key={v.id}
                          element={v}
                          setValue={setValue}
                          control={control}
                        />
                      ))}
                  </div>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  )
}

export default Radio
