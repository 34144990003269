import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { selectUser } from 'src/container/auth/auth.selector'
import logoRoutera from 'src/assets/images/logo-routera.png'
import { useAppSelector } from 'src/store'
import Avatar from '../atoms/avatar/Avatar.Atom'
import IconRoutera from 'src/components/atoms/icons/icons'
import { useAuth0 } from '@auth0/auth0-react'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {
  const location = useLocation()
  const navigation = useNavigate()
  const currentUser = useAppSelector(selectUser)
  const { logout } = useAuth0()

  const handlelogoutClick = () => {
    window.localStorage.removeItem('token')
    window.localStorage.clear()
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  const navigationItems = [
    {
      name: 'Dashboard',
      href: '/dashboard',
      current: location.pathname.startsWith('/dashboard'),
      hidden: false,
    },
    {
      name: 'Router',
      href: '/routers',
      current: location.pathname.startsWith('/routers'),
      hidden: false,
    },
    {
      name: 'Service Level Agreements',
      href: '/slas',
      current: location.pathname.startsWith('/slas'),
      hidden: false,
    },
    {
      name: 'Conversions',
      href: '/conversions',
      current: location.pathname.startsWith('/conversions'),
      hidden: false,
    },
  ]

  const goDefaultPage = () => {
    navigation('/dashboard')
  }

  return (
    <Disclosure as='nav' className='bg-routera-700'>
      {({ open }) => (
        <>
          <div className='mx-auto px-4 md:px-6 lg:px-8'>
            <div className='relative flex h-16 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center md:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon
                      className='block h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  ) : (
                    <Bars3Icon
                      className='block h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex flex-1 items-center justify-center md:items-stretch md:justify-start'>
                <div className='flex flex-shrink-0 items-center'>
                  <img
                    className='hidden sm:block shadow-sm cursor-pointer'
                    src={logoRoutera}
                    alt=''
                    width={32}
                    height={32}
                    onClick={goDefaultPage}
                  />
                  <img
                    className='sm:hidden block shadow-sm cursor-pointer'
                    src={logoRoutera}
                    alt=''
                    width={32}
                    height={32}
                    onClick={goDefaultPage}
                  />
                </div>
                <div className='hidden md:ml-6 md:block'>
                  <div className='flex space-x-4'>
                    {navigationItems.map(
                      (item) =>
                        !item.hidden && (
                          <NavLink
                            key={item.name}
                            className={classNames(
                              item.current
                                ? 'text-white px-3 py-2 bg-blue-800 rounded'
                                : 'text-white hover:text-gray-300',
                              'px-4 py-2 rounded-md text-sm font-medium hover:bg-[#263BE0]',
                            )}
                            aria-current={item.current ? 'page' : undefined}
                            to={item.href}
                          >
                            {item.name}
                          </NavLink>
                        ),
                    )}
                  </div>
                </div>
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0'>
                {/* Profile dropdown */}
                <Menu as='div' className='relative'>
                  <div className='flex items-center'>
                    <span
                      onClick={() => navigation('/settings/profile')}
                      className={`${
                        window.location.pathname.includes('settings') &&
                        'bg-routera-900'
                      } px-1 py-2 mr-4 rounded-lg cursor-pointer hover:bg-[#263BE0]`}
                    >
                      <IconRoutera
                        iconName='CogIcon'
                        className='h-[45px] w-[45px] text-white'
                      />
                    </span>
                    <Menu.Button
                      className={`flex w-10 h-10 rounded-full text-sm ${classNames(
                        location.pathname.startsWith('/settings/profile')
                          ? 'outline-none ring-2 ring-white ring-offset-2 ring-offset-gray-800'
                          : '',
                      )}`}
                    >
                      <span className='sr-only'>Open user menu</span>
                      <Avatar
                        className='w-10 h-10 text-sm'
                        firstName={currentUser.firstName}
                        lastName={currentUser.lastName}
                        imgURL={currentUser.avatar}
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[60] cursor-pointer'>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={handlelogoutClick}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700',
                            )}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='md:hidden'>
            <div className='space-y-1 px-2 pt-2 pb-3'>
              {navigationItems.map((item) => (
                <NavLink
                  key={item.name}
                  className={classNames(
                    item.current
                      ? 'bg-blue-800 text-white'
                      : 'text-gray-300 hover:bg-blue-800 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium',
                  )}
                  aria-current={item.current ? 'page' : undefined}
                  to={item.href}
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Header
