import React, { useState } from 'react'
import ComboBox from 'src/components/molecules/inputs/comboBox/ComboBox.Molecule'
import toastBuilder from 'src/utils/toastBuilder'
import { isEndTimeEarlier, isTimeSlotsOverlapping } from './helper'
import IconRoutera from 'src/components/atoms/icons/icons'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'

export type TimeSlot = {
  startTime: string
  endTime: string
}

export type TimeSlots = Record<string, Array<TimeSlot>>

interface Props {
  selectedSlots: TimeSlots
  onChange: (timeSlots: TimeSlots) => void
  permissions?: PermissionsTimeSlopt
}

export type PermissionsTimeSlopt = {
  delete?: boolean
  create?: boolean
  update?: boolean
}

const dayLabels: Record<string, string> = {
  '0': 'Sunday',
  '1': 'Monday',
  '2': 'Tuesday',
  '3': 'Wednesday',
  '4': 'Thursday',
  '5': 'Friday',
  '6': 'Saturday',
}

const RemoveSlot = (valueRemoveSlot: { onPress: () => void, delete: boolean }) => {

  return (
    <div
      onClick={valueRemoveSlot.onPress}
      className={`text-2xl w-8 h-8 rounded-full flex items-center justify-center text-slate-500 peer-checked:bg-slate-100 peer-checked:text-slate-900 ${valueRemoveSlot.delete ? 'cursor-not-allowed' : 'hover:cursor-pointer hover:bg-slate-200'}`}
    >
      <IconRoutera
        iconName='TrashIcon'
        className='h-[21px] w-[19px] text-gray-500'
      />
    </div>
  )
}

interface AddSlotProps {
  onSaveSlot: (day: string, timeSlot: TimeSlot) => void
  onCancel: () => void
}
const AddSlot = ({ onSaveSlot, onCancel }: AddSlotProps) => {

  const ability = useAbility(AbilityContext)

  const [slot, setSlot] = useState<TimeSlot>({ startTime: '', endTime: '' })
  const [selectedDay, setSelectedDay] = useState<string>('Sunday')

  const onStartTimeChange = (event: any) => {
    setSlot({
      ...slot,
      startTime: event.target.value,
    })
  }

  const onEndTimeChange = (event: any) => {
    setSlot({
      ...slot,
      endTime: event.target.value,
    })
  }

  const onSave = () => {
    onSaveSlot(selectedDay, slot)
  }

  const daySelectorOptions = {
    label: '',
    modelName: 'dayName',
    initialValue: 'Sunday',
    options: Object.values(dayLabels),
    handleOnSubmit: (selectedObj: any) => {
      setSelectedDay(selectedObj['dayName'])
    },
  }
  return (
    <div className='flex justify-between items-center text-sm'>
      <div className='flex basis-32'>
        <ComboBox {...daySelectorOptions} />
      </div>
      <span className='block mx-2'>from</span>
      <input
        type='time'
        value={slot.startTime}
        className='text-sm flex basis-39 rounded'
        onChange={onStartTimeChange}
      />
      <span className='block mx-1'>to</span>
      <input
        type='time'
        className='text-sm flex basis-39 rounded'
        min={slot.startTime}
        value={slot.endTime}
        onChange={onEndTimeChange}
      />
      <span onClick={onSave}>
        <i className='fa-solid fa-check text-lg w-8 h-8 rounded-full hover:cursor-pointer hover:bg-slate-200 flex items-center justify-center'></i>
      </span>
      <span onClick={onCancel}>
        <i className='fa-solid fa-xmark text-lg w-8 h-8 rounded-full hover:cursor-pointer hover:bg-slate-200 flex items-center justify-center'></i>
      </span>
    </div>
  )
}

interface TimeSlotRowProps {
  day: string
  timeSlot: TimeSlot
  onSlotRemove: () => void
  onChange: (timeSlot: TimeSlot) => void
  permissions?: PermissionsTimeSlopt
}

const TimeSlotRow = ({
  day,
  timeSlot,
  onSlotRemove,
  onChange,
  permissions
}: TimeSlotRowProps) => {
  const [slot, setSlot] = useState<TimeSlot>({
    startTime: timeSlot.startTime,
    endTime: timeSlot.endTime,
  })
  const onStartTimeChange = (event: any) => {
    setSlot({
      ...slot,
      startTime: event.target.value,
    })
  }

  const onEndTimeChange = (event: any) => {
    setSlot({
      ...slot,
      endTime: event.target.value,
    })
  }

  return (
    <div className='flex items-center py-2 text-sm flex-wrap max-w-[570px] mobile:justify-around tablet:justify-start'>
      <span className='mr-5 text-left first-letter:basis-28 w-[10%] tablet:w-[69px] text-[18px] text-gray-700 font-medium leading-[27px]'>
        {day}
      </span>
      <div className='flex items-center tablet:w-[100%] w-[85%] justify-end tablet:justify-start smMobile:flex-col'>
        <span className='block mx-10 font-normal text-[14px] text-gray-700'>from</span>
        <input
          className={`text-gray-500 text-sm rounded tablet:p-1 tablet:px-1 tablet:text-xs bg-white border border-gray-300 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${permissions?.update ? 'cursor-pointer' : ''}'cursor-not-allowed'`}
          type='time'
          value={slot.startTime}
          onChange={onStartTimeChange}
          onBlur={() => onChange(slot)}
          disabled={!permissions?.update}
        />
        <span className='block mx-2 font-normal text-[14px] text-gray-700'>to</span>
        <input
          type='time'
          className={`text-gray-500 text-sm rounded tablet:p-1 tablet:px-1 tablet:text-xs bg-white border border-gray-300 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${!permissions?.update ? 'cursor-pointer' : ''}'cursor-not-allowed'`}
          value={slot.endTime}
          onChange={onEndTimeChange}
          onBlur={() => onChange(slot)}
          disabled={!permissions?.update}
        />
        <div className='pl-2'>
          <RemoveSlot onPress={!permissions?.update ? () => { return } : onSlotRemove} delete={!permissions?.update} />
        </div>
      </div>
    </div>
  )
}

const TimeSlotPicker = ({ selectedSlots, onChange, permissions }: Props) => {
  const [showAddNewSlot, setShowAddNewSlot] = useState<boolean>(false)

  const onAddHours = () => setShowAddNewSlot(true)

  const onAddSlot = (day: string, slot: TimeSlot) => {
    if (day in selectedSlots) {
      if (isEndTimeEarlier(slot)) {
        // Show Toast
        toastBuilder(
          'error',
          'Oops. End time cannot be earlier than start time. Please check',
        )
        return
      }
      if (isTimeSlotsOverlapping(selectedSlots[day], slot)) {
        // Show Toast
        toastBuilder('error', 'Time slot seem to overlap. Please check')
        return
      } else {
        onChange({
          ...selectedSlots,
          [day]: [...selectedSlots[day], slot],
        })
      }
    } else {
      onChange({
        ...selectedSlots,
        [day]: [slot],
      })
    }
    setShowAddNewSlot(false)
  }

  const onCancelNewSlot = () => setShowAddNewSlot(false)

  const onRemoveSlot = (day: string, index: number) => {
    onChange({
      ...selectedSlots,
      [day]: selectedSlots[day].filter(
        (slot, slotIndex) => index !== slotIndex,
      ),
    })
  }

  const onTimeSlotChange = (day: string, slot: TimeSlot, index: number) => {
    if (day in selectedSlots) {
      if (isEndTimeEarlier(slot)) {
        // Show Toast
        toastBuilder(
          'error',
          'Oops. End time cannot be earlier than start time. Please check',
        )
        return
      }
      if (
        isTimeSlotsOverlapping(
          selectedSlots[day].filter(
            (timeSlot, runningIndex) => runningIndex !== index,
          ),
          slot,
        )
      ) {
        // Show Toast
        toastBuilder('error', 'Time slot seem to overlap. Please check')
        return
      } else {
        const clonedSlots = [...selectedSlots[day]]
        clonedSlots.splice(index, 1, slot)
        onChange({
          ...selectedSlots,
          [day]: clonedSlots,
        })
      }
    } else {
      onChange({
        ...selectedSlots,
        [day]: [slot],
      })
    }
  }
  return (
    <div className='flex flex-col max-w-[500px]'>
      {Object.keys(selectedSlots).map((day) => {
        return selectedSlots[day].map((timeSlot, index) => (
          <TimeSlotRow
            key={`${day}-${index}-${timeSlot.startTime}`}
            day={day}
            timeSlot={{
              startTime: timeSlot.startTime,
              endTime: timeSlot.endTime,
            }}
            onSlotRemove={() => onRemoveSlot(day, index)}
            onChange={(updatedTimeSlot) =>
              onTimeSlotChange(day, updatedTimeSlot, index)
            }
            permissions={permissions}
          />
        ))
      })}
      {showAddNewSlot && (
        <AddSlot onSaveSlot={onAddSlot} onCancel={onCancelNewSlot} />
      )}
      <button
        onClick={onAddHours}
        type='button'
        className={`px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg bg-routera-primary self-start ${!permissions?.update && 'cursor-not-allowed'}`}
        disabled={!permissions?.update}
      >
        <i className='fa-solid fa-plus text-white inline-block mr-2'></i>
        Add hours
      </button>
    </div>
  )
}

export default TimeSlotPicker
