import { createSlice } from '@reduxjs/toolkit'

import toastBuilder from 'src/utils/toastBuilder'
import {
  disconnectHubSpot,
  getIntegrations,
  getLeadStatusTypes,
} from './integrations.actions'
import { IntegrationsState } from './integrations.interface'
import { ROUTER_INTEGRATION_TYPES } from '../../types/routerDetail'

const initialState: IntegrationsState = {
  integrations: {},
}

const integrations = createSlice({
  name: 'integrations',
  initialState,
  reducers: {},
  extraReducers: {
    [getIntegrations.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        for (const integration of data.integrations) {
          state.integrations = {
            ...state.integrations,
            [integration.integration_type]: integration,
          }
        }
      }
    },

    [disconnectHubSpot.fulfilled.toString()]: (state, action) => {
      /* eslint-enable no-unused-vars */
      const { success } = action.payload

      if (success) {
        toastBuilder('success', 'Hubspot integration successfully deleted')
        delete state.integrations.hubspot
      }
    },
    [getLeadStatusTypes.fulfilled.toString()]: (state, action) => {
      /* eslint-enable no-unused-vars */
      const { success, data } = action.payload
      if (success) {
        state.integrations = {
          ...state.integrations,
          // @ts-ignore
          [ROUTER_INTEGRATION_TYPES.HUBSPOT]: {
            ...state.integrations[ROUTER_INTEGRATION_TYPES.HUBSPOT],
            lead_status_types: data,
          },
        }
      }
    },
  },
})

export default integrations.reducer
