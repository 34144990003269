import { HttpService } from './base.service'

class teamsService extends HttpService {
	private readonly prefix = 'teams'

	getTeamsList = () =>
		this.get(`${this.prefix}`)

	createTeam = async (data: { name: string}) =>
	 	this.post(`${this.prefix}/`, data)

	getTeamById = (id: string) => this.get(`${this.prefix}/${id}`)

	updateTeam = (id: string, data: { name: string }) =>
		this.patch(`${this.prefix}/${id}`, data)

	addUsers = (id: string, data: { users: string[], isManagers: boolean }) =>
		this.patch(`${this.prefix}/${id}/add-users`, data)

	deleteTeams = (team_ids: string[]) =>
		this.delete(`${this.prefix}/`, {}, { team_ids })

}

export const TeamsService = new teamsService()