import ComboBox from 'src/components/molecules/inputs/comboBox/ComboBox.Molecule'
import { HUBSPOT_OBJECT_TYPES, Source } from 'src/types/sources'
import { HandleUpdate } from '../HubspotQueryBuilder.Template'
import { DispatchQueryStepChange, QUERY_STEPS } from '../StepBuilder.Template'
import { MODEL_NAME as PROPERTY_MODEL_NAME } from './SetPropertyStep.Template'

interface Props {
  objectType?: HUBSPOT_OBJECT_TYPES
  handleUpdate: HandleUpdate
  dispatchQueryStepChange: DispatchQueryStepChange
}

export const MODEL_NAME = 'subscription_configs.object_type'

const SetObjectTypeStep: React.FC<Props> = ({
  objectType,
  handleUpdate,
  dispatchQueryStepChange,
}) => {
  const options = {
    label: 'Object Type',
    description: 'Please select an object from your Hubspot CRM Account',
    modelName: MODEL_NAME,
    initialValue: objectType || '',
    options: Object.values(HUBSPOT_OBJECT_TYPES),
    handleOnSubmit: (model: Partial<Source> & Record<string, any>) => {
      handleUpdate({
        $set: { [MODEL_NAME]: model[MODEL_NAME] },
        $unset: {
          [PROPERTY_MODEL_NAME]: '',
          conditions: '',
        },
      } as Partial<Source>)
      dispatchQueryStepChange(QUERY_STEPS.SET_PROPERTY)
    },
  }
  return (
    <div className='py-4'>
      <ComboBox<Source> {...options} />
    </div>
  )
}

export default SetObjectTypeStep
