import { createAsyncThunk } from '@reduxjs/toolkit'

import { integrationsService } from 'src/services/integrations.service'
import { ROUTER_INTEGRATION_TYPES } from 'src/types/routerDetail'

enum ACTION_TYPE {
  GET_INTEGRATIONS = 'integrations/getIntegrations',
  DISCONNECT_HUBSPOT_INTEGRATION = 'integrations/disconnectHubspotIntegration',
  GET_LEAD_STATUS_TYPES = 'integrations/disconnectHubspotIntegration',
  integrationCalendarAction = 'integrations/integrationCalendarAction',
  deleteCalendarAction = 'integrations/deleteCalendarAction',
  getFieldsByObjectType = 'getFieldByObjectType',
  getOwnerFieldsByObjectType = 'getOwnerFieldByObjectType',
  getPipelines = 'getPipelines'
}

export const getIntegrations = createAsyncThunk(
  ACTION_TYPE.GET_INTEGRATIONS,
  async (payload, thunkApi) => {
    const result = await integrationsService.getIntegrations()
    if (result) {
      if (
        result.data &&
        result.data?.integrations[0]?.integration_type ===
        ROUTER_INTEGRATION_TYPES.HUBSPOT
      ) {
        thunkApi.dispatch(getLeadStatusTypes(result.data.integrations[0]._id))
      }
      return result
    } else thunkApi.rejectWithValue('')
  },
)

export const disconnectHubSpot = createAsyncThunk(
  ACTION_TYPE.DISCONNECT_HUBSPOT_INTEGRATION,
  async (id: string, thunkApi) => {
    const result = await integrationsService.disconnectHubSpot(id)
    if (result) return result
    else thunkApi.rejectWithValue('')
  },
)

export const integrationCalendarAction = createAsyncThunk(
  ACTION_TYPE.DISCONNECT_HUBSPOT_INTEGRATION,
  async (data: any) => {
    return await integrationsService.integrationCalendar(data)
  },
)

export const deleteCalendarAction = createAsyncThunk(
  ACTION_TYPE.DISCONNECT_HUBSPOT_INTEGRATION,
  async () => {
    return await integrationsService.deleteCalendar()
  },
)

export const getLeadStatusTypes = createAsyncThunk(
  ACTION_TYPE.GET_LEAD_STATUS_TYPES,
  async (id: string, thunkApi) => {
    const result = await integrationsService.getLeadStatusTypes(id)
    if (result) return result
    else thunkApi.rejectWithValue('')
  },
)

export const getFieldsByObjectType = createAsyncThunk(
  ACTION_TYPE.getFieldsByObjectType,
  async (conversionId: string) => {
    return await integrationsService.getFieldsByObjectType(conversionId)
  },
)

export const getOwnerFieldsByObjectType = createAsyncThunk(
  ACTION_TYPE.getOwnerFieldsByObjectType,
  async (conversionId: string) => {
    return await integrationsService.getOwnerFieldsByObjectType(conversionId)
  },
)

export const getPipelines = createAsyncThunk(
  ACTION_TYPE.getPipelines,
  async (integrationId: string) => {
    return await integrationsService.getPipelines(integrationId)
  },
)
