import React from 'react'
import CreateTriggerForm from './CreateTriggerForm'
import Slider from '../../molecules/Slider/Slider.molecule'

interface IProps {
  showSlider: boolean
  setShowSlider: (value: boolean) => void
  hasData: boolean
  disabled?: boolean
}

const CreateTrigger: React.FC<IProps> = ({
  showSlider = false,
  setShowSlider,
  hasData = false,
  disabled = false
}) => {
  return (
    <div className='h-full'>
      {!hasData && (
        <div className='py-6 sm:px-0'>
          <div className='h-[50vh] rounded-lg border-4 border-dashed border-gray-200 text-center justify-center items-center flex'>
            <div className='flex flex-col items-center'>
              <div className=''>
                <span className='text-lg font-medium flex-grow-1'>
                  No Triggers Created
                </span>
              </div>
              <button
                onClick={() => setShowSlider(true)}
                disabled={disabled}
                className={`px-[20px] py-[15px] text-white text-[14px] rounded-lg bg-routera-primary self-start mt-3 w-full ${disabled && 'hover:cursor-not-allowed'}`}
              >
                Create Trigger
              </button>
            </div>
          </div>
        </div>
      )}
      <Slider
        showSlider={showSlider}
        setShowSlider={setShowSlider}
        className='tablet:w-[100vw]'
      >
        <CreateTriggerForm setShowSlider={setShowSlider} />
      </Slider>
    </div>
  )
}

export default CreateTrigger
