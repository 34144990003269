import Card from '../card/Card'
import RefreshIcon from '../../../assets/images/refresh.svg'
import { ROUTER_SLA_ACTION_TYPES } from 'src/types/routerDetail'
import { ability } from 'src/utils/permissionsCASL'
import IconRoutera from 'src/components/atoms/icons/icons'
import { useState } from 'react'

const Actions = ({
  setThereAreChanges,
  actions,
  setActions,
  routerId,
  setRouterId,
  routerList,
}: any) => {
  const [action, setAction] = useState('SEND_EMAIL')

  const changeAction = (value: string) => {
    setAction(value)
  }

  const addAction = () => {
    const currentActions = [...actions]

    const isExist = actions.find((value: string) => action === value)

    if (!isExist) {
      currentActions.push(action)
      setActions(currentActions)
      setThereAreChanges(true)
    }
  }

  const onDeleteAction = (index: number) => {
    const currentActions = [...actions]
    currentActions.splice(index, 1)
    setActions(currentActions)
    setThereAreChanges(true)
  }

  const onChangeRouter = (routerId: any) => {
    setRouterId(routerId)
    setThereAreChanges(true)
  }

  const allRouters: JSX.Element = (
    <div>
      <span className='text-gray-700 text-sm leading-[21px] mt-2 font-medium'>
        Router
      </span>
      <select
        id='router'
        className={`bg-white borderw-[145px] mt-2 md:w-[288px] border-gray-300 text-[14px] font-normal text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 ${
          !ability.can('update', 'router.sla') && 'cursor-not-allowed'
        }`}
        name='defaultuser'
        onChange={(e) => onChangeRouter(e.target.value)}
        value={routerId}
      >
        {routerList.map((router: any, index: number) => {
          return (
            <option value={router._id} key={index}>
              {router.name}
            </option>
          )
        })}
      </select>
    </div>
  )

  return (
    <div className='px-4 p-4'>
      <div className='flex flex-wrap justify-between items-center'>
        <div>
          <span className='text-gray-700 text-xl leading-[30px]'>Actions</span>
          <p className='text-gray-700 text-sm leading-[21px] mt-2'>
            What needs to happen if the SLA is not met?
          </p>
        </div>
        <div>
          <div className='flex tablet:flex-col justify-start items-start'>
            <div className='md:ml-4 flex flex-wrap justify-between items-center'>
              <div>
                <select
                  id='objectTypes'
                  className='w-full md:w-[258px] rounded-lg border border-gray-300 text-gray-500 text-sm bg-white py-2 pl-3 pr-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500'
                  onChange={(value) => changeAction(value.target.value)}
                >
                  <option value={ROUTER_SLA_ACTION_TYPES.EMAIL_REMINDER}>
                    Send email reminder to assignee
                  </option>
                  <option value={ROUTER_SLA_ACTION_TYPES.REASSIGN}>
                    Reassign record
                  </option>
                </select>
              </div>
              <div className='ml-4'>
                <button
                  className={`flex flex-row w-[160px] h-[41px] justify-center items-center px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start ${
                    !ability.can('update', 'router.sla') && 'cursor-not-allowed'
                  }`}
                  onClick={() => addAction()}
                >
                  <IconRoutera
                    iconName='PlusSmallIcon'
                    className='w-[19px] mr-2 mt-[2px]'
                  />{' '}
                  Add Action
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='my-4'>
        {actions && actions.length > 0 ? (
          actions.map((action: any, index: number) => {
            let title
            let icon = null
            let iconRoutera = false
            let iconName = null
            let body
            if (action === ROUTER_SLA_ACTION_TYPES.EMAIL_REMINDER) {
              title = 'Send Email'
              iconRoutera = true
              iconName = 'EnvelopeIcon'
              body = 'An email is sent if the SLA is not met'
            } else {
              title = 'Re-assign through Router'
              icon = RefreshIcon
              body = allRouters
            }
            return (
              <Card
                key={index}
                title={title}
                trashIcon={true}
                indexGroup={index}
                onDelete={onDeleteAction}
                icon={icon}
                iconRoutera={iconRoutera}
                iconName={iconName}
              >
                <div className='pb-[15px] text-gray-700 text-sm leading-[21px] mt-2'>
                  {body}
                </div>
              </Card>
            )
          })
        ) : (
          <Card icon={RefreshIcon} title='No Action'>
            <p className='text-gray-700 text-sm leading-[21px] mt-2'>
              Please, Add an action
            </p>
          </Card>
        )}
      </div>
    </div>
  )
}

export default Actions
