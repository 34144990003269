/* eslint-enable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toastBuilder from 'src/utils/toastBuilder'
import { IAuthstate } from '../../types/auth'
import {
  setcurrentuser,
  setcurrentuserandtoken,
  setPermissionsUser,
} from '../../utils/localstorage'
import { send_hubsprot_token } from '../Hubspot/hubsport.actions'
import {
  changeoffice_status_for_team_member,
  setTeamMemberWorkingHours,
} from '../my_account/account.actions'
import {
  changeoffice_status,
  loginwithGoogle,
  updateuserDataAction,
  UploadProfileAction,
  userloginAction,
  logoutAction,
  userSignupAction,
  getUserDetailsAction,
  getUserPermissionsAction,
  loginWithAuth0,
} from './auth.actions'

const initialState: IAuthstate = {
  user: {
    role: '',
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    avatar: '',
    phone: '',
    office_status: true,
    hub_list_id: '',
    timeZone: '',
    max_load_capacity: 0,
    max_seats: 0,
    curent_plan: '',
    account_id: '',
    plan_assignments: '',
    plan_routers: '',
    total_assignments: '',
    max_routers: '',
  },
  token: '',
  fresUser: {
    email: '',
    password: '',
  },
}

export const logout = createAsyncThunk('logoutaction', (state, actionType) => {
  // make any api call for server if need to logout from user
  // rightnow we are not doing this
})

const authslice = createSlice({
  name: 'authslice',
  initialState,
  reducers: {
    // logout: (state, action) => {
    //   state.user = {
    //     role: "",
    //     _id: "",
    //     firstName: "",
    //     lastName: "",
    //     email: "",
    //     avatar: "",
    //     phone: "",
    //   };
    //   state.token = "";
    //   localStorage.clear();
    // },
    setFreshUser: (state, action) => {
      state.fresUser.email = action.payload.email
      state.fresUser.password = action.payload.password
    },
    settokenAndUser: (state, action) => {
      const { token, user } = action.payload
      state.token = token
      state.user = user
    },
  },

  extraReducers: {
    [userSignupAction.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        state.token = data.token
        state.user = data.user
        setcurrentuserandtoken(data.user, data.token)
      }
    },
    [userloginAction.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        state.token = data.token
        state.user = data.user
        setcurrentuserandtoken(data.user, data.token)
      }
    },
    [logoutAction.fulfilled.toString()]: (state, action) => {
      state.token = ''
      state.user = {
        role: '',
        _id: '',
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        avatar: '',
        phone: '',
        office_status: false,
        timeZone: '',
        hub_list_id: '',
        max_load_capacity: 0,
        max_seats: 0,
        curent_plan: '',
        account_id: '',
        plan_assignments: '',
        plan_routers: '',
        total_assignments: '',
        max_routers: '',
      }
      localStorage.clear()
    },
    [updateuserDataAction.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        state.user = data.user
        setcurrentuser(data.user)
      }
    },
    [loginwithGoogle.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        state.token = data.token
        state.user = data.user
        setcurrentuserandtoken(data.user, data.token)
      }
    },

    [UploadProfileAction.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        state.user = data.user
        setcurrentuser(data.user)
      }
    },

    [changeoffice_status.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        state.user = data.updatedUser
        setcurrentuser(data.updatedUser)
      }
    },

    [send_hubsprot_token.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        toastBuilder('success', 'Hubspot integration successfully added')
        state.user = data.user
        setcurrentuser(data.user)
      }
    },

    [changeoffice_status_for_team_member.fulfilled.toString()]: (
      state,
      action,
    ) => {
      const { success, data } = action.payload
      const { updatedUser } = data
      if (success) {
        if (updatedUser._id === state.user._id) {
          state.user = updatedUser
          setcurrentuser(updatedUser)
        }
      }
    },

    [setTeamMemberWorkingHours.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success && data) {
        const { updatedUser } = data
        if (updatedUser._id === state.user._id) {
          state.user = updatedUser
          setcurrentuser(updatedUser)
        }
      }
    },

    // [listselected.fulfilled.toString()]: (state, action) => {
    //   const { success, user } = action.payload;

    //   if (success) {
    //     state.user = user;
    //     setcurrentuser(user);
    //   }
    // },
    [getUserDetailsAction.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload

      if (success) {
        state.user = data.user
        setcurrentuser(data.user)
      }
    },
    [getUserPermissionsAction.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success && data) {
        setPermissionsUser(data)
      }
    },
    [loginWithAuth0.fulfilled.toString()]: (state, action) => {
      const { success, data } = action.payload
      if (success) {
        state.token = data.token
        state.user = data.user
        setcurrentuserandtoken(data.user, data.token)
      }
    },
  },
})

export const { settokenAndUser, setFreshUser } = authslice.actions

export default authslice.reducer
