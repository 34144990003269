import { useEffect, useState } from 'react'
import IconRoutera from 'src/components/atoms/icons/icons'
import TableActions, {
  TableColumn,
} from 'src/components/atoms/tableActions/tableActions'
import Slider from 'src/components/molecules/Slider/Slider.molecule'
import ConversionForm from './conversionForm'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import { useParams } from 'react-router-dom'
import { useActions } from 'src/actions'
import { Conversions } from 'src/types/conversions'
import toastBuilder from 'src/utils/toastBuilder'
import moment from 'moment'

const conversionsListDefault: Conversions = {
  _id: '',
  name: '',
  updatedAt: new Date(),
  is_active: false,
  conversionRule: {
    objectType: HUBSPOT_OBJECT_TYPES.CONTACTS,
    value: '',
    fieldName: '',
    fieldLabel: '',
    operator: '',
  },
}
interface IProps {
  currentObjectType: HUBSPOT_OBJECT_TYPES
  thereAreChanges: boolean
}

const ConversionBuilder: React.FC<IProps> = ({ currentObjectType, thereAreChanges }) => {
  const [showTable, setShowTable] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [showSlider, setShowSlider] = useState(false)
  const [thereData, setThereData] = useState(false)
  const [conversionBData, setConversionBData] = useState<Conversions>(
    conversionsListDefault,
  )
  const [data, setData] = useState([])
  const { conversionId } = useParams()
  const { getConversionById, updateConversion, getFieldsByObjectType } = useActions()

  const dataField = async () => {
    const resp: any = await getFieldsByObjectType(currentObjectType)
    if (!resp.payload.success) {
      setShowSlider(false)
      toastBuilder('error', 'Error to show the data')
    } else {
      setData(resp.payload.data)
    }
  }

  const selectConversionGoal = () => {
    setShowSlider(true)
  }

  useEffect(() => {
    dataField()
    if (conversionId) {
      getDataConversion(conversionId)
    }
  }, [])

  useEffect(() => {
    if (thereAreChanges && currentObjectType !== conversionBData.conversionRule.objectType && conversionId) {
      setShowTable(false)
    }
  }, [thereAreChanges])

  useEffect(() => {
    dataField()
  }, [currentObjectType])

  useEffect(() => {
    if (!showSlider && conversionId) {
      getDataConversion(conversionId)
    }
  }, [showSlider])

  const getDataConversion = async (id: string) => {
    const resp: any = await getConversionById(id)
    if (resp.payload) {
      setThereData(true)
      if (
        'conversionRule' in resp.payload &&
        'fieldName' in resp.payload.conversionRule &&
        resp.payload.conversionRule.fieldName !== ''
      ) {
        setConversionBData(resp.payload)
        setShowTable(true)
      }
    }
  }

  const rows = [
    {
      [conversionBData.conversionRule.fieldLabel]:
        conversionBData.conversionRule.fieldLabel,
      [conversionBData.conversionRule.operator]:
        conversionBData.conversionRule.operator,
      [conversionBData.conversionRule.value]:
        conversionBData.conversionRule.value,
    },
  ]

  const editConversion = () => {
    setShowSlider(true)
    setIsEdit(true)
  }

  const deleteConversion = async () => {
    if (conversionId) {
      const data = {
        is_active: false,
        conversionRule: {
          fieldName: '',
          objectType: currentObjectType,
        },
      }
      const resp: any = await updateConversion({ id: conversionId, data })
      if (resp.payload.success) {
        setShowTable(false)
        setThereData(false)
        toastBuilder('success', 'Conversion builder deleted successfully')
      } else {
        toastBuilder('error', 'Failed to change object type')
      }
      setShowSlider(false)
    }
  }

  const tableColumns: TableColumn[] = [
    {
      label: 'Field',
      field: conversionBData.conversionRule.fieldLabel,
      className: 'w-[140px]',
    },
    {
      label: 'Operator',
      render: () => {
        return (
          <span className='capitalize'>
            {conversionBData.conversionRule.operator}
          </span>
        )
      },
      className: 'w-[140px]',
    },
    {
      label: 'Value',
      render: () => {
        return (
          <p className='capitalize-first text-xs m-0 text-gray-500'>
            {conversionBData.conversionRule.value}
          </p>
        )
      },
      className: 'w-[140px]',
    },
    {
      label: 'Actions',
      render: () => {
        return (
          <div className='flex items-center'>
            <IconRoutera
              iconName='PencilIcon'
              className='w-[15px] h-[15px] mr-2 mt-[2px] text-blue-600 cursor-pointer'
              onClick={editConversion}
            />
            <IconRoutera
              iconName='TrashIcon'
              className='w-[15px] h-[15px] mr-2 mt-[2px] text-blue-600 cursor-pointer'
              onClick={deleteConversion}
            />
          </div>
        )
      },
      className: 'w-[10rem]',
    },
  ]

  return (
    <div className='px-4 p-4'>
      <span className='text-gray-700 text-xl leading-[30px]'>
        Conversion Builder
      </span>
      <p className='text-gray-700 text-sm leading-[21px] mt-3'>
        Create your own conversion by selecting fields
      </p>
      <p className='text-gray-700 text-sm leading-[21px] inline-block mt-1 mb-4'>
        Conversion Goal - Select what criteria meets the conversion goals (i.e.
        lifecycle stage is customer)
      </p>
      {showTable ? (
        <TableActions
          columns={tableColumns}
          rows={rows}
        />
      ) : (
        <button
          className='flex flex-row items-center px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start'
          onClick={selectConversionGoal}
        >
          <IconRoutera
            iconName='PlusSmallIcon'
            className='w-[19px] mr-2 mt-[2px]'
          />{' '}
          Select Conversion Goal
        </button>
      )}
      <Slider
        showSlider={showSlider}
        setShowSlider={setShowSlider}
        className='tablet:w-[100vw]'
      >
        <ConversionForm
          setShowSlider={setShowSlider}
          setShowTable={setShowTable}
          conversionId={conversionId}
          currentObjectType={currentObjectType}
          conversionEditData={isEdit ? conversionBData : null}
          dataField={dataField}
          data={data}
        />
      </Slider>
    </div>
  )
}

export default ConversionBuilder
