import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { subscriptonService } from 'src/services/subscription.service'
import toastBuilder from 'src/utils/toastBuilder'

const onViewBilling = async () => {
  const rsp = await subscriptonService.viewBillingPortal()
  if (rsp && rsp.data) {
    const payload = rsp.data
    window.location.href = payload.url
    return
  } else {
    toastBuilder(
      'error',
      'Unable to view billing portal now. Please try again later',
    )
    return
  }
}

const TrialExpired = () => {
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        onClose={() => {
          console.log('closed')
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      Trial Expired
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>
                        Assignments have been stopped. Please update your
                        subscription on our billing portal.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-6'>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md border border-transparent bg-routera-700 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-routera-700 focus:outline-none focus:ring-2 focus:ring-routera-700 focus:ring-offset-2 sm:text-sm'
                    onClick={onViewBilling}
                  >
                    View Billing Portal
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default TrialExpired
