import {
  User,
  UserAvailability,
} from 'src/components/LeadRouterDetail/TeamMembersList/types'
import { ISLA } from './sla'
import { Ispecific_souce_detail } from './specific_source'
import { Teams } from './teams'

export interface AssignedUser {
  _id: string
  userId: string
  isDefaultForLeads: boolean
}

export enum ROUTER_INTEGRATION_TYPES {
  HUBSPOT = 'hubspot',
  ROUTERA = 'routera',
}

export type IRouterDetail = {
  _id: string
  name: string
  attachedLeads: Array<unknown>
  attachedSources: Array<string>
  webhookAddress: string
  isWebhookActive: boolean
  defaultUserForAssignment: string
  owner: string
  assignedUsers: AssignedUser[]
  createdAt: Date
  updatedAt: Date
  can_overwrite_owner: boolean
  disable_default_assignment: boolean
  assignedUsersInfo: User[]
  attachedSourcesInfo: Array<unknown>
  attachedLeadsInfo: Array<unknown>
  assignedModel: string
  isPublished: boolean
  haveAnyHubspotSource: boolean
  DefaultUserNotSelected: boolean
  trigger_type?: string
  routerStrategyData?: RouterStrategyNextIndex | RouterStrategyCurrent
  trigger_metadata?: IRouterTriggerMetadata
  slas?: ISLA[]
  account_matching?: Record<string, any> | null
  updated_by?: string | ''
  teams: Teams[]
  account_id: string
  optionsOverwriteOwner: optionsOverwriteOwner
  disableWorkingHours: boolean
  defaultAssignmentType: 'user' | 'team'
  defaultTeamAssignment: string
}

export type RouterStrategyNextIndex = {
  nextIndex: number
  userIds: string[]
}

export type RouterStrategyCurrent = {
  currentPointerIndex: number
  userIds: string[]
}
export type UsersQueueTypes = {
  assignedModel: string
  routerStrategyData: RouterStrategyNextIndex | RouterStrategyCurrent
  users: User[]
}

export type IRouterTriggerMetadata = {
  fieldName: string
  objectType?: string
  operator?: string
  value?: string
  customOwnerField?: string
}

export type ITeamMember = {
  _id: string
  id?: string
  phone: string
  managerName: string
  teamName: string
  avatar: string
  isgooglelogin: boolean
  usedLink: boolean
  email: string
  firstName: string
  lastName: string
  role: string
  teamLeadId: string
  createdAt: Date
  updatedAt: Date
  assignedToRouters: Array<unknown>
  office_status?: boolean | undefined
  userAvailability?: UserAvailability | undefined
}

export type ISource = {
  webhookAddress: string
  name: string
  _id: string
  owner: string
  createdAt: Date
  updatedAt: Date
  id: string
}

export type Iall_team_Members = Array<ITeamMember>

export interface IRouterDetailState {
  specific_router_detail: IRouterDetail
  allTeamMembers: Array<ITeamMember>
  assignedTeamMembers: Array<ITeamMember>
  allSources: Array<ISource>
  assignableSources: Array<ISource>
  assigned_user_full_details: Array<ITeamMember>
  web_hook_address: string
  specifisource: Ispecific_souce_detail
  defaultuser: string
  sourceUserList: any
}

export enum ROUTER_SLA_ACTION_TYPES {
  EMAIL_REMINDER = 'SEND_EMAIL',
  SMS_REMINDER = 'SEND_SMS',
  REASSIGN = 'REASSIGN',
  UPDATE_FIELD = 'UPDATE_FIELD',
}

export interface optionsOverwriteOwner {
  do_not_overwrite: boolean
  do_not_overwrite_check_availability: boolean
  overwrite: boolean
}
