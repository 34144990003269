import { useEffect, useState } from 'react'
import { Toggle } from 'src/components/atoms/Toggle/Toggle.atom'
import SelectableTable from 'src/components/organisms/tables/SelectableTable.Organism'
import { PERMISSIONS_SUBJECTS, PermissionsType, RolesPermissions } from 'src/types/roles'
interface IProps {
  dataRole: PermissionsType[] | undefined
  handleStatusChange: (data: any, value: boolean) => void
  setDataDashboard: (val: RolesPermissions[]) => void
  dataDashboard: RolesPermissions[]
}

const DashboardRole: React.FC<IProps> = (props) => {
  const { dataRole, handleStatusChange, dataDashboard, setDataDashboard } = props

  useEffect(() => {
    const permissionsDashboard = [
      {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
    ]
    setDataDashboard(permissionsDashboard)
    if (dataRole && dataRole.length > 0) {
      const dataPermissionsDashboard: PermissionsType[] = dataRole.filter(
        (item: PermissionsType) => item.subject === PERMISSIONS_SUBJECTS.DASHBOARD,
      )
      if (dataPermissionsDashboard.length > 0) {
        dataPermissionsDashboard.forEach((element: PermissionsType) => {
          switch (element.action) {
            case 'read':
              permissionsDashboard[0].read = true
              break
            case 'create':
              permissionsDashboard[0].create = true
              break
            case 'update':
              permissionsDashboard[0].update = true
              break
            case 'delete':
              permissionsDashboard[0].delete = true
              break
          }
        })
      }

      setDataDashboard(permissionsDashboard)
    }
  }, [dataRole])

  const columnsRolesDashboard = [
    {
      label: 'Name',
      render: () => {
        return <div>Dashboard</div>
      },
    },
    {
      label: 'Permission to Read',
      render: ({ read }: any) => {
        return (
          <Toggle
            initialValue={read}
            data={{ actionName: 'read', subject: PERMISSIONS_SUBJECTS.DASHBOARD }}
            handleChange={handleStatusChange}
          />
        )
      },
    },
    {
      label: 'Permission to create',
      render: () => {
        return <div>-</div>
      },
    },
    {
      label: 'Permission to edit',
      render: () => {
        return <div>-</div>
      },
    },
    {
      label: 'Permission to delete',
      render: () => {
        return <div>-</div>
      },
    },
  ]

  return (
    <div className='px-5 py-7'>
      <div className='flex flex-col'>
        <span className='text-lg leading-[27px] text-gray-700 font-medium'>
          Dashboard Permissions
        </span>
        <span className='text-sm leading-[21px] font-normal text-gray-700 mt-2'>
          Review your Role is setup correctly and publish it live
        </span>
      </div>
      <SelectableTable
        columns={columnsRolesDashboard}
        rows={dataDashboard}
        showSelectionType={false}
        showHeaderMeta={false}
        borderTable={true}
        className='p-0'
      />
    </div>
  )
}

export default DashboardRole
