import React from "react";
import { Button } from "react-bootstrap";

const Integrations: React.FC<{ onConnectClick: any }> = (props) => {
  const { onConnectClick } = props;
  return (
    <div className="integrations-page px-6">
      <div className="text-right">
        <Button onClick={onConnectClick} className="btn btn-primary">
          Connect
        </Button>
      </div>
      <div className="centered-heading">
        <h2>No integrations found, start by clicking "Connect"</h2>
      </div>
    </div>
  );
};

export default Integrations;
