import { IFormJson } from 'src/types/form'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'

export const createRuleFormJSON: IFormJson = {
  id: 'createRule',
  label: 'Create Rule',
  fields: [
    {
      id: 'ruleName',
      label: 'Name',
      type: 'string',
      value: '',
      required: false,
      validationRegex: '',
      requiredError: 'Please enter a name',
      showError: false,
    },
    {
      id: 'objectType',
      label: 'Object',
      type: 'select',
      value: '',
      options: [
        { value: HUBSPOT_OBJECT_TYPES.COMPANIES, label: 'Associated Company' },
        { value: HUBSPOT_OBJECT_TYPES.DEALS, label: 'Associated Deal' },
      ],
      width: 'w-[28.3rem]',
      validationRegex: '',
      requiredError: '',
      disabled: false,
    },
    {
      id: 'fieldType',
      label: 'Field',
      type: 'select',
      value: '',
      options: [],
      width: 'w-[28.3rem]',
      validationRegex: '',
      requiredError: '',
    },
    {
      id: 'operatorType',
      label: 'Operator',
      type: 'string',
      value: '',
      options: [],
      width: 'w-[28.3rem]',
      required: false,
      validationRegex: '',
      requiredError: 'error_text',
      showError: false,
      disabled: true,
    },
    {
      id: 'value',
      label: 'Value',
      type: 'string',
      value: '',
      options: [],
      customStyle: 'w-[28.3rem]',
      width: 'w-[28.3rem]',
      required: false,
      validationRegex: '',
      requiredError: '',
      disabled: true,
    },
  ],
}
