import { IFormJson } from 'src/types/form'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import {
  ROUTER_TRIGGER_TYPES,
  ROUTER_TRIGGER_VALUE_TYPES,
} from 'src/types/triggers'

export const createTriggerJSON: IFormJson = {
  id: 'createTrigger',
  label: 'Create Trigger',
  fields: [
    {
      id: 'triggerType',
      label: '',
      type: 'radio',
      value: ROUTER_TRIGGER_TYPES.FIELD_UPDATE,
      required: false,
      validationRegex: '',
      requiredError: '',
      options: [
        {
          id: ROUTER_TRIGGER_TYPES.FIELD_UPDATE,
          label: 'Field Update',
          description: 'When a specific property is updated',
          value: ROUTER_TRIGGER_TYPES.FIELD_UPDATE,
          fields: [
            {
              id: 'objectType',
              label: 'Object',
              type: 'select',
              value: '',
              options: [
                { value: HUBSPOT_OBJECT_TYPES.CONTACTS, label: 'Contact' },
                { value: HUBSPOT_OBJECT_TYPES.COMPANIES, label: 'Company' },
                { value: HUBSPOT_OBJECT_TYPES.DEALS, label: 'Deal' },
                { value: HUBSPOT_OBJECT_TYPES.TICKETS, label: 'Ticket' },
              ],
              validationRegex: '',
              requiredError: '',
            },
            {
              id: 'fieldType',
              label: 'Field',
              type: 'select',
              value: '',
              options: [],
              validationRegex: '',
              requiredError: '',
            },
            {
              id: 'fieldUpdateValue',
              label: 'Value',
              type: 'radio',
              value: '',
              options: [
                {
                  id: ROUTER_TRIGGER_VALUE_TYPES.ANY_VALUE,
                  label: 'Any Value',
                  description: '',
                  value: ROUTER_TRIGGER_VALUE_TYPES.ANY_VALUE,
                  validationRegex: '',
                  requiredError: '',
                },
                {
                  id: ROUTER_TRIGGER_VALUE_TYPES.SPECIFIC_VALUE,
                  label: 'Specific Value',
                  description: '',
                  value: ROUTER_TRIGGER_VALUE_TYPES.SPECIFIC_VALUE,
                  fields: [
                    {
                      id: 'fieldOperator',
                      label: 'Operator',
                      type: 'select',
                      width: 'w-[250px]',
                      options: [
                        {
                          value: 'equal',
                          label: 'Equals',
                        },
                        {
                          value: 'notEqual',
                          label: 'Not Equal',
                        },
                      ],
                      validationRegex: '',
                      requiredError: '',
                      value: '',
                      customStyle: 'w-[100%]',
                    },
                    {
                      id: 'fieldValue',
                      label: 'Value',
                      type: 'string',
                      value: '',
                      options: [],
                      required: false,
                      validationRegex: '',
                      requiredError: '',
                      width: '',
                      customStyle: 'w-[100%]',
                    },
                  ],
                },
              ],
              validationRegex: '',
              requiredError: '',
            },
          ],
          validationRegex: '',
          requiredError: '',
        },
        {
          id: ROUTER_TRIGGER_TYPES.NEW_RECORD,
          label: 'New Record',
          description: 'When a new record of a specific type is created',
          value: ROUTER_TRIGGER_TYPES.NEW_RECORD,
          fields: [
            {
              id: 'objectType',
              label: 'Object',
              type: 'select',
              options: [
                { value: HUBSPOT_OBJECT_TYPES.CONTACTS, label: 'Contact' },
                { value: HUBSPOT_OBJECT_TYPES.COMPANIES, label: 'Company' },
                { value: HUBSPOT_OBJECT_TYPES.DEALS, label: 'Deal' },
                { value: HUBSPOT_OBJECT_TYPES.TICKETS, label: 'Ticket' },
              ],
              validationRegex: '',
              requiredError: '',
              value: '',
            },
          ],
          validationRegex: '',
          requiredError: '',
        },
        {
          id: ROUTER_TRIGGER_TYPES.WORKFLOW_ACTION,
          label: 'Workflow Action',
          description: 'When an object hits a Routera workflow action',
          value: ROUTER_TRIGGER_TYPES.WORKFLOW_ACTION,
          validationRegex: '',
          requiredError: '',
        },
      ],
    },
  ],
}
