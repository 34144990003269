import React, { useEffect, useState } from 'react'
import Dropdown from '../Dropdown/Dropdown.Atom'

interface IProps {
  handleChange: any
  label: string
  value: string
}

const timeIntervalOptions = [
  { label: 'Minute(s)', value: 'minutes' },
  { label: 'Hour(s)', value: 'hours' },
  { label: 'Day(s)', value: 'days' },
]

const TimeInterval: React.FC<IProps> = ({ handleChange, label, value }) => {
  const [combinedValue, setCombinedValue] = useState<string>(value)
  const [inputValue, setInputValue] = useState<number>(0)
  const [maxInputValue, setMaxInputValue] = useState(59)
  const [dropdownValue, setDropdownValue] = useState('minutes')

  const getTimeIntervalValues = (v: string) => v.split(' ')

  const onValChange = (v: any, type: string) => {
    let updatedCombinedValue = `${combinedValue}`
    switch (type) {
      case 'number': {
        const splitV = getTimeIntervalValues(combinedValue)
        splitV[0] = v
        updatedCombinedValue = splitV.join(' ')
        setInputValue(v)
        break
      }
      case 'select': {
        if (v === 'minutes') {
          setMaxInputValue(59)
        } else if (v === 'hours') {
          setMaxInputValue(23)
        } else if (v === 'days') {
          setMaxInputValue(9999)
        }
        const splitV = getTimeIntervalValues(combinedValue)
        splitV[1] = v
        updatedCombinedValue = splitV.join(' ')
        setDropdownValue(v)
        break
      }
      default:
        break
    }
    setCombinedValue(updatedCombinedValue)
    handleChange(updatedCombinedValue)
  }

  useEffect(() => {
    if (value) {
      const splitV = getTimeIntervalValues(value)
      setInputValue(parseInt(splitV[0]) || 1)
      setDropdownValue(splitV[1])
    }
  }, [value])

  return (
    <div className='mt-3'>
      <label
        htmlFor='value'
        className='block text-sm font-medium text-gray-900'
      >
        {label}
      </label>
      <div className='flex flex-row'>
        <input
          type={'number'}
          name='value'
          id='field-update-value'
          className='block w-full rounded-l-md rounded-r-none border-gray-300 sm:text-sm mt-1.5 h-auto w-[65px]'
          placeholder=''
          onChange={(v) => onValChange(v.target.value, 'number')}
          value={inputValue}
          min={1}
          max={maxInputValue}
        />
        {/* <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
          <ExclamationCircleIcon
            className='h-5 w-5 text-red-500'
            aria-hidden='true'
          />
        </div> */}
        <Dropdown
          options={timeIntervalOptions}
          handleChange={(v) => onValChange(v.target.value, 'select')}
          value={dropdownValue}
          label={''}
          customClass={'rounded-r-md rounded-l-none w-[inherit]'}
          width={'w-[100%]'}
          showTopMargin={false}
        />
      </div>
    </div>
  )
}

export default TimeInterval
