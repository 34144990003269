import React, { useEffect, useState } from 'react'
import { getallusers } from 'src/container/my_account/account.selector'
import { useAppSelector } from 'src/store'
import { useActions } from '../../actions'
import toastBuilder from '../../utils/toastBuilder'
import SelectableTable, {
  SELECTION_MODES,
  TableColumn,
} from '../organisms/tables/SelectableTable.Organism'
import { ModalAtom } from '../atoms/Modal/Modal.atom'

const ImportUsersFromCRM: React.FC<{
  show: boolean
  setShow: any
  users: Array<{
    firstName: string
    lastName: string
    email: string
    id: string
  }>
}> = (props) => {
  const { show, setShow, users } = props
  const [rowsToShow, setRowsToShow] = useState<any[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Array<any>>([])
  const [limitItems, setLimitItems] = useState<number>(5)
  const [startLimit, setStartLimit] = useState<number>(0)
  const [endLimit, setEndLimit] = useState<number>(5)
  const [userList, setUserList] = useState<any[]>([])

  const { registerCustomer } = useActions()
  const allUsers = useAppSelector(getallusers)

  useEffect(() => {
    setRowsToShow(
      users.map((user: any) => ({
        ...user,
        disabled: isUserAlreadyImported(user.email),
      })),
    )
  }, [users])

    useEffect(() => {
    if (rowsToShow) {
      const fullUsers = [...rowsToShow]
      setUserList(
        fullUsers.splice(startLimit- 1, limitItems),
      )
    }
  }, [rowsToShow, startLimit, limitItems])

  const columns: Array<TableColumn> = [
    {
      label: 'Name',
      render: ({ firstName, lastName }: any) => {
        return (
          <div className='mr-2 flex items-center'>
            <span> {`${firstName} ${lastName}`}</span>
          </div>
        )
      },
      colName: 'firstName', // needed for sorting
    },
        {
      label: 'Email',
      field: 'email',
      render: ({ email }: any) => {
        return (
          <div className='mr-2 flex items-center'>
            <span> {`${email} `}</span>
          </div>
        )
      },
      colName: 'Email',
    },
    {
      label: 'Team',
      render: ({ teamName }: any) => {
        const team = teamName ? teamName : '-';
        return (
          <div className='mr-2 flex items-center'>
            <span>{team}</span>
          </div>
        )
      },
      colName: 'Team',
    },
  ]

    const onImport = async () => {
    if (selectedUsers.length > 0) {
      const action: any = await registerCustomer(selectedUsers)
      if (action.payload.success) {
        toastBuilder('success', 'User(s) imported')
        setShow(false)
      }
    } else {
      toastBuilder('error', 'Please select a user(s)')
      setShow(true)
    }
  }

  const handleUserSelect = (selectedUsersFromTable: any[]) => {
    setSelectedUsers(selectedUsersFromTable)
  }

  const isUserAlreadyImported = (userEmail: string) =>
    allUsers.find((user: any) => user.email === userEmail)

  const onSearchTextChange = (searchText: string) => {
    if (searchText === '') {
      setRowsToShow(
        users.map((user: any) => ({
          ...user,
          disabled: isUserAlreadyImported(user.email),
        })),
      )
      return
    }
    setRowsToShow(
      users
        .map((user: any) => ({
          ...user,
          disabled: isUserAlreadyImported(user.email),
        }))
        .filter((user: any) =>
          `${user.email}`
            .toLowerCase()
            .includes(searchText.toLowerCase()),
        ),
    )
  }

	const onPaginationChange = (
		startOffSet: number,
		endOffSet: number,
	) => {
		setStartLimit(startOffSet)
		setEndLimit(endOffSet)
	}

  return (
    <>
      <ModalAtom
        showModal={show}
        setShowModal={setShow}
        showCancelBtn={true}
        handleSave={onImport}
        buttonText={'Add users'}
        closeOnOut={true}
      >
        <div className='h-[580px] w-[780px] laptop:w-[90vw] overflow-y-scroll overflow-x-hidden flex flex-col'>
          <div className='ml-6 mt-6'>
            <span className='font-medium'>Import users from CRM</span>
          </div>
          <SelectableTable
            columns={columns}
            rows={userList}
            initialSelection={selectedUsers}
            selectionType={SELECTION_MODES.SELECT_MANY}
            onSelectionChange={handleUserSelect}
            onSearchChange={onSearchTextChange}
            borderTable={false}
            className='py-2'
            pagination={{
              pageSize: limitItems,
              handlePageChange: onPaginationChange,
              pageSizeOptions: [0],
              fetchedDataSize: userList.length,
              totalDataSize: (rowsToShow ? rowsToShow.length : 0),
              totalPages: Math.ceil(
                (rowsToShow ? rowsToShow.length : 0) / limitItems,
              ),
            }}
            limitItems={{
              setLimitItems: setLimitItems,
              totalItems: (rowsToShow ? rowsToShow.length : 0),
              startLimit: startLimit,
              endLimit: startLimit + userList.length - 1,
            }}
          />
      </div>
      </ModalAtom>
    </>
  )
}

export default ImportUsersFromCRM
