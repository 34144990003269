import { useEffect, useState } from 'react'
import DropdownSearch from 'src/components/atoms/Dropdown/dropdownSearch'
import { useAppSelector } from 'src/store'
import { uselistofAllConversions } from 'src/container/conversions/conversions.selector'
import { useActions } from 'src/actions'
import { Conversions } from 'src/types/conversions'
import {
  DropDownSearch,
  ConversionUserItem,
  ConversionRouterItem,
} from 'src/types/dashboard'
import Tabs from 'src/components/molecules/tabs/tabs'
import { DataTab } from 'src/types/tabs'
import SelectableTable, {
  TableColumn,
} from 'src/components/organisms/tables/SelectableTable.Organism'
import PercentageBar from 'src/components/atoms/percentageBar/percentageBar'
import Avatar from 'src/components/atoms/avatar/Avatar.Atom'
import IconRoutera from 'src/components/atoms/icons/icons'
import { conversionListData } from 'src/container/dashboard/dashboard.selector'
import { useSearchParams } from 'react-router-dom'
import { getallusers } from 'src/container/my_account/account.selector'
import { User } from 'src/types/customers'
import { uselistofAllroutes } from 'src/container/leadRouter/leadRoutes.selector'
import { IRouterDetail } from 'src/types/routerDetail'
import { IRoute } from 'src/types/leadRoutes'
import { IOption } from 'src/types/Ihome_state'
import { ability } from 'src/utils/permissionsCASL'
import { getAccountById } from 'src/container/my_account/account.selector'
import { selectUser } from 'src/container/auth/auth.selector'
import { config } from 'src/config/constants'
import { useNavigate } from 'react-router-dom'

const { FREE_PLAN_MONTHLY } = config

interface IProps {
  setConversionsSelected: (conversionSelected: DropDownSearch) => void
  conversionsSelected?: DropDownSearch
  setLimitConversionsUsersItems: (value: number) => void
  limitConversionsUsersItems: number
  setLimitConversionsRoutersItems: (value: number) => void
  limitConversionsRoutersItems: number
  usersSelected: IOption[]
  routersSelected: IOption[]
}

const ConversionOverviewDashboard: React.FC<IProps> = (props) => {
  const {
    setConversionsSelected,
    conversionsSelected,
    setLimitConversionsUsersItems,
    limitConversionsUsersItems,
    setLimitConversionsRoutersItems,
    limitConversionsRoutersItems,
    usersSelected,
    routersSelected,
  } = props

  const { getConversionsList, getAccount } = useActions()
  const conversionsList = useAppSelector(uselistofAllConversions)
  const data = useAppSelector(conversionListData)
  const allUsers = useAppSelector(getallusers)
  const listRoutes = useAppSelector(uselistofAllroutes)
  const currentUser = useAppSelector(selectUser)
  const account = useAppSelector(getAccountById)

  const [params] = useSearchParams()

  const [startLimitRouters, setStartLimitRouters] = useState<number>(0)
  const [endLimitRouters, setEndLimitRouters] = useState<number>(5)

  const [users, setUsers] = useState<User[]>()
  const [routers, setRouters] = useState<IRouterDetail[] | IRoute[]>()

  const [startLimitUsers, setStartLimitUsers] = useState<number>(0)
  const [endLimitUsers, setEndLimitUsers] = useState<number>(5)

  const [conversionsData, setConversionsData] = useState<DropDownSearch[]>([])

  const [rowsToShowUsers, setRowsToShowUsers] = useState<ConversionUserItem[]>(
    data.conversionUserItem,
  )
  const [rowsToShowU, setRowsToShowU] = useState<ConversionUserItem[]>(
    data.conversionUserItem,
  )
  const [rowsToShowRouters, setRowsToShowRouters] = useState<
    ConversionRouterItem[]
  >(data.conversionRouterItem)
  const [rowsToShowR, setRowsToShowR] = useState<ConversionRouterItem[]>(
    data.conversionRouterItem,
  )

  const [freePlan, setFreePlan] = useState(false)

  useEffect(() => {
    if (currentUser && currentUser.account_id) {
      getAccount(currentUser.account_id)
    }
  }, [])

  useEffect(() => {
    if (account && account.plan_id === FREE_PLAN_MONTHLY) {
      setFreePlan(true)
    }
  }, [account])

  const getActiveConversions = () => {
    const dataOld = [...conversionsList]
    const list = dataOld.filter((itemData: Conversions) => itemData.is_active)
    const dataFilter: DropDownSearch[] = []
    list.forEach((item: Conversions) => {
      dataFilter.push({ label: item.name, id: item._id })
    })
    return dataFilter
  }

  useEffect(() => {
    if (ability.can('access', 'conversions')) {
      getConversionsList()
    }
  }, [])

  useEffect(() => {
    if (allUsers.length > 0) {
      setUsers(allUsers)
    }
  }, [allUsers])

  useEffect(() => {
    if (listRoutes.length > 0) {
      setRouters(listRoutes)
    }
  }, [listRoutes])

  useEffect(() => {
    if (data) {
      let userItems: ConversionUserItem[] = []
      let routerItems: ConversionRouterItem[] = []
      let usersData: User[] | undefined = users
      let routersData: any = routers

      if (usersData && usersSelected.length !== users?.length && users) {
        usersData = []
        users.forEach((user: User) => {
          const userSelect = usersSelected.filter(
            (item: IOption) => item.value === user._id,
          )
          if (userSelect.length > 0 && usersData) {
            usersData.push(user)
          }
        })
      }

      if (
        data.conversionUserItem &&
        data.conversionUserItem.length > 0 &&
        data.conversionUserItem[0].user._id !== ''
      ) {
        userItems = [...data.conversionUserItem]
        if (usersData) {
          usersData.forEach((user: User) => {
            const filteredUsers: ConversionUserItem[] = userItems.filter(
              (item: ConversionUserItem) => item.user._id === user._id,
            )
            if (!filteredUsers || filteredUsers.length === 0) {
              const emptyUser: ConversionUserItem = {
                conversionRate: undefined,
                user: user,
              }
              userItems.push(emptyUser)
            }
          })
        }
      }

      if (
        routersData &&
        routersSelected.length !== routers?.length &&
        routers
      ) {
        routersData = []
        routers.forEach((router: IRoute | IRouterDetail) => {
          const routerSelect = routersSelected.filter(
            (item: IOption) => item.value === router._id,
          )
          if (routerSelect.length > 0 && routersData) {
            routersData.push(router)
          }
        })
      }

      if (
        data.conversionRouterItem &&
        data.conversionRouterItem.length > 0 &&
        data.conversionRouterItem[0].router._id !== ''
      ) {
        routerItems = [...data.conversionRouterItem]
        if (routersData) {
          routersData.forEach((router: IRoute | IRouterDetail) => {
            const filteredRouters: ConversionRouterItem[] = routerItems.filter(
              (item: ConversionRouterItem) => item.router._id === router._id,
            ) //isPublished
            if (!filteredRouters || filteredRouters.length === 0) {
              const emptyUser: ConversionRouterItem = {
                conversionRate: undefined,
                router: router,
              }
              routerItems.push(emptyUser)
            }
          })
        }
      }

      setRowsToShowUsers(userItems)
      setRowsToShowRouters(routerItems)
    }
  }, [data, usersSelected])

  useEffect(() => {
    if (rowsToShowUsers) {
      const fullUsers = [...rowsToShowUsers]
      setRowsToShowU(
        fullUsers.splice(startLimitUsers - 1, limitConversionsUsersItems),
      )
    }
  }, [rowsToShowUsers, startLimitUsers, limitConversionsUsersItems])

  useEffect(() => {
    if (rowsToShowRouters) {
      const fullRouters = [...rowsToShowRouters]
      setRowsToShowR(
        fullRouters.splice(startLimitRouters - 1, limitConversionsRoutersItems),
      )
    }
  }, [rowsToShowRouters, startLimitRouters, limitConversionsRoutersItems])

  useEffect(() => {
    if (conversionsList) {
      setConversionsData(getActiveConversions())
      if (params.has('conversion_id') && conversionsList.length > 0) {
        const value = params.get('conversion_id')
        const conversionId = conversionsList.filter(
          (element) => element._id === value,
        )
        if (conversionId.length === 0) {
          setConversionsSelected(getActiveConversions()[0])
          return
        }
        setConversionsSelected({
          label: conversionId[0].name,
          id: conversionId[0]._id,
        })
      } else {
        setConversionsSelected(getActiveConversions()[0])
      }
    }
  }, [conversionsList])

  const getDataFilter = () => {
    const dataList = conversionsList.filter(
      (itemData: Conversions) => itemData.is_active,
    )
    const dataFilter: DropDownSearch[] = []
    dataList.forEach((item: Conversions) => {
      dataFilter.push({ label: item.name, id: item._id })
    })
    return dataFilter
  }

  const onPaginationChangeUsers = (
    startOffSet: number,
    endOffSet: number,
    currentPage: number,
  ) => {
    setStartLimitUsers(startOffSet)
    setEndLimitUsers(endOffSet)
  }

  const onPaginationChangeRouters = (
    startOffSet: number,
    endOffSet: number,
    currentPage: number,
  ) => {
    setStartLimitRouters(startOffSet)
    setEndLimitRouters(endOffSet)
  }

  const zIndex = [40, 30, 20, 10]

  const getUserInfo = (id: string): User[] | undefined => {
    return users?.filter((item: User) => item._id === id)
  }

  const navigate = useNavigate()

  const redirectBillingPage = () => {
    navigate('/settings/billing')
  }

  const columnsUserTable: TableColumn[] = [
    {
      label: 'User',
      field: 'conversionStatus',
      render: ({ user }: any) => {
        const userData = getUserInfo(user._id)
        return (
          <div>
            {userData && userData.length > 0 && (
              <div className='mr-2 flex items-center'>
                <Avatar
                  className='text-sm mr-2 w-[32px] h-[32px]'
                  firstName={userData[0].firstName}
                  lastName={userData[0].lastName}
                  imgURL={userData[0].avatar}
                  available={{
                    userAvailability: userData[0].userAvailability,
                    officeStatus: userData[0].office_status
                      ? userData[0].office_status
                      : false,
                  }}
                />
                <span className='font-normal text-[14px] leading-[21px] text-gray-700'>
                  {' '}
                  {`${userData[0].firstName} ${userData[0].lastName}`}
                </span>
              </div>
            )}
          </div>
        )
      },
    },
    {
      label: 'Total Routed',
      render: ({ totalPropertyName }: any) => {
        return (
          <div>
            {totalPropertyName || totalPropertyName === 0 ? (
              <span className='text-gray-500 text-sm font-normal capitalize'>
                {totalPropertyName}
              </span>
            ) : (
              <span className='text-gray-500 text-sm font-normal italic mr-2'>
                No data*
              </span>
            )}
          </div>
        )
      },
    },
    {
      label: 'Total Conversion',
      render: ({ totalPropertyValue }: any) => {
        return (
          <div>
            {totalPropertyValue || totalPropertyValue === 0 ? (
              <span className='text-gray-500 text-sm font-normal capitalize'>
                {totalPropertyValue}
              </span>
            ) : (
              <span className='text-gray-500 text-sm font-normal italic mr-2'>
                No data*
              </span>
            )}
          </div>
        )
      },
    },
    {
      label: 'Conversion rate',
      className: 'md:w-[400px]',
      render: ({ conversionRate }: any) => {
        return (
          <div>
            {conversionRate || conversionRate === 0 ? (
              <div>
                <span className='text-gray-500 text-xs font-medium leading-[18px] w-[100%] text-right block'>
                  {Math.trunc(conversionRate)}%
                </span>
                <PercentageBar
                  value={conversionRate}
                  total={100}
                  changesBarColors={true}
                  numberIsPercentage={true}
                />
              </div>
            ) : (
              <div className='flex items-center'>
                <span className='text-gray-500 text-sm font-normal italic mr-2'>
                  No data*
                </span>
                <div className='bg-routera-50 py-[2px] px-[10px] rounded-md w-fit'>
                  <a href='#' target='_blank' rel='noreferrer'>
                    <span className='flex text-routera-700 text-xs font-medium leading-[18px] items-center cursor-pointer'>
                      <IconRoutera
                        iconName='InformationCircleIcon'
                        className='w-[14px] h-[14px] mr-1'
                      />
                      How does this widget work?
                      <IconRoutera
                        iconName='ArrowRightIcon'
                        className='w-[14px] h-[14px] ml-1'
                      />
                    </span>
                  </a>
                </div>
              </div>
            )}
          </div>
        )
      },
    },
  ]

  const columnsRoutersTable: TableColumn[] = [
    {
      label: 'Route Name',
      field: 'routerName',
      render: ({ router }: any) => {
        return (
          <div className='mr-2 flex items-center'>
            <span className='text-gray-500 text-sm font-normal capitalize'>
              {router.name}
            </span>
          </div>
        )
      },
    },
    {
      label: 'Model',
      render: ({ router }: any) => {
        return (
          <div>
            {router.assignedModel ? (
              <span className='text-gray-500 text-sm font-normal capitalize'>
                {router.assignedModel}
              </span>
            ) : (
              <span className='text-gray-500 text-sm font-normal italic mr-2'>
                No data*
              </span>
            )}
          </div>
        )
      },
    },
    {
      label: 'Users',
      field: 'usersRouters',
      render: ({ router }: any) => {
        return (
          <div className='mr-2 flex items-center -space-x-4 w-[113px]'>
            {router.assignedUsers &&
              router.assignedUsers.length > 0 &&
              router.assignedUsers.map((item: any, index: number) => {
                const userInfo = getUserInfo(item.userId)
                if (userInfo && userInfo.length > 0 && index <= 3 && item) {
                  return (
                    <div
                      key={'dataDashboard ' + userInfo[0]._id + zIndex[index]}
                    >
                      <Avatar
                        className='w-[32px] h-[32px] mr-[9px] border-2 border-white rounded-full'
                        firstName={userInfo[0].firstName}
                        lastName={userInfo[0].lastName}
                        imgURL={userInfo[0].avatar}
                        classDiv={`z-${zIndex[index]}`}
                        available={{
                          userAvailability: userInfo[0].userAvailability,
                          officeStatus: userInfo[0].office_status
                            ? userInfo[0].office_status
                            : false,
                        }}
                      />
                    </div>
                  )
                } else {
                  return (
                    router.assignedUsers.length > 4 &&
                    router.assignedUsers.length - 1 === index && (
                      <div className='w-[32px] h-[29px] mr-[10px] relative flex justify-center items-center rounded-full px-2 bg-blue-600 text-white uppercase ${className}'>
                        <span className='text-xs font-medium'>
                          +{router.assignedUsers.length - 4}
                        </span>
                      </div>
                    )
                  )
                }
              })}
          </div>
        )
      },
    },
    {
      label: 'Total Routed',
      render: ({ totalPropertyName }: any) => {
        return (
          <div>
            {totalPropertyName || totalPropertyName === 0 ? (
              <span className='text-gray-500 text-sm font-normal capitalize'>
                {totalPropertyName}
              </span>
            ) : (
              <span className='text-gray-500 text-sm font-normal italic mr-2'>
                No data*
              </span>
            )}
          </div>
        )
      },
    },
    {
      label: 'Total Conversion',
      render: ({ totalPropertyValue }: any) => {
        return (
          <div>
            {totalPropertyValue || totalPropertyValue === 0 ? (
              <span className='text-gray-500 text-sm font-normal capitalize'>
                {totalPropertyValue}
              </span>
            ) : (
              <span className='text-gray-500 text-sm font-normal italic mr-2'>
                No data*
              </span>
            )}
          </div>
        )
      },
    },
    {
      label: 'Conversion rate',
      render: ({ conversionRate }: any) => {
        return (
          <div>
            {conversionRate || conversionRate === 0 ? (
              <div>
                <span className='text-gray-500 text-xs font-medium leading-[18px] w-[100%] text-right block'>
                  {Math.trunc(conversionRate)}%
                </span>
                <PercentageBar
                  value={conversionRate}
                  total={100}
                  changesBarColors={true}
                  numberIsPercentage={true}
                />
              </div>
            ) : (
              <div className='flex items-center'>
                <span className='text-gray-500 text-sm font-normal italic mr-2'>
                  No data*
                </span>
                <div className='bg-routera-50 py-[2px] px-[10px] rounded-md w-fit'>
                  <a href='#' target='_blank' rel='noreferrer'>
                    <span className='flex text-routera-700 text-xs font-medium leading-[18px] items-center cursor-pointer'>
                      <IconRoutera
                        iconName='InformationCircleIcon'
                        className='w-[14px] h-[14px] mr-1'
                      />
                      How does this widget work?
                      <IconRoutera
                        iconName='ArrowRightIcon'
                        className='w-[14px] h-[14px] ml-1'
                      />
                    </span>
                  </a>
                </div>
              </div>
            )}
          </div>
        )
      },
    },
  ]

  const dataTabs: DataTab[] = [
    {
      label: 'Users',
      id: 'users-dashboard',
      dataContentTab: (
        <div
          className={`bg-white ${
            rowsToShowU && rowsToShowU.length === 0 && 'h-[370px]'
          }`}
        >
          {rowsToShowU && rowsToShowU.length > 0 ? (
            <SelectableTable
              columns={columnsUserTable}
              rows={rowsToShowU}
              initialSelection={[]}
              showSelectionType={false}
              showHeaderMeta={false}
              borderTable={false}
              limitItems={{
                setLimitItems: setLimitConversionsUsersItems,
                totalItems: rowsToShowUsers ? rowsToShowUsers.length : 0,
                startLimit: startLimitUsers,
                endLimit: startLimitUsers + rowsToShowU.length - 1,
              }}
              pagination={{
                pageSize: limitConversionsUsersItems,
                handlePageChange: onPaginationChangeUsers,
                fetchedDataSize: rowsToShowU.length,
                totalDataSize: rowsToShowUsers ? rowsToShowUsers.length : 0,
                totalPages: Math.ceil(
                  (rowsToShowUsers ? rowsToShowUsers.length : 0) /
                    limitConversionsUsersItems,
                ),
              }}
            />
          ) : (
            <div className='flex flex-col items-center justify-center h-full -mt-4 py-[130px]'>
              <span className='text-gray-700 text-3xl font-bold mb-2 mt-2'>
                No data*
              </span>
              <div className='bg-routera-50 py-[2px] px-[10px] rounded-md w-fit mt-2 z-10'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://help.routera.io/article/5-create-a-router'
                >
                  <span className='flex text-routera-700 text-xs font-medium leading-[18px] items-center cursor-pointer'>
                    <IconRoutera
                      iconName='InformationCircleIcon'
                      className='w-[14px] h-[14px] mr-1'
                    />
                    How does this list work?
                    <IconRoutera
                      iconName='ArrowRightIcon'
                      className='w-[14px] h-[14px] ml-1'
                    />
                  </span>
                </a>
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      label: 'Routers',
      id: 'routers-dashboard',
      dataContentTab: (
        <div
          className={`bg-white ${
            rowsToShowR && rowsToShowR.length === 0 && 'h-[350px]'
          }`}
        >
          {rowsToShowR && rowsToShowR.length > 0 ? (
            <SelectableTable
              columns={columnsRoutersTable}
              rows={rowsToShowR}
              initialSelection={[]}
              showSelectionType={false}
              showHeaderMeta={false}
              borderTable={false}
              limitItems={{
                setLimitItems: setLimitConversionsRoutersItems,
                totalItems: rowsToShowRouters ? rowsToShowRouters.length : 0,
                startLimit: startLimitRouters,
                endLimit: startLimitRouters + rowsToShowR.length - 1,
              }}
              pagination={{
                pageSize: limitConversionsRoutersItems,
                handlePageChange: onPaginationChangeRouters,
                pageSizeOptions: [0],
                fetchedDataSize: rowsToShowR.length,
                totalDataSize: rowsToShowRouters ? rowsToShowRouters.length : 0,
                totalPages: Math.ceil(
                  (rowsToShowRouters ? rowsToShowRouters.length : 0) /
                    limitConversionsRoutersItems,
                ),
              }}
            />
          ) : (
            <div className='flex flex-col items-center justify-center h-full -mt-4 py-[130px]'>
              <span className='text-gray-700 text-3xl font-bold mb-2 mt-2'>
                No data*
              </span>
              <div className='bg-routera-50 py-[2px] px-[10px] rounded-md w-fit mt-2 z-10'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://help.routera.io/article/5-create-a-router'
                >
                  <span className='flex text-routera-700 text-xs font-medium leading-[18px] items-center cursor-pointer'>
                    <IconRoutera
                      iconName='InformationCircleIcon'
                      className='w-[14px] h-[14px] mr-1'
                    />
                    How does this list work?
                    <IconRoutera
                      iconName='ArrowRightIcon'
                      className='w-[14px] h-[14px] ml-1'
                    />
                  </span>
                </a>
              </div>
            </div>
          )}
        </div>
      ),
    },
  ]

  return (
    <div className='bg-white p-3 flex flex-col shadow-sm rounded-lg m-4'>
      <div className='border-b-[1px] border-gray-200 pb-3 mx-4 mt-4 flex justify-between flex-wrap'>
        <div className='flex flex-col'>
          <span className='text-gray-900 text-2xl font-bold leading-[30px]'>
            Conversion Overview
          </span>
          <span className='text-gray-400 text-base font-normal leading-[24px] mt-2'>
            Information by User and Routers
          </span>
        </div>
        <DropdownSearch
          setData={setConversionsData}
          dataFilter={getDataFilter}
          data={conversionsData}
          setItemSelected={setConversionsSelected}
          itemSelected={conversionsSelected}
          zIndex='z-50'
          idDropDown='dropdownSearchDashboard'
          labelDropdown='Conversions'
        />
      </div>
      {freePlan ? (
        <div className='flex justify-center p-5'>
          <div className='text-center'>
            <div className='flex items-baseline'>
              <IconRoutera
                iconName='LockClosedIcon'
                className='w-[14px] h-[14px] mr-1'
              />
              <span className='text-gray-500 text-base font-normal ml-2 mt-2'>
                Upgrade to unlock Conversions
              </span>
            </div>
            <button
              onClick={redirectBillingPage}
              className='px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start mt-[10px]'
            >
              Upgrade Now
            </button>
          </div>
        </div>
      ) : (
        <Tabs
          dataTabs={dataTabs}
          className='bg-white'
          classTabs='px-4'
          classContentTab='bg-white'
          addPropsUrl={false}
        />
      )}
    </div>
  )
}

export default ConversionOverviewDashboard
