import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import {
  ROUTER_TRIGGER_TYPES,
  ROUTER_TRIGGER_VALUE_TYPES,
} from '../../../types/triggers'
import FormBuilder from '../../molecules/FormBuilder/FormBuilder'
import { createTriggerJSON } from '../../../forms/createTriggerFormJSON'
import { useAppSelector } from '../../../store'
import { usespecificRouterDetail } from '../../../container/RouterDetail/routerDetail.selector'
import { routerDetailService } from '../../../services/router_detail.service'
import { useActions } from '../../../actions'
import { HUBSPOT_OBJECT_TYPES } from 'src/types/sources'
import {
  AVAILABLE_OPERATORS,
  JsonFieldTypeMap,
} from '../../organisms/forms/inputs/jsonOperator/getJsonOperator'
import toastBuilder from 'src/utils/toastBuilder'

interface IProps {
  setShowSlider: (value: boolean) => void
}

const enumKeyToString = (key: string) => {
  const str = key.replaceAll('_', ' ')
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

const availableOperatorValues = Object.keys(AVAILABLE_OPERATORS).map((key) => {
  return {
    // @ts-ignore
    value: AVAILABLE_OPERATORS[key],
    // @ts-ignore
    label: enumKeyToString(key),
  }
})

const getOperatorValues = (type: string) => {
  const newOperatorValues: {
    value: string
    label: string
  }[] = []
  availableOperatorValues.forEach((opVal) => {
    // @ts-ignore
    if (JsonFieldTypeMap[type]?.includes(opVal.value)) {
      newOperatorValues.push(opVal)
    }
  })
  return newOperatorValues
}

const CreateTriggerForm: React.FC<IProps> = ({ setShowSlider }) => {
  const [triggerFormJSON, setTriggerFormJSON] = useState({})
  const [operatorsData, setOperatorsData] = useState({})

  const specificRouterDetail = useAppSelector(usespecificRouterDetail)

  const { routerId = '' } = useParams()
  const { upsertTrigger } = useActions()
  const navigate = useNavigate()

  const defaultValues = {
    triggerType: ROUTER_TRIGGER_TYPES.FIELD_UPDATE,
    objectType: HUBSPOT_OBJECT_TYPES.CONTACTS,
    fieldType: '',
    fieldUpdateValue: 'ANY_VALUE',
    fieldOperator: '',
    fieldValue: '',
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, dirtyFields, touchedFields },
    reset,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
  } = useForm({ defaultValues })

  const { isLoading, isSuccess, isRefetching, data, refetch } = useQuery(
    ['getFieldProperties'],
    () =>
      getValues().objectType &&
      getValues().triggerType &&
      routerDetailService.getFieldProperties(routerId, getValues().objectType),
    { retry: false, enabled: false },
  )

  const refetchHelper = async () => {
    getValues().objectType &&
      getValues().triggerType === ROUTER_TRIGGER_TYPES.FIELD_UPDATE &&
      (await refetch())
  }

  const setOperatorsToForm = (operatorsObj: any) => {
    const { operators, operatorOptions } = operatorsObj
    const oldFormJSON = { ...triggerFormJSON }
    if (
      //   @ts-ignore
      oldFormJSON?.fields.length
    ) {
      //   @ts-ignore
      oldFormJSON.fields[0].options[0].fields[2].options[1].fields[0].options =
        operators
      //   @ts-ignore
      oldFormJSON.fields[0].options[0].fields[2].options[1].fields[1].options =
        operatorOptions
      setTriggerFormJSON(oldFormJSON)
    }
  }

  useEffect(() => {
    if (specificRouterDetail.trigger_metadata && specificRouterDetail.trigger_metadata.objectType === getValues().objectType) {
      refetchHelper()
    }
  }, [data, getValues().objectType, getValues().triggerType])

  useEffect(() => {
    const formJSON = { ...createTriggerJSON }
    createTriggerJSON && setTriggerFormJSON(formJSON)
  }, [])

  useEffect(() => {
    // populate form with trigger_metadata values
    if (specificRouterDetail?.trigger_metadata) {
      //   @ts-ignore
      setValue('triggerType', specificRouterDetail.trigger_type)
      //   @ts-ignore
      setValue('objectType', specificRouterDetail.trigger_metadata.objectType)
    }
    refetchHelper()
  }, [specificRouterDetail])

  useEffect(() => {
    // @ts-ignore
    if (operatorsData?.operators?.length) {
      setOperatorsToForm(operatorsData)
      if (getValues().triggerType === ROUTER_TRIGGER_TYPES.FIELD_UPDATE) {
        const oldFormJSON = { ...triggerFormJSON }
        if (
          //   @ts-ignore
          oldFormJSON?.fields.length
        ) {
          const valueElementType = JSON.parse(getValues().fieldType).type
          //   @ts-ignore
          oldFormJSON.fields[0].options[0].fields[2].options[1].fields[1].customStyle =
            'w-[22.5rem]'
          //   @ts-ignore
          oldFormJSON.fields[0].options[0].fields[2].options[1].fields[1].type =
            valueElementType
          setTriggerFormJSON(oldFormJSON)
          if (
            !specificRouterDetail?.trigger_metadata?.operator ||
            JSON.parse(getValues().fieldType).name !==
            specificRouterDetail?.trigger_metadata?.fieldName
          ) {
            // @ts-ignore
            setValue('fieldOperator', operatorsData.operators[0].value)
          } else {
            setValue(
              'fieldOperator',
              specificRouterDetail?.trigger_metadata?.operator,
            )
          }
          let fieldUpdateSpecificValue = ''
          if (
            (['select', 'enumeration', 'bool'].includes(valueElementType) &&
              !specificRouterDetail?.trigger_metadata?.value) ||
            JSON.parse(getValues().fieldType).name !==
            specificRouterDetail?.trigger_metadata?.fieldName
          ) {
            fieldUpdateSpecificValue = JSON.parse(getValues().fieldType)
              ?.options[0]?.value
            setValue('fieldValue', fieldUpdateSpecificValue)
          }
          if (getValues().fieldValue === undefined) {
            if (['string', 'datetime', 'array'].includes(valueElementType)) {
              setValue('fieldValue', '')
            } else if (valueElementType === 'number') {
              // @ts-ignore
              setValue('fieldValue', 0)
            }
          }
        }
      }
    }
  }, [operatorsData])

  useEffect(() => {
    if (
      Object.keys(triggerFormJSON).length &&
      isLoading === false &&
      isSuccess
    ) {
      const { fieldProperties } = data?.data || []
      const fields:
        | ((prevState: never[]) => never[])
        | { label: string; value: string }[] = []
      if (fieldProperties && fieldProperties.length) {
        fieldProperties.forEach((field: any) => {
          fields.push({
            label: field.label,
            value: JSON.stringify({
              name: field.name,
              type: field.type,
              options: field?.options,
            }),
          })
        })
        const oldFormJSON = { ...triggerFormJSON }
        //   @ts-ignore
        if (oldFormJSON?.fields.length) {
          //   @ts-ignore
          oldFormJSON.fields[0].options[0].fields[1].options = fields
          setTriggerFormJSON(oldFormJSON)
          let value = fields[0].value
          if (specificRouterDetail.trigger_metadata) {
            // populate data from db for edit
            //   @ts-ignore
            value = fields.find(
              (field: any) =>
                JSON.parse(field.value).name ===
                specificRouterDetail?.trigger_metadata?.fieldName,
            )?.value
          }
          setValue('fieldType', value)
          if (!specificRouterDetail?.trigger_metadata?.value) {
            setValue('fieldUpdateValue', ROUTER_TRIGGER_VALUE_TYPES.ANY_VALUE)
          } else {
            setValue(
              'fieldUpdateValue',
              ROUTER_TRIGGER_VALUE_TYPES.SPECIFIC_VALUE,
            )
            setValue('fieldValue', specificRouterDetail.trigger_metadata.value)
          }
        }
      }
    }
  }, [isLoading, isRefetching])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        (name === 'objectType' && type === 'change') ||
        name === 'triggerType'
      ) {
        refetchHelper()
      } else if (name === 'fieldType' && value.fieldType) {
        // @ts-ignore
        const operators = getOperatorValues(JSON.parse(value.fieldType).type)
        // @ts-ignore
        const operatorOptions = JSON.parse(value.fieldType).options
        setOperatorsData({ operators, operatorOptions })
      }
    })
    refetch()
    return () => subscription.unsubscribe()
  }, [watch])

  const onSave = async () => {
    const values = getValues()
    const payload: any = {}
    payload.trigger_type = values.triggerType
    if (values.triggerType === ROUTER_TRIGGER_TYPES.FIELD_UPDATE) {
      payload.trigger_metadata = {
        objectType: values.objectType,
        fieldName: JSON.parse(values.fieldType).name,
      }
      if (values.fieldUpdateValue === ROUTER_TRIGGER_VALUE_TYPES.ANY_VALUE) {
        // TODO : check value & operator
        payload.trigger_metadata = {
          ...payload.trigger_metadata,
          value: null,
        }
      } else if (
        values.fieldUpdateValue === ROUTER_TRIGGER_VALUE_TYPES.SPECIFIC_VALUE
      ) {
        payload.trigger_metadata = {
          ...payload.trigger_metadata,
          operator: values.fieldOperator,
          value: values.fieldValue,
        }
      }
    } else if (values.triggerType === ROUTER_TRIGGER_TYPES.NEW_RECORD) {
      payload.trigger_metadata = {
        objectType: values.objectType,
        operator: '',
        value: null,
        fieldName: '',
      }
    }
    payload.trigger_metadata = {
      ...payload.trigger_metadata,
    }
    const rsp: any = await upsertTrigger({
      routerId,
      triggerPayload: payload,
    })
    if (rsp && rsp.payload.success) {
      setShowSlider(false)
      toastBuilder('success', 'Triggers setup')
      navigate(`/routers/${routerId}/users`)
    }
  }

  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='overflow-y-auto p-8'>
        <div className='mb-[2.5rem]'>
          <span className='text-lg font-medium flex-grow-1'>
            Create Trigger
          </span>
        </div>
        <form onSubmit={handleSubmit(onSave)}>
          <FormBuilder
            formJSON={triggerFormJSON}
            // @ts-ignore
            setValue={setValue}
            // @ts-ignore
            control={control}
          />
        </form>
      </div>
      <div className='flex flex-shrink-0 justify-between px-4 py-[0.75rem] bg-gray-50'>
        <button
          type='button'
          className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#0062FF] focus:ring-offset-2'
          onClick={() => setShowSlider(false)}
        >
          Cancel
        </button>
        <button
          type='submit'
          className={`ml-4 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[#0062FF] focus:ring-offset-2 ${isLoading ? 'bg-gray-400' : 'bg-[#0062FF]'
            }`}
          onClick={onSave}
          disabled={isLoading}
        >
          Save & close
        </button>
      </div>
    </div>
  )
}

export default CreateTriggerForm
