import React, { useEffect, useState } from 'react'
import { getallusers } from 'src/container/my_account/account.selector'
import { useAppSelector } from 'src/store'
import { useActions } from '../../../actions'
import toastBuilder from '../../../utils/toastBuilder'
import SelectableTable, {
  SELECTION_MODES,
  TableColumn,
} from '../../organisms/tables/SelectableTable.Organism'
import { ModalAtom } from '../../atoms/Modal/Modal.atom'
import { ITeamMember } from 'src/types/routerDetail'
import { ability } from 'src/utils/permissionsCASL'

const AddUsersModal: React.FC<{
  show: boolean
  setShow: any
  users: Array<{
    firstName: string
    lastName: string
    email: string
    id: string
  }>
  teams: Array<string>
  isManagerTeam: boolean
}> = (props) => {
  const { show, setShow, users, teams, isManagerTeam } = props
  const [rowsToShow, setRowsToShow] = useState<any[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Array<any>>(users)
  const [limitItems, setLimitItems] = useState<number>(5)
  const [startLimit, setStartLimit] = useState<number>(0)
  const [endLimit, setEndLimit] = useState<number>(5)
  const [userList, setUserList] = useState<any[]>([])

  const { addUsersTeam, getTeamsList } = useActions()
  const allUsers = useAppSelector(getallusers)

  useEffect(() => {
    if (users) {
      setSelectedUsers(users|| [])
    }
  }, [users])

  useEffect(() => {
    setRowsToShow(
      allUsers.map((user: any) => ({
        ...user,
      })),
    )
  }, [allUsers])

    useEffect(() => {
    if (rowsToShow) {
      const fullUsers = [...rowsToShow]
      setUserList(
        fullUsers.splice(startLimit- 1, limitItems),
      )
    }
  }, [rowsToShow, startLimit, limitItems])

  const columns: Array<TableColumn> = [
    {
      label: 'Name',
      render: ({ firstName, lastName }: any) => {
        return (
          <div className='mr-2 flex items-center'>
            <span> {`${firstName} ${lastName}`}</span>
          </div>
        )
      },
      colName: 'firstName',
    },
    {
      label: 'Email',
      field: 'email',
      render: ({ email }: any) => {
        return (
          <div className='mr-2 flex items-center'>
            <span> {`${email} `}</span>
          </div>
        )
      },
      colName: 'Email',
    },
    {
      label: 'Role',
      field: 'role',
      render: ({ role }: any) => {
        return (
          <div className='mr-2 flex items-center'>
            <span> {`${role} `}</span>
          </div>
        )
      },
      colName: 'Role',
    },
  ]

  const onAddUser= async () => {
		if (teams.length > 0 && selectedUsers.length > 0) {
			const newUsers: any = [];

			selectedUsers.map((user) => newUsers.push(user._id));

			const payload = {
				id: teams[0],
				data: {
					users: newUsers,
					isManagers: isManagerTeam
				}
			}

			const response: any = await addUsersTeam(payload)

			if (response.payload.success) {
				toastBuilder('success', `${isManagerTeam ? 'Manager(s) added' : 'User(s) added'}`)
				setShow(false)
        getTeamsList()
			}
		} else {
		toastBuilder('error', 'Please select a user(s)')
		setShow(true)
		}
  }

  const handleUserSelect = (selectedUsersFromTable: any[]) => {
    setSelectedUsers(selectedUsersFromTable)
  }

  const onSearchTextChange = (searchText: string) => {
    if (searchText === '') {
      setRowsToShow(
        allUsers.map((user: any) => ({
          ...user,
        })),
      )
      return
    }
    setRowsToShow(
      allUsers
        .map((user: any) => ({
          ...user,
        }))
        .filter((user: any) =>
          `${user.email}`
            .toLowerCase()
            .includes(searchText.toLowerCase()),
        ),
    )
  }

	const onPaginationChange = (
		startOffSet: number,
		endOffSet: number,
	) => {
		setStartLimit(startOffSet)
		setEndLimit(endOffSet)
	}

  const isUserSelected = (row: ITeamMember) => {
    if (row) {
      return selectedUsers.map((user: any) => user._id).includes(row._id)
    }
    return false
  }

  return (
    <>
      <ModalAtom
        showModal={show}
        setShowModal={setShow}
        showCancelBtn={true}
        handleSave={onAddUser}
        buttonText={'Save'}
        closeOnOut={true}
      >
        <div className='h-[580px] w-[780px] laptop:w-[90vw] overflow-y-scroll overflow-x-hidden flex flex-col'>
          <div className='ml-6 mt-6'>
            <span className='font-medium'>{isManagerTeam ? 'Add Manager' : 'Add User'}</span>
          </div>
          <SelectableTable
            columns={columns}
            rows={userList}
            initialSelection={selectedUsers}
            selectionType={SELECTION_MODES.SELECT_MANY}
            onSelectionChange={handleUserSelect}
            onSearchChange={onSearchTextChange}
            isRowSelected={isUserSelected}
            borderTable={false}
            className='py-2'
            pagination={{
              pageSize: limitItems,
              handlePageChange: onPaginationChange,
              pageSizeOptions: [0],
              fetchedDataSize: userList.length,
              totalDataSize: (rowsToShow ? rowsToShow.length : 0),
              totalPages: Math.ceil(
                (rowsToShow ? rowsToShow.length : 0) / limitItems,
              ),
            }}
            limitItems={{
              setLimitItems: setLimitItems,
              totalItems: (rowsToShow ? rowsToShow.length : 0),
              startLimit: startLimit,
              endLimit: startLimit + userList.length - 1,
            }}
          />
      </div>
      </ModalAtom>
    </>
  )
}

export default AddUsersModal
