import imgUpgradePlan from 'src/assets/images/upgrade-plan.svg'
import IconRoutera from 'src/components/atoms/icons/icons'
import { useNavigate } from 'react-router-dom'

interface IProps {
  value: 'access' | 'read'
  classHeight?: string
  title?: string
  description?: string
  boldDescription?: string
  width?: string
}

const NoPermission: React.FC<IProps> = (props) => {
  const { value, classHeight, title, description, boldDescription, width } =
    props

  const navigate = useNavigate()

  const redirectBillingPage = () => {
    navigate('/settings/billing')
  }

  return (
    <div
      className={`flex items-center justify-center w-full bg-gray-50 ${
        classHeight ? classHeight : 'h-full'
      }`}
    >
      {value === 'access' ? (
        <div
          className={`bg-white p-6 rounded-lg ${
            width ? `h-[${width}]` : 'h-[270px]'
          } w-[672px] shadow-md`}
        >
          {!title && <img src={imgUpgradePlan} className='mb-3' />}
          <div className='px-3 rounded-md bg-routera-100 w-fit text-routera-800 flex items-center'>
            <IconRoutera
              iconName='LockClosedIcon'
              className='h-[14px] w-[14px]'
              aria-hidden='true'
            />
            <span className='ml-1 text-xs font-medium'>Upgrade to unlock</span>
          </div>

          {description && boldDescription ? (
            <>
              <span className='text-xl text-gray-900 font-extrabold mt-[10px] inline-block'>
                {title}
              </span>
              <p className='text-gray-500 leading-[21px] font-normal mt-[10px] text-sm'>
                {description}
                <span className='font-bold'> {boldDescription} </span>
              </p>
            </>
          ) : (
            <>
              <span className='text-xl text-gray-900 font-extrabold mt-[10px] inline-block'>
                Unlock all the Power
              </span>
              <p className='text-gray-500 leading-[21px] font-normal mt-[10px] text-sm'>
                Enjoy all the features without limitations, Dashboard, Advances
                Reports and more.
              </p>
            </>
          )}

          <p className='text-gray-500 leading-[21px] font-normal mt-[35px] text-sm mb-[1rem]'>
            To find out more about the different types plans, features and
            options please visit our
            <span className='text-gray-900 underline mb-[10px] ml-1'>
              Pricing
            </span>
            .
          </p>

          <button
            onClick={redirectBillingPage}
            className='btn btn-primary px-4'
          >
            Upgrade Plan
          </button>
        </div>
      ) : (
        <div className='bg-white p-4 w-fit rounded-lg'>
          <div className='px-3 rounded-md bg-orange-200 w-fit text-orange-700 flex items-center mt-3'>
            <IconRoutera
              iconName='HandIcon'
              className='h-[14px] w-[14px]'
              aria-hidden='true'
            />
            <span className='ml-1 text-xs font-medium'>
              Don't have access to this feature{' '}
            </span>
          </div>
          <span className='text-xl text-gray-900 font-extrabold mt-[10px] inline-block'>
            Permissions required
          </span>
          <p className='text-gray-500 leading-[21px] font-normal mt-[10px]'>
            You don't have access to this feature, please request access to the
            workspace owner.
          </p>
        </div>
      )}
    </div>
  )
}

export default NoPermission
