import Tabs from 'src/components/molecules/tabs/tabs'
import { DataTab } from 'src/types/tabs'
import { useEffect, useState } from 'react'
import UserSetting from 'src/components/organisms/profile/userSetting'
import { useActions } from 'src/actions'
import toastBuilder from 'src/utils/toastBuilder'
import AssignmentStatus from 'src/components/organisms/profile/assignmentStatus'
import WorkingHoursTabs from 'src/components/organisms/profile/workingHours'
import { DataDate, DateString } from 'src/types/profile'
import { isTimeSlotsValid } from 'src/components/organisms/time-slot-picker/helper'
import moment from 'moment'
import { TimeSlots } from 'src/components/organisms/time-slot-picker/TimeSlotPicker.Molecule'
import { useAppSelector } from 'src/store'
import { selectUser } from 'src/container/auth/auth.selector'
import { timeZonesNames } from '@vvo/tzdb'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'
import { useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'

const Profile: React.FC = () => {

  const ability = useAbility(AbilityContext)

  if (!ability.can('read', 'setting.profile.user_setting') && !ability.can('read', 'setting.profile.assignment_status') && !ability.can('read', 'setting.profile.working_hours')) {
    return <NoPermission value='read' />
  }

  const { updateuserDataAction, getUserDetailsAction } = useActions()
  const [dataDates, setDataDates] = useState<DataDate[]>([])
  const [workingHours, setWorkingHours] = useState<TimeSlots>({})
  const user = useAppSelector(selectUser)
  const [showWorkingHours, setShowWorkingHours] = useState<boolean>(true)
  const timeZones = timeZonesNames

  useEffect(() => {
    getUserDetailsAction()
  }, [])

  const location = useLocation()

  const [userTimeZone, setuserTimeZone] = useState(
    timeZones.find((stdTz) => {
      return stdTz.toLowerCase().includes(user.timeZone?.toLowerCase())
    }) || timeZones[0],
  )

  useEffect(() => {
    const datesUser: DataDate[] = []
    user.outOfficeDates &&
      user.outOfficeDates.forEach((element: any) => {
        const startDate = DateTime.fromISO(element.startDate).setZone(user.timeZone).toUTC();
        const endDate = DateTime.fromISO(element.endDate).setZone(user.timeZone).toUTC();

        datesUser.push({
          endDate: endDate.toJSDate(),
          startDate: startDate.toJSDate(),
        })
      })
    setDataDates(datesUser)
    setuserTimeZone(
      timeZones.find((stdTz) => {
        return stdTz.toLowerCase().includes(user.timeZone?.toLowerCase())
      }) || timeZones[0],
    )
  }, [user])

  const isDateOverlapping = (): boolean => {
    let isDateOverLapping = false
    dataDates.forEach((element1, index1) => {
      dataDates.forEach((element2, index2) => {
        if (index1 !== index2) {
          if (
            (element1.startDate <= element2.startDate &&
              element2.endDate <= element1.endDate) ||
            (element1.startDate <= element2.startDate &&
              element2.startDate <= element1.endDate) ||
            (element1.startDate <= element2.endDate &&
              element2.endDate <= element1.endDate) ||
            (element1.startDate >= element2.startDate &&
              element2.endDate >= element1.endDate)
          ) {
            isDateOverLapping = true
          }
        }
      })
    })
    return isDateOverLapping
  }

  const getDate = () => {
    const dates: DateString[] = []
    dataDates.forEach((element) => {
        element.startDate.setMilliseconds(0);
        element.endDate.setMilliseconds(0);
        const startDate = DateTime.fromISO(element.startDate.toISOString()).setZone(user.timeZone).toUTC();
        const endDate = DateTime.fromISO(element.endDate.toISOString()).setZone(user.timeZone).toUTC();

      dates.push({
        startDate: startDate.toISO(),
        endDate: endDate.toISO(),
      })
    })
    return [dates, isDateOverlapping()]
  }

  const getWorkingHours247 = () => {
    return {
      'Monday': [{ startTime: "00:00", endTime: '23:59' }],
      'Tuesday': [{ startTime: "00:00", endTime: '23:59' }],
      'Wednesday': [{ startTime: "00:00", endTime: '23:59' }],
      'Thursday': [{ startTime: "00:00", endTime: '23:59' }],
      'Friday': [{ startTime: "00:00", endTime: '23:59' }],
      'Saturday': [{ startTime: "00:00", endTime: '23:59' }],
      'Sunday': [{ startTime: "00:00", endTime: '23:59' }],
    }
  }

  const handleFormSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any,
  ) => {
    if (getDate()[1]) {
      toastBuilder('error', 'Time slot seem to overlap. Please check')
      return
    }

    const { valid, msg } = isTimeSlotsValid(workingHours)
    if (!valid) {
      toastBuilder(
        'error',
        msg || 'Working hours are not setup right. Please check',
      )
      return
    }

    let dataWorkingHours: TimeSlots = workingHours

    if (!showWorkingHours) {
      dataWorkingHours = getWorkingHours247()
    }

    const action: any = await updateuserDataAction({
      ...formValues,
      timeZone: userTimeZone,
      workingHours: dataWorkingHours,
      outOfficeDates: getDate()[0],
    })

    if (action.payload.success) {
      toastBuilder('success', 'Profile information updated successfully')
      setSubmitting(false)
      resetForm()
    }
  }

  const idForm = 'userForm'

  const dataTabs: DataTab[] = [
    {
      label: 'User Setting',
      id: 'user-setting',
      dataContentTab: (
        <>
          {!ability.can('read', 'setting.profile.user_setting') && <NoPermission value='read' />}
          <UserSetting
            saveForm={handleFormSubmit}
            idForm={idForm}
            user={user}
            userTimeZone={userTimeZone}
            timeZones={timeZones}
            setuserTimeZone={setuserTimeZone}
          />
        </>

      ),
    },
    {
      label: 'Assignment Status',
      id: 'assignment-status',
      dataContentTab: <AssignmentStatus user={user} />
    },
    {
      label: 'Working Hours',
      id: 'working-hours',
      dataContentTab: (
        <>
          <WorkingHoursTabs
            saveForm={handleFormSubmit}
            idForm={idForm}
            user={user}
            dataDates={dataDates}
            setDataDates={setDataDates}
            setWorkingHours={setWorkingHours}
            workingHours={workingHours}
            setShowWorkingHours={setShowWorkingHours}
            showWorkingHours={showWorkingHours}
          />
        </>
      ),
    },
  ]

  return (
    <section className='border-l border-gray-200'>
      <div className='p-4 px-5 bg-gray-100'>
        <h3 className='text-2xl block'>Settings</h3>
      </div>

      <Tabs dataTabs={dataTabs} classContentTab='min-h-[calc(100vh-260px)] bg-routera-gray' />
      <div className='py-[8px] -my-[6px] px-4 bg-white'>
        <button
          type='submit'
          form={idForm}
          className='btn btn-primary px-4 d-block ms-auto w-[143px] bg-routera-primary'
          disabled={!ability.can('update', 'setting.profile.user_setting') && !ability.can('update', 'setting.profile.working_hours') && !ability.can('update', 'setting.profile.out_office_dates')}
        >
          Save
        </button>
      </div>
    </section>
  )
}

export default Profile
