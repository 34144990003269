import { combineReducers } from '@reduxjs/toolkit'
import userstate, { logout } from './container/auth/auth.slice'
import integrationsState from './container/integrations/integrations.slice'
import loaderstate from './container/Loader/loader.slice'
import customerstate from './container/my_account/account.slice'
import leadRouterState from './container/leadRouter/leadRoutes.slice'
import routerDetailState from './container/RouterDetail/routerDetail.slice'
import hubsport_reducer from './container/Hubspot/hubsport.slice'
import basicState from './container/Basic/basic.slice'
import slaState from './container/SLA/SLA.slice'
import planState from './container/Plan/plan.slice'
import conversionsState from './container/conversions/conversions.slice'
import dashboardState from './container/dashboard/dashboard.slice'
import home_reducer from './container/Home/home.slice'
import rolesState from './container/roles/roles.slice'
import teamsState from './container/users/teams.slice'

const appReducer = combineReducers({
  integrationsState,
  userstate,
  loaderstate,
  customerstate,
  leadRouterState,
  routerDetailState,
  hubsport_reducer,
  basicState,
  slaState,
  planState,
  conversionsState,
  dashboardState,
  home_reducer,
  rolesState,
  teamsState
})

const rootReducer = (state: ReduceParams[0], action: ReduceParams[1]) => {
  if (action.type === logout.fulfilled.toString()) {
    localStorage.clear()

    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer

type ReduceParams = Parameters<typeof appReducer>
