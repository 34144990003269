import React, { useState } from 'react'
import { Form, Modal, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import { useActions } from '../../../actions'
import toastBuilder from '../../../utils/toastBuilder'
import { createTeamValidation } from '../../../validations/myAccount'
import { getrunagain } from '../../../container/Loader/loader.selector'
import { useAppSelector } from '../../../store'
import { useDispatch } from 'react-redux'
import { setrunagain } from '../../../container/Loader/loader.slice'

const CreateTeam: React.FC<{ showModalTeam: boolean; setShowModalTeam: any }> = (props) => {
  const { showModalTeam, setShowModalTeam } = props
  const [loading, setLoading] = useState(false)
  const runagain = useAppSelector(getrunagain)
  const dispatch = useDispatch()
  const { createTeam } = useActions()

  const handleFormSubmit = async (
    values: { name: string },
    { resetForm, setSubmitting }: any
    ) => {
      
      const payload = {
        name: values.name,
      }
      
    setLoading(true)
    const response: any = await createTeam(payload)

    if (response.payload.success) {
      setLoading(false) 
      toastBuilder('success', 'Team created successfully')
      setShowModalTeam(false)
      dispatch(setrunagain(!runagain))
      resetForm()
    }

    setSubmitting(false)
  }

  const initialValue = {
    name: '',
  }

  return (
    <>
      {showModalTeam ? (
        <Modal show={true} onHide={() => setShowModalTeam(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>New Team</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValue}
              validationSchema={createTeamValidation}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                dirty,
                isSubmitting,
                errors,
              }) => {
                return (
                  <Form className='' onSubmit={handleSubmit}>
                    <div className='mainform accountform mt-0 mb-0'>
                      <div className='mb-4'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          className='input border border-slate-100'
                          type='text'
                          value={values.name}
                          onBlur={handleBlur}
                          name='name'
                          onChange={handleChange}
                          isInvalid={!!errors.name && touched.name}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {touched.name && errors.name}
                        </Form.Control.Feedback>
                      </div>
                      <div>
                        <Button
                          type='submit'
                          className='btn btn-primary float-end mb-3 mt-2'
                          disabled= {!dirty || isSubmitting}
                          >
                          {!loading ? (
                            <>
                              Add Team
                            </>
                          ) : (
                            <div className='spinner-border spinner-border-sm' role='status' />
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  )
}

export default CreateTeam
