import { Dispatch, SetStateAction } from 'react'

interface Props {
  searchText: string
  setSearchText: Dispatch<SetStateAction<string>>
}

const SearchInput = ({ searchText, setSearchText }: Props) => {
  return (
    <div className='pb-4 bg-gray-50 dark:bg-gray-900 p-4'>
      <label htmlFor='table-search font-normal' className='sr-only'>
        Search
      </label>
      <div className='relative mt-1'>
        <input
          type='text'
          name='search'
          id='search'
          value={searchText}
          onChange={(evt) => {
            setSearchText(evt.currentTarget.value)
          }}
          className='block pl-[45px] text-[14px] font-normal text-gray-500 border border-gray-300 rounded-lg w-80 bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
          placeholder='Search'
        />
        <div className='absolute inset-y-0 right-0 h-full pr-3 flex justify-center items-center'>
          <i className='fa-solid fa-magnifying-glass'></i>
        </div>
      </div>
    </div>
  )
}

export default SearchInput
