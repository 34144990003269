import { createAsyncThunk } from '@reduxjs/toolkit'
import { conversionsService } from 'src/services/conversions.service'
import { Conversions } from 'src/types/conversions'

enum actiontype {
  getConversionsList = 'getConversionsList',
  getConversionById = 'getConversionById',
  createConversion = 'createConversion',
  updateConversion = 'updateConversion',
  deleteConversion = 'deleteConversion',
}

export const getConversionsList = createAsyncThunk(
  actiontype.getConversionsList,
  async () => {
    const resp = await conversionsService.getConversionsList()
    return resp.data as Conversions[]
  },
)

export const getConversionById = createAsyncThunk(
  actiontype.getConversionById,
  async (conversionId: string) => {
    const resp = await conversionsService.getConversionById(conversionId)
    return resp.data as Conversions
  },
)

export const createConversion = createAsyncThunk(
  actiontype.createConversion,
  async (payload: any) => {
    return await conversionsService.createConversion(payload)
  },
)

export const updateConversion = createAsyncThunk(
  actiontype.updateConversion,
  async (payload: { id: string; data: any }) => {
    return await conversionsService.updateConversion(payload.id, payload.data)
  },
)

export const deleteConversion = createAsyncThunk(
  actiontype.deleteConversion,
  async (data: string[]) => {
    return await conversionsService.deleteConversion(data)
  },
)
