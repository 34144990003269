import IconRoutera from "src/components/atoms/icons/icons"

interface IProps {
	editName: boolean
	setName: (value: string) => void
	name: string
	setEditName: (value: boolean) => void
	editNameChange: () => void
	className?: string
	placeholder?: string
	loading?: boolean
	disabled?: boolean
}

const EditName: React.FC<IProps> = (props) => {

	const {
		editName,
		name,
		setEditName,
		setName,
		editNameChange,
		className,
		placeholder,
		loading = false,
		disabled = false
	} = props

	const changeName = () => {
		setEditName(true)
	}

	return (
		<div className={className}>
			{!editName ? (
				<div className='flex border-b-2 w-[fit-content] mx-4 pb-2'>
					<span className='mr-[30px] mobile:mr-0 text-gray-500 text-lg leading-[27px]'>
						{name.substring(0, 30)}{' '}
						{name.length > 30 && '...'}
					</span>
					<IconRoutera
						iconName='PencilIcon'
						className={`h-[45px] w-[45px] text-gray-500 ${loading || disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
						onClick={disabled ? () => { return } : (!loading ? changeName : () => { '' })}
					/>
				</div>
			) : (
				<div className='flex border-b-2 w-[fit-content] mx-4 pb-2'>
					<input
						type='text'
						className='bg-gray-100 w-[280px] mobile:max-w-[155px] border-0 focus:ring-0 text-gray-700 text-lg leading-[27px]'
						id='edit-name'
						placeholder={placeholder ? placeholder : name}
						value={name}
						onChange={(e) => {
							setName(e.target.value)
						}}
						required
					/>
					<button
						className='px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start'
						onClick={editNameChange}
					>
						<span className='inline-block text-[14px]'>Save</span>
					</button>
				</div>
			)}
		</div>
	)
}

export default EditName