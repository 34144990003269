import { createAsyncThunk } from '@reduxjs/toolkit'
import { DashboardService } from 'src/services/dashboard.service'
import { DashbordData, DashbordStat } from '../../types/Ihome_state'

enum actiontype {
  leadinfo = 'leadinfo action type',
  delete_dashbord_item = 'delete_dashbord_item action type',
  dashboard_stats = 'DASHBOART_STATS',
}

export const get_dashboard_data_action = createAsyncThunk(
  actiontype.leadinfo,
  async (payload: any, thunk_api) => {
    const result = await DashboardService.get_dashbard_data(payload)
    if (result) return result.data as DashbordData
    else thunk_api.rejectWithValue('')
  },
)

export const get_dashboard_stats_action = createAsyncThunk(
  actiontype.dashboard_stats,
  async (payload: any, thunk_api) => {
    const result = await DashboardService.get_dashbard_stats(payload)
    if (result) return result.data as DashbordStat
    else thunk_api.rejectWithValue('')
  },
)

export const delete_dashbord_item = createAsyncThunk(
  actiontype.delete_dashbord_item,
  async (payload: { leadsIdsArray: string[] }, thunk_api) => {
    const result = await DashboardService.delete_dashbard_item(payload)
    if (result) return result.data as Array<DashbordData>
    else thunk_api.rejectWithValue('')
  },
)
