import { useState, useEffect, useMemo, useRef } from 'react'
import 'react-phone-input-2/lib/style.css'
import TeamMemberList from '../../components/LeadRouterDetail/TeamMembersList'
import DistributionModel from '../../components/LeadRouterDetail/DistributionModel'
import ReviewAndPublish from '../../components/LeadRouterDetail/ReviewAndPublish'
import { useNavigate, useParams } from 'react-router-dom'
import { useActions } from '../../actions'
import { useAppSelector } from '../../store'
import {
  useListOfAllTeamMembers,
  usespecificRouterDetail,
} from './routerDetail.selector'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import EditRouterName from '../../components/LeadRouterDetail/Edit_router_name'
import toastBuilder from '../../utils/toastBuilder'
import { PERCENTAGE_MODEL } from '../../config/constants'
import Triggers from '../../components/LeadRouterDetail/Triggers'
import SLA from '../../components/LeadRouterDetail/SLA'
import AccountMatching from '../../components/LeadRouterDetail/AccountMatching'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import { User } from 'src/components/LeadRouterDetail/TeamMembersList/types'
import { listAllTeams } from '../users/teams.selector'
import { getAccountById } from 'src/container/my_account/account.selector'
import { selectUser } from 'src/container/auth/auth.selector'
import { config } from 'src/config/constants'

const { FREE_PLAN_MONTHLY } = config

const LeadRouterDetail = () => {
  const {
    getTeamMembersList,
    assign_modelAction,
    getAllSources,
    getspecificRouterDetail,
    updateAssignedSourcesList,
    AssignedUserAction,
    publish_router,
    edit_router_action,
    get_Specific_Source_action,
    delete_soruce,
    getIntegrations,
    getAllSLAsAction,
    getTeamsList,
    getAccount,
  } = useActions()

  const [hasMounted, setHasMounted] = useState(false)
  const [tabDisabled, setTabDisabled] = useState([true, true, true, true, true])
  const [nextDisabled, setNextDisabled] = useState(false)
  const parentRef = useRef()
  const [usersModel, setUsersModel] = useState<User[]>()
  const [orderedUsersModel, setOrderedUsersModel] = useState<User[]>([])
  const [objectType, setObjectType] = useState<string>()
  const [freePlan, setFreePlan] = useState(false)

  const { routerId = '', tab = '' } = useParams()

  const tabsArray = [
    'triggers',
    'users',
    'model',
    'sla',
    'account-matching',
    'review-publish',
  ]

  useEffect(() => {
    if (!hasMounted) {
      getIntegrations()
      getspecificRouterDetail(routerId)
      setHasMounted(true)
      setObjectType(undefined)
    }

    if (!hasMounted && ability.can('access', 'router.sla')) {
      getAllSLAsAction()
    }
  }, [hasMounted])

  const TeamMembers = useAppSelector(useListOfAllTeamMembers)
  const allTeams = useAppSelector(listAllTeams)
  const account = useAppSelector(getAccountById)
  const currentUser = useAppSelector(selectUser)

  const specificRouterDetail = useAppSelector(usespecificRouterDetail)

  const assigned_user_full_details = useMemo(
    () => specificRouterDetail.assignedUsersInfo,
    [specificRouterDetail.assignedUsersInfo.length],
  )

  const ability = useAbility(AbilityContext)

  const [activeTab, setActiveTab] = useState(tab)
  //showing webhook address page
  const [web_hook, setweb_hook] = useState<leadpage>({
    type: 'webhook',
    id: '',
  })

  const [percentagemodel, setpercentagemodel] = useState<{
    model: string
    error: boolean
  }>({
    model: specificRouterDetail?.assignedModel ?? '',
    error: false,
  })

  const [edit_router_name, set_edit_router_name] = useState<{
    error: boolean
    edit: boolean
  }>({ edit: false, error: false })

  const navigate = useNavigate()

  useEffect(() => {
    setpercentagemodel({
      model: specificRouterDetail?.assignedModel ?? '',
      error: false,
    })
  }, [specificRouterDetail])

  useEffect(() => {
    // set nextDisabled to false on change of model type
    setNextDisabled(false)
  }, [percentagemodel])

  useEffect(() => {
    ;(async () => {
      if (web_hook.id) {
        get_Specific_Source_action(web_hook.id)
      }

      if (activeTab === 'users') {
        await getTeamMembersList()
        await getTeamsList()
        AssignedUserAction(routerId)
      } else if (activeTab === 'model') {
        await getDataUsersModel()
      } else if (activeTab === 'review-publish') {
        // Noop
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    })()
  }, [activeTab, web_hook])

  const getDataUsersModel = async () => {
    const dataUsers: any = await AssignedUserAction(routerId)
    setUsersModel(dataUsers.payload)
  }

  useEffect(() => setActiveTab(tab), [tab])
  useEffect(() => getIfTabDisabled(), [specificRouterDetail])

  useEffect(() => {
    if (usersModel && specificRouterDetail._id !== '') {
      const valueUsersModel: User[] = []
      specificRouterDetail.assignedUsers.forEach((item) => {
        const value = usersModel.find((user: User) => item.userId === user._id)
        if (value) {
          valueUsersModel.push(value)
        }
      })
      setOrderedUsersModel(valueUsersModel)
    }
  }, [usersModel, specificRouterDetail])

  const onSaveDistributionModel = async (
    current_percentage: any,
    percentage: Array<{ userId: string; percentage: number }>,
  ) => {
    if (!percentage.length) {
      toastBuilder('error', 'No assigned users')
      return true
    }
    if (!percentagemodel.model) {
      toastBuilder('error', 'Please select a model')
      return true
    }
    if (
      percentagemodel.model === PERCENTAGE_MODEL.PERCENTAGE &&
      percentage.length &&
      !freePlan
    ) {
      let sum = 0
      for (const val of percentage) {
        sum += val.percentage
      }
      if (Math.round(sum) !== 100) {
        toastBuilder(
          'error',
          'Please make sure the distribution tallies to 100%',
        )
        return true
      }
    }

    if (
      percentagemodel.model === PERCENTAGE_MODEL.PERCENTAGE &&
      percentage.length &&
      freePlan
    ) {
      toastBuilder(
        'error',
        'This model is not available for your current plan, Please select another.',
      )
      return true
    }

    const updatedOrderNum = percentage.map((p, index) => {
      return {
        ...p,
        order: index,
      }
    })
    let isModelUpdate = false // check if need to update or not
    if (specificRouterDetail?.assignedModel !== percentagemodel.model)
      isModelUpdate = true
    specificRouterDetail?.assignedUsers.forEach((assignedUser) => {
      const newValue = updatedOrderNum.find(
        (val) => val.userId === assignedUser.userId,
      )
      if (specificRouterDetail?.assignedModel === PERCENTAGE_MODEL.ROUNDROBIN) {
        // @ts-ignore
        if (assignedUser?.userStrategyData?.order !== newValue?.order)
          isModelUpdate = true
      } else if (
        specificRouterDetail?.assignedModel === PERCENTAGE_MODEL.PERCENTAGE
      ) {
        // @ts-ignore
        if (assignedUser?.userStrategyData?.percentage !== newValue?.percentage)
          isModelUpdate = true
      }
    })
    let result: any
    if (isModelUpdate) {
      result = await assign_modelAction({
        routerId: routerId,
        modelName: percentagemodel.model,
        assignedUsers: updatedOrderNum,
      })
    }

    if (!isModelUpdate || result.payload) {
      setTabDisabled([false, false, false, false, false])
      navigate(`/routers/${routerId}/account-matching`)
    } else {
      navigate(`/routers/${routerId}/review-publish`)
    }
  }

  const onSaveReviewandpublish = async () => {
    const result: any = await publish_router({ routerId })
    if (result.payload) {
      navigate(`/routers`)
      toastBuilder('success', 'Router published successfully')
    }
  }

  const onTabSelect = (newTab: string | null) => {
    if (newTab !== activeTab) setNextDisabled(false) // to revert nextDisabled on change of tab
    if (newTab) navigate(`/routers/${routerId}/${newTab}`)
  }

  const handle_router_save_click = async (text: string) => {
    if (!text) {
      return set_edit_router_name({
        ...edit_router_name,
        error: true,
      })
    }
    const result: any = await edit_router_action({
      routerId,
      name: text,
    })
    if (result.payload) {
      set_edit_router_name({
        ...edit_router_name,
        edit: false,
        error: false,
      })
    }
  }

  const delete_source = async (data: string[]) => {
    const payload = { sourcesIdsArray: data }
    const result: any = await delete_soruce(payload)
    if (result.payload) {
      toastBuilder('success', 'soruce deleted successfully')
      getAllSources(routerId)
    }
  }

  const getIfTabDisabled = () => {
    const disabledArray = [true, true, true, true, true]
    if (
      specificRouterDetail.trigger_metadata ||
      specificRouterDetail.trigger_type
    ) {
      disabledArray[0] = false
    }
    if (specificRouterDetail?.assignedUsers.length > 0) {
      disabledArray[1] = false
    }
    if (specificRouterDetail.assignedModel !== '') {
      disabledArray[2] = false
      disabledArray[3] = false
      disabledArray[4] = false
    }
    setTabDisabled(disabledArray)
  }

  const onNextTab = async () => {
    const indexOfCurrentTab = tabsArray.indexOf(activeTab)
    let isNextDisabled = false
    if (indexOfCurrentTab === 0) {
      if (!specificRouterDetail?.trigger_metadata) {
        toastBuilder('error', 'Please create a trigger')
      } else {
        navigate(`/routers/${routerId}/${tabsArray[indexOfCurrentTab + 1]}`)
      }
    } else {
      if (!parentRef.current) {
        navigate(`/routers/${routerId}/${tabsArray[indexOfCurrentTab + 1]}`)
        return
      }
      // @ts-ignore
      isNextDisabled = await parentRef.current.onRefSave() // add useImperativeHandle in every tab
    }
  }

  const onPrevTab = () => {
    const indexOfCurrentTab = tabsArray.indexOf(activeTab)
    if (indexOfCurrentTab < tabsArray.length) {
      setNextDisabled(false)
      navigate(`/routers/${routerId}/${tabsArray[indexOfCurrentTab - 1]}`)
    }
  }

  useEffect(() => {
    if (currentUser && currentUser.account_id) {
      getAccount(currentUser.account_id)
    }
  }, [])

  useEffect(() => {
    if (account && account._id) {
      if (account.plan_id === FREE_PLAN_MONTHLY) {
        setFreePlan(true)
      }
    }
  }, [account])

  useEffect(() => {
    if (
      specificRouterDetail &&
      specificRouterDetail.trigger_metadata &&
      specificRouterDetail.trigger_metadata.objectType
    ) {
      setObjectType(specificRouterDetail.trigger_metadata.objectType)
    }
  }, [
    specificRouterDetail &&
      specificRouterDetail.trigger_metadata &&
      specificRouterDetail.trigger_metadata?.objectType,
  ])

  useEffect(() => {
    if (
      ability.can('access', 'router.sla') &&
      specificRouterDetail &&
      specificRouterDetail.trigger_type === 'WORKFLOW_ACTION'
    ) {
      getAllSLAsAction(undefined)
    }
  }, [specificRouterDetail])

  useEffect(() => {
    if (ability.can('access', 'router.sla') && objectType) {
      getAllSLAsAction(objectType)
    }
  }, [objectType])

  return (
    <section className='flex flex-col justify-between bg-routera-gray min-h-full'>
      <div className='mb-[60px]'>
        <div className='h-[67px] w-full justify-start items-center flex px-4'>
          <span className='font-semibold text-2xl'>Routers</span>
        </div>
        <Container fluid className='custom-padding bg-routera-gray px-4'>
          <Row>
            <Col md={12} className='lead-subNav'>
              <EditRouterName
                edit_router_name={edit_router_name}
                handle_router_save_click={handle_router_save_click}
                set_edit_router_name={set_edit_router_name}
                disabled={!ability.can('update', 'router')}
              />
              <Tabs
                activeKey={activeTab}
                id='uncontrolled-tab-example'
                onSelect={(newTab) => onTabSelect(newTab)}
                className='bluebordertabs'
              >
                <Tab eventKey='triggers' title='Triggers'></Tab>
                <Tab
                  eventKey='users'
                  title='Users'
                  //   @ts-ignore
                  className={`'nav-disabled'}`}
                  disabled={tabDisabled[0]}
                ></Tab>

                <Tab
                  eventKey='model'
                  title='Model'
                  disabled={tabDisabled[1]}
                ></Tab>
                <Tab
                  eventKey='account-matching'
                  title='Account Matching'
                  disabled={tabDisabled[2]}
                ></Tab>
                <Tab
                  eventKey='review-publish'
                  title='Review & Publish'
                  disabled={tabDisabled[3]}
                ></Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>

        <div className='mr-[32px] ml-[25px]'>
          <Row>
            <Col md={12}>
              {activeTab === 'users' ? (
                <TeamMemberList
                  teamMembers={TeamMembers}
                  specificRouterDetail={specificRouterDetail}
                  assignedUsersList={assigned_user_full_details}
                  teams={allTeams}
                  // @ts-ignore
                  ref={parentRef}
                />
              ) : activeTab === 'model' ? (
                <DistributionModel
                  onSave={onSaveDistributionModel}
                  percentagemodel={percentagemodel}
                  setpercentagemodel={setpercentagemodel}
                  assigned_user_full_details={orderedUsersModel}
                  specificRouterDetail={specificRouterDetail}
                  setNextDisabled={setNextDisabled}
                  isFreePlan={freePlan}
                  // @ts-ignore
                  ref={parentRef}
                />
              ) : activeTab === 'review-publish' ? (
                <ReviewAndPublish
                  onSave={onSaveReviewandpublish}
                  specificRouterDetail={specificRouterDetail}
                  // @ts-ignore
                  ref={parentRef}
                />
              ) : activeTab === 'triggers' ? (
                <Triggers />
              ) : activeTab === 'account-matching' ? (
                <AccountMatching ref={parentRef} />
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
      {/* footer */}
      <div className='flex flex-shrink-0 w-full justify-between px-4 py-[0.95rem] bg-white fixed bottom-0 z-30'>
        {tabsArray.indexOf(activeTab) !== 0 ? (
          <button
            type='button'
            className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#0062FF] focus:ring-offset-2'
            onClick={onPrevTab}
          >
            Back
          </button>
        ) : (
          <div />
        )}

        <button
          type='submit'
          className={`ml-8 w-[${
            activeTab === 'review-publish' ? '11rem' : '9rem'
          }] inline-flex justify-center rounded-md border border-transparent bg-[#0062FF] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#0062FF] focus:outline-none focus:ring-2 focus:ring-[#0062FF] focus:ring-offset-2 ${
            (nextDisabled || !ability.can('update', 'router')) &&
            'cursor-not-allowed'
          }`}
          onClick={
            !ability.can('update', 'router') && activeTab === 'review-publish'
              ? () => {
                  return
                }
              : onNextTab
          }
          disabled={
            nextDisabled ||
            !ability.can('update', 'router') ||
            (activeTab === 'review-publish' &&
              !specificRouterDetail.assignedUsers.length)
          }
        >
          {activeTab === 'review-publish' ? 'Save & Publish' : 'Next'}
        </button>
      </div>
    </section>
  )
}

export default LeadRouterDetail
