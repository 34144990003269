import React, { useEffect, useState } from 'react'
import { timeformater } from '../../utils'
import { IRoute } from '../../types/leadRoutes'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../store'
import toastBuilder from 'src/utils/toastBuilder'
import { useActions } from '../../actions'
import SelectableTable, {
  SELECTION_MODES,
  TableColumn,
} from '../organisms/tables/SelectableTable.Organism'
import { IRouterDetail } from 'src/types/routerDetail'
import { useListOfAllTeamMembers } from 'src/container/RouterDetail/routerDetail.selector'
import { TriggerTypeToText } from 'src/types/triggers'
import { User } from 'src/types/customers'
import Avatar from 'src/components/atoms/avatar/Avatar.Atom'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import { getAccountById } from 'src/container/my_account/account.selector'
import { selectUser } from 'src/container/auth/auth.selector'
import { config } from 'src/config/constants'

const { FREE_PLAN_MONTHLY } = config

const zIndex = [30, 20, 10, 0]

const LeadRoutesTable: React.FC<{ setShow: any; allRoutes: IRoute[] }> = (
  props,
) => {
  const { setShow, allRoutes } = props

  const [rowsToShow, setRowsToShow] = useState<IRoute[]>(allRoutes)
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const {
    Delete_bulk_routes,
    getallRoutes,
    getTeamMembersList,
    unpublishrouter,
    getAccount,
  } = useActions()
  const ability = useAbility(AbilityContext)
  const teamMembers = useAppSelector(useListOfAllTeamMembers)
  const account = useAppSelector(getAccountById)
  const currentUser = useAppSelector(selectUser)

  const [freePlan, setFreePlan] = useState(false)

  useEffect(() => {
    if (currentUser && currentUser.account_id) {
      getAccount(currentUser.account_id)
    }
  }, [])

  useEffect(() => {
    if (account && account._id) {
      if (account.plan_id === FREE_PLAN_MONTHLY) {
        setFreePlan(true)
      }
    }
  }, [account])

  useEffect(() => {
    getTeamMembersList()
  }, [])

  useEffect(() => {
    setRowsToShow(allRoutes)
  }, [allRoutes])

  const onSearchTextChange = (searchText: string) => {
    // FIXME - Change this to server side search as per need
    setIsSearch(true)
    if (searchText === '') {
      setRowsToShow(allRoutes)
      setIsSearch(false)
      return
    }
    setRowsToShow(
      allRoutes.filter((route) =>
        route.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    )
  }

  const columnsInRouterTable: TableColumn[] = [
    {
      label: 'Status',
      field: 'isPublished',
      render: ({ isPublished }: any) => {
        return (
          <div className='mb-0 name-with_status ml-3'>
            {isPublished ? (
              <div className='py-[4px] px-[12px] bg-green-100 flex items-center rounded-[99px]'>
                <div className='bg-green-500 w-[12px] h-[12px] rounded mr-2' />
                <span className='text-green-800 text-xs font-medium'>
                  Published
                </span>
              </div>
            ) : (
              <div className='py-[4px] px-[12px] bg-red-100 flex items-center rounded-[99px]'>
                <div className='bg-red-500 w-[12px] h-[12px] rounded mr-2' />
                <span className='text-red-800 text-xs font-medium'>Draft</span>
              </div>
            )}
          </div>
        )
      },
    },
    {
      label: 'Name',
      render: ({ _id, name, isPublished }: any) => {
        return (
          <div>
            <div className='mb-0 name-with_status' style={{ color: '#44444f' }}>
              <Link to={`/routers/${_id}/triggers`}>
                <div className='text-blue-500'>{name}</div>
              </Link>
            </div>
          </div>
        )
      },
    },
    {
      label: 'Users',
      render: (rowData: any) => {
        const assignedUserIds = rowData.assignedUsers.map(
          (assignedUser: any) => assignedUser.userId,
        )
        const assignedUsers = teamMembers.filter((teamMember) =>
          assignedUserIds.includes(teamMember.id),
        )
        return (
          <div className='flex -space-x-4 w-[113px]'>
            {assignedUsers.length > 0 &&
              assignedUsers.map(
                (data: User, index: number) =>
                  index < 4 && (
                    <Avatar
                      key={'dataUsers ' + data._id + zIndex[index]}
                      className={
                        'w-[32px] h-[32px] dark:border-gray-800 mr-[2px] border-2 border-white rounded-full'
                      }
                      firstName={data.firstName}
                      classDiv={`z-${zIndex[index]}`}
                      lastName={data.lastName}
                      imgURL={data.avatar}
                      available={{
                        userAvailability: data.userAvailability,
                        officeStatus: data.office_status
                          ? data.office_status
                          : false,
                      }}
                    />
                  ),
              )}
          </div>
        )
      },
    },
    {
      label: 'Type',
      field: 'triggerType',
      render: ({ trigger_type }: IRouterDetail) => {
        return (
          <div className=''>
            {/* @ts-ignore */}
            {trigger_type && TriggerTypeToText[trigger_type]}
          </div>
        )
      },
    },
    {
      label: 'Updated',
      render: ({ updated_by, updatedAt }: IRouterDetail) => {
        let message

        if (updated_by && updated_by.length > 0) {
          message = `Updated by ${updated_by} at ${timeformater(updatedAt)}`
        } else {
          message = `${timeformater(updatedAt)}`
        }

        return <div>{message}</div>
      },
    },
  ]

  const handleDelete = async (routerList: Array<any>) => {
    await Delete_bulk_routes(routerList.map((router) => router._id))
    getallRoutes()
  }

  const handleUnpublish = async (routerList: Array<any>) => {
    for (const router of routerList) {
      await unpublishrouter({ routerId: router._id })
    }
    toastBuilder(
      'success',
      routerList.length + ' Router(s) unpublished successfully',
    )
    getallRoutes()
  }

  const createRouterModal = () => {
    if (freePlan && allRoutes && allRoutes.length >= account.max_routers) {
      toastBuilder(
        'error',
        'Account has reached the maximum capacity of your routers. Please update your plan',
      )
    } else {
      setShow(true)
    }
  }

  const createRouter: JSX.Element = (
    <button
      className={`flex flex-row items-center px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start ${
        !ability.can('create', 'router') && 'cursor-not-allowed'
      }`}
      onClick={createRouterModal}
      disabled={!ability.can('create', 'router')}
    >
      Create Router
    </button>
  )

  return (
    <div className='px-4 mb-5 min-h-[calc(100vh-275px)]'>
      {rowsToShow.length > 0 || isSearch ? (
        <div className='mt-2 w-full relative'>
          <div className='section-header w-full flex justify-end'>
            <div className='flex items-center justify-between w-full'>
              <span className='text-gray-700 text-[18px] font-medium leading-[27px]'>
                Router List
              </span>
              {createRouter}
            </div>
          </div>
          <SelectableTable
            columns={columnsInRouterTable}
            rows={rowsToShow}
            initialSelection={[]}
            selectionType={SELECTION_MODES.SELECT_MANY}
            onSearchChange={onSearchTextChange}
            onDelete={handleDelete}
            unpublish={handleUnpublish}
            permissions={{
              delete: ability.can('delete', 'router'),
              unPublish: ability.can('update', 'router'),
              update: true,
            }}
          />
        </div>
      ) : (
        <div className='flex flex-col border-dashed border-4 border-gray-200 rounded-lg align-items-center justify-center mt-12 py-16 h-[70vh]'>
          <span className='font-semibold text-base mb-3'>
            No Routers created
          </span>
          <div className='mx-auto'>{createRouter}</div>
        </div>
      )}
    </div>
  )
}

export default LeadRoutesTable
